const modalUserStyle = theme => ({
  paper: {
    marginTop: theme?.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme?.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme?.shadows[5],
    padding: theme?.spacing(2, 4, 3),
    minHeight: 200,
  },
  avatar: {
    margin: theme?.spacing(1),
    backgroundColor: theme?.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme?.spacing(3),
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    backgroundColor: theme?.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme?.shadows[5],
    padding: theme?.spacing(2, 4, 3),
    minHeight: 350,
    minWidth: 550,
    width: "50%",
    height: "60%",
  },
  submit: {
    margin: theme?.spacing(3, 0, 2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerModal: {
    display: "flex",
    // flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "flex-end",
    marginBottom: 30,
    flexDirection: "columnn",
    alignItems: "start"
  },
  addAndPaginate: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  autocomplete: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "25%",
  },
  filterUsers: {
    display: "flex",
    alignSelf: "flex-start",
    fontFamily: "futura",
    color: "#6E6FA9",
  },
  input: {
    color: "#6E6FA9",
    fontFamily: "futura",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "futura",
    fontSize: 10,
    backgroundColor: "white",
    color: "#6E6FA9",
    borderRadius: 13,
    boxShadow: theme?.shadows[5],
    marginTop: 30,
    width: 180,
    height: 40,
  },
  pagination: {
    color: "red",
  },
});

export default modalUserStyle;
