import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

export default function RoleDisplay(props) {
  const {collectionsList, getTitle, globalRole, roles } = props;

  return (
    <Grid container>
      <Grid item md={12} style={{ display: "flex" }}>
        <div style={{ marginRight: "2%" }}>Accès</div>
        {globalRole ? (
          <CheckIcon style={{ color: "#5cb85c" }} />
        ) : (
          <ClearIcon style={{ color: "#c9302c" }} />
        )}
      </Grid>
      {globalRole &&
        roles &&
        roles.map((role, i) => (
          <Grid item md={12} style={{ display: "flex" }} key={i}>
            <div style={{ marginRight: "2%" }}>{getTitle(role.title)}</div>
            {typeof role.role === 'boolean' ? (role.role ? (
              <CheckIcon style={{ color: "#5cb85c" }} />
            ) : (
              <ClearIcon style={{ color: "#c9302c" }} />
            )) : (role.role < 0 ? 'Tous' : collectionsList.find(it => it.id === role.role)?.title)
            }
          </Grid>
        ))}
    </Grid>
  );
}
RoleDisplay.propTypes = {
  collectionsList: PropTypes.array,
  getTitle: PropTypes.func,
  globalRole: PropTypes.bool,
  roles: PropTypes.array,
};
