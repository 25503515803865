import { ressourceConstants } from "../constants";

export function ressourcesFetching(state = {}, action) {
  switch (action.type) {
    case ressourceConstants.GET_ALL_REQUEST:
      return {
        loadingRessources: true,
      };
    case ressourceConstants.GET_ALL_SUCCESS:
      return {
        loadingRessources: false,
        ressourcesLoaded: true,
        ressources: action.ressources,
      };
    case ressourceConstants.GET_ALL_FAILURE:
      return action.error;
    default:
      return state;
  }
}
