import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  CircularProgress,
  IconButton,
  Switch,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

import Pagination from "@material-ui/lab/Pagination";

import { makeStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/views/userStyle.js";

import Autocomplete from "@material-ui/lab/Autocomplete";
import SendIcon from "@material-ui/icons/Send";

// core components
import Button from "components/CustomButtons/Button.js";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import TableCollapseRegister from "components/Table/TableCollapseRegister";

import {
  Backdrop,
  Container,
  CssBaseline,
  Modal,
  Typography,
} from "@material-ui/core";
import { loadState } from "helpers/localStorage";

const useStyles = makeStyles(styles);
const textStyles = makeStyles({
  formControlRoot: {
    fontFamily: "futura",
    color: "#6E6FA9",
    borderRadius: "7px",
    "& label.Mui-focused": {
      color: "#6E6FA9",
    },
  },
  inputLabelRoot: {
    color: "#6E6FA9",
    fontFamily: "futura",
    "&.focused": {
      color: "#6E6FA9",
    },
  },
});
const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const Subscriptions = props => {
  const {
    estateSelected,
    toggleValidationModal,
    validationModal,
    page,
    userSelected,
    usersList,
    handleUserState,
    validate,
    userState,
    usersMeta,
    downloadProofOfResidence,
    handleUserFiltering,
    handlePagination,
    searchFilterHandling,
    toggleQuery,
    handleClearSearch,
    handleReason,
    handleReject,
    handleUpdateOccupationStatus,
    query,
    groups,
    groupFilter,
    reject,
    sectors,
    sectorFilter,
  } = props;

  const classes = useStyles();
  const textClasses = textStyles();
  const paginationClasses = paginationStyles();

  const { myself } = useSelector(state => state.myself);

  useEffect(() => {
    handleUserState("pending")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalBody = (
    <>
      {validationModal && (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.headerModal}>
            <Typography component="h4" variant="h6">
              {`Lier ${userSelected.firstName} ${userSelected.lastName} ?`}
            </Typography>

            <IconButton
              size="small"
              aria-label="delete"
              onClick={() => {
                handleReject();
                toggleValidationModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <GridContainer>
            {userSelected && userSelected?.estateToUsers?.length && (
              <>
                {userSelected.estateToUsers
                  .filter(
                    eTu =>
                      eTu.state === userState &&
                      eTu.estateId === loadState("site").id &&
                      (!estateSelected || estateSelected === eTu.uuid)
                  )
                  .map((eTu, key) => (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      key={key}
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Card>
                        <CardHeader
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#F5F5F5",
                          }}
                        >
                          {
                            userSelected.groups.find(
                              group => group.id === eTu.groupId
                            )?.title
                          }
                        </CardHeader>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignSelf: "center",
                            alignItems: "flex-end",
                          }}
                        >
                          {reject.find(ETU => ETU.uuid === eTu.uuid) ? (
                            <div>
                              <TextField
                                variant="standard"
                                margin="normal"
                                inputProps={{
                                  onChange: event =>
                                    handleReason(eTu.uuid, event.target.value),
                                  placeholder: "Raison du refus",
                                  defaultValue: "",
                                }}
                                value={reject[eTu.uuid]}
                              />
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={() => {
                                  handleReject(eTu.uuid);
                                  validate(eTu.uuid, false);
                                }}
                              >
                                <SendIcon />
                              </IconButton>
                            </div>
                          ) : (
                            <>
                              <IconButton
                                aria-label="delete"
                                color="primary"
                                onClick={() => validate(eTu.uuid, true)}
                              >
                                <CheckIcon />
                              </IconButton>
                              {userState === "pending" && (
                                <IconButton
                                  aria-label="delete"
                                  color="secondary"
                                  onClick={() => handleReject(eTu.uuid)}
                                >
                                  <CloseIcon />
                                </IconButton>
                              )}
                            </>
                          )}
                        </div>
                      </Card>
                    </GridItem>
                  ))}
              </>
            )}
          </GridContainer>
        </Container>
      )}
    </>
  );

  return myself && usersList ? (
    <>
      {validationModal && (
        <Modal
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          // scrollable={true}
          style={{
            overflowY: "auto",
          }}
          className={classes.modal}
          onClose={() => {
            handleReject();
            toggleValidationModal(false);
          }}
          open={validationModal}
        >
          <div className={classes.modalContent}>{modalBody}</div>
        </Modal>
      )}

      <Card
        style={{
          boxShadow: "none",
          marginTop: 0,
          paddingTop: 0,
        }}
      >
        {(myself?.rights?.role_superadmin || myself?.rights?.role_users) && (
          <CardHeader
            style={{
              borderRadius: 1,
              paddingTop: 0,
              border: "none",
              boxShadow:
                "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
              // boxShadow: "inset 0 0 0 rgba(0,0,0,0.1), 0 0 0 2px rgb(255,255,255), 0.2em 0.3em 0.5em rgba(0,0,0,0.3), 0 0 0 rgba(0,0,0,0)",
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  fullWidth
                  variant="standard"
                  classes={{
                    root: textClasses.formControlRoot,
                  }}
                  label="Rechercher par mot clé"
                  onChange={ev => {
                    ev.preventDefault();
                    searchFilterHandling();
                  }}
                  inputProps={{
                    // placeholder: "Rechercher par mot clé",
                    className: classes.input,
                    onChange: event => {
                      toggleQuery(event.target.value);
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: textClasses.inputLabelRoot,
                    },
                  }}
                  value={query}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Autocomplete
                  multiple
                  id="tags-groups"
                  options={groups}
                  getOptionLabel={option => option.title}
                  onChange={(_, value) => {
                    handleUserFiltering("group", value);
                    searchFilterHandling();
                  }}
                  value={groupFilter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      classes={{
                        root: textClasses.formControlRoot,
                      }}
                      label="Filtrer par groupe"
                      placeholder="Groupes sélectionnés"
                      InputProps={{
                        ...params.InputProps,
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: textClasses.inputLabelRoot,
                        },
                      }}
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Autocomplete
                  multiple
                  id="tags-sectors"
                  options={sectors}
                  getOptionLabel={option => option.name}
                  onChange={(_, value) => {
                    handleUserFiltering("sector", value);
                    searchFilterHandling();
                  }}
                  value={sectorFilter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      classes={{
                        root: textClasses.formControlRoot,
                      }}
                      label="Filtrer par bâtiments"
                      placeholder="Bâtiments sélectionnés"
                      InputProps={{
                        ...params.InputProps,
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: textClasses.inputLabelRoot,
                        },
                      }}
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Button
                    onClick={event => {
                      handleClearSearch(event);
                      searchFilterHandling(event);
                    }}
                    color="danger"
                    style={{
                      fontFamily: "futura",
                      fontSize: 10,
                      width: 110,
                      borderRadius: 10,
                    }}
                  >
                    Effacer
                  </Button>

                  <Button
                    onClick={event => searchFilterHandling(event)}
                    color="success"
                    style={{
                      fontFamily: "futura",
                      fontSize: 10,
                      width: 110,
                      borderRadius: 10,
                    }}
                  >
                    Rechercher
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </CardHeader>
        )}
        <GridItem xs={12} sm={12} md={12}>
          <CardBody>
            {usersList?.length >= 1 &&
              !myself?.rights?.role_superadmin &&
              !myself?.rights?.role_users_read ? (
              <GridItem xs={12} sm={12} md={6}>
                <h4>
                  {
                    "Vous n'avez pas la permission de voir les demandes d'inscriptions"
                  }
                </h4>
              </GridItem>
            ) : (
              <GridContainer>
                <TableCollapseRegister
                  usersList={usersList}
                  userState={userState}
                  myself={myself}
                  downloadProofOfResidence={downloadProofOfResidence}
                  toggleValidationModal={toggleValidationModal}
                  handleUpdateOccupationStatus={handleUpdateOccupationStatus}
                />
                <GridItem xs={12} sm={12} md={12}>
                  {usersList &&
                    usersList.length > 0 &&
                    (myself?.rights?.role_superadmin ||
                      myself?.rights.role_users_read) && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: 100,
                        }}
                      >
                        <Pagination
                          count={usersMeta?.pageCount}
                          page={page}
                          onChange={(_, pageNumber) => {
                            handlePagination(pageNumber);
                          }}
                          classes={{ ul: paginationClasses.ul }}
                          size="large"
                        />
                      </div>
                    )}
                </GridItem>
                {usersList?.length < 1 && (
                  <GridItem xs={12} sm={12} md={6}>
                    <h4>Aucun utilisateur en attente de validation</h4>
                  </GridItem>
                )}
              </GridContainer>
            )}
          </CardBody>
        </GridItem>
      </Card>
    </>
  ) : (
    <div className={classes.circularProgress}>
      <CircularProgress />
    </div>
  );
};

Subscriptions.propTypes = {
  estateSelected: PropTypes.string,
  toggleValidationModal: PropTypes.func,
  validationModal: PropTypes.bool,
  userSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  page: PropTypes.number,
  usersMeta: PropTypes.object,
  handlePagination: PropTypes.func,
  handleUser: PropTypes.func,
  usersList: PropTypes.array,
  handleUserState: PropTypes.func,
  handleUpdateOccupationStatus: PropTypes.func,
  validate: PropTypes.func,
  userState: PropTypes.string,
  handleUserFiltering: PropTypes.func,
  downloadProofOfResidence: PropTypes.func,
  searchFilterHandling: PropTypes.func,
  toggleQuery: PropTypes.func,
  handleClearSearch: PropTypes.func,
  handleReason: PropTypes.func,
  handleReject: PropTypes.func,
  query: PropTypes.string,
  groups: PropTypes.array,
  groupFilter: PropTypes.array,
  sectors: PropTypes.array,
  sectorFilter: PropTypes.array,
  reject: PropTypes.array,
};

export default Subscriptions;
