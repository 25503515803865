import React from "react";
import PropTypes from "prop-types";

import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../../helpers";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={props =>
        isLogin() && restricted ? (
          <Redirect to="/admin/statistics" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;

PublicRoute.propTypes = {
  component: PropTypes.func,
  restricted: PropTypes.bool,
};
