import { paginationConstants } from "../constants";

export const paginationActions = {
  getPageBookings,
  setPageBookings,
  getPageUsers,
  setPageUsers,
};

function getPageUsers(page) {
  return {
    type: paginationConstants.GET_PAGE_USERS,
    page: page,
  };
}

function setPageUsers(page) {
  return {
    type: paginationConstants.SET_PAGE_USERS,
    page: page,
  };
}
function getPageBookings(page) {
  return {
    type: paginationConstants.GET_PAGE_BOOKINGS,
    page: page,
  };
}

function setPageBookings(page) {
  return {
    type: paginationConstants.SET_PAGE_BOOKINGS,
    page: page,
  };
}
