import React, { useEffect } from "react";
import LinksSettingsLogic from "./LinksSettingsLogic";

import {
  CircularProgress,
  Modal,
  CssBaseline,
  Container,
  makeStyles,
  TextField,
  Button as MUIButton,
  InputLabel,
  Grid,
  Typography,
  Backdrop,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

import Pagination from "@material-ui/lab/Pagination";

// core components
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import ConfirmModal from "components/ConfirmModal";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { IconButton } from "@material-ui/core";
import ClassIcon from "@material-ui/icons/Class";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const useStyles = makeStyles(styles);

export default function LinksSettings(props) {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    editModalOpened,
    groupsCount,
    groupsList,
    linkGroupDestroy,
    limitGroups,
    modalType,
    newGroupsInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    deleteGroup,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewGroup,
    toggleConfirmLinkGroupDestroy,
    toggleModal,
  } = LinksSettingsLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (groupsList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
         {linkGroupDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmLinkGroupDestroy(null)}
            open={linkGroupDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmLinkGroupDestroy(null)}
                kind={`Supprimer le groupe "${linkGroupDestroy.title}" ?`}
                makeAction={() => deleteGroup(linkGroupDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        {editModalOpened && newGroupsInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <Container component="main" maxWidth="md" style={{
                overflowY: "auto",
              }}>
                <CssBaseline />
                <div>
                  <div className={classes.headerModal}>
                    {modalType ? (
                      <Typography variant="h5">Modifier le groupe</Typography>
                    ) : (
                      <Typography variant="h5">Ajouter un groupe</Typography>
                    )}
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => toggleModal()}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <form className={classes.form} onSubmit={submitNewGroup} noValidate>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <InputLabel>Titre *</InputLabel>
                        <TextField
                          autoComplete="fname"
                          defaultValue={newGroupsInfo.title}
                          name="title"
                          required
                          fullWidth
                          id="title"
                          onChange={event =>
                            handleFormChange("title", event.target.value)
                          }
                          autoFocus
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={!newGroupsInfo?.title?.length}
                    >
                      Enregister
                    </Button>
                  </form >
                </div >
              </Container >
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Groupes",
                tabIcon: ClassIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MUIButton
                            className={classes.button}
                            onClick={() => toggleModal()}
                            startIcon={<AddIcon />}
                          >
                            Ajouter un groupe
                          </MUIButton>
                        </GridItem>
                      </GridContainer>
                    </CardBody>

                    <div>
                      {groupsList && groupsList.length === 0 ? (
                        <h4>Aucun groupe à afficher</h4>
                      ) :
                        (<Table
                          tableHeaderColor="primary"
                          tableType="links"
                          tableHead={
                            [
                              "Titre",
                              ""
                            ]
                          }
                          tableData={
                            groupsList &&
                            groupsList.map(group => {
                              const groupList = {
                                title: group?.title,
                                actions: (
                                  <div style={{
                                    display: "flex",
                                  }}>
                                    <Button
                                      style={{ marginLeft: 'auto', }}
                                      size="sm"
                                      onClick={() => toggleModal(group)}
                                      color="warning"
                                    >
                                      Éditer
                                    </Button>
                                    <Button
                                      size="sm"
                                      style={{ marginLeft: '2%' }}
                                      onClick={() => toggleConfirmLinkGroupDestroy(group)}
                                      color="danger"
                                    >
                                      Supprimer
                                    </Button>
                                  </div>
                                ),
                              };
                              return Object.values(groupList);
                            })
                          }
                        />)
                      }
                      {groupsCount > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 100,
                          }}
                        >
                          <Pagination
                            count={Math.ceil(groupsCount / limitGroups)}
                            page={page}
                            onChange={(event, pageNumber) => {
                              handlePagination(pageNumber);
                            }}
                            classes={{ ul: paginationClasses.ul }}
                            size="large"
                          />
                        </div>
                      )}
                    </div>
                  </GridItem>
                )
              }
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}