import { sectorConstants } from "../constants";

export function sectorsFetching(state = {}, action) {
  switch (action.type) {
    case sectorConstants.GETALL_REQUEST:
      return { loadingSectors: true };
    case sectorConstants.GETALL_SUCCESS:
      return {
        loadingSectors: false,
        sectorsLoaded: true,
        sectors: action.sectors,
      };
    case sectorConstants.GETALL_FAILURE:
      return action.error;
    default:
      return state;
  }
}

export function sectorAttach(state = {}, action) {
  switch (action.type) {
    case sectorConstants.ATTACH_REQUEST:
      return { attachingSector: true };
    case sectorConstants.ATTACH_SUCCESS:
      return {
        attachingSector: false,
        attachedSector: true,
        message: action.message,
      };
    case sectorConstants.ATTACH_FAILURE:
      return action.error;
    default:
      return state;
  }
}

export function sectorDetach(state = {}, action) {
  switch (action.type) {
    case sectorConstants.DETACH_REQUEST:
      return { detachingSector: true };
    case sectorConstants.DETACH_SUCCESS:
      return {
        detachingSector: false,
        detachedSector: true,
        message: action.message,
      };
    case sectorConstants.DETACH_FAILURE:
      return action.error;
    default:
      return state;
  }
}
