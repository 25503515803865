import {useEffect, useState} from "react";
import moment from "moment";
import localization from "moment/locale/fr";
import {
		reservation2nServices,
		reservationLocationServices,
} from "services";

moment.updateLocale("fr", localization);

const TwoNListLogic = () => {
		const [refresh, setRefresh] = useState(false);

		const limits = {"2n": 15};

		const [openNotif, setOpenNotif] = useState(false);
		const [notifMessage, setNotifMessage] = useState("");
		const [notifColor, setNotifColor] = useState("info");

		const [formLocationList, setFormLocationList] = useState(undefined);
		const [locationList, setLocationList] = useState(undefined);

		const [twoNList, setTwoNList] = useState(undefined);
		const [twoNCount, setTwoNCount] = useState(0);
		const [pageTwoN, setPageTwoN] = useState(1);
		const [twoNModalOpened, setTwoNModalOpened] = useState(false);
		const [newTwoNInfo, setNewTwoNInfo] = useState(undefined);
		const [modalTwoNType, setModalTwoNType] = useState(false);
		const [formTwoNValid, setFormTwoNValid] = useState(false);

		const [bookingDestroy, setBookingDestroy] = useState(undefined);

		const toggleConfirmBookingDestroy = (type, booking) => {
				setBookingDestroy(booking);
		};
		
		const deleteBooking = uuid => {
				delete2n(uuid);
				toggleConfirmBookingDestroy(null);
		};
		
		const delete2n = async uuid => {
				await reservation2nServices.destroy(uuid);
				openNotification(
						`Le 2N ${twoNList.find(it => it.uuid === uuid)?.label} a été supprimé`,
						"success"
				);
				refreshData();
		};

		const refreshData = () => setRefresh(!refresh);

		useEffect(() => {
				(async () => {
					let twoN = await reservation2nServices.getAll();
					if (twoN) {
							setTwoNCount(twoN.length);
							setTwoNList(
									twoN.slice(
											limits["2n"] * (pageTwoN - 1),
											limits["2n"] * (pageTwoN - 1) + limits["2n"]
									)
							);
					}
				})();
				// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
				pageTwoN,
				refresh,
		]);

		const handlePagination2n = page => {
				setPageTwoN(page);
		};

		const toggleModal2n = (twoN = null) => {
				getAll2nform();
				setFormTwoNValid(false);
				setModalTwoNType(twoN ? true : false);
				const twoNInfo = {
						uuid: twoN?.uuid,
						label: twoN?.label ? twoN?.label : "",
						host: twoN?.host ? twoN?.host : "",
						port: twoN?.port ? twoN?.port : "",
						username: twoN?.username ? twoN?.username : "",
						password: twoN?.password ? twoN?.password : "",
						location: twoN?.location?.uuid ? twoN?.location?.uuid : "",
						showPassword: false,
				};
				setNewTwoNInfo(twoNInfo);
				setTwoNModalOpened(!twoNModalOpened);
		};
		
		const getAll2nform = async () => {
				const location = await reservationLocationServices.getAll();
				if (location) {
						setFormLocationList(location);
				}
		};

		const submitNew2n = async event => {
				event.preventDefault();
				const twoNInfo = {
						label: newTwoNInfo.label,
						host: newTwoNInfo.host,
						port: newTwoNInfo.port,
						username: newTwoNInfo.username,
						password: newTwoNInfo.password,
						free_user_uuid: newTwoNInfo.free_user_uuid,
				};
				if (newTwoNInfo.uuid) {
						if (
								twoNList.find(it => it.uuid === newTwoNInfo.uuid)?.location?.uuid !==
								newTwoNInfo.location
						)
								await reservation2nServices.setLocation(
										newTwoNInfo.uuid,
										newTwoNInfo.location
								);
						if (await reservation2nServices.update(newTwoNInfo.uuid, twoNInfo)) {
								openNotification(
										`Le 2N ${newTwoNInfo?.label} a été modifié`,
										"success"
								);
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de la modification du 2N ${newTwoNInfo?.label}`,
										"danger"
								);
						}
				} else {
						const res = await reservation2nServices.add(twoNInfo);
						if (res) {
								await reservation2nServices.setLocation(res.uuid, newTwoNInfo.location);
								openNotification(`Le 2N ${newTwoNInfo?.label} a été ajouté`, "success");
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de l'ajout du 2N ${newTwoNInfo?.label}`,
										"danger"
								);
						}
				}
				refreshData();
				setTwoNModalOpened(!twoNModalOpened);
				setFormTwoNValid(true);
		};
		
		const openNotification = (msg, color = "info", time = 6) => {
				setOpenNotif(false);
				setNotifMessage(msg);
				setNotifColor(color);
				setTimeout(() => setOpenNotif(false), time * 1000);
				setTimeout(() => setOpenNotif(true), 100);
		};

		const handle2nFormChange = (key, value, idx = 0) => {
				const twoNInfo = {...newTwoNInfo};
				if (key === "showPassword") {
						twoNInfo[key] = !twoNInfo[key];
				} else {
						twoNInfo[key] = value;
				}
				setNewTwoNInfo(twoNInfo);
				isForm2nValid(twoNInfo);
		};

		const isForm2nValid = twoN => {
				if (
						!twoN?.label?.length ||
						!twoN?.host?.length ||
						!twoN?.username?.length ||
						!twoN?.password?.length ||
						isNaN(parseInt(twoN?.port)) ||
						parseInt(twoN?.port) < 0 ||
						!twoN?.location?.length
				)
						setFormTwoNValid(false);
				else setFormTwoNValid(true);
		};
		
		const modalMessageBooking = () => {
			return `Supprimer le 2N "${bookingDestroy.label}" ?`;
		};

		return {
			twoNList,
			twoNCount,
			handlePagination2n,
			toggleModal2n,
			toggleConfirmBookingDestroy,
			limits,
			pageTwoN,
			twoNModalOpened,
			submitNew2n,
			newTwoNInfo,
			formTwoNValid,
			modalTwoNType,
			formLocationList,
			handle2nFormChange,
			bookingDestroy,
			modalMessageBooking,
			deleteBooking,
			refreshData,
			notifColor,
			notifMessage,
			openNotif,
			setOpenNotif
		};
};

export default TwoNListLogic;
