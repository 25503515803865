import React, { useEffect } from "react";
import IncidentsLogic from "./IncidentsLogic";

import moment from "moment";
import localization from "moment/locale/fr";

import {
  Collapse,
  CircularProgress,
  TextField,
  makeStyles,
  Divider,
  Select,
  MenuItem,
  Modal,
  Backdrop,
  Chip,
} from "@material-ui/core";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";

// core components
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ConfirmModal from "components/ConfirmModal";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const textStyles = makeStyles({
  formControlRoot: {
    fontFamily: "futura",
    color: "#6E6FA9",
    borderRadius: "7px",
    "& label.Mui-focused": {
      color: "#6E6FA9",
    },
  },
  inputLabelRoot: {
    color: "#6E6FA9",
    fontFamily: "futura",
    "&.focused": {
      color: "#6E6FA9",
    },
  },
});
const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

moment.updateLocale("fr", localization);

const useStyles = makeStyles(styles);

export default function Incidents(props) {
  const classes = useStyles();
  const textClasses = textStyles();
  const paginationClasses = paginationStyles();

  const {
    allCategoryList,
    categoryFilter,
    incidentDestroy,
    incidentsCategoryList,
    incidentsList,
    incidentsCount,
    incidentsNewReports,
    notifColor,
    notifMessage,
    limitIncidents,
    myself,
    openComment,
    openNotif,
    page,
    status,
    statusFilter,
    deleteReport,
    handlePagination,
    handleClickComment,
    onChangeReport,
    refreshData,
    sendNewReport,
    setOpenNotif,
    toggleConfirmIncidentDestroy,
    updateCategoryFilter,
    updateStatusIncident,
    updateStatusFilter,
  } = IncidentsLogic();

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (
    incidentsList &&
    allCategoryList &&
    incidentsCategoryList &&
    incidentsNewReports.length >= incidentsList.length
  ) {
    return (
      <div>
        <Snackbar
          place="tr"
          message={notifMessage}
          open={openNotif}
          close
          icon={
            notifColor === "success"
              ? CheckCircleIcon
              : notifColor === "danger"
              ? WarningIcon
              : NotificationsIcon
          }
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {incidentDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmIncidentDestroy(null)}
            open={incidentDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmIncidentDestroy(null)}
                kind={`Supprimer le rapport d'incident ?`}
                message={incidentDestroy.description}
                makeAction={() => deleteReport(incidentDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Incidents",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {!myself?.rights?.role_superadmin &&
                    !myself?.rights?.role_incident_read ? (
                      <GridItem xs={12} sm={12} md={6}>
                        <h4>
                          {
                            "Vous n'avez pas la permission de voir les incidents"
                          }
                        </h4>
                      </GridItem>
                    ) : (
                      <div>
                        <Card
                          style={{
                            boxShadow: "none",
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          <CardHeader
                            style={{
                              borderRadius: 1,
                              paddingTop: 0,
                              border: "none",
                              boxShadow:
                                "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
                            }}
                          >
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={3}>
                                <Autocomplete
                                  multiple
                                  id="tags-status"
                                  options={status}
                                  getOptionSelected={(option, value) =>
                                    option.id === value.id
                                  }
                                  getOptionLabel={option => option.title}
                                  onChange={(_, value) => {
                                    updateStatusFilter(value);
                                  }}
                                  value={statusFilter}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      classes={{
                                        root: textClasses.formControlRoot,
                                      }}
                                      label="Filtrer par état"
                                      placeholder="états sélectionnés"
                                      InputProps={{
                                        ...params.InputProps,
                                        className: classes.input,
                                      }}
                                      InputLabelProps={{
                                        classes: {
                                          root: textClasses.inputLabelRoot,
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                  multiple
                                  id="tags-category"
                                  options={allCategoryList.filter(
                                    it => it.activated
                                  )}
                                  getOptionLabel={option => option.title}
                                  onChange={(_, value) => {
                                    updateCategoryFilter(value);
                                  }}
                                  value={categoryFilter}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      classes={{
                                        root: textClasses.formControlRoot,
                                      }}
                                      label="Filtrer par Catégorie"
                                      placeholder="catégories sélectionnées"
                                      InputProps={{
                                        ...params.InputProps,
                                        className: classes.input,
                                      }}
                                      InputLabelProps={{
                                        classes: {
                                          root: textClasses.inputLabelRoot,
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </GridItem>
                            </GridContainer>
                          </CardHeader>
                        </Card>
                        {incidentsList && incidentsList.length === 0 && (
                          <h4>Aucun incident à afficher</h4>
                        )}
                        {incidentsList &&
                          incidentsList?.map((incidents, i) => {
                            if (
                              !incidentsNewReports.find(
                                it => it.id === incidents.id
                              )
                            )
                              return (
                                <div
                                  key={incidents.id}
                                  className={classes.root}
                                >
                                  <CircularProgress />
                                </div>
                              );
                            return (
                              <Card key={incidents.id}>
                                <CardBody>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                      <div
                                        style={{
                                          marginLeft: "1%",
                                          fontWeight: "bold",
                                          fontSize: 16,
                                        }}
                                      >
                                        {incidents.category.main.title}
                                      </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16,
                                        }}
                                      >
                                        {incidents.category.title}
                                      </div>
                                    </GridItem>
                                    {(myself?.rights?.role_superadmin ||
                                      myself?.rights
                                        ?.role_incident_change_status) && (
                                      <GridItem xs={12} sm={12} md={1}>
                                        <Select
                                          style={{ top: "-5px" }}
                                          labelId="status"
                                          id="select-status"
                                          value={incidents.status}
                                          onChange={e => {
                                            updateStatusIncident(
                                              e.target.value,
                                              incidents
                                            );
                                          }}
                                        >
                                          <MenuItem value={"opened"}>
                                            <em>Ouvert</em>
                                          </MenuItem>
                                          <MenuItem value={"ongoing"}>
                                            <em>En cours</em>
                                          </MenuItem>
                                          <MenuItem value={"closed"}>
                                            <em>Terminé</em>
                                          </MenuItem>
                                        </Select>
                                      </GridItem>
                                    )}
                                    <GridItem xs={12} sm={12} md={12}>
                                      <Divider
                                        style={{
                                          marginTop: "5px",
                                          marginBottom: "10px",
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <Chip
                                        label={`Suivi par ${
                                          incidents?.followers?.length
                                        } personne${
                                          incidents?.followers?.length > 1
                                            ? "s"
                                            : ""
                                        }`}
                                        color="primary"
                                      />
                                    </GridItem>
                                    <GridItem
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      style={{
                                        marginTop: "10px",
                                        display: "flex",
                                      }}
                                    >
                                      <div>
                                        {
                                          incidents?.descriptions[0]?.user
                                            ?.first_name
                                        }{" "}
                                        {
                                          incidents?.descriptions[0]?.user
                                            ?.last_name
                                        }
                                      </div>
                                      <div
                                        style={{
                                          marginLeft: "5px",
                                          fontStyle: "italic",
                                          opacity: "0.7",
                                        }}
                                      >
                                        {" "}
                                        -{" "}
                                        {moment(
                                          incidents.descriptions[0].created_at
                                        ).format("DD/MM/YYYY HH:mm")}
                                      </div>
                                    </GridItem>
                                    <GridItem
                                      xs={12}
                                      sm={12}
                                      md={incidents?.has_picture ? 8 : 12}
                                      style={{
                                        marginTop: "15px",
                                        marginBottom: "20px",
                                        fontSize: 16,
                                      }}
                                    >
                                      {incidents?.descriptions[0]?.text}
                                    </GridItem>
                                    {incidents?.has_picture && (
                                      <GridItem xs={12} sm={12} md={4}>
                                        <img
                                          style={{ size: "100%" }}
                                          src={`${
                                            process.env.REACT_APP_CDN_URL
                                          }/app/module/incident/declarations/${
                                            incidents.uuid
                                          }/thumb?refresh=${Date.now()}`}
                                          alt=""
                                          onError={i => {
                                            i.target.style.display = "none";
                                            i.target.alt =
                                              "Aucune image disponible";
                                          }}
                                        />
                                      </GridItem>
                                    )}
                                    {incidents?.comments?.length !== 0 && (
                                      <GridItem xs={12} sm={12} md={12}>
                                        <List
                                          component="nav"
                                          aria-labelledby="nested-list-subheader"
                                          subheader={
                                            <ListSubheader
                                              component="div"
                                              id="nested-list-subheader"
                                            >
                                              {incidents?.comments?.length}
                                              {incidents?.comments?.length > 1
                                                ? " commentaires"
                                                : " commentaire"}
                                            </ListSubheader>
                                          }
                                          className={classes.root}
                                        >
                                          <ListItem
                                            button
                                            onClick={() =>
                                              handleClickComment(incidents.uuid)
                                            }
                                          >
                                            <ListItemIcon>
                                              <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Afficher les commentaires" />
                                            {incidents?.show ? (
                                              <ExpandLess />
                                            ) : (
                                              <ExpandMore />
                                            )}
                                          </ListItem>
                                          <Collapse
                                            in={incidents.show}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <List
                                              component="div"
                                              disablePadding
                                            >
                                              {incidents?.comments?.map(
                                                comment => (
                                                  <ListItem key={comment.uuid}>
                                                    <Card>
                                                      <CardBody>
                                                        <GridContainer>
                                                          <GridItem
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            style={{
                                                              marginTop: "10px",
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <div>
                                                              {
                                                                comment.user
                                                                  .first_name
                                                              }{" "}
                                                              {
                                                                comment.user
                                                                  .last_name
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginLeft:
                                                                  "5px",
                                                                fontStyle:
                                                                  "italic",
                                                                opacity: "0.7",
                                                              }}
                                                            >
                                                              {moment(
                                                                comment.created_at
                                                              ).format(
                                                                "DD/MM/YYYY HH:mm"
                                                              )}
                                                            </div>
                                                          </GridItem>
                                                          <GridItem
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                          >
                                                            <Divider
                                                              style={{
                                                                marginTop:
                                                                  "5px",
                                                                marginBottom:
                                                                  "15px",
                                                              }}
                                                            />
                                                          </GridItem>
                                                          <GridItem
                                                            xs={12}
                                                            sm={12}
                                                            md={8}
                                                            style={{
                                                              marginBottom:
                                                                "15px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                fontSize: 16,
                                                              }}
                                                            >
                                                              {
                                                                comment?.description
                                                              }
                                                            </div>
                                                          </GridItem>
                                                          <GridItem
                                                            xs={12}
                                                            sm={12}
                                                            md={4}
                                                          >
                                                            <img
                                                              style={{
                                                                size: "100%",
                                                              }}
                                                              src={`${
                                                                process.env
                                                                  .REACT_APP_CDN_URL
                                                              }/app/module/incident/declarations/${
                                                                incidents.uuid
                                                              }/${
                                                                comment?.uuid
                                                              }/thumb?refresh=${Date.now()}`}
                                                              alt=""
                                                              onError={i => {
                                                                i.target.style.display =
                                                                  "none";
                                                                i.target.alt =
                                                                  "Aucune image disponible";
                                                              }}
                                                            />
                                                          </GridItem>
                                                        </GridContainer>
                                                      </CardBody>
                                                    </Card>
                                                  </ListItem>
                                                )
                                              )}
                                            </List>
                                          </Collapse>
                                        </List>
                                      </GridItem>
                                    )}
                                    {incidents?.reports?.length !== 0 && (
                                      <GridItem xs={12} sm={12} md={12}>
                                        <Divider />
                                      </GridItem>
                                    )}
                                  </GridContainer>
                                  {incidents?.reports?.length !== 0 &&
                                    incidents?.reports
                                      ?.sort((a, b) => {
                                        return (
                                          new Date(a.created_at) -
                                          new Date(b.created_at)
                                        );
                                      })
                                      .map(report => (
                                        <Card key={report.id}>
                                          <CardBody>
                                            <GridContainer>
                                              <GridItem
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                style={{
                                                  marginTop: "10px",
                                                  display: "flex",
                                                }}
                                              >
                                                <div>
                                                  {report.user.first_name}{" "}
                                                  {report.user.last_name}
                                                </div>
                                                <div
                                                  style={{
                                                    marginLeft: "5px",
                                                    fontStyle: "italic",
                                                    opacity: "0.7",
                                                  }}
                                                >
                                                  {" "}
                                                  -{" "}
                                                  {moment(
                                                    report.created_at
                                                  ).format("DD/MM/YYYY HH:mm")}
                                                </div>
                                              </GridItem>
                                              <GridItem xs={12} sm={12} md={12}>
                                                <Divider
                                                  style={{
                                                    marginTop: "5px",
                                                    marginBottom: "15px",
                                                  }}
                                                />
                                              </GridItem>
                                              <GridItem
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                style={{ marginBottom: "15px" }}
                                              >
                                                <div style={{ fontSize: 16 }}>
                                                  {report?.description}
                                                </div>
                                              </GridItem>
                                              {(myself?.rights
                                                ?.role_superadmin ||
                                                myself?.rights
                                                  ?.role_incident_comment_delete) && (
                                                <Button
                                                  style={{ marginLeft: "1%" }}
                                                  onClick={() =>
                                                    toggleConfirmIncidentDestroy(
                                                      report
                                                    )
                                                  }
                                                  color="danger"
                                                >
                                                  Supprimer
                                                </Button>
                                              )}
                                            </GridContainer>
                                          </CardBody>
                                        </Card>
                                      ))}
                                  {(myself?.rights?.role_superadmin ||
                                    myself?.rights
                                      ?.role_incident_comment_add) && (
                                    <div>
                                      <Divider
                                        style={{
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                        }}
                                      />
                                      <div style={{ display: "flex" }}>
                                        <TextField
                                          style={{ marginTop: "8px" }}
                                          id="add-report"
                                          value={
                                            incidentsNewReports?.find(
                                              it => it.id === incidents.id
                                            ).text
                                          }
                                          onChange={e =>
                                            onChangeReport(
                                              e.target.value,
                                              incidents.id
                                            )
                                          }
                                          placeholder="Ajouter un rapport d'évolution"
                                          multiline
                                          fullWidth
                                        />
                                        <Button
                                          onClick={() =>
                                            sendNewReport(
                                              incidents.id,
                                              incidents.uuid
                                            )
                                          }
                                          style={{
                                            marginLeft: "1%",
                                            height: 40,
                                          }}
                                          color="primary"
                                        >
                                          Envoyer
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                </CardBody>
                              </Card>
                            );
                          })}
                        {incidentsCount > 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: 100,
                            }}
                          >
                            <Pagination
                              count={Math.ceil(incidentsCount / limitIncidents)}
                              page={page}
                              onChange={(event, pageNumber) => {
                                handlePagination(pageNumber);
                              }}
                              classes={{ ul: paginationClasses.ul }}
                              size="large"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </GridItem>
                ),
              },
            ]}
          />
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}
