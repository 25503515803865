export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GET_ALL_REQUEST: "USERS_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "USERS_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "USERS_GET_ALL_FAILURE",

  GET_INSCRIPTIONS_REQUEST: "USERS_GET_INSCRIPTIONS_REQUEST",
  GET_INSCRIPTIONS_SUCCESS: "USERS_GET_INSCRIPTIONS_SUCCESS",
  GET_INSCRIPTIONS_FAILURE: "USERS_GET_INSCRIPTIONS_FAILURE",

  GET_USER_REQUEST: "USERS_GET_USER_REQUEST",
  GET_USER_SUCCESS: "USERS_GET_USER_SUCCESS",
  GET_USER_FAILURE: "USERS_GET_USER_FAILURE",

  GET_SELF_REQUEST: "USERS_GET_SELF_REQUEST",
  GET_SELF_SUCCESS: "USERS_GET_SELF_SUCCESS",
  GET_SELF_FAILURE: "USERS_GET_SELF_FAILURE",

  RESET_PASSWORD_REQUEST: "USERS_RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "USERS_RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "USERS_RESET_PASSWORD_FAILURE",


  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  TOGGLE_ENABLE_REQUEST: "USERS_TOGGLE_ENABLE_USER_REQUEST",
  TOGGLE_ENABLE_SUCCESS: "USERS_TOGGLE_ENABLE_USER_SUCCESS",
  TOGGLE_ENABLE_FAILURE: "USERS_TOGGLE_ENABLE_USER_FAILURE",

  USERS_CREATE_REQUEST: "USERS_CREATE_REQUEST",
  USERS_CREATE_SUCCESS: "USERS_CREATE_SUCCESS",
  USERS_CREATE_FAILURE: "USERS_CREATE_FAILURE",

  UPDATE_REQUEST: "USERS_UPDATE_REQUEST",
  UPDATE_SUCCESS: "USERS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "USERS_UPDATE_FAILURE",

  CONTROL_ACCESS_REQUEST: "CONTROL_ACCESS_REQUEST",
  CONTROL_ACCESS_SUCCESS: "CONTROL_ACCESS_SUCCESS",
  CONTROL_ACCESS_FAILURE: "CONTROL_ACCESS_FAILURE",
};
