import React, { memo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4, validate as uuidValidate } from "uuid";

import {
  Modal,
  Select,
  MenuItem,
  CssBaseline,
  Container,
  makeStyles,
  TextField,
  InputLabel,
  Grid,
  Switch,
  Typography,
  Backdrop,
  IconButton,
} from "@material-ui/core";

import { Close, Loop } from "@material-ui/icons";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import Button from "components/CustomButtons/Button.js";

const EditOubaboxModal = ({
  newItemInfos,
  editModalOpened,
  modalType,
  handleFormChange,
  submitNewOubabox,
  toggleModal,
  estateSectorsList,
}) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Modal
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      className={classes.modal}
      onClose={toggleModal}
      open={editModalOpened}
    >
      <div className={classes.paper}>
        <Container component="main" maxWidth="md" style={{ overflowY: "auto" }}>
          <CssBaseline />
          <div>
            <div className={classes.headerModal}>
              <Typography variant="h5">
                {modalType ? "Modifier l'" : "Ajouter une "}Oubabox
              </Typography>
              <IconButton
                size="small"
                aria-label="delete"
                onClick={toggleModal}
              >
                <Close />
              </IconButton>
            </div>
            <form
              className={classes.form}
              onSubmit={submitNewOubabox}
              noValidate
            >
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <InputLabel>Modèle</InputLabel>
                  <Select
                    value={newItemInfos.model}
                    onChange={({ target }) =>
                      handleFormChange("model", target.value)
                    }
                  >
                    {
                      // TODO: replace this hardcodded version by dynamic one
                      [
                        { id: 1, name: "Evolutive" },
                        { id: 2, name: "Essentiel" },
                      ].map((model, key) => (
                        <MenuItem value={model.id} key={key}>
                          {model.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </Grid>
                <Grid item xs={10}>
                  <InputLabel>Nom *</InputLabel>
                  <TextField
                    required
                    autoFocus
                    fullWidth
                    autoComplete="fname"
                    defaultValue={newItemInfos.label}
                    onChange={({ target }) =>
                      handleFormChange("label", target.value)
                    }
                  />
                </Grid>
                {newItemInfos?.uuid && (
                  <>
                    <Grid item xs={11}>
                      <InputLabel>UUID *</InputLabel>
                      <TextField
                        fullWidth
                        autoComplete="fname"
                        value={newItemInfos.uuid}
                        onChange={({ target }) =>
                          handleFormChange("uuid", target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        color="primary"
                        className={classes.submit}
                        onClick={() => handleFormChange("uuid", uuidv4())}
                      >
                        <Loop />
                      </IconButton>
                    </Grid>
                  </>
                )}
                <Grid item xs={4}>
                  <InputLabel>Adresse</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.address}
                    onChange={({ target }) =>
                      handleFormChange("address", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Ville</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.city}
                    onChange={({ target }) =>
                      handleFormChange("city", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Code Postal</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.zipcode}
                    onChange={({ target }) =>
                      handleFormChange("zipcode", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Code de Pays</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.countryA2}
                    onChange={({ target }) =>
                      handleFormChange("countryA2", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Latitude *</InputLabel>
                  <TextField
                    required
                    autoComplete="fname"
                    defaultValue={newItemInfos.latitude}
                    onChange={({ target }) =>
                      handleFormChange("latitude", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Longitude *</InputLabel>
                  <TextField
                    required
                    autoComplete="fname"
                    defaultValue={newItemInfos.longitude}
                    onChange={({ target }) =>
                      handleFormChange("longitude", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Lignes</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.rows}
                    onChange={({ target }) =>
                      handleFormChange("rows", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Colones</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.cols}
                    onChange={({ target }) =>
                      handleFormChange("cols", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Désactivée</InputLabel>
                  <Switch
                    color="primary"
                    checked={!newItemInfos.isAvailable}
                    onChange={({ target }) =>
                      handleFormChange("isAvailable", !target.checked)
                    }
                    inputProps={{ "aria-label": "activation-checkbox" }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Accès</InputLabel>
                  <Select
                    value={newItemInfos.access}
                    onChange={({ target }) =>
                      handleFormChange("access", target.value)
                    }
                  >
                    {
                      // TODO: replace this hardcodded version by dynamic one
                      [
                        {
                          id: "PUBLIC",
                          name: "Publique",
                        },
                        {
                          id: "ESTATE_USERS",
                          name: "Résidence",
                        },
                        {
                          id: "SECTOR_USERS",
                          name: "Bâtiment",
                        },
                      ].map((model, key) => (
                        <MenuItem value={model.id} key={key}>
                          {model.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Bâtiment(s)</InputLabel>
                  <Select
                    value={newItemInfos.sectorUUIDs}
                    id="select-type"
                    fullWidth
                    displayEmpty
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return <em>Aucune séléction</em>;
                      }
                      return estateSectorsList
                        .filter(sector =>
                          selected.find(it => it === sector.uuid)
                        )
                        .map(sector => sector.name)
                        .join(", ");
                    }}
                    multiple
                    onChange={({ target }) =>
                      handleFormChange("sectorUUIDs", target.value)
                    }
                  >
                    {estateSectorsList?.map((item, key) => {
                      return (
                        <MenuItem value={item.uuid} key={key}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Résidence</InputLabel>
                  <Select
                    value={newItemInfos.estateId}
                    onChange={({ target }) =>
                      handleFormChange("estateId", target.value)
                    }
                  >
                    {JSON.parse(localStorage.getItem("sites"))?.map(
                      (estate, key) => (
                        <MenuItem value={estate.id} key={key}>
                          {estate.title}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </Grid>
                <Grid item xs={1}>
                  <InputLabel>Résident</InputLabel>
                  <Switch
                    color="primary"
                    checked={newItemInfos.enaftLocalUsers}
                    onChange={({ target }) =>
                      handleFormChange("enaftLocalUsers", target.checked)
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <InputLabel>Postale</InputLabel>
                  <Switch
                    color="primary"
                    checked={newItemInfos.enaftPostal}
                    onChange={({ target }) =>
                      handleFormChange("enaftPostal", target.checked)
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <InputLabel>Voisin</InputLabel>
                  <Switch
                    color="primary"
                    checked={newItemInfos.enaftUserToUser}
                    onChange={({ target }) =>
                      handleFormChange("enaftUserToUser", target.checked)
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <InputLabel>Externe</InputLabel>
                  <Switch
                    color="primary"
                    checked={newItemInfos.enaftExtProvider}
                    onChange={({ target }) =>
                      handleFormChange("enaftExtProvider", target.checked)
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Transporteur</InputLabel>
                  <Switch
                    color="primary"
                    checked={newItemInfos.enaftTransporter}
                    onChange={({ target }) =>
                      handleFormChange("enaftTransporter", target.checked)
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <InputLabel>Note</InputLabel>
                  <TextField
                    fullWidth
                    autoComplete="fname"
                    defaultValue={newItemInfos.note}
                    onChange={({ target }) =>
                      handleFormChange("note", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Apparence</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.appearance}
                    onChange={({ target }) =>
                      handleFormChange("appearance", target.value)
                    }
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={
                  !(
                    newItemInfos?.label?.length &&
                    (newItemInfos.uuid
                      ? uuidValidate(newItemInfos.uuid)
                      : true) &&
                    !isNaN(parseFloat(newItemInfos?.longitude)) &&
                    !isNaN(parseFloat(newItemInfos?.latitude))
                  )
                }
              >
                Enregister
              </Button>
            </form>
          </div>
        </Container>
      </div>
    </Modal>
  );
};

EditOubaboxModal.propTypes = {
  newItemInfos: PropTypes.object,
  editModalOpened: PropTypes.bool,
  modalType: PropTypes.bool,
  handleFormChange: PropTypes.func,
  submitNewOubabox: PropTypes.func,
  toggleModal: PropTypes.func,
};

export default memo(EditOubaboxModal);
