import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { loadState, saveState } from "../../helpers/localStorage";
import { siteActions } from "../../actions";
import {
  linkFileServices,
  siteService,
} from "../../services";
import { useDropzone } from "react-dropzone";

import { saveAs } from 'file-saver'
import FileType from 'file-type/browser'

const PlatformLogic = () => {
  const dispatch = useDispatch();
  const [site, setSite] = useState(loadState("site"));

  const filterColorToHex = color => {
    if (color.substring(0, 1) === "#") {
      return color;
    }
    return "#" + color;
  };

  const [title, setTitle] = useState(site.title);
  const [uuid] = useState(site.uuid);
  const [currency, setCurrency] = useState(site.currency);
  const [city, setCity] = useState(site.city);
  const [zipcode, setZipcode] = useState(site.zipcode);
  const [estateCode, setEstateCode] = useState(site.estateCode);
  const [address, setAddress] = useState(site.address);
  const [webHostName, setWebHostName] = useState(site.web_host_name);
  const [country, setCountry] = useState(site.country);
  const [timezone, setTimezone] = useState(site.timezone);
  const [maintenanceMode, setMaintenanceMode] = useState(site.maintenance_mode);
  const [creditWalletEnable, setCreditWalletEnable] = useState(
    site.credit_wallet_enable
  );
  const [mainColor, setMainColor] = useState(filterColorToHex(site.color_main));
  const [backgroundColor, setbackgroundColor] = useState(
    filterColorToHex(site.color_background)
  );

  const [deleteIcon, setDeleteIcon] = useState(false);
  const [imgIcon, setImgIcon] = useState(undefined);
  const [refreshImageIcon, setRefreshImageIcon] = useState(false);
  const [deleteLogo, setDeleteLogo] = useState(false);
  const [imgLogo, setImgLogo] = useState(undefined);
  const [refreshImageLogo, setRefreshImageLogo] = useState(false);
  const [deleteBackground, setDeleteBackground] = useState(false);
  const [imgBackground, setImgBackground] = useState(undefined);
  const [refreshImageBackground, setRefreshImageBackground] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [file, setFile] = useState(undefined);
  const [fileSelect, setFileSelect] = useState(undefined);
  const [filesList, setFilesList] = useState(undefined)
  const [refresh, setRefresh] = useState(false);

  const [pictureDestroy, setPictureDestroy] = useState(undefined);

  const toggleConfirmPictureDestroy = picture => setPictureDestroy(picture);

  useEffect(() => {
    (async () => {
      const s = await siteService.getSite(site.uuid)
      if (s) {
        setSite(s)
      }
      let files = await linkFileServices.getAll()
      if (files) {
        files = files.filter(it => it.mime === 'application/pdf')
        files.sort((a, b) => b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1)
        setFilesList(files)
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const refreshData = () => setRefresh(!refresh);

  const getDelete = type => {
    if (type === "favicon") return deleteIcon;
    else if (type === "logo") return deleteLogo;
    else if (type === "background") return deleteBackground;
    return null;
  };

  const getRefresh = type => {
    if (type === "favicon") return refreshImageIcon;
    else if (type === "logo") return refreshImageLogo;
    else if (type === "background") return refreshImageBackground;
    return false;
  };

  const filterColor = hexaWithSymbol => {
    if (hexaWithSymbol.substring(0, 1) === "#") {
      return hexaWithSymbol.substring(1, 7);
    } else {
      return hexaWithSymbol.substring(0, 6);
    }
  };

  const updateSite = event => {
    event.preventDefault();
    const siteToUpdate = {
      ...site,
      ...{
        credit_wallet_enable: creditWalletEnable,
        maintenance_mode:
          !maintenanceMode || maintenanceMode === "null"
            ? null
            : maintenanceMode,
        color_main: filterColor(mainColor),
        color_background: filterColor(backgroundColor),
        address,
        country,
        timezone,
        title,
        web_host_name: webHostName,
        city,
        zipcode,
        currency,
        estateCode
      },
    };
    if (siteToUpdate) {
      dispatch(siteActions.update(siteToUpdate));
      saveState("site", siteToUpdate);
      openNotification("Les nouveaux paramètres du site ont été enregistré", "success")
    }
  };

  const onChangeImg = (e, setter) => {
    let data = new FormData();
    data.append("file", e.target.files[0]);
    setter(data);
  };

  const refreshImg = type => {
    if (type === "favicon") {
      setRefreshImageIcon(true);
      setTimeout(() => setRefreshImageIcon(false), 700);
    } else if (type === "logo") {
      setRefreshImageLogo(true);
      setTimeout(() => setRefreshImageLogo(false), 700);
    } else if (type === "background") {
      setRefreshImageBackground(true);
      setTimeout(() => setRefreshImageBackground(false), 700);
    }
  };

  const uploadImg = async type => {
    let img;
    if (type === "favicon") img = imgIcon;
    else if (type === "logo") img = imgLogo;
    else if (type === "background") img = imgBackground;

    if (img) {
      dispatch(await siteActions.uploadImage(type, img));
      openNotification("La nouvelle image a été enregistré", "success")
      refreshImg(type);
    }
  };

  const deleteImg = async type => {
    if (type === "favicon" || type === "logo" || type === "background") {
      dispatch(await siteActions.deleteImage(type));
      openNotification("L'image a été supprimé", "success")
      refreshImg(type);
    }
    toggleConfirmPictureDestroy(null)
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const submitNewFile = async () => {
    let f
    if (file) {
      if (filesList.find(f => f.name === file?.name)) {
        openNotification('Erreur, le fichier existe déjà', 'danger')
        return
      }
      let data = new FormData();
      data.append('file', file)
      f = await linkFileServices.add(data, file?.name)
      setFile(null)
    }
    const res = await siteService.attachFile(fileSelect ? fileSelect : f.uuid)
    if (fileSelect) {
      setFileSelect(null)
    }
    if (res) {
      saveState("site", res);
      refreshData()
      openNotification("Le nouveau règlement de copropriété à été ajouté", "success")
    } else {
      openNotification("Une erreur est survenu lors de l'ajout du nouveau règlement de copropriété", "danger")
    }
  }

  const handleFileSelect = file => {
    setFile(null)
    setFileSelect(file)
  }

  const downloadFile = async () => {
    const res = await linkFileServices.download(site?.file?.uuid)
    const blob = new Blob([res])
    const type = await FileType.fromBlob(blob)
    saveAs(blob, site?.file?.name.endsWith("." + type.ext) ? site?.file?.name : site?.file?.name + "." + type.ext)
  }

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 1) {
      setFileSelect(null)
      setFile(acceptedFiles[0]);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    type: "file",
    onDrop,
    accept: "application/pdf",
    maxFiles: 1,
  });

  return {
    uuid,
    address,
    backgroundColor,
    city,
    country,
    creditWalletEnable,
    currency,
    estateCode,
    file,
    fileSelect,
    filesList,
    isDragActive,
    isDragAccept,
    isDragReject,
    mainColor,
    maintenanceMode,
    notifColor,
    notifMessage,
    getRefresh,
    openNotif,
    pictureDestroy,
    site,
    timezone,
    title,
    webHostName,
    zipcode,
    deleteImg,
    downloadFile,
    handleFileSelect,
    getDelete,
    getRootProps,
    getInputProps,
    onChangeImg,
    refreshData,
    setAddress,
    setbackgroundColor,
    setCity,
    setCountry,
    setCreditWalletEnable,
    setCurrency,
    setDeleteBackground,
    setDeleteIcon,
    setDeleteLogo,
    setEstateCode,
    setImgBackground,
    setImgIcon,
    setImgLogo,
    setMainColor,
    setMaintenanceMode,
    setOpenNotif,
    setTimezone,
    setTitle,
    setWebHostName,
    setZipcode,
    submitNewFile,
    toggleConfirmPictureDestroy,
    updateSite,
    uploadImg,
  };
};

export default PlatformLogic;
