import { tabConstants } from "../constants";

export const tabActions = {
  getTabUsers,
  setTabUsers,
};

function getTabUsers(tab) {
  return {
    type: tabConstants.GET_TAB_USERS,
    tab: tab,
  };
}

function setTabUsers(tab) {
  return {
    type: tabConstants.SET_TAB_USERS,
    tab: tab,
  };
}
