import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import Button from "components/CustomButtons/Button.js";

import { Close, Brightness1 } from "@material-ui/icons";
import {
  Modal,
  Select,
  MenuItem,
  CssBaseline,
  Container,
  makeStyles,
  TextField,
  InputLabel,
  Grid,
  Switch,
  Typography,
  Backdrop,
  IconButton,
} from "@material-ui/core";

const EditDoorModal = ({
  newItemInfos,
  oubaboxsList,
  sizesList,
  editModalOpened,
  modalType,
  handleFormChange,
  submitNewDoor,
  toggleModal,
}) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Modal
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      className={classes.modal}
      onClose={toggleModal}
      open={editModalOpened}
    >
      <div className={classes.paper}>
        <Container component="main" maxWidth="md" style={{ overflowY: "auto" }}>
          <CssBaseline />
          <div>
            <div className={classes.headerModal}>
              <Typography variant="h5">
                {modalType ? "Modifier le " : "Ajouter un "}Casier
              </Typography>
              <IconButton
                size="small"
                aria-label="delete"
                onClick={toggleModal}
              >
                <Close />
              </IconButton>
            </div>
            <form className={classes.form} onSubmit={submitNewDoor} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <InputLabel>BID</InputLabel>
                  <TextField
                    autoFocus
                    autoComplete="fname"
                    defaultValue={newItemInfos.bid}
                    onChange={({ target }) =>
                      handleFormChange("bid", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Emplacement X</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.row}
                    onChange={({ target }) =>
                      handleFormChange("row", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Emplacement Y</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.col}
                    onChange={({ target }) =>
                      handleFormChange("col", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Sous Ligne</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.subrow}
                    onChange={({ target }) =>
                      handleFormChange("subrow", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Sous Colonne</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.subcol}
                    onChange={({ target }) =>
                      handleFormChange("subcol", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Apparence</InputLabel>
                  <TextField
                    autoComplete="fname"
                    defaultValue={newItemInfos.appearance}
                    onChange={({ target }) =>
                      handleFormChange("appearance", target.value)
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <InputLabel>Taille</InputLabel>
                  <Select
                    value={newItemInfos.sizeId}
                    onChange={({ target }) =>
                      handleFormChange("sizeId", target.value)
                    }
                  >
                    {sizesList?.map((size, key) => (
                      <MenuItem value={size.id} key={key}>
                        {size.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Oubabox</InputLabel>
                  <Select
                    value={newItemInfos.oubaboxId}
                    onChange={({ target }) =>
                      handleFormChange("oubaboxId", target.value)
                    }
                  >
                    {oubaboxsList.map((oubabox, key) => (
                      <MenuItem value={oubabox.id} key={key}>
                        {oubabox.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={1}>
                  <InputLabel>Désactivé</InputLabel>
                  <Switch
                    color="primary"
                    checked={newItemInfos.disable}
                    onChange={({ target }) =>
                      handleFormChange("disable", target.checked)
                    }
                    inputProps={{ "aria-label": "activation-checkbox" }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <InputLabel style={{ textAlign: "center" }}>♿</InputLabel>
                  <Switch
                    color="primary"
                    checked={newItemInfos.reducedMobility}
                    onChange={({ target }) =>
                      handleFormChange("reducedMobility", target.checked)
                    }
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    Connecté
                  </InputLabel>
                  <Brightness1
                    style={{
                      color: newItemInfos.connected ? "#0F0" : "#F00",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    Vérouillé
                  </InputLabel>
                  <Brightness1
                    style={{
                      color: newItemInfos.locked ? "#0F0" : "#F00",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    Fermé
                  </InputLabel>
                  <Brightness1
                    style={{
                      color: newItemInfos.closed ? "#0F0" : "#F00",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    Occupé
                  </InputLabel>
                  <Brightness1
                    style={{
                      color: newItemInfos.busy ? "#0F0" : "#F00",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <InputLabel
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    Buzzer
                  </InputLabel>
                  <Brightness1
                    style={{
                      color: newItemInfos.buzz ? "#0F0" : "#F00",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Note</InputLabel>
                  <TextField
                    fullWidth
                    autoComplete="fname"
                    defaultValue={newItemInfos.note}
                    onChange={({ target }) =>
                      handleFormChange("note", target.value)
                    }
                  />
                </Grid>
              </Grid>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!(newItemInfos.sizeId && newItemInfos.oubaboxId)}
              >
                Enregister
              </Button>
            </form>
          </div>
        </Container>
      </div>
    </Modal>
  );
};

EditDoorModal.propTypes = {
  newItemInfos: PropTypes.object,
  editModalOpened: PropTypes.bool,
  modalType: PropTypes.bool,
  handleFormChange: PropTypes.func,
  submitNewDoor: PropTypes.func,
  toggleModal: PropTypes.func,
  oubaboxsList: PropTypes.array,
  sizesList: PropTypes.array,
};

export default memo(EditDoorModal);
