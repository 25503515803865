import { get_axs, post_axs, put_axs, remove_axs } from "../helpers/fetcher";

export const siteService = {
  getAll,
  getSite,
  update,
  add,
  removeImage,
  setImage,
  attachFile,
};

async function getAll() {
  return get_axs("/sites");
}

async function getSite(id) {
  return get_axs(`/sites/${id}`);
}

async function add(data) {
  return post_axs(
    "/sites",
    new URLSearchParams({
      ...data,
    })
  );
}

async function attachFile(uuid) {
  return post_axs(`/sites/cgu/${uuid}`);
}

async function update(data) {
  return put_axs(
    "/sites",
    new URLSearchParams({
      ...data,
    })
  );
}

async function removeImage(type) {
  return remove_axs(`/sites/image/${type}`);
}

async function setImage(type, data) {
  return post_axs(
    `/sites/image/${type}`,
    data,
    'multipart/form-data'
  );
}
