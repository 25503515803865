import { useEffect, useState } from "react";

import {
  b2ebikeServices
} from "../../services";

const B2ebikesLogic = () => {

  const [refresh, setRefresh] = useState(false);

  const limitB2ebike = 20

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [page, setPage] = useState(1)
  const [b2ebikeList, setB2ebikeList] = useState(undefined)
  const [b2ebikeCount, setB2ebikeCount] = useState(undefined)

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newB2ebikeInfo, setNewB2ebikeInfo] = useState(undefined);
  const [modalType, setModalType] = useState(false);

  const [b2ebikeDestroy, setB2ebikeDestroy] = useState(undefined);

  const toggleConfirmB2ebikeDestroy = b2ebike => setB2ebikeDestroy(b2ebike);

  useEffect(() => {
    (async () => {
      const bike = await b2ebikeServices.getAll()
      if (bike) {
        setB2ebikeCount(bike.length)
        setB2ebikeList(bike.slice(limitB2ebike * (page - 1), limitB2ebike * (page - 1) + limitB2ebike))
      }
    })();
  }, [refresh, page]);

  const refreshData = () => setRefresh(!refresh);

  const handlePagination = page => {
    setPage(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const deleteB2ebike = async id => {
    await b2ebikeServices.destroy(id);
    openNotification(`Le b2ebike de ${b2ebikeList.find(it => it.id === id)?.apiURL} a été supprimé`, 'success');
    toggleConfirmB2ebikeDestroy(null)
    refreshData()
  };

  const toggleModal = async (b2ebike = null) => {
    setModalType(b2ebike ? true : false);
    const b2ebikesInfo = {
      id: b2ebike?.id,
      apiURL: b2ebike?.apiURL ? b2ebike?.apiURL : '',
      apiKey: b2ebike?.apiKey ? b2ebike?.apiKey : '',
    };
    setNewB2ebikeInfo(b2ebikesInfo);
    setEditModalOpened(!editModalOpened);
  };

  const handleFormChange = (key, value) => {
    let b2ebikesInfo = { ...newB2ebikeInfo };
    b2ebikesInfo[key] = value;
    setNewB2ebikeInfo(b2ebikesInfo);
  };

  const submitNewB2ebike = async event => {
    event.preventDefault();
    const b2ebikesInfo = {
      apiURL: newB2ebikeInfo.apiURL,
      apiKey: newB2ebikeInfo.apiKey,
    };
    if (newB2ebikeInfo.id) {
      if (await b2ebikeServices.update(newB2ebikeInfo.id, b2ebikesInfo)) {
        openNotification(`Le b2ebike de ${newB2ebikeInfo?.apiURL} a été modifié`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de la modification du b2ebike de ${newB2ebikeInfo?.apiURL}`, 'danger')
      }
    } else {
      if (await b2ebikeServices.add(b2ebikesInfo)) {
        openNotification(`Le b2ebike de ${newB2ebikeInfo?.apiURL} a été ajouté`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de l'ajout du b2ebike de ${newB2ebikeInfo?.apiURL}`, 'danger')
      }
    }
    setEditModalOpened(!editModalOpened);
    refreshData();
  };

  return {
    editModalOpened,
    limitB2ebike,
    modalType,
    newB2ebikeInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    b2ebikeDestroy,
    b2ebikeCount,
    b2ebikeList,
    deleteB2ebike,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewB2ebike,
    toggleConfirmB2ebikeDestroy,
    toggleModal,
  }
}

export default B2ebikesLogic