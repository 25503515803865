import { searchConstants } from "../constants";

export function searchUsers(
  state = {},
  { type, search, activation, sectorsFilter, groupsFilter }
) {
  switch (type) {
    case searchConstants.GET_USERS_SEARCH:
      return {
        search,
        activation,
        sectorsFilter,
        groupsFilter,
      };
    case searchConstants.SET_USERS_SEARCH:
      return {
        search,
        activation,
        sectorsFilter,
        groupsFilter,
      };
    default:
      return state;
  }
}
