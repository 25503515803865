import React from "react";
import PropTypes from "prop-types";

// import PropTypes from "prop-types";

import MuiPhoneNumber from "material-ui-phone-number";

import {
  Container,
  CssBaseline,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  makeStyles,
  Select,
  Typography,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import styles from "assets/jss/material-dashboard-react/views/userStyle.js";

// core components

import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function FormAdmins(props) {
  const classes = useStyles();

  const {
    formAdminValid,
    newAdminsInfo,
    handleFormAdminChange,
    submitNewAdmin,
    toggleModalAdmin,
  } = props;
  return (
    <Container component="main" maxWidth="xs" style={{
      overflowY: "auto",
    }}>
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          <Typography variant="h5">Ajouter un administrateur</Typography>
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModalAdmin(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form
          className={classes.form}
          onSubmit={submitNewAdmin}
          noValidate
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel>Civilité *</InputLabel>
              <Select
                value={newAdminsInfo.call_title}
                onChange={event =>
                  handleFormAdminChange("call_title", event.target.value)
                }
                inputProps={{
                  name: "title",
                  id: "title",
                }}
              >
                <MenuItem value={""}>
                  <b>
                    <em>Non précisé</em>
                  </b>
                </MenuItem>
                <br />

                <MenuItem value={"MM"}>Madame</MenuItem>
                <MenuItem value={"MR"}>Monsieur</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                defaultValue={newAdminsInfo.first_name}
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Prénom"
                onChange={event =>
                  handleFormAdminChange("first_name", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                defaultValue={newAdminsInfo.last_name}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Nom"
                name="lastName"
                onChange={event =>
                  handleFormAdminChange("last_name", event.target.value)
                }
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                defaultValue={newAdminsInfo.email}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                onChange={event =>
                  handleFormAdminChange("email", event.target.value)
                }
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputLabel>Numéro de téléphone</InputLabel>
              <MuiPhoneNumber
                defaultCountry={"fr"}
                value={newAdminsInfo.mobile_phone}
                onChange={(event, country) =>
                  handleFormAdminChange("mobile_phone", event, country.coundryCode)
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formAdminValid}
          >
            Enregister
          </Button>
        </form>
      </div>
    </Container>
  );
}

FormAdmins.propTypes = {
  formAdminValid: PropTypes.bool,
  newAdminsInfo: PropTypes.object,
  handleFormAdminChange: PropTypes.func,
  submitNewAdmin: PropTypes.func,
  toggleModalAdmin: PropTypes.func,
};
