import { get_axs, post_axs, put_axs, remove_axs } from "../helpers/fetcher";

export const alertServices = {
  getAll,
  add,
  destroy,
  disable,
  update,
  enable,
  getItem,
};

async function getAll() {
  return get_axs("/alerts");
}

async function enable(uuid) {
  return put_axs(`/alerts/${uuid}/enable`);
}

async function disable(uuid) {
  return put_axs(`/alerts/${uuid}/disable`);
}

async function getItem(uuid) {
  return get_axs(`/alerts/${uuid}`);
}

async function add(data) {
  return post_axs(
    "/alerts",
    new URLSearchParams({
      ...data
    })
  );
}

async function update(uuid, data) {
  return put_axs(
    `/alerts/${uuid}`,
    new URLSearchParams({
      ...data
    })
  );
}

async function destroy(uuid) {
  return remove_axs(`/alerts/${uuid}`);
}
