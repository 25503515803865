import { tabConstants } from "../constants";

export function tabUsersSelection(state = {}, { type, tab }) {
  switch (type) {
    case tabConstants.GET_TAB_USERS:
      return {
        tab,
      };
    case tabConstants.SET_TAB_USERS:
      return {
        tab,
      };
    default:
      return state;
  }
}
