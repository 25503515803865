export const groupConstants = {
  GETALL_REQUEST: "GROUP_GETALL_REQUEST",
  GETALL_SUCCESS: "GROUP_GETALL_SUCCESS",
  GETALL_FAILURE: "GROUP_GETALL_FAILURE",

  ATTACH_REQUEST: "GROUP_ATTACH_REQUEST",
  ATTACH_SUCCESS: "GROUP_ATTACH_SUCCESS",
  ATTACH_FAILURE: "GROUP_ATTACH_FAILURE",

  DETACH_REQUEST: "GROUP_DETACH_REQUEST",
  DETACH_SUCCESS: "GROUP_DETACH_SUCCESS",
  DETACH_FAILURE: "GROUP_DETACH_FAILURE",
};
