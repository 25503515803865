import { modalConstants } from "../constants";

export function displaySitesModal(state = {}, { type, open }) {
  switch (type) {
    case modalConstants.SHOW_SITES_MODAL:
      return {
        open,
      };
    case modalConstants.HIDE_SITES_MODAL:
      return {
        open,
      };
    default:
      return state;
  }
}
export function displayUserModal(state = {}, { type, open }) {
  switch (type) {
    case modalConstants.SHOW_USER_MODAL:
      return {
        open,
      };
    case modalConstants.HIDE_USER_MODAL:
      return {
        open,
      };
    default:
      return state;
  }
}
