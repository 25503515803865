import React, { memo } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import clsx from "clsx";
import Filters from "./Filters";
import { saveAs } from "file-saver";

import {
  CircularProgress,
  Modal,
  makeStyles,
  Backdrop,
  IconButton,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Tooltip
} from "@material-ui/core";
import {
  Add,
  Check,
  Close,
  Save
} from "@material-ui/icons";
import { lighten } from "@material-ui/core/styles";
import { LogLogic } from "./LogLogic";

const headCells = [
  { id: "type",     label: "Type"      },
  { id: "subType",  label: "Sous-Type" },
  { id: "data",     label: "Donées"    },
  { id: "oubabox",  label: "Oubabox"   },
  { id: "box",      label: "Casier"    },
  { id: "loggedAt", label: "Date"      },
  { id: "actions",  label: "Actions"   }
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
    paddingLeft:    theme.spacing(2),
    paddingRight:   theme.spacing(1),
  },
  highlight: theme.palette.type === "light" ? {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  } : {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark,
  },
  title: {
    display: "flex",
  }
}));

const useStyles = makeStyles(() => ({
  root: { width: "100%" },
  paper: {
    width:     "70%",
    height:    "90%",
    margin:    "auto",
    overflow:  "auto",
    marginTop: "40px"
  },
  table: { minWidth: 750 },
  visuallyHidden: {
    border:   0,
    clip:     "rect(0 0 0 0)",
    height:   1,
    margin:   -1,
    overflow: "hidden",
    padding:  0,
    position: "absolute",
    top:      20,
    width:    1,
  },
}));

const EnhancedTableHead = ({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort
}) => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount && numSelected === rowCount}
          onChange={onSelectAllClick}
          inputProps={{ "aria-label": "select all" }}
        />
      </TableCell>
      {headCells.map((headCell, key) => (
        <TableCell
          key={key}
          align="center"
          padding={key ? "none" : "default"}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={event => onRequestSort(event, headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const EnhancedTableToolbar = ({
  oubaboxsList,
  numSelected,
  boxsFilter,
  oubaboxsFilter,
  typesFilter,
  subTypesFilter,
  activeFilter,
  handleFilter,
  bulkResolveLog,
  toggleDisplayLogs,
  subTypes
}) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selectionné
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Logs
        </Typography>
      )}
      <Filters
        oubaboxsList={oubaboxsList}
        numSelected={numSelected}
        boxsFilter={boxsFilter}
        oubaboxsFilter={oubaboxsFilter}
        typesFilter={typesFilter}
        subTypesFilter={subTypesFilter}
        activeFilter={activeFilter}
        handleFilter={handleFilter}
        subTypes={subTypes}
      />
      <Tooltip title={!numSelected ? "Ajouter un rapport de maintenance" : "Supprimer les Filtres"}>
        <IconButton>
          {!numSelected ? <Add /> : (
            <Check onClick={() => bulkResolveLog(false)} />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Fermer">
        <IconButton
          aria-label="close"
          onClick={() => toggleDisplayLogs(null)
        }>
          <Close />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const Log = ({
  oubaboxUUID,
  defaultData,
  oubaboxsList,
  toggleDisplayLogs,
  getComparator,
  stableSort
}) => {
  const classes = useStyles();

  const {
    logsList,
    oubaboxsFilter,
    boxsFilter,
    typesFilter,
    subTypesFilter,
    handleFilter,
    handleRequestSort,
    handleClick,
    handleSelectAllClick,
    bulkResolveLog,
    activeFilter,
    resolveLog,
    getCamShot,
    getScreenshot,
    isSelected,
    subTypes,
    selected,
    order,
    orderBy
  } = LogLogic(oubaboxsList, oubaboxUUID, defaultData);

  return (
    <Modal
      className={classes.modal}
      open={oubaboxUUID ? true : false}
      onClose={() => toggleDisplayLogs(null)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
    }}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          oubaboxsList={oubaboxsList}
          numSelected={selected.length}
          boxsFilter={boxsFilter}
          oubaboxsFilter={oubaboxsFilter}
          typesFilter={typesFilter}
          subTypesFilter={subTypesFilter}
          activeFilter={activeFilter}
          handleFilter={handleFilter}
          bulkResolveLog={bulkResolveLog}
          toggleDisplayLogs={toggleDisplayLogs}
          subTypes={subTypes}
        />
        {logsList ? (
          logsList?.length ? (
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={logsList.length}
                />
                <TableBody>
                  {stableSort(logsList, getComparator(order, orderBy))
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((log, index) => (
                      <TableRow
                        hover
                        key={index}
                        role="checkbox"
                        aria-checked={isSelected(log.id)}
                        tabIndex={-1}
                        selected={isSelected(log.id)}
                        style={{
                          backgroundColor: log.isActive || "#ccc"
                        }}
                      >
                        <TableCell
                          padding="checkbox"
                          onClick={event => handleClick(event, log.id)
                        }>
                          <Checkbox
                            checked={isSelected(log.id)}
                            inputProps={{ "aria-labelledby": `enhanced-table-checkbox-${index}` }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={event => handleClick(event, log.id)}
                          style={{ color:
                            log.type === "failure"  ? "#e53935" : (
                            log.type === "image"    ? "#3f51b5" : (
                            log.type === "success" && "#66bb6a"
                          ))
                        }}>
                          <b>
                            [{log.type}]
                          </b>
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={event => handleClick(event, log.id)
                        }>
                          {log.subType}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={event => handleClick(event, log.id)
                        }>
                          {log.data || '-'}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={event => handleClick(event, log.id)
                        }>
                          {log.oubabox?.label || '-'}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={event => handleClick(event, log.id)
                        }>
                          {log.box?.bid || '-'}
                        </TableCell>
                        <TableCell
                          align="center"
                          onClick={event => handleClick(event, log.id)
                        }>
                          {moment.tz(log.loggedAt, undefined, "Europe/Paris").format(`DD/MM/YY HH[h]mm`)}
                        </TableCell>
                        {log.isActive ? (
                          <TableCell align="center">
                            <Tooltip title="Marquer comme résolu">
                              <IconButton
                                size="small"
                                onClick={() => resolveLog(log, false)
                              }>
                                <Check />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        ) : (
                          log.type === "image" ? (
                            <TableCell align="center">
                              <Tooltip title="Récupérer la photo">
                                <IconButton
                                  size="small"
                                  onClick={async () => saveAs(
                                    log.subType === "OubaboxScreenShot"
                                      ? (await getScreenshot(log.oubabox.uuid, log.uuid))
                                      : (await getCamShot(log.oubabox.uuid, log.uuid)),
                                    `${log.oubabox.label}-${new Date().toLocaleDateString("fr-FR")}-${new Date().toLocaleTimeString("fr-FR")}.${log.subType === "OubaboxScreenShot" ? "png" : "jpg"}`
                                  )}>
                                  <Save />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          ) : <TableCell/>
                        )}
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              Aucun log à afficher...
            </div>
          )
        ) : (
          <div className={classes.circularProgress}>
            <CircularProgress />
          </div>
        )}
      </Paper>
    </Modal>
  );
}

Log.propTypes = {
  oubaboxUUID:       PropTypes.string,
  defaultData:       PropTypes.array,
  oubaboxsList:      PropTypes.array,
  sizesList:         PropTypes.array,
  bulkResolveLog:    PropTypes.func,
  toggleDisplayLogs: PropTypes.func,
  getComparator:     PropTypes.func,
  stableSort:        PropTypes.func
};

EnhancedTableToolbar.propTypes = {
  numSelected:       PropTypes.number.isRequired,
  bulkResolveLog:    PropTypes.func.isRequired,
  oubaboxsList:      PropTypes.array,
  boxsFilter:        PropTypes.array,
  oubaboxsFilter:    PropTypes.array,
  typesFilter:       PropTypes.array,
  subTypesFilter:    PropTypes.array,
  handleFilter:      PropTypes.func,
  toggleDisplayLogs: PropTypes.func,
  activeFilter:      PropTypes.any,
  subTypes:          PropTypes.array
};

EnhancedTableHead.propTypes = {
  classes:          PropTypes.object.isRequired,
  numSelected:      PropTypes.number.isRequired,
  onRequestSort:    PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order:            PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy:          PropTypes.string.isRequired,
  rowCount:         PropTypes.number.isRequired,
};

export default memo(Log);
