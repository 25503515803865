import {useEffect, useState} from "react";
import moment from "moment";
import localization from "moment/locale/fr";
import {
		reservationLocationServices,
		sectorServices,
} from "services";

moment.updateLocale("fr", localization);

const LocationListLogic = () => {
		const [bookingState, setBookingState] = useState("ressource");
		const [refresh, setRefresh] = useState(false);

		const limits = {location: 10};

		const [openNotif, setOpenNotif] = useState(false);
		const [notifMessage, setNotifMessage] = useState("");
		const [notifColor, setNotifColor] = useState("info");
		const [refreshImageLocation, setRefreshImageLocation] = useState(undefined);

		const [locationList, setLocationList] = useState(undefined);
		const [locationCount, setLocationCount] = useState(0);
		const [pageLocation, setPageLocation] = useState(1);
		const [locationModalOpened, setLocationModalOpened] = useState(false);
		const [newLocationInfo, setNewLocationInfo] = useState(undefined);
		const [modalLocationType, setModalLocationType] = useState(false);
		const [formLocationValid, setFormLocationValid] = useState(false);

		const [bookingDestroy, setBookingDestroy] = useState(undefined);
		const [bookingDestroyType, setBookingDestroyType] = useState(undefined);
		
		const [formSectorList, setFormSectorList] = useState(undefined);

		const toggleConfirmBookingDestroy = (type, booking) => {
				setBookingDestroyType(type);
				setBookingDestroy(booking);
		};

		const deleteBooking = uuid => {
				deleteLocation(uuid);
				toggleConfirmBookingDestroy(null);
		};

		const modalMessageBooking = () => {
			return `Supprimer l'emplacement "${bookingDestroy.title}" ?`;
		};

		const refreshData = () => setRefresh(!refresh);

		useEffect(() => {
				(async () => {
					let location = await reservationLocationServices.getAll();
					if (location) {
							setLocationCount(location.length);
							const l = location.slice(
									limits.location * (pageLocation - 1),
									limits.location * (pageLocation - 1) + limits.location
							);
							setLocationList(l);
							if (!refreshImageLocation) {
									let r = {};
									l.forEach(it => (r[it.uuid] = false));
									setRefreshImageLocation(r);
							}
					}
				})();
				// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
				pageLocation,
				refresh,
		]);

		const handlePaginationLocation = page => {
				setPageLocation(page);
		};

		const refreshImgLocation = uuid => {
				const refreshOn = {...refreshImageLocation};
				refreshOn[uuid] = true;
				setRefreshImageLocation(refreshOn);
				setTimeout(() => {
						const refreshOff = {...refreshImageLocation};
						refreshOff[uuid] = false;
						setRefreshImageLocation(refreshOff);
				}, 700);
		};

		const openNotification = (msg, color = "info", time = 6) => {
				setOpenNotif(false);
				setNotifMessage(msg);
				setNotifColor(color);
				setTimeout(() => setOpenNotif(false), time * 1000);
				setTimeout(() => setOpenNotif(true), 100);
		};

		const toggleModalLocation = (location = null) => {
				fetchSectors();
				setFormLocationValid(false);
				setModalLocationType(location ? true : false);
				const locationInfo = {
						uuid: location?.uuid,
						title: location?.title ? location?.title : "",
						details: location?.details ? location?.details : "",
						sector: location?.sectorId,
						image: null,
						has_picture: location?.has_picture ? location?.has_picture : false,
				};
				setNewLocationInfo(locationInfo);
				setLocationModalOpened(!locationModalOpened);
		};
		
		const fetchSectors = async () => {
				const sectors = await sectorServices.getAll();
				if (sectors) {
						setFormSectorList(sectors);
				}
		};

		const submitNewLocation = async event => {
				event.preventDefault();
				const locationInfo = {
						title: newLocationInfo.title,
						details: newLocationInfo.details,
						sectorId: newLocationInfo.sector,
				};
				let res;
				if (newLocationInfo.uuid) {
						res = await reservationLocationServices.update(
								newLocationInfo.uuid,
								locationInfo
						);
						if (res) {
								openNotification(
										`L'emplacement ${res?.title} a été modifié`,
										"success"
								);
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de la modification de l'emplacement ${newLocationInfo?.title}`,
										"danger"
								);
						}
				} else {
						res = await reservationLocationServices.add(locationInfo);
						if (res) {
								openNotification(`L'emplacement ${res?.title} a été ajouté`, "success");
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de l'ajout de l'emplacement ${newLocationInfo?.title}`,
										"danger"
								);
						}
				}
				if (newLocationInfo.image && res) {
						let data = new FormData();
						data.append("file", newLocationInfo.image);
						await reservationLocationServices.setImage(res.uuid, data);
						refreshImgLocation(res.uuid);
				}
				refreshData();
				setLocationModalOpened(!locationModalOpened);
				setFormLocationValid(true);
		};

		const handleLocationFormChange = (key, value) => {
				const locationInfo = {...newLocationInfo};
				locationInfo[key] = value;
				setNewLocationInfo(locationInfo);
				isFormLocationValid(locationInfo);
		};

		const isFormLocationValid = location => {
				if (!location?.title?.length) setFormLocationValid(false);
				else setFormLocationValid(true);
		};

		const deleteLocation = async uuid => {
				await reservationLocationServices.destroy(uuid);
				openNotification(
						`L'emplacement ${
								locationList.find(it => it.uuid === uuid)?.title
						} a été supprimé`,
						"success"
				);
				refreshData();
		};
	
		return {
				bookingDestroy,
				formLocationValid,
				formSectorList,
				limits,
				locationCount,
				locationList,
				locationModalOpened,
				modalLocationType,
				newLocationInfo,
				notifColor,
				notifMessage,
				openNotif,
				pageLocation,
				refresh,
				refreshImageLocation,
				deleteBooking,
				handleLocationFormChange,
				handlePaginationLocation,
				modalMessageBooking,
				refreshData,
				setBookingState,
				setOpenNotif,
				submitNewLocation,
				toggleConfirmBookingDestroy,
				toggleModalLocation,
		};
};

export default LocationListLogic;
