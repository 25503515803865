import React          from "react";
import moment         from "moment";
import localization   from "moment/locale/fr";
import GridItem       from "components/Grid/GridItem.js";
import Button         from "components/CustomButtons/Button.js";
import CardHeader     from "components/Card/CardHeader.js";
import Card           from "components/Card/Card.js";
import CardIcon       from "components/CardIcon";
import GridContainer  from "components/Grid/GridContainer.js";
import ConfirmModal   from "components/ConfirmModal";
import StatisticLogic from "./StatisticLogic";
import Graph          from "./Graph";
import modalUserStyle from "assets/jss/material-dashboard-react/views/newsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import {
  DatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import {
  Person,
  Timer,
  Storage,
} from "@material-ui/icons";
import {
  Grid,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  Modal,
  Switch,
  Backdrop,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core/";

const styles = {
  ...modalUserStyle(),
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  filters: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  gridItem: {
    marginBottom: "0"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  },
  select: {
    display: "inline-block",
    marginLeft: "10px",
    marginRight: "10px"
  },
  reset: {
    fontFamily: "futura",
    borderRadius: 10,
    marginLeft: "10px",
    marginRight: "10px"
  }
};

moment.updateLocale("fr", localization);
const useStyles = makeStyles(styles);

const Statistics = ({ mode }) => {
  const classes = useStyles();
  const {
    data,
    type,
    myself,
    grouped,
    dropped,
    pickedup,
    interval,
    sizesList,
    oubaboxFilter,
    oubaboxsList,
    startDate,
    endDate,
    downloadStatistics,
    handleFilter,
    setStartDate,
    setEndDate,
    sectorsList,
    sectorFilter,
    toggleResetFilters,
    isModalOpen,
    deliveriesList,
    estatesToUsers,
    estateFilter,
    estatesList,
    toggleModal
  } = StatisticLogic(mode);

  return (myself && oubaboxsList && deliveriesList && estatesToUsers && oubaboxFilter && sectorFilter) ? (
    <>
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        className={classes.modal}
        onClose={() => toggleModal(false)}
        open={isModalOpen}
      >
        <div className={classes.paper}>
          <ConfirmModal
            closeModal={() => toggleModal(false)}
            kind={`Exporter ?`}
            message={`Vous êtes sur le point de télécharger les statistiques du ${startDate.format("DD/MM/YYYY")} au ${endDate.format("DD/MM/YYYY")} au format Excel (.xslx)`}
            makeAction={() => downloadStatistics()}
          />
        </div>
      </Modal>
      <div>
        <Card>
          <CardHeader className={classes.filters}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                label="Date de début"
                labelFunc={date => `${moment(date).format("DD MMMM YYYY")}`}
                value={startDate}
                onChange={date => {
                  date >= endDate && setEndDate(moment(date).add(1, "months"));
                  setStartDate(date);
                }}
              />
              <DatePicker
                autoOk
                label="Date de fin"
                labelFunc={date => `${moment(date).format("DD MMMM YYYY")}`}
                value={endDate}
                onChange={date => {
                  date <= startDate && setStartDate(moment(date).subtract(1, "months"));
                  setEndDate(date);
                }}
              />
            </MuiPickersUtilsProvider>
            <div className={classes.select}>
              <InputLabel id="label">
                Oubabox
              </InputLabel>
              <Select
                multiple
                displayEmpty
                value={oubaboxFilter}
                onChange={({ target }) => handleFilter("oubabox", target.value)}
                // TODO: Replace it by raw string from template litteral
                renderValue={o => `${o.length} Oubabox séléctionée${o.length ? 's' : ''}`}
              >
                {/* TODO: put the new "Select all" button here */}
                <MenuItem value={null}>
                  <em>
                    Filtrer par oubabox
                  </em>
                </MenuItem>
                {oubaboxsList?.map(oubabox => (
                  <MenuItem key={oubabox.uuid} value={oubabox.uuid}>
                    <Checkbox checked={oubaboxFilter?.indexOf(oubabox.uuid) > -1} />
                    {oubabox.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {(myself?.rights?.role_superadmin && mode) && (
              <div className={classes.select}>
                <InputLabel id="label">
                  Résidence
                </InputLabel>
                <Select
                  multiple
                  displayEmpty
                  value={estateFilter}
                  onChange={({ target }) => handleFilter("estate", target.value)}
                  // TODO: Replace it by raw string from template litteral
                  renderValue={e => `${e.length} Résidence${e.length ? 's' : ''} séléctioné${e.length ? 's' : ''}`}
                >
                  <MenuItem value={null}>
                    <em>
                      Filtrer par résidence
                    </em>
                  </MenuItem>
                  {estatesList?.map(estate => (
                    <MenuItem
                      key={estate.uuid}
                      value={estate.uuid}
                    >
                      <Checkbox checked={estateFilter?.indexOf(estate.uuid) > -1} />
                      {estate.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
            <div className={classes.select}>
              <InputLabel id="label">
                Bâtiments
              </InputLabel>
              <Select
                multiple
                displayEmpty
                labelId="Sector"
                id="select-sector"
                value={sectorFilter}
                onChange={({ target }) => handleFilter("sector", target.value)}
                // TODO: Replace it by raw string from template litteral
                renderValue={s => `${s.length} Batiment${s.length ? 's' : ''} séléctioné${s.length ? 's' : ''}`}
              >
                {/* TODO: put the new "Select all" button here */}
                <MenuItem value={null}>
                  <em>
                    Filtrer par bâtiment
                  </em>
                </MenuItem>
                {sectorsList?.map(sector => (
                  <MenuItem
                    key={sector.uuid}
                    value={sector.uuid}
                  >
                    <Checkbox checked={sectorFilter?.indexOf(sector.uuid) > -1} />
                    {sector.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Button
              color="danger"
              className={classes.reset}
              onClick={toggleResetFilters}
            >
              Enlever les filtres
            </Button>
          </CardHeader>
        </Card>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          alignContent="center"
          align="center"
          justify="center"
        >
          {[/*{
            icon: Person,
            title: "Utilisateurs inscrits Oubabox",
            text: data?.users ?? "--"
          }, {
            icon: Person,
            title: "Colis totaux déposés",
            // FIXME: magicNumber multiplicating by 3 all total packages
            text: (deliveriesList?.length) ?? "--"
          }, {
            icon: Timer,
            title: "Temps moyen avant récupération",
            text: data?.averageTime ?? "--"
          }, {
            icon: Storage,
            title: "Colis déposés par jours",
            text: data?.deliveryByDay ?? "--"
          }, {
            icon: Storage,
            title: "Colis retiré par jours",
            text: data?.pickupByDay ?? "--"
          }*/].map(({ icon, title, text, primary, secondary }, index) => (
            <GridItem className={classes.gridItem} key={index}>
              <CardIcon
                Icon={icon}
                title={title}
                text={text}
                primary={primary}
                secondary={secondary}
              />
            </GridItem>
          ))}
        </Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <div style={{ display: "flex" }}>
                <Graph
                  dropped={dropped}
                  pickedup={pickedup}
                  data={data?.aquisition}
                />
                <div style={{ display: "block", alignSelf: "center" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <b style={{
                      fontFamily: "Roboto",
                      color: "#6E6FA9"
                    }}>
                      Dépôt
                    </b>
                    <Switch
                      disableRipple
                      color="primary"
                      checked={dropped ? true : false}
                      onClick={() => handleFilter("dropped", !dropped)}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <b style={{
                      fontFamily: "Roboto",
                      color: "#6E6FA9"
                    }}>
                      Retrait
                    </b>
                    <Switch
                      disableRipple
                      color="primary"
                      checked={pickedup ? true : false}
                      // It's "pickup" and not "pickedUp" to keep alignement in handleFilter
                      onClick={() => handleFilter("pickup", !pickedup)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <Graph
                  type={type}
                  grouped={grouped}
                  interval={interval}
                  data={data.graphData}
                />
                <div style={{  }}>
                  <div>
                    <InputLabel id="label">
                      Intervalle
                    </InputLabel>
                    <Select
                      value={interval}
                      onChange={({ target }) => handleFilter("interval", target.value)
                    }>
                      {[
                        { name: "1 h",    value: 1  },
                        { name: "30 min", value: 2  },
                        { name: "15 min", value: 4  },
                        { name: "1 min",  value: 60 }
                      ].map((term, i) => (
                        <MenuItem key={i} value={term.value}>
                          {term.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <InputLabel id="label">
                      Type
                    </InputLabel>
                    <Select
                      value={type}
                      onChange={({ target }) => handleFilter("type", target.value)
                    }>
                      {["Barres", "Lignes"].map((type, i) => (
                        <MenuItem key={i} value={i}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {!type && (
                    <div style={{ display: "flex" }}>
                      <b style={{
                        alignSelf: "center",
                        fontFamily: "Roboto",
                        color: "#6E6FA9",
                      }}>
                        Regrouper
                      </b>
                      <Switch
                        disableRipple
                        color="primary"
                        checked={grouped ? true : false}
                        onClick={() => handleFilter("grouped", !grouped)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </GridItem>
        </GridContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                "Taille", "Colis Recu",
                "Temps Moyen avant Récupération",
                "Nombre Moyen de Rappel",
                ...(myself?.rights?.role_superadmin ? ["Homme"] : []),
                ...(myself?.rights?.role_superadmin ? ["Femme"] : []),
              ].map((value, key) => (
                <TableCell align="center" key={key}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sizesList.map(({ name, total, mean, remind, boy, girl }, key) => (
              <TableRow className={classes.row} key={key}>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {name}
                </TableCell>
                <TableCell align="center">{total}</TableCell>
                <TableCell align="center">{mean}</TableCell>
                <TableCell align="center">{remind}</TableCell>
                {Number.isInteger(boy) && (
                  <TableCell align="center">{boy}</TableCell>
                )}
                {Number.isInteger(girl) && (
                  <TableCell align="center">{girl}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <div className={classes.container}>
          <Button
            color="primary"
            aria-label="export"
            startIcon={<GetApp />}
            onClick={() => toggleModal(true)}>
            Télécharger les statistiques
          </Button>
        </div> */}
      </div>
    </>
  ) : (
    <div className={classes.circularProgress}>
      <CircularProgress />
    </div>
  );
}

export default Statistics;
