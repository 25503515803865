import { get_axs, post_axs } from "../helpers/fetcher";

export const mailServices = {
  getBasicMailBuild,
  sendInternalMail,
  sendMailToResidents,
};

async function getBasicMailBuild() {
  return get_axs("/mails/basic");
}

async function sendInternalMail(domain, email, content, captcha, token) {
  return post_axs(
    "/mails/basic",
    new URLSearchParams({
      domain,
      email,
      content,
      captcha,
      token,
    })
  );
}

async function sendMailToResidents(data) {
  return post_axs(
    "/mails/residence",
    new URLSearchParams({
      ...data
    })
  );
}
