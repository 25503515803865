import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; // @material-ui/core components
import moment from "moment";
import localization from "moment/locale/fr";
import { bookingServices } from "services";
import { paginationActions, ressourceActions } from "actions";

moment.updateLocale("fr", localization);

const BookingListLogic = () => {
	const dispatch = useDispatch();

	const [startDate, setStartDate] = useState(moment().subtract(1, "months"));
	const [endDate, setEndDate] = useState(moment().add(1, "months"));
	const [query, setQuery] = useState("");

	const [ressource, setRessource] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [page, setPage] = useState(1);

	const { myself } = useSelector(state => state.myself);
	const { ressources } = useSelector(
		state => state.ressourcesFetching
	);
	const [bookingsList, setBookingsList] = useState(undefined);

	const statusBookings = [
		{ id: 1, title: 'Innitié', key: 'initiated' },
		{ id: 2, title: 'Réservé', key: 'booked' },
		{ id: 3, title: 'Annulé', key: 'canceled' },
	]
	const [bookingStatus, setBookingStatus] = useState(statusBookings.filter(it => it.key === 'booked'))

	const toggleSelectedRessource = value => setRessource(value);
	const refreshData = () => setRefresh(!refresh);

	useEffect(() => {
		dispatch(ressourceActions.getAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(paginationActions.getPageBookings(page));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		if (startDate && endDate) {
			(async () => {
				const bookings = await bookingServices.getAllPage(
					startDate?.format("MM/DD/YYYY"),
					endDate?.format("MM/DD/YYYY"),
					query,
					ressource.map(ressource => `&ressourceId=${ressource.id}`),
					bookingStatus.map(status => `&status=${status.key}`),
					page
				);
				if (bookings) setBookingsList(bookings);
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate, page, ressource, bookingStatus, refresh]);

	const toggleQuery = query => {
		setQuery(query);
	};

	const handlePagination = page => {
		setPage(page);
	};
	
	const updateBookingStatusFilter = (value) => {
		setBookingStatus(value)
		refreshData()
	}

	return {
		bookingsList,
		endDate,
		myself,
		page,
		query,
		ressource,
		ressources,
		startDate,
		statusBookings,
		bookingStatus,
		handlePagination,
		refreshData,
		setEndDate,
		setStartDate,
		toggleQuery,
		toggleSelectedRessource,
		updateBookingStatusFilter,
	};
};

export default BookingListLogic;
