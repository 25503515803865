import moment from "moment";

export const getAverageBookingsPrice = (bookings) => (
  bookings.filter(r => (r.status === "booked" &&
    r?.payflow_in_id && r?.payflow_out_id
  )).map(b => b?.payflow_out?.amount || 0)
  .reduce((a, b) => a + b, 0) / 100
);

export const getAverageBookingDuration = bookings => {
  const datas = bookings?.map(booking =>
    moment(booking.end_at).diff(moment(booking.begin_at), "hours"));
  return (datas?.reduce((a, b) => a + b, 0) / (datas?.length || 1))
    .toFixed(1).replace(/(\.0+|0+)$/, '');
}

export const getAverageBookingsNumber = (bookings) => bookings.length;

export const getBookingsStatsByRessourceID = (bookings, ressourceID) => {
  const getDiff = (start, end) => moment(end).diff(moment(start), "days");
  const reservations = bookings.filter(booking => booking.ressource_id === ressourceID);
  return {
    amount: reservations.filter(r => r.status === "booked").length,
    duration: getAverageBookingDuration(reservations.filter(r => r.status === "booked")),
    cancel_number: reservations.filter(r => r.status === "canceled").length,
    anticipation: ((reservations.map(r => getDiff(r.created_at, r.begin_at)).reduce((a, c) => a + c, 0) / reservations.length) || 0).toFixed(1).replace(/(\.0+|0+)$/, ''),
    price: getAverageBookingsPrice(reservations)
  };
};

export const getAverageUsersLogged = (users) => {
    //return users.reduce((a, c) => )
};

export const getAverageNewUsersNumber = (users) => {

};

export const getAverageUsersGender = (users) => {

};

export const getAverageUsersPendingTime = (users) => {

};

// TODO: to comment
export const getInterval = ({ start, end, segmentation }) => (
  [ ...new Array((segmentation || 9) + 1) ].map((_, i) => ({
    name: moment(start).add((((end.diff(start) / 1000) / segmentation) || 1) * (i + 0.5), "seconds").format("DD/MM/YY"),
    begin: moment(start).add((((end.diff(start) / 1000) / segmentation) || 1) * i, "seconds").format(),
    end: moment(start).add((((end.diff(start) / 1000) / segmentation) || 1) * (i + 1), "seconds").format()
  }))
);

// TODO: to comment
export const getData = ({
  bookings, users, pendingUsers, notActivatedUsers, start, end, filter
}) => {
  const result = getInterval({ start, end, segmentation: 9 });
  const estateToUsers = users?.map(user => user.estateToUsers).reduce((a, c) => [ ...a, ...c ], []);
  return result.map(data => ({
    name: data.name,
    "Utilisateurs n'ayant pas activé leur compte": users?.filter(user => (
      moment(user.activatedAt) > moment(data.begin) && moment(user.activatedAt) < moment(data.end)
    )).length || 0,
    "Comptes Ouba créés parmi vos résidents": users?.filter(user => (
      moment(user.createdAt) > moment(data.begin) && moment(user.createdAt) < moment(data.end)
    )).length || 0,
    "Associations acceptées": estateToUsers?.filter(eTu =>
      eTu.state === "accepted" && moment(eTu.updatedAt) > moment(data.begin) && moment(eTu.updatedAt) < moment(data.end)
    ).length || 0,
    "Associations refusées": estateToUsers?.filter(eTu =>
      eTu.state === "denied" && moment(eTu.updatedAt) > moment(data.begin) && moment(eTu.updatedAt) < moment(data.end)
    ).length || 0,
    "Associations désactivées": estateToUsers?.filter(eTu =>
      eTu.state === "disabled" && moment(eTu.updatedAt) > moment(data.begin) && moment(eTu.updatedAt) < moment(data.end)
    ).length || 0,
    "Associations en attente de validation": estateToUsers?.filter(eTu =>
      eTu.state === "pending" && moment(eTu.updatedAt) > moment(data.begin) && moment(eTu.updatedAt) < moment(data.end)
    ).length || 0,
  }));
}
