import { applyMiddleware, createStore } from "redux";

import thunkMiddleware from "redux-thunk";
//import { createLogger } from "redux-logger";
import rootReducer from "../reducers";
// import { composeWithDevTools } from "redux-devtools-extension";

// const persistConfig = {
//   key: "root",
//   storage,
//   stateReconciler: autoMergeLevel2,
//   blacklist: ["csrfProtection"], // which reducer want to store
// };

//const loggerMiddleware = createLogger();
// const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  rootReducer,
  // composeWithDevTools(
  applyMiddleware(thunkMiddleware),
  //)
);

export { store };
