import { siteConstants } from "../constants/";

// const initialState = user ? { loggedIn: true, user } : {};
export function sitesFetching(state = {}, action) {
  switch (action.type) {
    case siteConstants.GETALL_REQUEST:
      return {
        loadingSites: true,
      };
    case siteConstants.GETALL_SUCCESS:
      return {
        loadingSites: false,
        sitesLoaded: true,
        sites: action.list,
      };
    case siteConstants.GETALL_FAILURE:
      return {};
    default:
      return state;
  }
}

export function siteSelection(state = {}, action) {
  switch (action.type) {
    case siteConstants.GETSITE_REQUEST:
      return {
        loadingSite: true,
        site: action.item,
      };
    case siteConstants.GETSITE_SUCCESS:
      return {
        loadingSite: false,
        siteLoaded: true,
        site: action.item,
      };
    case siteConstants.GETSITE_FAILURE:
      return {};
    default:
      return state;
  }
}
