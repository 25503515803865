import React, { useEffect } from "react";
import Table from "components/Table/Table.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Pagination from "@material-ui/lab/Pagination";
import GridContainer from "components/Grid/GridContainer.js";
import Form2ns from "components/FormUsers/Form2ns";
import ConfirmModal from "components/ConfirmModal";
import Snackbar from "components/Snackbar/Snackbar.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
// @material-ui/core components
import {
	Modal,
	Backdrop,
	Button as MUIButton
} from "@material-ui/core";
import {ReactComponent as AddIcon} from "assets/img/svg/AddIcon.svg";
import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import TwoNListLogic from "./../TabLogics/TwoNListLogic";

const useStyles = makeStyles(styles);

const paginationStyles = makeStyles(() => ({
		ul: {
				"& .MuiPaginationItem-root": {
						color: "#6E6FA9",
				},
				"& .Mui-selected": {
						backgroundColor: "#6E6FA9",
						color: "#E7E6ED",
				},
		},
}));

export default function TwoNListView(props) {
	
	const classes = useStyles();
	const paginationClasses = paginationStyles();
	
	const {
		twoNList,
		twoNCount,
		handlePagination2n,
		toggleModal2n,
		toggleConfirmBookingDestroy,
		limits,
		pageTwoN,
		twoNModalOpened,
		submitNew2n,
		newTwoNInfo,
		formTwoNValid,
		modalTwoNType,
		formLocationList,
		handle2nFormChange,
		bookingDestroy,
		modalMessageBooking,
		deleteBooking,
		refreshData,
		notifColor,
		notifMessage,
		openNotif,
		setOpenNotif
	} = TwoNListLogic();
	
	useEffect(() => {
		refreshData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);

	return (
		<>
		<Snackbar
				place="tr"
				message={notifMessage}
				open={openNotif}
				close
				icon={
						notifColor === "success"
								? CheckCircleIcon
								: notifColor === "danger"
										? WarningIcon
										: NotificationsIcon
				}
				closeNotification={() => setOpenNotif(false)}
				color={notifColor}
		/>
		{bookingDestroy && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{timeout: 500}}
						className={classes.modal}
						onClose={() => toggleConfirmBookingDestroy(null)}
						open={!!bookingDestroy}
				>
						<div className={classes.paper}>
								<ConfirmModal
										closeModal={() => toggleConfirmBookingDestroy(null)}
										kind={modalMessageBooking()}
										makeAction={() => deleteBooking(bookingDestroy.uuid)}
								/>
						</div>
				</Modal>
		)}
		{twoNModalOpened && newTwoNInfo && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{
								timeout: 500,
						}}
						className={classes.modal}
						onClose={() => toggleModal2n()}
						open={twoNModalOpened}
				>
						<div
								className={classes.paper}
								style={{
										maxHeight: "80%",
								}}
						>
								<Form2ns
										formTwoNValid={formTwoNValid}
										modalTwoNType={modalTwoNType}
										formLocationList={formLocationList}
										newTwoNInfo={newTwoNInfo}
										handle2nFormChange={handle2nFormChange}
										submitNew2n={submitNew2n}
										toggleModal2n={toggleModal2n}
								/>
						</div>
				</Modal>
		)}
		<GridItem xs={12} sm={12} md={12}>
				<CardBody>
						<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
										<MUIButton
												className={classes.button}
												onClick={() => toggleModal2n()}
												startIcon={<AddIcon/>}
												style={{width: "auto"}}
										>
												Ajouter un nouveau 2N
										</MUIButton>
								</GridItem>
						</GridContainer>
				</CardBody>
				<GridItem xs={12} sm={12} md={12}>
						{twoNList && twoNList.length === 0 && (
								<h4>Aucun 2N à afficher</h4>
						)}
						{twoNList && twoNList.length > 0 && (
								<Table
										tableHeaderColor="primary"
										tableType="booking"
										tableHead={[
												"Titre",
												"IP / Domaine",
												"Port",
												"Utilisateur",
												"Mot de passe",
												"Emplacement",
												"",
										]}
										tableData={
												twoNList &&
												twoNList.map((twoN, i) => {
														const TwoNList = {
																label: twoN?.label,
																host: twoN?.host,
																port: twoN?.port,
																user: twoN?.username,
																password: (
																		<div
																				style={{
																						display: "flex-end",
																				}}
																		>
																				<Button
																						size="sm"
																						onClick={() => {
																								const isChromium = !!window.chrome;
																								const copyToClipboard = (text) => {
																										navigator.clipboard.writeText(text).then(() => {
																												console.log("Copied to Clipboard Succefully");
																										}, () => {
																												console.error("Clipboard Copy Failed");
																										});
																								}
																								if (isChromium){
																										navigator.permissions.query({name: "clipboard-write"}).then((result) => {
																											if (result.state === "granted" || result.state === "prompt") {
																													copyToClipboard(twoN.password)
																											}else{
																													console.warn("User did not authorized clipboard copy");
																											}
																										});
																								}else{
																										copyToClipboard(twoN.password)
																								}
																										
																						}}
																						color="primary"
																				>Copier</Button>
																		</div>
																),
																location: twoN?.location?.title,
																actions: (
																		<div
																				style={{
																						display: "flex-end",
																				}}
																		>
																				<Button
																						size="sm"
																						target="_blank"
																						href={`https://${twoN.host}:${twoN.port}`}
																						color="primary"
																				>
																						Accéder
																				</Button>
																				<Button
																						style={{marginLeft: "2%"}}
																						onClick={() => toggleModal2n(twoN)}
																						size="sm"
																						color="warning"
																				>
																						Éditer
																				</Button>
																				<Button
																						style={{marginLeft: "2%"}}
																						size="sm"
																						onClick={() =>
																								toggleConfirmBookingDestroy("2n", twoN)
																						}
																						color="danger"
																				>
																						Supprimer
																				</Button>
																		</div>
																),
														};
														return Object.values(TwoNList);
												})
										}
								/>
						)}
						{twoNCount > 0 && (
								<div
										style={{
												display: "flex",
												justifyContent: "center",
												paddingTop: 100,
										}}
								>
										<Pagination
												count={Math.ceil(twoNCount / limits["2n"])}
												page={pageTwoN}
												onChange={(event, pageNumber) => {
														handlePagination2n(pageNumber);
												}}
												classes={{ul: paginationClasses.ul}}
												size="large"
										/>
								</div>
						)}
				</GridItem>
		</GridItem>
		</>
	)
}