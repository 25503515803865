import React, { useEffect } from "react";
import SubscriptionListLogic from "./../TabLogics/SubscriptionListLogic";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import { TextField } from "@material-ui/core";
import Table from "components/Table/Table.js";
import moment from "moment";
import localization from "moment/locale/fr";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";

const textStyles = makeStyles({
	formControlRoot: {
		fontFamily: "futura",
		color: "#6E6FA9",
		borderRadius: "7px",
		"& label.Mui-focused": {
			color: "#6E6FA9",
		},
	},
	inputLabelRoot: {
		color: "#6E6FA9",
		fontFamily: "futura",
		"&.focused": {
			color: "#6E6FA9",
		},
	},
});

const paginationStyles = makeStyles(() => ({
	ul: {
		"& .MuiPaginationItem-root": {
			color: "#6E6FA9",
		},
		"& .Mui-selected": {
			backgroundColor: "#6E6FA9",
			color: "#E7E6ED",
		},
	},
}));

moment.updateLocale("fr", localization);

const useStyles = makeStyles(styles);

export default function SubcriptionListView(props){
	
	const textClasses = textStyles();
	const classes = useStyles();
	const paginationClasses = paginationStyles();
	
	const {
		refreshData,
		myself,
		statusSub,
		updateSubscriptionStatusFilter,
		subscriptionStatus,
		handlePaginationSubscription,
		limits,
		subscriptions,
		subscriptionsCount,
		pageSubscription
	} = SubscriptionListLogic();
	
	useEffect(() => {
		refreshData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	return (
		<div>
			{!myself?.rights?.role_superadmin && !myself?.rights?.role_bookings_subscriptions_read ? (
				<GridItem xs={12} sm={12} md={6}>
					<h4>
						{
							"Vous n'avez pas la permission de voir les abonnements"
						}
					</h4>
				</GridItem>
			) : (<div>
				<Card
					style={{
						boxShadow: "none",
						marginTop: 0,
						paddingTop: 0,
					}}>
					<CardHeader
						style={{
							borderRadius: 1,
							paddingTop: 0,
							border: "none",
							boxShadow:
								"inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
						}}
					>
						<GridContainer>
							<GridItem xs={12} sm={12} md={4}>
								<Autocomplete
									multiple
									id="tags-groups"
									options={statusSub}
									getOptionSelected={(option, value) => option.id === value.id}
									getOptionLabel={option => option.title}
									onChange={(_, value) => {
										updateSubscriptionStatusFilter(value);
									}}
									value={subscriptionStatus}
									renderInput={params => (
										<TextField
											{...params}
											variant="standard"
											classes={{
												root: textClasses.formControlRoot,
											}}
											label="Filtrer par statut"
											placeholder="statut sélectionnés"
											InputProps={{
												...params.InputProps,
												className: classes.input,
											}}
											InputLabelProps={{
												classes: {
													root: textClasses.inputLabelRoot,
												},
											}}
										/>
									)}
								/>
							</GridItem>
						</GridContainer>
					</CardHeader>
				</Card>
				<GridItem xs={12} sm={12} md={12}>
					{subscriptions && subscriptions?.length === 0 && (
						<h4>
							Aucun abonnement à afficher
						</h4>
					)}
					{subscriptions && subscriptions?.length > 0 && (<Table
						tableHeaderColor="primary"
						tableType="booking"
						tableHead={[
							"Ressource",
							"Prénom",
							"Nom",
							"Email",
							"Periode",
							"Fin le",
							"Montant",
							"Statut",
							"Créé le"
						]}
						tableData={subscriptions &&
							subscriptions.map(sub => {
								let periodType = "";
								if (sub?.period?.period_type == "MONTH"){
									periodType = "mois";
								}else if(sub?.period?.period_type == "YEAR"){
									periodType = "ans";
								}else if(sub?.period?.period_type == "WEEK"){
									periodType = "semaines";
								}
								const amountt = sub?.payflow_out?.amount;
								const period = `${sub?.period?.period_number} ${periodType}`;
								const subList = {
									ressource: sub?.ressource_title,
									firstName: sub?.user?.first_name,
									lastName: sub?.user?.last_name,
									email: sub?.user?.email,
									period: period,
									end: (sub?.end_date ? moment(sub.end_date).format("DD/MM/YYYY") : ""),
									amount : amountt ? `${amountt/100} €` : "---",
									status: (sub?.status ? statusSub.find(it => it.key === sub.status).title : ""),
									date: (sub?.created_at ? moment(sub.created_at).format("DD/MM/YYYY") : "")
								};
								return Object.values(subList);
							})
						}
					/>)
					}
					{subscriptionsCount > 0 && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								paddingTop: 100,
							}}
						>
							<Pagination
								count={Math.ceil(subscriptionsCount / limits.subscription)}
								page={pageSubscription}
								onChange={(event, pageNumber) => {
									handlePaginationSubscription(pageNumber);
								}}
								classes={{ ul: paginationClasses.ul }}
								size="large"
							/>
						</div>
					)}
				</GridItem>
			</div>)
			}
		</div>
	)
}