import { get_axs, post_axs, put_axs, remove_axs } from "../helpers/fetcher";

export const featureServices = {
  add,
  attachAttribute,
  destroy,
  detachAttribute,
  disable,
  enable,
  getAll,
  getAllOfModule,
  update,
  updatePosition,
};

async function add(data) {
  return post_axs(
    "/features",
    new URLSearchParams({
      ...data,
    })
  );
}

async function attachAttribute(featureUUID, attributeUUID) {
  return post_axs(
    `/features/${featureUUID}/attribute`,
    new URLSearchParams({
      attribute_uuid: attributeUUID,
    })
  );
}

async function destroy(featureUUID) {
  return remove_axs(`/features/${featureUUID}`);
}

async function detachAttribute(featureUUID, attributeUUID) {
  return remove_axs(
    `/features/${featureUUID}/attribute`,
    new URLSearchParams({
      attribute_uuid: attributeUUID,
    })
  );
}

async function disable(featureUUID) {
  return put_axs(`/features/${featureUUID}/disable`);
}

async function enable(featureUUID) {
  return put_axs(`/features/${featureUUID}/enable`);
}

async function getAll() {
  return get_axs("/features");
}

async function getAllOfModule(moduleIdentifier) {
  return get_axs(`/features/of/module/${moduleIdentifier}`);
}

async function update(featureUUID, data) {
  return put_axs(
    `/features/${featureUUID}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function updatePosition(featureUUID, position) {
  return put_axs(
    `/features/${featureUUID}/position`,
    new URLSearchParams({
      position,
    })
  );
}
