import React, { useEffect } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import localization from "moment/locale/fr";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";
import MomentUtils from "@date-io/moment";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import BookingListLogic from "./../TabLogics/BookingListLogic";

const textStyles = makeStyles({
	formControlRoot: {
		fontFamily: "futura",
		color: "#6E6FA9",
		borderRadius: "7px",
		"& label.Mui-focused": {
			color: "#6E6FA9",
		},
	},
	inputLabelRoot: {
		color: "#6E6FA9",
		fontFamily: "futura",
		"&.focused": {
			color: "#6E6FA9",
		},
	},
});

const paginationStyles = makeStyles(() => ({
	ul: {
		"& .MuiPaginationItem-root": {
			color: "#6E6FA9",
		},
		"& .Mui-selected": {
			backgroundColor: "#6E6FA9",
			color: "#E7E6ED",
		},
	},
}));

moment.updateLocale("fr", localization);

const useStyles = makeStyles(styles);

export default function BookingListView(props){
	const textClasses = textStyles();
	const classes = useStyles();
	const paginationClasses = paginationStyles();
	
	const {
		myself,
		startDate,
		endDate,
		setEndDate,
		setStartDate,
		refreshData,
		toggleQuery,
		query,
		ressources,
		toggleSelectedRessource,
		ressource,
		statusBookings,
		updateBookingStatusFilter,
		bookingStatus,
		bookingsList,
		page,
		handlePagination
	} = BookingListLogic();
	
	useEffect(() => {
		refreshData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	if (bookingsList){
		return (
			<GridItem xs={12} sm={12} md={12}>
				{!myself?.rights?.role_superadmin && !myself?.rights?.role_bookings_read ? (
					<GridItem xs={12} sm={12} md={6}>
						<h4>
							{
								"Vous n'avez pas la permission de voir les réservations"
							}
						</h4>
					</GridItem>
				) : (
					<div>
						<Card
							style={{
								boxShadow: "none",
								marginTop: 0,
								paddingTop: 0,
							}}>
							<CardHeader
								style={{
									borderRadius: 1,
									paddingTop: 0,
									border: "none",
									boxShadow:
										"inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
								}}
							>
								<GridContainer>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<GridItem xs={12} sm={12} md={3}>
											<DatePicker
												autoOk
												label="Date de début"
												labelFunc={date => `${moment(date).format("DD MMMM YYYY")}`}
												value={startDate}
												onChange={date => {
													date >= endDate && setEndDate(moment(date).add(1, "months"));
													setStartDate(date);
												}}
												InputProps={{ className: classes.input }}
												InputLabelProps={{
													classes: {
														root: textClasses.inputLabelRoot,
													},
												}}
												classes={{ root: textClasses.formControlRoot }}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<DatePicker
												autoOk
												label="Date de fin"
												labelFunc={date => `${moment(date).format("DD MMMM YYYY")}`}
												value={endDate}
												onChange={date => {
													date <= startDate && setStartDate(moment(date).subtract(1, "months"));
													setEndDate(date);
												}}
												InputProps={{ className: classes.input }}
												InputLabelProps={{
													classes: {
														root: textClasses.inputLabelRoot,
													},
												}}
												classes={{ root: textClasses.formControlRoot }}
											/>
										</GridItem>
									</MuiPickersUtilsProvider>
									<GridItem xs={12} sm={12} md={6}>
										<TextField
											fullWidth
											variant="standard"
											classes={{
												root: textClasses.formControlRoot,
											}}
											label="Rechercher par mot clé"
											onChange={ev => {
												//if (ev.key === "Enter") {
													ev.preventDefault();
													refreshData();
												//}
											}}
											inputProps={{
												// placeholder: "Rechercher par mot clé",
												className: classes.input,
												onChange: event => {
													toggleQuery(event.target.value);
												},
											}}
											InputLabelProps={{
												classes: {
													root: textClasses.inputLabelRoot,
												},
											}}
											value={query}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									{ressources && (<GridItem xs={12} sm={12} md={6}>
										<Autocomplete
											multiple
											id="tags-groups"
											options={ressources}
											getOptionLabel={option => option.title}
											onChange={(_, value) => {
												toggleSelectedRessource(value);
											}}
											value={ressource}
											renderInput={params => (
												<TextField
													{...params}
													variant="standard"
													classes={{
														root: textClasses.formControlRoot,
													}}
													label="Filtrer par ressource"
													placeholder="ressources sélectionnées"
													InputProps={{
														...params.InputProps,
														className: classes.input,
													}}
													InputLabelProps={{
														classes: {
															root: textClasses.inputLabelRoot,
														},
													}}
												/>
											)}
										/>
									</GridItem>)
									}
									<GridItem xs={12} sm={12} md={6}>
										<Autocomplete
											multiple
											id="tags-booking-status"
											options={statusBookings}
											getOptionSelected={(option, value) => option.id === value.id}
											getOptionLabel={option => option.title}
											onChange={(_, value) => {
												updateBookingStatusFilter(value);
											}}
											value={bookingStatus}
											renderInput={params => (
												<TextField
													{...params}
													variant="standard"
													classes={{
														root: textClasses.formControlRoot,
													}}
													label="Filtrer par statut"
													placeholder="statut sélectionnés"
													InputProps={{
														...params.InputProps,
														className: classes.input,
													}}
													InputLabelProps={{
														classes: {
															root: textClasses.inputLabelRoot,
														},
													}}
												/>
											)}
										/>
									</GridItem>
								</GridContainer>
							</CardHeader>
						</Card>
						<CardBody>
							{bookingsList?.data.rows.length > 0 ? (
								<Table
									tableHeaderColor="primary"
									tableHead={[
										"ID",
										"Statut",
										"Ressource",
										"Date",
										"Début",
										"Fin",
										"Prénom",
										"Nom",
										"Email",
										"Télephone",
										"Code",
										"Montant",
										"Transaction",
									]}
									tableData={bookingsList?.data.rows.map(booking => {
										const bookingList = {
											id: booking?.id,
											status:
												booking?.status === "booked"
													? "Reservé"
													: booking.status === "canceled"
														? "Annulé"
														: booking.status === "initiated"
															? "Initié"
															: "",
											ressource:
												booking.ressource !== null
													? booking.ressource.title
													: "Non indiqué",
											date: moment(booking.created_at).format(
												"DD MMMM YYYY [à] HH[h]mm",
											),
											begin: moment(booking.begin_at).format(
												"DD MMMM YYYY [à] HH[h]mm",
											),
											end: moment(booking.end_at).format(
												"DD MMMM YYYY [à] HH[h]mm",
											),
											firstName: booking.user_first_name,
											lastName: booking.user_last_name,
											email: booking.user_email,
											phoneNumber: booking.sms_number,
											code: booking.access_code,
											price:
												booking.payflow_out != null
													? `${booking.payflow_out.amount / 100.0} €`
													: 0,
											state:
												booking?.payflow_out != null &&
												booking?.payflow_out?.status === "SUCCEEDED"
													? "Confirmé"
													: booking?.payflow_out?.status === "CREATED"
														? "En attente"
														: booking?.payflow_out?.status === "FAILED"
															? "Échec"
															: "Aucune",
										};
										return Object.values(bookingList);
									})}
								/>
							) : (
								<h4>Aucune réservation à afficher</h4>
							)}
						</CardBody>
						<CardFooter
							style={{ display: "flex", justifyContent: "center" }}
						>
							{bookingsList?.data.rows.length > 0 && (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										paddingTop: 100,
									}}
								>
									<Pagination
										count={bookingsList?.data.meta.pageCount}
										page={page}
										onChange={(event, pageNumber) => {
											// if(!searchEnabled || !groupsFilter || !rolesFilter || !sectorsFilter)
											handlePagination(pageNumber);
										}}
										classes={{ ul: paginationClasses.ul }}
										size="large"
									/>
								</div>
							)}
						</CardFooter>
					</div>
				)}
			</GridItem>
		)
	}else{
		return (
			<div className={classes.circularProgress}>
				<CircularProgress />
			</div>
		);
	}
}