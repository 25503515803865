import React from "react";
import IncidentsSettingsLogic from "./IncidentsSettingsLogic";

import {
  CircularProgress,
  makeStyles,
  Switch,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

// core components
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import ClassIcon from "@material-ui/icons/Class";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(styles);

export default function IncidentsSettings(props) {
  const classes = useStyles();

  const {
    allCategoryList,
    notifColor,
    notifMessage,
    openNotif,
    handleChangeCategory,
    setOpenNotif,
    submitCategoriesSettings,
  } = IncidentsSettingsLogic();

  if (allCategoryList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Catégories",
                tabIcon: ClassIcon,
                tabContent: (
                  <div>
                    <Button
                      onClick={submitCategoriesSettings}
                      style={{ marginTop: '1%', marginLeft: '1%', marginBottom: '1%' }}
                      color="success"
                    >Appliquer</Button>
                    <Table
                      tableHeaderColor="primary"
                      tableType="categories"
                      tableHead={[
                        "Titre",
                        "Catégorie",
                        "Désactivé / Activé",
                      ]}
                      tableData={
                        allCategoryList &&
                        allCategoryList?.map(category => {
                          const incidentCategory = {
                            title: category?.title,
                            category: category?.main?.title,
                            edit: (
                              <Switch
                                checked={category.activated}
                                onChange={e => handleChangeCategory(e, category)}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            )
                          }
                          return Object.values(incidentCategory);
                        })
                      }
                    />
                    <Button
                      onClick={submitCategoriesSettings}
                      style={{ marginTop: '1%', marginLeft: '1%' }}
                      color="success"
                    >Appliquer</Button>
                  </div>
                ),
              },
            ]}
          />
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}