import { get_axs, post_axs, put_axs, remove_axs } from "../../../helpers/fetcher";

export const reservationRuleServices = {
  add,
  update,
  destroy,
  getAll,
  getItem,
};

function setRule(data) {
  data = {
    title: data["title"],
    content: data["content"],
  };
  return data;
}

async function add(data) {
  data = setRule(data);
  return post_axs(
    "/modules/reservations/rules",
    new URLSearchParams({
      ...data,
    })
  );
}

async function update(uuid, data) {
  data = setRule(data);
  return put_axs(
    `/modules/reservations/rules/${uuid}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function getAll() {
  return get_axs("/modules/reservations/rules");
}

async function getItem(uuid) {
  return get_axs(`/modules/reservations/rules/${uuid}`);
}

async function destroy(uuid) {
  return remove_axs(`/modules/reservations/rules/${uuid}`);
}
