import React, { /*useEffect*/ } from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  Switch,
  InputLabel,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(styles);

export default function FormA1001(props) {
  const classes = useStyles();

  const {
    formA1001Valid,
    modalA1001Type,
    newA1001Info,
    handleA1001FormChange,
    submitNewA1001,
    toggleModalA1001,
  } = props;
  return (
    <Container component="main" maxWidth="md" style={{
      overflowY: 'scroll',
    }}>
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {modalA1001Type ? (
            <Typography variant="h5">Modifier l'A1001</Typography>
          ) : (
            <Typography variant="h5">Ajouter un A1001</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModalA1001()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form
          className={classes.form}
          onSubmit={submitNewA1001}
          noValidate
        >
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Nom du A1001 *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newA1001Info.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleA1001FormChange("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={4}>
              <InputLabel>IP / Domaine *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newA1001Info.host}
                name="host"
                required
                fullWidth
                id="host"
                variant="outlined"
                margin="normal"
                onChange={event =>
                  handleA1001FormChange("host", event.target.value)
                }
              />
            </Grid>
            <Grid item md={2}>
              <InputLabel>Port *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newA1001Info.port}
                name="port"
                required
                fullWidth
                type="number"
                variant="outlined"
                margin="normal"
                InputProps={{
                  inputProps: { min: 0 }
                }}
                id="port"
                onChange={event =>
                  handleA1001FormChange("port", event.target.value)
                }
              />
            </Grid>
            <Grid item md={6}>
            </Grid>
            <Grid item md={'auto'}>
              <InputLabel>TLS faible</InputLabel>
              <Switch
                checked={newA1001Info.is_weak_cert}
                onChange={e => handleA1001FormChange('is_weak_cert', e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Utilisateur *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newA1001Info.username}
                name="username"
                required
                fullWidth
                id="username"
                onChange={event =>
                  handleA1001FormChange("username", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Mot de passe *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newA1001Info.password}
                name="password"
                required
                fullWidth
                id="password"
                type={newA1001Info.showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleA1001FormChange('showPassword', null)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {newA1001Info.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                }}
                onChange={event =>
                  handleA1001FormChange("password", event.target.value)
                }
                autoFocus
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formA1001Valid}
          >
            Enregister
          </Button>
        </form>
      </div>
    </Container>
  )
}

  FormA1001.propTypes = {
  formA1001Valid: PropTypes.bool,
  modalA1001Type: PropTypes.bool,
  newA1001Info: PropTypes.object,
  handleA1001FormChange: PropTypes.func,
  submitNewA1001: PropTypes.func,
  toggleModalA1001: PropTypes.func,
};
