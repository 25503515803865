import React, { useEffect, useMemo } from "react";
import NewsLogic from "./NewsLogic";

import moment from "moment";
import localization from "moment/locale/fr";
// @material-ui/core components

import {
  Backdrop,
  CircularProgress,
  Button as MUIButton,
  makeStyles,
  Chip,
  Modal,
} from "@material-ui/core";
import Tabs from "components/CustomTabs/CustomTabs.js";

// Other front packages
import Table from "components/Table/Table.js";
import Pagination from "@material-ui/lab/Pagination";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
// import MUIRichTextEditor from "mui-rte";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardBody from "components/Card/CardBody";
import AssignmentIcon from "@material-ui/icons/Assignment";
import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import ConfirmModal from "components/ConfirmModal";
import DialogNewsLink from "components/CustomDialog/DialogNewsLink";
import DialogNewsPicture from "components/CustomDialog/DialogNewsPicture";
import FormPosts from "components/FormUsers/FormPosts";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const textStyles = makeStyles({
  formControlRoot: {
    fontFamily: "futura",
    color: "#6E6FA9",
    fontSize: 20,
    borderRadius: "7px",
    "& label.Mui-focused": {
      color: "#6E6FA9",
    },
  },
  inputLabelRootPost: {
    color: "#6E6FA9",
    fontFamily: "futura",
    fontSize: 16,
    "&.focused": {
      color: "#6E6FA9",
    },
  },
  inputLabelRoot: {
    color: "#6E6FA9",
    fontFamily: "futura",
    "&.focused": {
      color: "#6E6FA9",
    },
  },
});
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fbfbfb",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

moment.updateLocale("fr", localization);

const useStyles = makeStyles(styles);

export default function News(props) {
  const paginationClasses = paginationStyles();
  const classes = useStyles();
  const textClasses = textStyles();

  //const paginationClasses = paginationStyles();
  const {
    autoCompleteKey,
    dropzone,
    editModalOpened,
    file,
    filesAssociated,
    formFileList,
    isDragActive,
    isDragAccept,
    isDragReject,
    featuresList,
    formModulesList,
    limitNews,
    linkInfo,
    linkDialog,
    loading,
    myself,
    newsDestroy,
    newsList,
    newsCategory,
    newsCategoryList,
    newsCount,
    newsPublished,
    newsSelected,
    newsTitle,
    notifColor,
    notifMessage,
    openNotif,
    page,
    pictureDialog,
    pictureInfo,
    downloadFile,
    getRootProps,
    getInputProps,
    handleFormFileChange,
    handleLinkChange,
    handleNewsCategory,
    handleNewsFeatures,
    handlePagination,
    handlePictureChange,
    refreshData,
    removeNews,
    setOpenNotif,
    submitLink,
    submitPicture,
    submitUpdateHandler,
    toggleConfirmNewDestroy,
    toggleLinkDialog,
    toggleModal,
    toggleNewsPublished,
    toggleModalNewsType,
    toggleNewsTitle,
    togglePictureDialog,
    uploadfile,
  } = NewsLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const stylePicture = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(dropzone.isDragActive ? activeStyle : {}),
      ...(dropzone.isDragAccept ? acceptStyle : {}),
      ...(dropzone.isDragReject ? rejectStyle : {}),
    }),
    [dropzone.isDragActive, dropzone.isDragReject, dropzone.isDragAccept]
  );

  if (newsList) {
    return (
      <div>
        <Snackbar
          place="tr"
          message={notifMessage}
          open={openNotif}
          close
          icon={
            notifColor === "success"
              ? CheckCircleIcon
              : notifColor === "danger"
                ? WarningIcon
                : NotificationsIcon
          }
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        <DialogNewsLink
          linkDialog={linkDialog}
          linkInfo={linkInfo}
          handleLinkChange={handleLinkChange}
          submitLink={submitLink}
          toggleLinkDialog={toggleLinkDialog}
        />
        <DialogNewsPicture
          isDragActive={isDragActive}
          pictureDialog={pictureDialog}
          pictureInfo={pictureInfo}
          style={stylePicture}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          handlePictureChange={handlePictureChange}
          submitPicture={submitPicture}
          togglePictureDialog={togglePictureDialog}
        />
        <FormPosts
          autoCompleteKey={autoCompleteKey}
          editModalOpened={editModalOpened}
          featuresList={featuresList}
          file={file}
          filesAssociated={filesAssociated}
          formFileList={formFileList}
          formModulesList={formModulesList}
          isDragActive={dropzone.isDragActive}
          loading={loading}
          newsCategory={newsCategory}
          newsCategoryList={newsCategoryList}
          newsPublished={newsPublished}
          newsSelected={newsSelected}
          newsTitle={newsTitle}
          style={style}
          textClasses={textClasses}
          downloadFile={downloadFile}
          getRootProps={dropzone.getRootProps}
          getInputProps={dropzone.getInputProps}
          handleFormFileChange={handleFormFileChange}
          handleNewsCategory={handleNewsCategory}
          handleNewsFeatures={handleNewsFeatures}
          submitUpdateHandler={submitUpdateHandler}
          toggleLinkDialog={toggleLinkDialog}
          toggleModal={toggleModal}
          toggleNewsPublished={toggleNewsPublished}
          toggleNewsTitle={toggleNewsTitle}
          togglePictureDialog={togglePictureDialog}
          uploadfile={uploadfile}
        />
        {newsDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmNewDestroy(null)}
            open={newsDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmNewDestroy(null)}
                kind={`Supprimer l'actualité "${newsDestroy.title.slice(0, 24)}${newsDestroy.title.length > 24 ? '...' : ''}" ?`}
                makeAction={() => removeNews(newsDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Articles",
                tabIcon: AssignmentIcon,
                tabOnClick: () => null,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {(myself?.rights?.role_superadmin ||
                      myself?.rights?.role_news_add) && (
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <MUIButton
                                className={classes.button}
                                onClick={() => toggleModalNewsType()}
                                startIcon={<AddIcon />}
                              >
                                Créer une actualité
                              </MUIButton>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      )}
                    <Card
                      style={{
                        boxShadow: "none",
                        marginTop: 0,
                        paddingTop: 0,
                      }}>
                      {!myself?.rights?.role_superadmin && !myself?.rights?.role_news_read ? (
                        <GridItem xs={12} sm={12} md={6}>
                          <h4>
                            {
                              "Vous n'avez pas la permission de voir les articles"
                            }
                          </h4>
                        </GridItem>
                      ) : (
                        <CardBody>
                          {newsCount === 0 ? (
                            <h4>Aucune actualité à afficher</h4>
                          ) :
                            (<Table
                              tableHeaderColor="primary"
                              tableType="news"
                              tableHead={[
                                "Titre",
                                "Catégorie",
                                "Modules",
                                "Créé le",
                                "Modifié le",
                                "Publié",
                                "Fichiers joints",
                                "",
                              ]}
                              tableData={
                                newsList &&
                                newsList
                                  ?.sort((a, b) => a.id < b.id)
                                  ?.map(news => {
                                    const newsList = {
                                      title: (
                                        <div style={{ width: 200 }}>{news?.title}</div>
                                      ),
                                      category: news?.category?.title,
                                      module: news?.features?.length ? (
                                        <p>
                                          {news?.features?.map(f => f.custom_title)}
                                        </p>
                                      ) : (
                                        "Pas de module rattaché"
                                      ),
                                      createdAt: moment(news.created_at).format(
                                        "DD MMMM YYYY [à] HH[h]mm"
                                      ),
                                      updatedAt: moment(news.updated_at).format(
                                        "DD MMMM YYYY [à] HH[h]mm"
                                      ),
                                      published: news?.published ? "Oui" : "Non",
                                      file: news?.files && (
                                        news.files.map((file, i) => (
                                          <Chip key={i}
                                            label={file.name}
                                            color='primary'
                                            onClick={() => downloadFile(file)}
                                            style={{ marginRight: '5px' }}
                                          />
                                        ))),
                                      buttons: (
                                        <div
                                          style={{
                                            display: "flex",
                                          }}
                                        >
                                          {(myself?.rights?.role_superadmin ||
                                            myself.rights.role_news_edit_other ||
                                            myself.rights.user_id ===
                                            news.author_id) && (
                                              <Button
                                                style={{ marginLeft: 'auto' }}
                                                onClick={() => toggleModalNewsType(news)}
                                                size="sm"
                                                color="warning"
                                              >
                                                Éditer
                                              </Button>
                                            )}
                                          {(myself?.rights?.role_superadmin ||
                                            myself?.rights?.role_news_delete_other ||
                                            myself.rights.user_id ===
                                            news.author_id) && (
                                              <Button
                                                size="sm"
                                                color="danger"
                                                style={{ marginLeft: '4%' }}
                                                onClick={() =>
                                                  toggleConfirmNewDestroy(news)
                                                }
                                              >
                                                Supprimer
                                              </Button>
                                            )}
                                        </div>
                                      ),
                                    };
                                    return Object.values(newsList);
                                  })
                              }
                            />)
                          }
                          {newsCount > 0 && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: 100,
                              }}
                            >
                              <Pagination
                                count={Math.ceil(newsCount / limitNews)}
                                page={page}
                                onChange={(event, pageNumber) => {
                                  handlePagination(pageNumber);
                                }}
                                classes={{ ul: paginationClasses.ul }}
                                size="large"
                              />
                            </div>
                          )}
                        </CardBody>)
                      }
                    </Card>
                  </GridItem>
                ),
              },
            ]}
          />
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}
