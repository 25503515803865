import React, { useEffect } from "react";
import ClassifiedSettingsLogic from "./ClassifiedSettingsLogic";

import {
  CircularProgress,
  Modal,
  makeStyles,
  CssBaseline,
  Container,
  Typography,
  IconButton,
  Grid,
  Button as MUIButton,
  Backdrop,
  Select,
  MenuItem,
  Switch,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

// core components
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import ConfirmModal from "components/ConfirmModal";
import Snackbar from "components/Snackbar/Snackbar.js";
import Skeleton from "@material-ui/lab/Skeleton";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";
import CloseIcon from "@material-ui/icons/Close";
import ClassIcon from "@material-ui/icons/Class";
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles(styles);

export default function Classified(props) {
  const classes = useStyles();

  const {
    editModalOpened,
    categoriesList,
    formClassifiedList,
    formMessagesList,
    modalType,
    newSettingsInfo,
    notifColor,
    notifMessage,
    openNotif,
    settingDestroy,
    settingsList,
    deleteSetting,
    handleChangeCategory,
    handleFormChange,
    refreshData,
    setOpenNotif,
    submitCategoriesSettings,
    submitNewSettings,
    toggleModal,
    toggleConfirmSettingDestroy,
  } = ClassifiedSettingsLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (categoriesList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {settingDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmSettingDestroy(null)}
            open={settingDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmSettingDestroy(null)}
                kind={`Supprimer la relation "${settingDestroy?.classified_feature?.custom_title} - ${settingDestroy?.message_feature?.custom_title}" ?`}
                makeAction={() => deleteSetting(settingDestroy.id)}
              />
            </div>
          </Modal>
        )}
        {editModalOpened && newSettingsInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper} style={{
              width: '40%',
              maxWidth: "40%"
            }}>
              <Container component="main" maxWidth="md" style={{
                overflowY: "auto",
              }}>
                <CssBaseline />
                <div>
                  <div className={classes.headerModal}>
                    {modalType ? (
                      <Typography variant="h5">Modifier la relation</Typography>
                    ) : (
                      <Typography variant="h5">Ajouter une relation</Typography>
                    )}
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => toggleModal()}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <form className={classes.form} onSubmit={submitNewSettings} noValidate>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        {formClassifiedList ? (
                          <Select
                            value={newSettingsInfo.classified}
                            style={{ top: "16px" }}
                            id="select-type"
                            fullWidth
                            displayEmpty
                            onChange={event =>
                              handleFormChange("classified", event.target.value)
                            }
                          >
                            <MenuItem value={""}>
                              <em>Aucune séléction</em>
                            </MenuItem>
                            {formClassifiedList &&
                              formClassifiedList.map((it, i) => (
                                <MenuItem key={i} value={it.uuid}>
                                  <div>{it.custom_title}</div>
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          <Typography variant="h3">
                            <Skeleton width="auto" />
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {formMessagesList ? (
                          <Select
                            value={newSettingsInfo.message}
                            style={{ top: "16px" }}
                            id="select-type"
                            fullWidth
                            displayEmpty
                            onChange={event =>
                              handleFormChange("message", event.target.value)
                            }
                          >
                            <MenuItem value={""}>
                              <em>Aucune séléction</em>
                            </MenuItem>
                            {formMessagesList &&
                              formMessagesList.map((it, i) => (
                                <MenuItem key={i} value={it.uuid}>
                                  <div>{it.custom_title}</div>
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          <Typography variant="h3">
                            <Skeleton width="auto" />
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={!newSettingsInfo?.classified?.length || !newSettingsInfo?.message?.length}
                    >
                      Enregister
                    </Button>
                  </form >
                </div >
              </Container >
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Catégories",
                tabIcon: ClassIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    <div>
                      {categoriesList && categoriesList.length === 0 ? (
                        <h4>Aucune catégorie à afficher</h4>
                      ) : (<div>
                        <Button
                          onClick={submitCategoriesSettings}
                          style={{ marginTop: '1%', marginLeft: '1%', marginBottom: '1%' }}
                          color="success"
                        >Appliquer</Button>
                        <Table
                          tableHeaderColor="primary"
                          tableType="links"
                          tableHead={
                            [
                              "Titre",
                              "Type",
                              "Désactivé / Activé"
                            ]
                          }
                          tableData={
                            categoriesList &&
                            categoriesList.map(category => {
                              const categoryList = {
                                title: category?.title,
                                type: category.type === "services" ? "Services" : "Biens",
                                actions: <Switch
                                  checked={category.activated}
                                  onChange={e => handleChangeCategory(e, category)}
                                  color="primary"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />,
                              };
                              return Object.values(categoryList);
                            })
                          }
                        />
                        <Button
                          onClick={submitCategoriesSettings}
                          style={{ marginTop: '1%', marginLeft: '1%' }}
                          color="success"
                        >Appliquer</Button>
                      </div>)
                      }
                    </div>
                  </GridItem>
                )
              },
              {
                tabName: "Réglages",
                tabIcon: SettingsIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MUIButton
                            className={classes.button}
                            onClick={() => toggleModal()}
                            startIcon={<AddIcon />}
                          >
                            Ajouter une relation
                          </MUIButton>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <div>
                      {settingsList && settingsList.length === 0 ? (
                        <h4>Aucune catégorie à afficher</h4>
                      ) : (<div>
                        <Table
                          tableHeaderColor="primary"
                          tableType="links"
                          tableHead={
                            [
                              "Module petite annonce",
                              "Module messagerie",
                              "",
                            ]
                          }
                          tableData={
                            settingsList &&
                            settingsList.map(setting => {
                              const settingList = {
                                classified: setting?.classified_feature?.custom_title,
                                msg: setting?.message_feature?.custom_title,
                                actions: <div style={{
                                  display: "flex",
                                }}>
                                  <Button
                                    style={{ marginLeft: 'auto', }}
                                    size="sm"
                                    onClick={() => toggleModal(setting)}
                                    color="warning"
                                  >
                                    Éditer
                                  </Button>
                                  <Button
                                    size="sm"
                                    style={{ marginLeft: '2%' }}
                                    onClick={() => toggleConfirmSettingDestroy(setting)}
                                    color="danger"
                                  >
                                    Supprimer
                                  </Button>
                                </div>,
                              };
                              return Object.values(settingList);
                            })
                          }
                        />
                      </div>)
                      }
                    </div>
                  </GridItem>
                )
              }
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}