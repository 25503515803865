import { get_axs, post_axs, put_axs, remove_axs } from "../../../helpers/fetcher";

export const reservationA1001Services = {
  add,
  update,
  destroy,
  getAll,
  getItem,
};

async function add(data) {
  return post_axs(
    "/modules/reservations/a1001s",
    new URLSearchParams({
      ...data,
    })
  );
}

async function update(uuid, data) {
  return put_axs(
    `/modules/reservations/a1001s/${uuid}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function getAll() {
  return get_axs("/modules/reservations/a1001s");
}

async function getItem(uuid) {
  return get_axs(`/modules/reservations/a1001s/${uuid}`);
}

async function destroy(uuid) {
  return remove_axs(`/modules/reservations/a1001s/${uuid}`);
}
