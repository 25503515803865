/* eslint-disable react/no-unescaped-entities */
import React, {useState} from "react";
import PropTypes from "prop-types";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    CircularProgress,
    Container,
    CssBaseline,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(styles);

export default function FormRessources(props) {
    const classes = useStyles();

    const {
        formCleaningList,
        formLocationList,
        formModulesList,
        formRessourceValid,
        periodsList,
        formRulesList,
        formTypeList,
        modalRessourceType,
        newRessourceInfo,
        refreshImageRessource,
        handleRessourceFormChange,
        setPeriodsList,
        submitNewRessource,
        toggleModalRessource,
        addPeriodToRessource,
        deletePeriodFromRessource,
    } = props;

    // const [formPeriodValid, setFormPeriodValid] = useState(false);
    const [subscriptionLength, setSubscriptionsLength] = useState("");
    const [subscriptionPeriodType, setSubscriptionPeriodType] = useState("");
    const [subscriptionPeriodPrice, setSubscriptionPeriodPrice] = useState("");

    const formPeriodValid = () => {
        return !!(subscriptionLength && subscriptionPeriodType && subscriptionPeriodPrice);
    };
    
    const SeparatorLine = () => (
        <div
            style={{
                borderWidth: "1px",
                borderStyle:"dashed",
                borderColor: '#aaa',
                width: '100%',
                height: "1px",
                marginTop: '20px',
                marginBottom: '20px',
                marginLeft: '10px',
                marginRight: '10px'
            }}
        />
    );

    return (<Container
        component="main"
        // maxWidth="md"
        style={{
            overflowY: "auto",
        }}
    >
        <CssBaseline/>
        <div className={classes.headerModal}>
            {modalRessourceType ? (<Typography variant="h5"> Modifier la ressource</Typography>) : (
                <Typography variant="h5">Ajouter une ressource</Typography>)}
            <IconButton
                size="small"
                aria-label="delete"
                onClick={() => toggleModalRessource()}
            >
                <CloseIcon/>
            </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewRessource} noValidate>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={8}>
                    <InputLabel>Titre de la ressource *</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.title}
                        name="title"
                        required
                        fullWidth
                        id="title"
                        onChange={event => handleRessourceFormChange("title", event.target.value)}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <InputLabel>Activé</InputLabel>
                    <Switch
                        checked={newRessourceInfo.enabled}
                        onChange={e => handleRessourceFormChange("enabled", e.target.checked)}
                        color="primary"
                        inputProps={{"aria-label": "primary checkbox"}}
                    />
                </Grid>
                <SeparatorLine/>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}><h3>Définition tarifaire</h3></Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Prix fixe de départ</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.booking_price}
                        name="booking_price"
                        required
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputProps={{
                            inputProps: {min: 0, step: 0.01},
                            startAdornment: (<InputAdornment position="start">€</InputAdornment>),
                        }}
                        fullWidth
                        id="booking_price"
                        onChange={event => handleRessourceFormChange("booking_price", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Prix par créneau réservé</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.unit_price}
                        name="unit_price"
                        required
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputProps={{
                            inputProps: {min: 0, step: 0.01},
                            startAdornment: (<InputAdornment position="start">€</InputAdornment>),
                        }}
                        fullWidth
                        id="unit_price"
                        onChange={event => handleRessourceFormChange("unit_price", event.target.value)}
                    />
                </Grid>
                <SeparatorLine/>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}><h3>Contraintes d'associations</h3></Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel>Module(s)</InputLabel>
                    {formModulesList ? (<Select
                        value={newRessourceInfo.features}
                        style={{top: "16px"}}
                        variant="outlined"
                        id="select-type"
                        fullWidth
                        multiple
                        displayEmpty
                        onChange={event => handleRessourceFormChange("features", event.target.value)}
                        renderValue={selected => {
                            if (selected.length === 0) {
                                return <em>Aucune séléction</em>;
                            }
                            return formModulesList
                                .filter(feature => selected.find(it => it === feature.uuid))
                                .map(feature => feature.custom_title)
                                .join(", ");
                        }}
                    >
                        {formModulesList && formModulesList.map((it, i) => (<MenuItem key={i} value={it.uuid}>
                            <div>{it.custom_title}</div>
                        </MenuItem>))}
                    </Select>) : (<Typography variant="h3">
                        <Skeleton width="auto"/>
                    </Typography>)}
                </Grid>
                
                <Grid item xs={12} md={4}>
                    <InputLabel>Type de Ressource</InputLabel>
                    {formTypeList ? (<Select
                        value={newRessourceInfo.type}
                        style={{top: "16px"}}
                        id="select-type"
                        fullWidth
                        variant="outlined"
                        displayEmpty
                        onChange={event => handleRessourceFormChange("type", event.target.value)}
                    >
                        <MenuItem value={""}>
                            <em>Aucune séléction</em>
                        </MenuItem>
                        {formTypeList && formTypeList.map((it, i) => (<MenuItem key={i} value={it.keyid}>
                            <div>{it.name}</div>
                        </MenuItem>))}
                    </Select>) : (<Typography variant="h3">
                        <Skeleton width="auto"/>
                    </Typography>)}
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel>Type de réservation</InputLabel>
                    <Select
                        id="select-type"
                        style={{top: "16px"}}
                        variant="outlined"
                        fullWidth
                        value={newRessourceInfo.booking_method || "INSTANT"}
                        onChange={event => handleRessourceFormChange("booking_method", event.target.value)}
                    >
                        <MenuItem value={""}>
                            <b>Sélectionnez un type de reservation</b>
                        </MenuItem>
                        <MenuItem value={"INSTANT"}>Ponctuel</MenuItem>
                        <MenuItem value={"SUBSCRIBE"}>Abonnement</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel>Emplacement</InputLabel>
                    {formLocationList ? (<Select
                        value={newRessourceInfo.location}
                        style={{top: "16px"}}
                        id="select-type"
                        fullWidth
                        variant="outlined"
                        displayEmpty
                        onChange={event => handleRessourceFormChange("location", event.target.value)}
                    >
                        <MenuItem value={""}>
                            <em>Aucune séléction</em>
                        </MenuItem>
                        {formLocationList && formLocationList.map((it, i) => (<MenuItem key={i} value={it.uuid}>
                            <div>{it.title}</div>
                        </MenuItem>))}
                    </Select>) : (<Typography variant="h3">
                        <Skeleton width="auto"/>
                    </Typography>)}
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel>CGU de la ressource</InputLabel>
                    {formRulesList ? (<Select
                        value={newRessourceInfo.rule}
                        style={{top: "16px"}}
                        id="select-type"
                        fullWidth
                        variant="outlined"
                        displayEmpty
                        onChange={event => handleRessourceFormChange("rule", event.target.value)}
                    >
                        <MenuItem value={""}>
                            <em>Aucune séléction</em>
                        </MenuItem>
                        {formRulesList && formRulesList.map((it, i) => (<MenuItem key={i} value={it.uuid}>
                            <div>{it.title}</div>
                        </MenuItem>))}
                    </Select>) : (<Typography variant="h3">
                        <Skeleton width="auto"/>
                    </Typography>)}
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel>Ménage</InputLabel>
                    {formCleaningList ? (<Select
                        value={newRessourceInfo.cleaning}
                        style={{top: "16px"}}
                        id="select-type"
                        fullWidth
                        variant="outlined"
                        displayEmpty
                        onChange={event => handleRessourceFormChange("cleaning", event.target.value)}
                    >
                        <MenuItem value={""}>
                            <em>Aucune séléction</em>
                        </MenuItem>
                        {formCleaningList && formCleaningList.map((it, i) => (<MenuItem key={i} value={it.uuid}>
                            <div>{it.title}</div>
                        </MenuItem>))}
                    </Select>) : (<Typography variant="h3">
                        <Skeleton width="auto"/>
                    </Typography>)}
                </Grid>
                <SeparatorLine/>
            </Grid>
            {newRessourceInfo.booking_method === "SUBSCRIBE" ? (<Grid container spacing={3}><Grid
                item
                xs={12}
                md={12}
                style={{
                    top: "16px", bottom: "16px",
                }}
            >
                <Accordion
                    variant="outlined"
                    style={{
                        backgroundColor: "#F1F1F1",
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>
                            Ajouter un forfait
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        style={{flexDirection: "column"}}>
                        <Grid
                            container
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                                paddingBottom: "46px",
                                alignItems: "center"
                            }}
                        >
                            <Grid item xs={12} md={2}>
                                <InputLabel>Durée de l'abonnement</InputLabel>
            
                                <TextField
                                    variant="filled"
                                    placeholder="Indiquez une durée"
                                    type="number"
                                    value={subscriptionLength}
                                    InputProps={{
                                        inputProps: {min: 1, pattern: "[0-9]*"},
                                    }}
                                    style={{
                                        top: "16px", bottom: "16px", background: "white"
                                    }}
                                    onChange={event => setSubscriptionsLength(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <InputLabel>Semaines/Mois/Années</InputLabel>
                                <Select
                                    value={subscriptionPeriodType}
                                    id="select-type"
                                    style={{
                                        top: "16px", bottom: "16px",
                                    }}
                                    fullWidth
                                    variant="filled"
                                    displayEmpty
                                    onChange={event => setSubscriptionPeriodType(event.target.value)}
                                >
                                    <MenuItem value={""}>
                                        <em>Aucune séléction</em>
                                    </MenuItem>
                                    <MenuItem value={"WEEK"}>Semaine</MenuItem>
                                    <MenuItem value={"MONTH"}>Mois</MenuItem>
                                    <MenuItem value={"YEAR"}>Année</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <InputLabel>Prix de l'abonnement</InputLabel>
            
                                <TextField
                                    variant="filled"
                                    placeholder="Indiquez un prix"
                                    type="number"
                                    InputProps={{
                                        inputProps: {min: 1, pattern: "[0-9]*"},
                                    }}
                                    style={{
                                        top: "16px", bottom: "16px",
                                    }}
                                    onChange={event => setSubscriptionPeriodPrice(event.target.value * 100)}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    style={{top: "16px"}}
                                    variant="contained"
                                    className={classes.submit}
                                    onClick={async () => {
                                        const period = await addPeriodToRessource(newRessourceInfo.uuid, subscriptionLength, subscriptionPeriodType, subscriptionPeriodPrice)
                                        setPeriodsList([...periodsList, period])
                                    }}
                                    disabled={!formPeriodValid()}
                                >
                                    Ajouter
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Durée</TableCell>
                                                <TableCell>Prix</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {periodsList?.map((period) => (<TableRow key={period.uuid}>
                                                <TableCell>{`${period.period_number} ${period.period_type === "WEEK" ? "Semaines" : period.period_type === "MONTH" ? "Mois" : period.period_type === "YEAR" ? "Années" : "N/A"}`}</TableCell>
                                                <TableCell>{`${period.price / 100} €`}</TableCell>
                                                <TableCell>
                                                    <Button onClick={async () => {
                                                        const deletedPeriod = await
                                                        deletePeriodFromRessource(period.uuid)
                                                        setPeriodsList([...periodsList.filter(period => period.uuid !== deletedPeriod.uuid)])
                                                    }}>Supprimer</Button></TableCell>
                                            </TableRow>))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
            
                    </AccordionDetails>
                </Accordion>
            </Grid><SeparatorLine/></Grid>) : (<Grid container spacing={3}><Grid
                item
                xs={12}
                md={12}
                style={{top: "16px", bottom: "16px"}}
            ></Grid></Grid>)}
            <Grid container spacing={3}>
                <Grid item xs={12}><h3>Règles d'affichage du calendrier</h3></Grid>
                <Grid item xs={12}>
                    <InputLabel>Jours d'ouverture</InputLabel>
                    <FormControlLabel
                        control={<Checkbox
                            checked={newRessourceInfo.weeks_availability[0] === "1"}
                            onChange={e => handleRessourceFormChange("recurrence", e.target.checked, 0)}
                            color="primary"
                            inputProps={{"aria-label": "secondary checkbox"}}
                        />}
                        label="Lundi"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={newRessourceInfo.weeks_availability[1] === "1"}
                            onChange={e => handleRessourceFormChange("recurrence", e.target.checked, 1)}
                            color="primary"
                            inputProps={{"aria-label": "secondary checkbox"}}
                        />}
                        label="Mardi"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={newRessourceInfo.weeks_availability[2] === "1"}
                            onChange={e => handleRessourceFormChange("recurrence", e.target.checked, 2)}
                            color="primary"
                            inputProps={{"aria-label": "secondary checkbox"}}
                        />}
                        label="Mercredi"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={newRessourceInfo.weeks_availability[3] === "1"}
                            onChange={e => handleRessourceFormChange("recurrence", e.target.checked, 3)}
                            color="primary"
                            inputProps={{"aria-label": "secondary checkbox"}}
                        />}
                        label="Jeudi"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={newRessourceInfo.weeks_availability[4] === "1"}
                            onChange={e => handleRessourceFormChange("recurrence", e.target.checked, 4)}
                            color="primary"
                            inputProps={{"aria-label": "secondary checkbox"}}
                        />}
                        label="Vendredi"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={newRessourceInfo.weeks_availability[5] === "1"}
                            onChange={e => handleRessourceFormChange("recurrence", e.target.checked, 5)}
                            color="primary"
                            inputProps={{"aria-label": "secondary checkbox"}}
                        />}
                        label="Samedi"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={newRessourceInfo.weeks_availability[6] === "1"}
                            onChange={e => handleRessourceFormChange("recurrence", e.target.checked, 6)}
                            color="primary"
                            inputProps={{"aria-label": "secondary checkbox"}}
                        />}
                        label="Dimanche"
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InputLabel>Nombre de mois affichés *</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.available_months}
                        name="month"
                        required
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="normal"
                        id="month"
                        onChange={event => handleRessourceFormChange("available_months", event.target.value)}
                    />
                </Grid><Grid item xs={12} md={3}>
                    <InputLabel>Nombre de jours affichés *</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.available_days}
                        name="day"
                        required
                        variant="outlined"
                        type="number"
                        fullWidth
                        margin="normal"
                        id="day"
                        onChange={event => handleRessourceFormChange("available_days", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InputLabel>Heure d'ouverture</InputLabel>
                    <TextField
                        type="time"
                        defaultValue={newRessourceInfo.open_at}
                        name="timeStart"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="timeStart"
                        onChange={event => handleRessourceFormChange("open_at", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InputLabel>Heure de fermeture</InputLabel>
                    <TextField
                        type="time"
                        defaultValue={newRessourceInfo.close_at}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="timeEnd"
                        name="timeEnd"
                        onChange={event => handleRessourceFormChange("close_at", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InputLabel>Nombre d'accès simultanés par créneau *</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.available_units}
                        name="available_units"
                        required
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputProps={{
                            inputProps: {min: 1},
                        }}
                        fullWidth
                        id="available_units"
                        onChange={event => handleRessourceFormChange("available_units", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InputLabel>Durée d'un créneau *</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.interval_value}
                        name="unitTime"
                        required
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputProps={{inputProps: {min: 1}}}
                        fullWidth
                        id="unitTime"
                        onChange={event => handleRessourceFormChange("interval_value", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <InputLabel>Unité de durée *</InputLabel>
                    <Select
                        value={newRessourceInfo.interval_type}
                        style={{top: "16px"}}
                        id="select-type"
                        variant="outlined"
                        fullWidth
                        onChange={event => handleRessourceFormChange("interval_type", event.target.value)}
                    >
                        <MenuItem value={"days"}>
                            <em>Jour</em>
                        </MenuItem>
                        <MenuItem value={"nights"}>
                            <em>Nuit</em>
                        </MenuItem>
                        <MenuItem value={"hours"}>
                            <em>Heure</em>
                        </MenuItem>
                        <MenuItem value={"minutes"}>
                            <em>Minute</em>
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} md={3}>
                    <InputLabel>Accès continu <br/><small>(Activer si unité "Nuit")</small></InputLabel>
                    <Switch
                        checked={newRessourceInfo.interval_unified}
                        onChange={e => handleRessourceFormChange("interval_unified", e.target.checked)}
                        color="primary"
                        inputProps={{"aria-label": "primary checkbox"}}
                    />
                </Grid>
                <SeparatorLine/>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}><h3>Contraintes de réservation</h3></Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Délai entre la réservation et l'occupation (en heure) *</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.booking_delay_hr}
                        name="booking_delay_hr"
                        required
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputProps={{
                            inputProps: {min: 0},
                        }}
                        fullWidth
                        id="booking_delay_hr"
                        onChange={event => handleRessourceFormChange("booking_delay_hr", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Délai minimum d'annulation avant occupation (en heure) *</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.cancel_delay_hr}
                        name="cancel_delay_hr"
                        required
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputProps={{
                            inputProps: {min: 0},
                        }}
                        fullWidth
                        id="cancel_delay_hr"
                        onChange={event => handleRessourceFormChange("cancel_delay_hr", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Délai entre deux réservations pour le réservataire</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.unit_self_delay}
                        name="unit_self_delay"
                        required
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputProps={{
                            inputProps: {min: 0},
                        }}
                        fullWidth
                        id="unit_self_delay"
                        onChange={event => handleRessourceFormChange("unit_self_delay", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Délai entre deux réservations par logement</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.unit_users_delay}
                        name="unit_users_delay"
                        required
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputProps={{
                            inputProps: {min: 0},
                        }}
                        fullWidth
                        id="unit_users_delay"
                        onChange={event => handleRessourceFormChange("unit_users_delay", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Nombre de créneau réservable min *</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.unit_min}
                        name="unit_min"
                        required
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputProps={{
                            inputProps: {min: 1},
                        }}
                        fullWidth
                        id="unit_min"
                        onChange={event => handleRessourceFormChange("unit_min", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Nombre de créneau réservable max *</InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.unit_max}
                        name="unit_max"
                        required
                        variant="outlined"
                        margin="normal"
                        type="number"
                        InputProps={{
                            inputProps: {min: newRessourceInfo.unit_min},
                        }}
                        fullWidth
                        id="unit_max"
                        onChange={event => handleRessourceFormChange("unit_max", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>
                        Nombre de réservation max glissant
                    </InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.max_rolling_bookings}
                        name="max_rolling_bookings"
                        label="Vide ou 0, 1, ..."
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        id="max_rolling_bookings"
                        type="number"
                        onChange={event => handleRessourceFormChange("max_rolling_bookings", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <InputLabel>
                        Quota de réservation <b>[0-9]T[0-9][DMY]</b>
                    </InputLabel>
                    <TextField
                        defaultValue={newRessourceInfo.quota}
                        name="quota"
                        label="ex: Vide ou 5T1Y ou 10T3M ou ..."
                        fullWidth
                        id="quota"
                        onChange={event => handleRessourceFormChange("quota", event.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <InputLabel>Application du quota sur...</InputLabel>
                    <Select
                        value={newRessourceInfo.is_group_access}
                        style={{top: "16px"}}
                        id="select-type"
                        fullWidth
                        onChange={event => handleRessourceFormChange("is_group_access", event.target.value)}
                    >
                        <MenuItem value={true}>
                            <em>Le logement</em>
                        </MenuItem>
                        <MenuItem value={false}>
                            <em>L'utilisateur</em>
                        </MenuItem>
                    </Select>
                </Grid>
                <SeparatorLine/>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}><h3>Autres paramètres</h3></Grid>
                <Grid item md={12}>
                    <InputLabel>Message pour le réservataire</InputLabel>
                    <TextField
                        id="custom_message"
                        value={newRessourceInfo.custom_message}
                        onChange={e => handleRessourceFormChange("custom_message", e.target.value)}
                        multiline
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={newRessourceInfo.warnAdmin}
                            onChange={e => handleRessourceFormChange("warnAdmin", e.target.checked, 2)}
                            color="primary"
                            inputProps={{"aria-label": "secondary checkbox"}}
                        />}
                        label="Avertir les administrateurs lors d'une nouvelle réservation"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <InputLabel>Photo / Image</InputLabel>
                    <div style={{display: "flex"}}>
                        {refreshImageRessource && newRessourceInfo.uuid && (refreshImageRessource[newRessourceInfo.uuid] ? (
                            <CircularProgress/>) : (newRessourceInfo.has_picture && (<img
                            style={{
                                marginTop: "1%", height: "100px", marginRight: "2%",
                            }}
                            src={`${process.env.REACT_APP_CDN_URL}/app/module/reservation/ressources/${newRessourceInfo?.uuid}/image?refresh=${Date.now()}`}
                            alt=""
                            onError={i => {
                                i.target.style.display = "none";
                                i.target.alt = "Aucune image disponible";
                            }}
                        />)))}
                        <input
                            style={{
                                marginTop: "1%", display: "column", alignSelf: "center",
                            }}
                            type="file"
                            name="icon"
                            accept="image/*"
                            onChange={e => handleRessourceFormChange("image", e.target.files[0])}
                        />
                    </div>
                </Grid>
                <SeparatorLine/>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!formRessourceValid}
            >
                Suivant
            </Button>
        </form>
    </Container>);
}

FormRessources.propTypes = {
    formCleaningList: PropTypes.array,
    formLocationList: PropTypes.array,
    formModulesList: PropTypes.array,
    formRessourceValid: PropTypes.bool,
    formPeriodValid: PropTypes.bool,
    formRulesList: PropTypes.array,
    formTypeList: PropTypes.array,
    modalRessourceType: PropTypes.bool,
    newRessourceInfo: PropTypes.object,
    refreshImageRessource: PropTypes.object,
    handleRessourceFormChange: PropTypes.func,
    submitNewRessource: PropTypes.func,
    toggleModalRessource: PropTypes.func,
    addPeriodToRessource: PropTypes.func,
    updatePeriodFromRessource: PropTypes.func,
    deletePeriodFromRessource: PropTypes.func,
};
