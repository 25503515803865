import { useEffect, useState      } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveAs                   } from "file-saver";
import { ressourceActions         } from "../../actions";
import {
  bookingServices,
  siteService,
  fetchOubaboxes,
  sectorServices,
  statisticServices,
} from "../../services";
import moment       from "moment";
import localization from "moment/locale/fr";
import { loadState } from "helpers/localStorage";
moment.updateLocale("fr", localization);

const StatisticsLogic = refresh => {
  const dispatch = useDispatch();
  const { myself, loadedMyself } = useSelector(state => state.myself);
  const { ressources, ressourcesLoaded } = useSelector(
    state => state.ressourcesFetching
  );

  const [startDate,         setStartDate        ] = useState(moment({ d: 1, h: 0, m: 0, s: 0 }));
  const [endDate,           setEndDate          ] = useState(moment());
  const [bookingsList,      setBookingsList     ] = useState();
  const [meta,              setMeta             ] = useState();
  const [usersList,         setUsersList        ] = useState();
  const [notActivatedUsers, setNotActivatedUsers] = useState();
  const [pendingUsers,      setPendingUsers     ] = useState();
  const [estatesList,       setEstatesList      ] = useState();
  const [sectorsList,       setSectorsList      ] = useState();
  const [ressourceFilter,   setRessourceFilter  ] = useState([]);
  const [estateFilter,      setEstateFilter     ] = useState([]);
  const [sectorFilter,      setSectorFilter     ] = useState([]);
  const [priceFilter,       setPriceFilter      ] = useState([0, 1000]);
  const [globalStats,       setGlobalStats      ] = useState(false);
  const [isModalOpen,       setIsModalOpen      ] = useState(false);
  const [displayOubabox,    setDisplay          ] = useState(false);
  const [refreshData                            ] = useState(false);
  const [currency                               ] = useState("€");

  useEffect(() => {
    dispatch(ressourceActions.getAll());
    (async () => {
      const estates  = await siteService.getAll();
      const sectors  = await sectorServices.getAll();
      const oubaboxs = await fetchOubaboxes(loadState("site")?.uuid);
      setEstatesList(estates);
      setSectorsList(sectors);
      setEstateFilter(estates?.map(e => e.uuid)?.filter(e => e === loadState("site")?.uuid));
      setSectorFilter(sectors?.map(sector => sector.id));
      setDisplay(oubaboxs?.length ? true : false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ressourcesLoaded) {
      setRessourceFilter(ressources?.map(ressource => ressource.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ressourcesLoaded]);
  
  useEffect(() => {
    (async () => {
      const bookings = await bookingServices.getAll({
        ressources: ressourceFilter,
        estates:    estateFilter,
        sectors:    sectorFilter,
        startDate:  startDate?.format("MM/DD/YYYY"),
        endDate:    endDate?.format("MM/DD/YYYY"),
        price:      priceFilter,
      });
      const users = await statisticServices.getAllStatistics({
        estates:   estateFilter,
        sectors:   sectorFilter,
        start:     startDate?.format("MM/DD/YYYY"),
        end:       endDate?.format("MM/DD/YYYY"),
        activated: false,
        created:   false,
      });
      const pending = await statisticServices.getAllStatistics({
        estates:   estateFilter,
        sectors:   sectorFilter,
        start:     startDate?.format("MM/DD/YYYY"),
        end:       endDate?.format("MM/DD/YYYY"),
        activated: false,
        created:   false,
        state:     "pending",
      });
      const notActivated = await statisticServices.getAllStatistics({
        estates:   estateFilter,
        sectors:   sectorFilter,
        start:     startDate?.format("MM/DD/YYYY"),
        end:       endDate?.format("MM/DD/YYYY"),
        activated: true,
        created:   false,
      });
      bookings?.success     && setBookingsList(bookings.data);
      users?.success        && setUsersList(users.data);
      pending?.success      && setPendingUsers(pending.data);
      notActivated?.success && setNotActivatedUsers(notActivated.data);
    })();
  }, [startDate, ressourceFilter, estateFilter, sectorFilter, endDate, priceFilter]);

  useEffect(() => {
    setSectorFilter([]);
    setRessourceFilter([]);
    setStartDate(moment({ d: 1, h: 0, m: 0, s: 0 }));
    setEndDate(moment());
    setPriceFilter([0, 1000]);
  }, [globalStats, refresh]);

  const handlePriceFilter  = (_, value) => setPriceFilter(value);
  const toggleResetFilters = ()             => setGlobalStats(!globalStats);
  const toggleModal        = type           => setIsModalOpen(type);

  const handleFilter = (type, item) => {
    type === "ressource" && setRessourceFilter(item);
    type === "estate"    && setEstateFilter(item);
    type === "sector"    && setSectorFilter(item);
  };

  const downloadStatistics = async () => {
    const response = await statisticServices.downloadStatistics({
      start:      startDate?.format("MM/DD/YYYY"),
      end:        endDate?.format("MM/DD/YYYY"),
      ressources: ressourceFilter,
      estates:    estateFilter
    });
    const blob = new Blob([response], {
      type: "application/vnd.ms-excel;charset=utf-8",
    });
    saveAs(
      blob,
      `Statistiques-${startDate?.format("MM/DD/YYYY")}-${endDate?.format(
        "MM/DD/YYYY"
      )}.xlsx`
    );
    setIsModalOpen(false);
  };

  return {
    bookingsList,
    currency,
    myself,
    loadedMyself,
    ressourceFilter,
    handleFilter,
    priceFilter,
    refresh,
    refreshData,
    ressources,
    ressourcesLoaded,
    setStartDate,
    setEndDate,
    startDate,
    sectorsList,
    sectorFilter,
    estatesList,
    estateFilter,
    usersList,
    pendingUsers,
    notActivatedUsers,
    handlePriceFilter,
    downloadStatistics,
    displayOubabox,
    setMeta,
    toggleResetFilters,
    meta,
    endDate,
    isModalOpen,
    toggleModal,
  };
};

export default StatisticsLogic;
