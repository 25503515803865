/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  Switch,
  InputLabel,
  makeStyles,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function FormAlerts(props) {
  const classes = useStyles();

  const {
    formValid,
    modalType,
    newAlertsInfo,
    handleFormChange,
    submitNewAlert,
    toggleModal,
  } = props;
  return (
    <Container component="main" maxWidth="md" style={{
      overflowY: "auto",
    }}>
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {modalType ? (
            <Typography variant="h5">Modifier le lien</Typography>
          ) : (
            <Typography variant="h5">Ajouter un lien</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewAlert} noValidate>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Titre *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newAlertsInfo.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleFormChange("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Contenu *</InputLabel>
              <TextField
                defaultValue={newAlertsInfo.content}
                name="content"
                required
                fullWidth
                id="content"
                onChange={event =>
                  handleFormChange("content", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Type</InputLabel>
              <Select
                value={newAlertsInfo.type}
                style={{ top: "5px" }}
                id="select-type"
                fullWidth
                displayEmpty
                onChange={event =>
                  handleFormChange("type", event.target.value)
                }
              >
                <MenuItem value={"INFO"}>
                  <div>INFO</div>
                </MenuItem>
                <MenuItem value={"SUCCESS"}>
                  <div>SUCCESS</div>
                </MenuItem>
                <MenuItem value={"WARNING"}>
                  <div>WARNING</div>
                </MenuItem>
                <MenuItem value={"DANGER"}>
                  <div>DANGER</div>
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={9}>
            </Grid>
            <Grid item xs={12} md={3}>
            <InputLabel>Activer / Désactiver</InputLabel>
              <Switch
                checked={newAlertsInfo.enabled}
                onChange={e => handleFormChange("enabled", e.target.checked)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formValid}
          >
            Enregister
          </Button>
        </form >
      </div >
    </Container >
  );
}

FormAlerts.propTypes = {
  formValid: PropTypes.bool,
  modalType: PropTypes.bool,
  newAlertsInfo: PropTypes.object,
  handleFormChange: PropTypes.func,
  submitNewAlert: PropTypes.func,
  toggleModal: PropTypes.func,
};
