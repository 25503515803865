import {useEffect, useState} from "react";
import moment from "moment";
import localization from "moment/locale/fr";
import {
		reservationA1001DoorServices,
		reservationA1001Services,
		reservationCleaningServices,
		reservationLocationServices,
} from "services";

moment.updateLocale("fr", localization);

const A1001DoorListLogic = () => {
		const [refresh, setRefresh] = useState(false);

		const limits = {door: 15};

		const [openNotif, setOpenNotif] = useState(false);
		const [notifMessage, setNotifMessage] = useState("");
		const [notifColor, setNotifColor] = useState("info");

		const [newCleaningInfo, setNewCleaningInfo] = useState(undefined);
		const [formCleaningValid, setFormCleaningValid] = useState(false);
		const [isKeyRegenerate, setIsKeyRegenerate] = useState(false);

		const [pageA1001, setPageA1001] = useState(1);
		const [formA1001Valid, setFormA1001Valid] = useState(false);

		const [doorsList, setDoorsList] = useState(undefined);
		const [doorsCount, setDoorsCount] = useState(0);
		const [pageDoors, setPageDoors] = useState(1);
		const [doorsModalOpened, setDoorsModalOpened] = useState(false);
		const [newA1001DoorsInfo, setNewDoorsInfo] = useState(undefined);
		const [modalDoorsType, setModalDoorsType] = useState(false);
		const [formDoorsValid, setFormDoorsValid] = useState(false);
		const [formLocationDoorsList, setFormLocationDoorsList] = useState(undefined);
		const [formA1001List, setFormA1001List] = useState(undefined);

		const [bookingDestroy, setBookingDestroy] = useState(undefined);
		const [bookingDestroyType, setBookingDestroyType] = useState(undefined);

		const toggleConfirmBookingDestroy = (type, booking) => {
				setBookingDestroyType(type);
				setBookingDestroy(booking);
		};

		const deleteBooking = uuid => {
				deleteDoors(uuid);
				toggleConfirmBookingDestroy(null);
		};

		const modalMessageBooking = () => {
			return `Supprimer la porte "${bookingDestroy.title}" ?`;
		};

		const refreshData = () => setRefresh(!refresh);

		useEffect(() => {
				(async () => {
					let door = await reservationA1001DoorServices.getAll();
					if (door) {
							setDoorsCount(door.length);
							setDoorsList(
									door.slice(
											limits.door * (pageDoors - 1),
											limits.door * (pageDoors - 1) + limits.door
									)
							);
					}
				})();
				// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
				pageDoors,
				refresh,
		]);

		const handlePaginationDoors = page => {
				setPageDoors(page);
		};

		const openNotification = (msg, color = "info", time = 6) => {
				setOpenNotif(false);
				setNotifMessage(msg);
				setNotifColor(color);
				setTimeout(() => setOpenNotif(false), time * 1000);
				setTimeout(() => setOpenNotif(true), 100);
		};

		const requestNewIcsKey = async uuid => {
				const res = await reservationCleaningServices.renewIcsKey(uuid);
				if (res) {
						const cleaningInfo = {...newCleaningInfo};
						cleaningInfo.ics_key = res?.ics_key;
						cleaningInfo.dialog = !cleaningInfo.dialog;
						setNewCleaningInfo(cleaningInfo);
						setIsKeyRegenerate(true);
						isFormCleaningValid(cleaningInfo);
				}
		};

		const isFormCleaningValid = cleaning => {
				if (isKeyRegenerate) {
						setFormCleaningValid(true);
						return;
				}
				if (!cleaning?.title?.length || !cleaning?.email?.length)
						setFormCleaningValid(false);
				else setFormCleaningValid(true);
		};

		const isFormA1001Valid = a1001 => {
				if (
						!a1001?.title?.length ||
						!a1001?.host?.length ||
						!a1001?.username?.length ||
						!a1001?.password?.length ||
						isNaN(parseInt(a1001?.port)) ||
						parseInt(a1001?.port) < 0
				)
						setFormA1001Valid(false);
				else setFormA1001Valid(true);
		};

		const fetchRessourceDoors = async () => {
				const a1001 = await reservationA1001Services.getAll();
				if (a1001) {
						setFormA1001List(a1001);
				}
				const location = await reservationLocationServices.getAll();
				if (location) {
						setFormLocationDoorsList(location);
				}
		};

		const toggleModalA1001Doors = (door = null) => {
				fetchRessourceDoors();
				setFormDoorsValid(false);
				setModalDoorsType(door ? true : false);
				const doorInfo = {
						uuid: door?.uuid,
						title: door?.title ? door?.title : "",
						accessPointToken: door?.accessPointToken ? door?.accessPointToken : "",
						doorToken: door?.doorToken ? door?.doorToken : "",
						fastOpen: door?.fastOpen ? door?.fastOpen : 0,
						a1001: door?.a1001?.uuid ? door?.a1001?.uuid : "",
						location: door?.locations ? door?.locations.map(it => it.uuid) : [],
				};
				setNewDoorsInfo(doorInfo);
				setDoorsModalOpened(!doorsModalOpened);
		};

		const submitNewDoors = async event => {
				event.preventDefault();
				const doorInfo = {
						title: newA1001DoorsInfo.title,
						doorToken: newA1001DoorsInfo.doorToken,
						accessPointToken: newA1001DoorsInfo.accessPointToken,
						fastOpen: newA1001DoorsInfo.fastOpen
				};
				if (newA1001DoorsInfo.uuid) {
						const doors = doorsList.find(it => it.uuid === newA1001DoorsInfo.uuid);
						doors.locations
								.filter(
										location => !newA1001DoorsInfo.location.find(it => it === location.uuid)
								)
								.forEach(
										async it =>
												await reservationA1001DoorServices.unsetLocationForDoor(
														newA1001DoorsInfo.uuid,
														it.uuid
												)
								);
						newA1001DoorsInfo.location
								.filter(it => !doors.locations.find(location => location.uuid === it))
								.forEach(
										async it =>
												await reservationA1001DoorServices.setLocationToDoor(
														it,
														newA1001DoorsInfo.uuid
												)
								);
						if (doors?.a1001 && doors?.a1001?.uuid !== newA1001DoorsInfo.a1001)
								await reservationA1001DoorServices.unsetA1001ForDoor(newA1001DoorsInfo.uuid);
						if (
								newA1001DoorsInfo.a1001?.length &&
								(!doors?.a1001 || doors?.a1001?.uuid !== newA1001DoorsInfo.a1001)
						)
								await reservationA1001DoorServices.setA1001ToDoor(
										newA1001DoorsInfo.a1001,
										newA1001DoorsInfo.uuid
								);
						if (
								await reservationA1001DoorServices.update(newA1001DoorsInfo.uuid, doorInfo)
						) {
								openNotification(
										`La porte ${newA1001DoorsInfo?.title} a été modifié`,
										"success"
								);
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de la modification de la prote ${newA1001DoorsInfo?.title}`,
										"danger"
								);
						}
				} else {
						const res = await reservationA1001DoorServices.add(doorInfo);
						if (res) {
								if (newA1001DoorsInfo.a1001?.length)
										await reservationA1001DoorServices.setA1001ToDoor(
												newA1001DoorsInfo.a1001,
												res.uuid
										);
								for (let x of newA1001DoorsInfo.location) {
										await reservationA1001DoorServices.setLocationToDoor(x, res.uuid);
								}
								openNotification(
										`La porte ${newA1001DoorsInfo?.title} a été ajouté`,
										"success"
								);
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de l'ajout de la prote ${newA1001DoorsInfo?.title}`,
										"danger"
								);
						}
				}
				refreshData();
				setDoorsModalOpened(!doorsModalOpened);
				setFormDoorsValid(true);
		};

		const handleDoorsFormChange = (key, value, idx = 0) => {
				const doorInfo = {...newA1001DoorsInfo};
				doorInfo[key] = value;
				setNewDoorsInfo(doorInfo);
				isFormDoorsValid(doorInfo);
		};

		const isFormDoorsValid = door => {
				if (door?.title?.length < 1) return setFormDoorsValid(false);
				return setFormDoorsValid(true);
		};

		const deleteDoors = async uuid => {
				await reservationA1001DoorServices.destroy(uuid);
				openNotification(
						`La porte ${
								doorsList.find(it => it.uuid === uuid)?.title
						} a été supprimé`,
						"success"
				);
				refreshData();
		};

		return {
				bookingDestroy,
				doorsList,
				doorsCount,
				doorsModalOpened,
				formA1001List,
				formA1001Valid,
				formCleaningValid,
				formDoorsValid,
				formLocationDoorsList,
				limits,
				modalDoorsType,
				newCleaningInfo,
				newA1001DoorsInfo,
				notifColor,
				notifMessage,
				openNotif,
				pageA1001,
				pageDoors,
				refresh,
				deleteBooking,
				handleDoorsFormChange,
				handlePaginationDoors,
				modalMessageBooking,
				refreshData,
				requestNewIcsKey,
				setOpenNotif,
				submitNewDoors,
				toggleConfirmBookingDestroy,
				toggleModalA1001Doors
		};
};

export default A1001DoorListLogic;
