/*eslint-disable*/
import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";

// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: "auto",
    "&$expanded": {
      minHeight: "auto"
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({}))(MuiAccordionDetails);

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();

  const { myself } = useSelector(userState => userState.myself);

  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, headTitle, routes, disabledEstate } = props;
  let links = (
    <List className={classes.list}>
      {routes
        .filter(prop => prop.path !== "/panel")
        .map((prop, key) => {
          let activePro = " ";
          let listItemClasses;
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
          });
          let listItemClassesSettings;
          listItemClassesSettings = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop?.settings),
          });
          const whiteFontClassesSettings = classNames({
            [" " + classes.whiteFont]: activeRoute(
              prop.layout + prop?.settings
            ),
          });
          return myself?.rights?.role_superadmin && prop?.settings ? (
            <Accordion
              style={{ backgroundColor: "#1e1b16", width: "100%", boxShadow: "none", marginTop: "5px", }}
              key={key}
              expanded={expanded === prop.name}
              onChange={handleChange(prop.name)}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    className={classNames(
                      classes.expandIcon,
                      whiteFontClasses,
                      whiteFontClassesSettings,
                      {
                        [classes.itemIconRTL]: props.rtlActive,
                      }
                    )}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(
                      classes.itemIcon,
                      whiteFontClasses,
                      whiteFontClassesSettings,
                      {
                        [classes.itemIconRTL]: props.rtlActive,
                      }
                    )}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(
                      classes.itemIcon,
                      whiteFontClasses,
                      whiteFontClassesSettings,
                      {
                        [classes.itemIconRTL]: props.rtlActive,
                      }
                    )}
                  />
                )}
                <div
                  className={classNames(
                    classes.itemText,
                    whiteFontClasses,
                    whiteFontClassesSettings,
                    {
                      [classes.itemTextRTL]: props.rtlActive,
                    }
                  )}
                >
                  {prop.name}
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ display: "grid" }}>
                <NavLink
                  to={prop.layout + prop.path}
                  className={activePro + classes.item}
                  activeClassName="active"
                >
                  <ListItem
                    button
                    className={classes.itemLink + listItemClasses}
                  >
                    <ListItemText
                      primary={prop.menuName ? prop.menuName : "Gérer"}
                      className={classNames(
                        classes.itemText,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive,
                        }
                      )}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>
                {prop.settings && (
                  <NavLink
                    to={prop.layout + prop?.settings}
                    className={activePro + classes.item}
                    activeClassName="active"
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClassesSettings}
                    >
                      <ListItemText
                        primary={props.rtlActive ? prop.rtlName : "Paramétrer"}
                        className={classNames(
                          classes.itemText,
                          whiteFontClassesSettings,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                )}
              </AccordionDetails>
            </Accordion>
          ) : (
            <NavLink
              // onClick={handleChange(prop.name)}
              to={prop.layout + prop.path}
              className={activePro + classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={classes.itemLink + listItemClasses}>
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                ></ListItemText>
              </ListItem>
            </NavLink>
          );
        })}
    </List>
  );
  let sidebarHeader = (
    <div className={classes.logo}>
      <a
        href="/#dashboard"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
      >
        <img src={logo} style={{ display: "flex", flex: 1, padding: 20 }} alt="logo" className={classes.img} />
        <p style={{ display: "flex", flex: 1, justifyContent: "center", marginTop: 5 }}>
          {headTitle}
        </p>
        {( disabledEstate === true && <div style={{background: "#e76e51", borderRadius: 2, height: 4}}></div>)}
        {( disabledEstate === false && <div style={{background: "#30a04b", borderRadius: 2, height: 4}}></div>)}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {sidebarHeader}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {sidebarHeader}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf([
    "purple",
    "blue",
    "green",
    "orange",
    "red",
    "black",
    "brown",
  ]),
  logo: PropTypes.string,
  image: PropTypes.string,
  headTitle: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  disabledEstate: PropTypes.bool
};
