import React from "react";
import PropTypes from "prop-types";

import $ from 'jquery';

// import "assets/css/bootstrap-summernote.css"
import ReactSummernote from 'react-summernote';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-summernote/dist/react-summernote.css';
import 'react-summernote/lang/summernote-fr-FR';

window.jQuery = $;
require('popper.js');
require('bootstrap');

const CustomPost = ({
  newsSelected,
  toggleLinkDialog,
  togglePictureDialog,
}) => {

  return (
    <div>
      <link href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" rel="stylesheet" />
      <ReactSummernote
        onInit={() => {
          const editArea = document.querySelector(".note-editable");
          editArea.innerHTML = newsSelected;
          var linkbtn = '<button id="insertLink" type="button" class="note-btn btn btn-default btn-sm c-btn-link" type="button" aria-label="Link" data-bs-original-title="Link" title="Insérer un lien" tabindex="-1"><i class="note-icon-link"></i></button>';
          var imagebtn = '<button id="insertPicture" type="button" class="note-btn btn btn-default btn-sm c-btn-picture" type="button" aria-label="Picture" data-bs-original-title="Picture" title="Insérer une image" tabindex="-1"><i class="note-icon-picture"></i></button>';
          var fileGroup = '<div class="note-btn-group btn-group note-insert">' + linkbtn + imagebtn + '</div>';
          $(fileGroup).appendTo($('.note-toolbar'));
          $('#insertLink').tooltip({
            container: $('.c-btn-link'),
            className: 'link-dialog',
            trigger: 'hover',
            placement: 'bottom'
          });
          $('#insertLink').click((event) => {
            event.preventDefault()
            toggleLinkDialog()
          });
          $('#insertPicture').tooltip({
            container: $('.c-btn-picture'),
            trigger: 'hover',
            placement: 'bottom'
          });
          $('#insertPicture').click((event) => {
            event.preventDefault()
            togglePictureDialog(window.width)
          });
        }}
        options={{
          placeholder: "Rédigez votre actualité ici...",
          lang: 'fr-FR',
          height: (window.innerHeight - 300),
          dialogsInBody: true,
          disableResizeEditor: true,
          toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'italic', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['height', ['height']],
            ['table', ['table']],
            // ['insert', ['link', 'picture']],
            //<img style="width: 32px;" src="data:image/png;base64,iVBO //src="data:image/jpeg;base64,/9j/4A
            //<a href="(http://)(default protocol)link" target="_blank"(new window)>display</a>
          ]
        }}
        // onChange={(content) => {
        // }}
      />
    </div>
  );
}


CustomPost.propTypes = {
  newsSelected: PropTypes.string,
  toggleLinkDialog: PropTypes.func,
  togglePictureDialog: PropTypes.func,
};


export default CustomPost;