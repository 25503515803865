import React, { useEffect } from "react";
import NewsSettingsLogic from "./NewsSettingsLogic";

import moment from "moment";
import localization from "moment/locale/fr";
// @material-ui/core components

import {
  Backdrop,
  CircularProgress,
  TextField,
  makeStyles,
  Button as MUIButton,
  Modal,
} from "@material-ui/core";
import Tabs from "components/CustomTabs/CustomTabs.js";
import Pagination from "@material-ui/lab/Pagination";
import InputLabel from "@material-ui/core/InputLabel";

// Other front packages
import Table from "components/Table/Table.js";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import ClassIcon from "@material-ui/icons/Class";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import TitleForm from "components/TitleForm";
import ConfirmModal from "components/ConfirmModal";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const textStyles = makeStyles({
  formControlRoot: {
    fontFamily: "futura",
    color: "#6E6FA9",
    borderRadius: "7px",
    "& label.Mui-focused": {
      color: "#6E6FA9",
    },
  },
  inputLabelRoot: {
    color: "#6E6FA9",
    fontFamily: "futura",
    "&.focused": {
      color: "#6E6FA9",
    },
  },
});

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

moment.updateLocale("fr", localization);

const useStyles = makeStyles(styles);

export default function NewsSettings(props) {
  const classes = useStyles();
  const textClasses = textStyles();
  const paginationClasses = paginationStyles();

  const {
    categoryDestroy,
    categoryTitle,
    //currentUser,
    //dialogSelected,
    editCategory,
    editCategoryName,
    isOpen,
    //loading,
    //modalType,
    //newsMeta,
    limitCategory,
    newsCategoryCount,
    newsCategoryList,
    notifColor,
    notifMessage,
    openNotif,
    page,
    //newsUUID,
    //openDialog,
    titleValidate,
    //validationModal,
    handlePagination,
    handleTitle,
    refreshData,
    removeCategory,
    setOpenNotif,
    submitNewCategory,
    toggleConfirmCategoryDestroy,
    toggleCreateModal,
    toggleEdit,
  } = NewsSettingsLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (newsCategoryList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {isOpen && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleCreateModal(false)}
            open={isOpen}
          >
            <div className={classes.paper}>
              <TitleForm
                categoryTitle={categoryTitle}
                titleValidate={titleValidate}
                handleTitle={handleTitle}
                submitNewCategory={submitNewCategory}
                toggleCreateModal={toggleCreateModal}
              />
            </div>
          </Modal>
        )}
        {categoryDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmCategoryDestroy(null)}
            open={categoryDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmCategoryDestroy(null)}
                kind={`Supprimer la catégorie "${categoryDestroy.title}" ?`}
                makeAction={() => removeCategory(categoryDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Catégories",
                tabIcon: ClassIcon,
                tabOnClick: () => null,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MUIButton
                            className={classes.button}
                            onClick={() => toggleCreateModal(true)}
                            startIcon={<AddIcon />}
                          >
                            Ajouter une catégorie
                          </MUIButton>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <div>
                      {newsCategoryList && newsCategoryList.length === 0 ? (
                        <h4>Aucune catégorie à afficher</h4>
                      ) : (<Table
                          tableHeaderColor="primary"
                          tableType="categories"
                          tableHead={[
                            "Nom",
                            "Créé le",
                            "Modifié le",
                            "",
                          ]}
                          tableData={
                            newsCategoryList?.map(category => (
                              Object.values({
                                title: category.edit ? (
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 200 }}
                                    classes={{ root: textClasses.formControlRoot }}
                                    inputProps={{
                                      className: classes.input,
                                      onChange: e => editCategoryName(category, e.target.value)
                                    }}
                                    InputLabelProps={{
                                      classes: { root: textClasses.inputLabelRoot }
                                    }}
                                    value={category.title}
                                  />
                                ) : (
                                  <InputLabel style={{ width: 500 }}>
                                    {category?.title || "Iconnu"}
                                  </InputLabel>
                                ),
                                createdAt: moment(category.created_at).format(
                                  "DD MMMM YYYY [à] HH[h]mm"
                                ),
                                updatedAt: moment(category.updated_at).format(
                                  "DD MMMM YYYY [à] HH[h]mm"
                                ),
                                buttons: /*myself.rights.role_categories_edit && */(
                                  <div style={{
                                    display: "flex",
                                  }}>
                                    {category.edit ? (
                                      <>
                                        <Button
                                          style={{ marginLeft: 'auto' }}
                                          size="sm"
                                          color="success"
                                          onClick={() => editCategory(category)}
                                        >
                                          Enregistrer
                                        </Button>
                                        <Button
                                          size="sm"
                                          color="danger"
                                          onClick={() => toggleEdit(category, false)}
                                          style={{ marginLeft: '4%' }}
                                        >
                                          Annuler
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          size="sm"
                                          color="warning"
                                          onClick={() => toggleEdit(category, true)}
                                        >
                                          Éditer
                                        </Button>
                                        <Button
                                          size="sm"
                                          color="danger"
                                          style={{ marginLeft: '4%' }}
                                          onClick={() => {
                                            toggleConfirmCategoryDestroy(category)
                                          }}
                                        >
                                          Supprimer
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                ),
                              })
                            )).sort()
                          }
                        />)
                      }
                      {newsCategoryCount > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 100,
                          }}
                        >
                          <Pagination
                            count={Math.ceil(newsCategoryCount / limitCategory)}
                            page={page}
                            onChange={(event, pageNumber) => {
                              handlePagination(pageNumber);
                            }}
                            classes={{ ul: paginationClasses.ul }}
                            size="large"
                          />
                        </div>
                      )}
                    </div>
                  </GridItem>
                )
              },
            ]}
          />
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}
