import { get_axs, post_axs, put_axs, get_cdn } from "../../../helpers/fetcher";

export const incidentDeclarationServices = {
  getAllBySite,
  add,
  setStatus,
  getPicture,
};

async function getAllBySite(page, limit, status, category) {
  return get_axs(
    `/modules/incidents/declarations?page=${page}&limit=${limit}${status}${category}`
  );
}

async function add(categoryUUID, description) {
  return post_axs(
    "/modules/incidents/declarations",
    new URLSearchParams({
      category_uuid: categoryUUID,
      description: description,
    })
  );
}

async function setStatus(declarationUUID, status) {
  return put_axs(
    "/modules/incidents/declarations/status",
    new URLSearchParams({
      declaration_uuid: declarationUUID,
      status,
    })
  );
}

async function getPicture(declarationUUID, type) {
  return get_cdn(`/app/module/incident/declarations/${declarationUUID}/${type}`);
}
