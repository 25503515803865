import { userConstants } from "../constants/";

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_ALL_REQUEST:
      return {
        loadingUsers: true,
      };
    case userConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loadingUsers: false,
        usersLoaded: true,
        users: action.users,
      };
    case userConstants.GET_ALL_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}

export function inscriptions(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_INSCRIPTIONS_REQUEST:
      return {
        loadingInscriptions: true,
      };
    case userConstants.GET_INSCRIPTIONS_SUCCESS:
      return {
        loadingInscriptions: false,
        loadedInscriptions: true,
        inscriptionList: action.user,
      };
    case userConstants.GET_INSCRIPTIONS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getUser(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return {
        loadingUser: true,
        user: action.uid,
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        loadingUser: false,
        loadedUser: true,
        user: action.user,
      };
    case userConstants.GET_USER_FAILURE:
      return {
        error: action.error,
      };

    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id ? { ...user, deleting: true } : user
        ),
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id),
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }
          return user;
        }),
      };
    default:
      return state;
  }
}

export function myself(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_SELF_REQUEST:
      return {
        loadingMyself: true,
      };
    case userConstants.GET_SELF_SUCCESS:
      return {
        loadingMyself: false,
        loadedMyself: true,
        myself: action.myself,
      };
    case userConstants.GET_SELF_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function create(state = {}, action) {
  switch (action.type) {
    case userConstants.USERS_CREATE_REQUEST:
      return {
        creatingUser: true,
      };
    case userConstants.USERS_CREATE_SUCCESS:
      return {
        creatingUser: false,
        createdUser: true,
        response: action.response,
      };
    case userConstants.USERS_CREATE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
export function update(state = {}, action) {
  switch (action.type) {
    case userConstants.UPDATE_REQUEST:
      return {
        updatingUser: true,
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        updatingUser: false,
        updatedUser: true,
        response: action.response,
      };
    case userConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function resetUserPassword(state = {}, action) {
  switch (action.type) {
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        resetingPassword: true,
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        resetingPassword: false,
        resetPassword: true,
        response: action.response,
      };
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function forgotPassword(state = {}, action) {
  switch (action.type) {
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return {
        resetingPassword: true,
      };
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        resetingPassword: false,
        resetPassword: true,
        response: action.response,
      };
    case userConstants.FORGOT_PASSWORD_FAILURE:
      return {
        resetError: action.error,
      };
    default:
      return state;
  }
}
export function controlAccess(state = {}, action) {
  switch (action.type) {
    case userConstants.CONTROL_ACCESS_REQUEST:
      return {
        awaitingResponse: true,
      };
    case userConstants.CONTROL_ACCESS_SUCCESS:
      return {
        awaitingResponse: false,
        accountTreated: true,
        response: action.response,
      };
    case userConstants.CONTROL_ACCESS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
