import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../actions";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { ReactComponent as OubaLogo } from "../assets/img/svg/logo_main.svg";

import { Modal } from "@material-ui/core";

import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Snackbar from "../components/Snackbar/Snackbar";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://ouba.fr/">
        Ouba
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    marginTop: theme.spacing(6),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  forgotPasswordForm: {
    width: "20%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  lostPasswordModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "snow",
  },
  sitesButtons: {
    paddingTop: 20,
  },
}));



export default function Auth() {
  const classes = useStyles();
  const dispatch = useDispatch();

  // COMPONENT STATE
  const [enteredEmail, setEmail] = useState("");
  const [enteredPassword, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submittedForgotPassword, setSubmittedForgotPassword] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const { loggingIn, error } = useSelector(state => state.authentication);
  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");


  useEffect(() => {
    if (enteredEmail && enteredPassword) {
      dispatch(userActions.login(enteredEmail, enteredPassword));
    }
  }, [submitted]);

  useEffect(() => {
    if (enteredEmail) {
      dispatch(userActions.forgotPassword(enteredEmail));
      openNotification("Une demande de réinitialisation de mot de passe a été envoyée à l'email que vous avez renseigné.", "info");

    }
  }, [submittedForgotPassword]);

  useEffect(() => {
    if (!loggingIn && error) {
      openNotification("Vos identifiants de connexion sont incorrects. Veuillez vérifier votre adresse mail ou bien votre mot de passe.", "danger");
    }
  }, [loggingIn, error]);


  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const submitHandler = event => {
    event.preventDefault();
    setSubmitted(!submitted);

  };

  const submitForgotPasswordHandler = event => {
    event.preventDefault();
    setSubmittedForgotPassword(!submittedForgotPassword);

  };
  
  const lostPasswordForm = () => {
    return (<div className={classes.paper}>
          <Snackbar
            place="tr"
            message={notifMessage}
            open={openNotif}
            close
            icon={
              notifColor === "success"
                ? CheckCircleIcon
                : notifColor === "danger"
                  ? WarningIcon
                  : NotificationsIcon
            }
            closeNotification={() => setOpenNotif(false)}
            color={notifColor}
          />
          <Typography component="h1" variant="h5">
            Mot de passe oublié
          </Typography>
          <form className={classes.form} noValidate onSubmit={submitForgotPasswordHandler}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="username"
              autoComplete="email"
              autoFocus
              value={enteredEmail}
              onChange={event => {
                setEmail(event.target.value);
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Envoyer
            </Button>
    
            <Link href="#" variant="body2" onClick={() => setOpenForgotPassword(!openForgotPassword)}>
              Retour à l'écran de connexion
            </Link>
          </form>
        </div>)
  }
  
  const loginContent = () =>  {
    return (<div className={classes.paper}>
      <Snackbar
        place="tr"
        message={notifMessage}
        open={openNotif}
        close
        icon={
          notifColor === "success"
            ? CheckCircleIcon
            : notifColor === "danger"
              ? WarningIcon
              : NotificationsIcon
        }
        closeNotification={() => setOpenNotif(false)}
        color={notifColor}
      />
      <Typography component="h1" variant="h5">
        Espace administrateur
      </Typography>
      <form className={classes.form} noValidate onSubmit={submitHandler}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="username"
          autoComplete="email"
          autoFocus
          value={enteredEmail}
          onChange={event => {
            setEmail(event.target.value);
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Mot de passe"
          type="password"
          id="password"
          autoComplete="current-password"
          value={enteredPassword}
          onChange={event => {
            setPassword(event.target.value);
          }}
        />
        {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Se souvenir de moi"
          /> */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Connexion
        </Button>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Link href="#" onClick={() => setOpenForgotPassword(!openForgotPassword)}>
            J'ai oublié mon mot de passe
          </Link>
          <a href="https://support.ouba.fr" target="_blank">Aide aux utilisateurs</a>
        </div>
      </form>
    </div>)
  }

  return (

    <Container component="main" maxWidth="xs">
      <div style={{ paddingLeft: 100, paddingRight: 100, paddingBottom: 0, paddingTop: 40 }}>
        <OubaLogo style={{ width: "100%" }} />
      </div>
      {openForgotPassword && lostPasswordForm() }
      {!openForgotPassword && loginContent() }
      <CssBaseline />
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
