import { get_axs, post_axs, remove_axs } from "../../../helpers/fetcher";

export const classifiedCategoryServices = {
  getAll,
  fetchForSettings,
  activateForSettings,
  deactivateForSettings,
};

async function getAll() {
  return get_axs("/modules/classifieds/categories/settings");
}

async function fetchForSettings() {
  return get_axs("/modules/classifieds/categories/settings");
}

async function activateForSettings(uuid) {
  return post_axs(
    "/modules/classifieds/categories/settings",
    new URLSearchParams({
      category_uuid: uuid,
    })
  );
}

async function deactivateForSettings(uuid) {
  return remove_axs(
    "/modules/classifieds/categories/settings",
    new URLSearchParams({
      category_uuid: uuid,
    })
  );
}
