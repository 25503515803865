import { bookingConstants } from "../constants/";

export function bookingsFetching(state = {}, action) {
  switch (action.type) {
    case bookingConstants.GETALL_REQUEST:
      return { loadingBookings: true };
    case bookingConstants.GETALL_SUCCESS:
      return {
        loadingBookings: false,
        bookingsLoaded: true,
        bookings: action.bookings,
      };
    case bookingConstants.GETALL_FAILURE:
      return action.error;
    default:
      return state;
  }
}
