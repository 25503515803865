import { sectorConstants } from "../constants";
import { alertActions } from ".";
import { sectorServices } from "../services";

export const sectorActions = {
  getAll,
};

function getAll() {
  return dispatch => {
    dispatch(request());
    sectorServices.getAll().then(
      sectors => {
        dispatch(success(sectors));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request() {
    return { type: sectorConstants.GETALL_REQUEST };
  }

  function success(sectors) {
    return { type: sectorConstants.GETALL_SUCCESS, sectors };
  }

  function failure(error) {
    return { type: sectorConstants.GETALL_FAILURE, error };
  }
}
