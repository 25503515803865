import { useEffect, useState } from "react";

import { newsCategoryServices } from "../../../../services";

const NewsSettingsLogic = () => {
  const limitCategory = 15

  const [newsCategoryList, setNewsCategoryList] = useState(undefined);
  const [newsCategoryCount, setNewsCategoryCount] = useState(undefined);
  const [page, setPage] = useState(1)
  const [isOpen, setIsOpen] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [titleValidate, setTitleValidate] = useState(false);
  const [categoryDestroy, setCategoryDestroy] = useState(undefined);
  const [refresh, setRefresh] = useState(false)

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  useEffect(() => {
    (async () => {
      const categories = await newsCategoryServices.getAll();
      if (categories) {
        setNewsCategoryCount(categories.length);
        setNewsCategoryList(categories.slice(limitCategory * (page - 1), limitCategory * (page - 1) + limitCategory))
      }
    })();
  }, [refresh, page]);

  useEffect(() => {
    setTitleValidate(!categoryTitle.length ? true : false);
  }, [categoryTitle]);

  const handlePagination = page => {
    setPage(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const refreshData = () => setRefresh(!refresh)
  const handleTitle = title => setCategoryTitle(title.slice(0, 128));
  const toggleCreateModal = isOpen => setIsOpen(isOpen);
  const toggleConfirmCategoryDestroy = category => setCategoryDestroy(category);

  const editCategoryName = (category, name) => setNewsCategoryList([
    ...newsCategoryList.filter(c => c.id !== category.id), { ...category, title: name.slice(0, 128) }
  ]);
  const toggleEdit = (category, isOpen) => setNewsCategoryList([
    ...newsCategoryList.filter(c => c.id !== category.id), { ...category, edit: isOpen }
  ]);
  const submitNewCategory = async e => {
    e.preventDefault();
    const response = await newsCategoryServices.add(categoryTitle);
    if (response) {
      openNotification(`La catégorie ${categoryTitle} a été ajouté`, 'success')
      refreshData()
      setCategoryTitle("");
      setIsOpen(false);
    } else {
      openNotification(`Erreur, une erreur est survenu lors de l'ajout de la catégorie ${categoryTitle}`, 'danger')
    }
  };
  const editCategory = async category => {
    const response = await newsCategoryServices.update(category.uuid, category.title);
    if (response) {
      openNotification(`La catégorie ${category?.title} a été modfié`, 'success')
      refreshData()
    } else {
      openNotification(`Erreur, une erreur est survenu lors de la modification de la catégorie ${category?.title}`, 'danger')
    }
  };
  const removeCategory = async uuid => {
    const response = await newsCategoryServices.destroy(uuid);
    if (response) {
      openNotification(`La catégorie ${newsCategoryList.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
      refreshData()
      setCategoryDestroy(undefined);
    }
  };

  return {
    categoryDestroy,
    categoryTitle,
    //currentUser,
    //dialogSelected,
    editCategory,
    editCategoryName,
    isOpen,
    //loading,
    //modalType,
    //newsMeta,
    limitCategory,
    newsCategoryCount,
    newsCategoryList,
    notifColor,
    notifMessage,
    openNotif,
    page,
    //newsUUID,
    //openDialog,
    titleValidate,
    //validationModal,
    handlePagination,
    handleTitle,
    refreshData,
    removeCategory,
    setOpenNotif,
    submitNewCategory,
    toggleConfirmCategoryDestroy,
    toggleCreateModal,
    toggleEdit,
  };
};

export default NewsSettingsLogic;
