import React, { useEffect } from "react";
import ResourceListLogic from "./../TabLogics/ResourceListLogic"
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import FormRessources from "components/FormUsers/FormRessources.js";
import DoneIcon from "@material-ui/icons/Done";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as AddIcon} from "assets/img/svg/AddIcon.svg";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import ConfirmModal from "components/ConfirmModal";
import BlockIcon from "@material-ui/icons/Block";
import Button from "components/CustomButtons/Button.js";
import Pagination from "@material-ui/lab/Pagination";
import Snackbar from "components/Snackbar/Snackbar.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
		Button as MUIButton,
		Backdrop,
		Modal,
		Chip,
		Tooltip,
		Divider,
		CircularProgress
} from "@material-ui/core";

const paginationStyles = makeStyles(() => ({
		ul: {
				"& .MuiPaginationItem-root": {
						color: "#6E6FA9",
				},
				"& .Mui-selected": {
						backgroundColor: "#6E6FA9",
						color: "#E7E6ED",
				},
		},
}));

const useStyles = makeStyles(styles);

export default function ResourceListView(props){
	
	const paginationClasses = paginationStyles();
	const classes = useStyles();
	const {
		ressourcesList,
		formRulesList,
		formCleaningList,
		formLocationList,
		formTypeList,
		parseHourTime,
		refreshImageRessource,
		formModulesList,
		updatePeriodFromRessource,
		toggleModalRessource,
		ressourceRecurrence,
		submitNewRessource,
		bookingDestroy,
		ressourceModalOpened,
		toggleConfirmBookingDestroy,
		handlePaginationRessource,
		ressourcesCount,
		modalRessourceType,
		periodsList,
		deletePeriodFromRessource,
		getTitleInterval,
		pageRessource,
		modalMessageBooking,
		deleteBooking,
		limits,
		refresh,
		newRessourceInfo,
		formRessourceValid,
		handleRessourceFormChange,
		addPeriodToRessource,
		setPeriodsList,
		refreshData,
		notifColor,
		notifMessage,
		openNotif,
		setOpenNotif
	} = ResourceListLogic();
	
	useEffect(() => {
		refreshData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	return (
		<>
		<Snackbar
				place="tr"
				message={notifMessage}
				open={openNotif}
				close
				icon={
						notifColor === "success"
								? CheckCircleIcon
								: notifColor === "danger"
										? WarningIcon
										: NotificationsIcon
				}
				closeNotification={() => setOpenNotif(false)}
				color={notifColor}
		/>
		{bookingDestroy && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{timeout: 500}}
						className={classes.modal}
						onClose={() => toggleConfirmBookingDestroy(null)}
						open={!!bookingDestroy}
				>
						<div className={classes.paper}>
								<ConfirmModal
										closeModal={() => toggleConfirmBookingDestroy(null)}
										kind={modalMessageBooking()}
										makeAction={() => deleteBooking(bookingDestroy.uuid)}
								/>
						</div>
				</Modal>
		)}
		{ressourceModalOpened && newRessourceInfo && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{
								timeout: 500,
						}}
						className={classes.modal}
						onClose={() => toggleModalRessource()}
						open={ressourceModalOpened}
				>
						<div
								className={classes.paper}
								style={{
										maxHeight: "80%",
										maxWidth: "70%",
								}}
						>
								<FormRessources
										formCleaningList={formCleaningList}
										formLocationList={formLocationList}
										formModulesList={formModulesList}
										formRessourceValid={formRessourceValid}
										periodsList={periodsList}
										formRulesList={formRulesList}
										formTypeList={formTypeList}
										modalRessourceType={modalRessourceType}
										newRessourceInfo={newRessourceInfo}
										refreshImageRessource={refreshImageRessource}
										handleRessourceFormChange={handleRessourceFormChange}
										submitNewRessource={submitNewRessource}
										toggleModalRessource={toggleModalRessource}
										addPeriodToRessource={addPeriodToRessource}
										updatePeriodFromRessource={updatePeriodFromRessource}
										deletePeriodFromRessource={deletePeriodFromRessource}
										setPeriodsList={setPeriodsList}
								/>
						</div>
				</Modal>
		)}
		<GridItem xs={12} sm={12} md={12}>
				<CardBody>
						<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
										<MUIButton
												className={classes.button}
												onClick={() => toggleModalRessource()}
												startIcon={<AddIcon/>}
												style={{width: "auto"}}
										>
												Ajouter une nouvelle ressource
										</MUIButton>
								</GridItem>
						</GridContainer>
				</CardBody>
				<div>
						{ressourcesList && ressourcesList.length === 0 && (
								<h4>Aucune ressource à afficher</h4>
						)}
						{ressourcesList &&
								ressourcesList?.map((ressource, i) => (
										<div key={i}>
												<Card>
														<div>
																<CardHeader
																		style={{
																				borderRadius: 1,
																				paddingTop: 0,
																				border: "none",
																				boxShadow:
																						"inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
																		}}
																>
																		<h4
																				className={classes.cardTitle}
																				style={{display: "flex"}}
																		>
																				{ressource?.enabled ? (
																						<Tooltip title="Activé">
																								<DoneIcon
																										style={{color: "#5cb85c"}}
																								/>
																						</Tooltip>
																				) : (
																						<Tooltip title="Désactivé">
																								<BlockIcon
																										style={{color: "#c9302c"}}
																								/>
																						</Tooltip>
																				)}
																				<div style={{marginLeft: "0.5%"}}>
																						{ressource?.title}
																				</div>
																		</h4>
																		<p className={classes.cardDescription}>
																				{parseHourTime(ressource?.open_at) +
																						" - " +
																						parseHourTime(ressource?.close_at)}
																		</p>
																</CardHeader>
																<CardBody>
																		<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				style={{marginTop: "1%"}}
																		>
																				{ressource?.features &&
																						ressource.features.map((it, i) => (
																								<Chip
																										key={i}
																										label={it.custom_title}
																										color="primary"
																										style={{marginRight: "5px"}}
																								/>
																						))}
																		</GridItem>
																		<div style={{display: "flex"}}>
																				{refreshImageRessource &&
																						(refreshImageRessource[
																								ressource?.uuid
																								] ? (
																								<CircularProgress/>
																						) : (
																								ressource.has_picture && (
																										<img
																												style={{
																														marginTop: "1%",
																														height: "100px",
																												}}
																												src={`${process.env.REACT_APP_CDN_URL}/app/module/reservation/ressources/${ressource?.uuid}/image?refresh=${refresh}`}
																												alt=""
																												onError={i => {
																														i.target.style.display = "none";
																														i.target.alt =
																																"Aucune image disponible";
																												}}
																										/>
																								)
																						))}
																				<div
																						style={{
																								display: "column",
																								alignSelf: "center",
																						}}
																				>
																						<GridItem
																								xs={12}
																								sm={12}
																								md={12}
																								style={{
																										marginTop: "1%",
																										fontWeight: "bold",
																								}}
																						>
																								{`Prix: ${parseFloat(
																										((ressource?.booking_price
																														? ressource?.booking_price
																														: 0) +
																												(ressource?.unit_price
																														? ressource?.unit_price
																														: 0)) /
																										100
																								).toFixed(2)} €`}
																						</GridItem>
																						<GridItem xs={12} sm={12} md={12}>
																								{`Prix de la réservation: ${parseFloat(
																										ressource?.booking_price
																												? ressource?.booking_price / 100
																												: 0
																								).toFixed(
																										2
																								)} € / prix de l'unité: ${parseFloat(
																										ressource?.unit_price
																												? ressource?.unit_price / 100
																												: 0
																								).toFixed(2)} €`}
																						</GridItem>
																				</div>
																		</div>
																		{ressource?.custom_message?.length > 0 && (
																				<div
																						style={{
																								marginTop: "2%",
																								fontSize: 17,
																								marginLeft: "8px",
																						}}
																				>
																						{`Message complémentaire: ${ressource?.custom_message}`}
																				</div>
																		)}
																		<Table
																				tableHeaderColor="primary"
																				tableType="booking"
																				tableHead={[
																						"Emplacement",
																						"Mode de réservation",
																						"Règles d'usage",
																						"Jours d'ouverture",
																						"Nettoyage",
																						"Accès continu",
																						"Unité de réservation",
																						"Unité réservable",
																						"Mois disponible",
																						"Jours disponible",
																				]}
																				tableData={[
																						Object.values({
																								location: ressource?.location?.title,
																								booking_method:
																										ressource?.booking_method ===
																										"SUBSCRIBE"
																												? "Abonnement"
																												: "Ponctuel",
																								rule: ressource?.rule?.title,
																								recurence:
																										ressourceRecurrence?.length > i &&
																										ressourceRecurrence[i].length ===
																										7 ? (
																												ressourceRecurrence[
																														i
																														].map((it, j) => (
																														<Chip
																																key={j}
																																label={it.d}
																																color={
																																		it.active
																																				? "primary"
																																				: "default"
																																}
																																style={{marginRight: "5px"}}
																														/>
																												))
																										) : (
																												<div className={classes.root}>
																														<CircularProgress/>
																												</div>
																										),
																								cleaning: ressource?.cleaning?.title,
																								access: ressource?.interval_unified
																										? "Oui"
																										: "Non",
																								unit: `${
																										ressource?.interval_value
																												? ressource?.interval_value
																												: 0
																								} ${getTitleInterval(
																										ressource?.interval_type
																								)}`,
																								available:
																										ressource?.unit_min ===
																										ressource?.unit_max
																												? ressource?.unit_min
																												: `De ${
																														ressource?.unit_min
																																? ressource?.unit_min
																																: 0
																												} à ${
																														ressource?.unit_max
																																? ressource?.unit_max
																																: 0
																												}`,
																								month: ressource?.available_months,
																								day: ressource?.available_days,
																						}),
																				]}
																		/>
																		<Table
																				tableHeaderColor="primary"
																				tableType="booking"
																				tableHead={[
																						"Nombre d'accès simultanés",
																						"Délai de réservation pour un utilisateur",
																						"Délai de réservation entre deux utilisateurs",
																						"Délai de réservation",
																						"Délai d'annulation",
																						"Quota d'utilisation",
																						"Nombre Réservation max",
																						"Réservation appliquée au groupe",
																						"Avertir Admins",
																				]}
																				tableData={[
																						Object.values({
																								access: ressource?.available_units,
																								delay: `${
																										ressource?.unit_self_delay
																												? ressource?.unit_self_delay
																												: 0
																								}  unité(s)`,
																								delay_other: `${
																										ressource?.unit_users_delay
																												? ressource?.unit_users_delay
																												: 0
																								} unité(s)`,
																								bookingD:
																								(ressource?.booking_delay_hr
																										? ressource?.booking_delay_hr
																										: 0) + " heure(s)",
																								cancelD:
																										(ressource?.cancel_delay_hr
																												? ressource?.cancel_delay_hr
																												: 0) + " heure(s)",
																								quota: ressource?.quota
																										? ressource?.quota
																										: "Sans limite",
																								max_rolling_bookings: ressource?.max_rolling_bookings
																										? ressource?.max_rolling_bookings
																										: "Sans limite",
																								group: ressource?.is_group_access
																										? "Oui"
																										: "Non",
																								warnAdmin: ressource?.warnAdmin
																										? "Oui"
																										: "Non",
																						}),
																				]}
																		/>
																		<GridItem
																				xs={12}
																				sm={12}
																				md={12}
																				style={{marginTop: "1%"}}
																		>
																				<Button
																						onClick={() =>
																								toggleModalRessource(ressource)
																						}
																						color="warning"
																				>
																						Éditer
																				</Button>
																				<Button
																						style={{marginLeft: "2%"}}
																						onClick={() =>
																								toggleConfirmBookingDestroy(
																										"ressource",
																										ressource
																								)
																						}
																						color="danger"
																				>
																						Supprimer
																				</Button>
																		</GridItem>
																</CardBody>
														</div>
												</Card>
												{ressourcesList.length - 1 !== i && <Divider/>}
										</div>
								))}
						{ressourcesCount > 0 && (
								<div
										style={{
												display: "flex",
												justifyContent: "center",
												paddingTop: 100,
										}}
								>
										<Pagination
												count={Math.ceil(
														ressourcesCount / limits.ressource
												)}
												page={pageRessource}
												onChange={(event, pageNumber) => {
														handlePaginationRessource(pageNumber);
												}}
												classes={{ul: paginationClasses.ul}}
												size="large"
										/>
								</div>
						)}
				</div>
		</GridItem>
		</>
	)
}