/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  InputLabel,
  makeStyles,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function FormLocations(props) {
  const classes = useStyles();

  const {
    formLocationValid,
    formSectorList,
    handleLocationFormChange,
    modalLocationType,
    newLocationInfo,
    refreshImageLocation,
    submitNewLocation,
    toggleModalLocation,
  } = props;
  return (
    <Container
      component="main"
      maxWidth="md"
      style={{
        overflowY: "auto",
      }}
    >
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {modalLocationType ? (
            <Typography variant="h5">Modifier l'emplacement</Typography>
          ) : (
            <Typography variant="h5">Ajouter un emplacement</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModalLocation()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewLocation} noValidate>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Titre *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newLocationInfo.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleLocationFormChange("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Détails de l'emplacement</InputLabel>
              <TextField
                id="details"
                value={newLocationInfo.details}
                onChange={e =>
                  handleLocationFormChange("details", e.target.value)
                }
                multiline
                required
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Bâtiment</InputLabel>
              {formSectorList ? (
                <Select
                  value={newLocationInfo.sector}
                  style={{ top: "16px" }}
                  id="select-type"
                  fullWidth
                  displayEmpty
                  onChange={event =>
                    handleLocationFormChange("sector", event.target.value)
                  }
                >
                  <MenuItem value={""}>
                    <em>Aucune séléction</em>
                  </MenuItem>
                  {formSectorList &&
                    formSectorList.map((it, i) => (
                      <MenuItem key={i} value={it.id}>
                        <div>{it.name}</div>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
            <Grid item md={12}>
              <InputLabel>Image</InputLabel>
              <div style={{ display: 'flex' }}>
                {refreshImageLocation && newLocationInfo?.uuid &&
                  (refreshImageLocation[newLocationInfo.uuid] ? (
                    <CircularProgress />
                  ) : (newLocationInfo.has_picture && (<img
                    style={{ marginTop: "1%", height: '100px', marginRight: '2%' }}
                    src={`${process.env.REACT_APP_CDN_URL
                      }/app/module/reservation/locations/${newLocationInfo?.uuid}/image?refresh=${Date.now()}`}
                    alt=""
                    onError={i => {
                      i.target.style.display = "none";
                      i.target.alt = "Aucune image disponible";
                    }}
                  />)))
                }
                <input
                  style={{ marginTop: "1%", display: 'column', alignSelf: 'center' }}
                  type="file"
                  name="icon"
                  accept="image/*"
                  onChange={e => handleLocationFormChange("image", e.target.files[0])}
                />
              </div>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formLocationValid}
          >
            Enregister
          </Button>
        </form>
      </div>
    </Container>
  );
}

FormLocations.propTypes = {
  formSectorList: PropTypes.array,
  formLocationValid: PropTypes.bool,
  modalLocationType: PropTypes.bool,
  newLocationInfo: PropTypes.object,
  refreshImageLocation: PropTypes.object,
  handleLocationFormChange: PropTypes.func,
  submitNewLocation: PropTypes.func,
  toggleModalLocation: PropTypes.func,
};
