import { groupConstants } from "../constants";
import { loadState } from "helpers/localStorage";

const myself = loadState("myself")

export function groupsFetching(state = {}, action) {
  switch (action.type) {
    case groupConstants.GETALL_REQUEST:
      return {
        loadingGroups: true,
      };
    case groupConstants.GETALL_SUCCESS:
      return {
        loadingGroups: false,
        groupsLoaded: true,
        groups: action.groups.filter(it => !myself?.rights?.groupCollectionId || myself?.rights?.role_superadmin || it.groupCollectionId === myself?.rights?.groupCollectionId),
      };
    case groupConstants.GETALL_FAILURE:
      return action.error;
    default:
      return state;
  }
}

export function groupAttach(state = {}, action) {
  switch (action.type) {
    case groupConstants.ATTACH_REQUEST:
      return {
        attachingGroup: true,
      };
    case groupConstants.ATTACH_SUCCESS:
      return {
        attachingGroup: false,
        attachedGroup: true,
        message: action.message,
      };
    case groupConstants.ATTACH_FAILURE:
      return action.error;
    default:
      return state;
  }
}

export function groupDetach(state = {}, action) {
  switch (action.type) {
    case groupConstants.DETACH_REQUEST:
      return {
        detachingGroup: true,
      };
    case groupConstants.DETACH_SUCCESS:
      return {
        detachingGroup: false,
        detachedGroup: true,
        message: action.message,
      };
    case groupConstants.DETACH_FAILURE:
      return action.error;
    default:
      return state;
  }
}
