import { get_axs, post_axs, put_axs, remove_axs } from "../../../helpers/fetcher";

export const reservationSubscriptionServices = {
  getAll,
  getAllPeriod,
  addPeriod,
  updatePeriod,
  deletePeriod,
};

const headerType = "application/json";
async function getAll() {
  return get_axs("/modules/reservations/subscriptions");
}

async function getAllPeriod(uuid) {
  return get_axs(`/modules/reservations/subscriptions/${uuid}/periods`);
}

async function addPeriod(uuid, data) {
  return post_axs(
    `/modules/reservations/subscriptions/period/${uuid}/add`,
    data,
    headerType
  );
}

async function updatePeriod(uuid, data) {
  return put_axs(
    `/modules/reservations/subscriptions/period/${uuid}`,
    data,
    headerType
  );
}

async function deletePeriod(uuid) {
  return remove_axs(`/modules/reservations/subscriptions/period/${uuid}`);
}
