const modalUserStyle = theme => ({
  cardDescription: {
    color: "#3d406e",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
    whiteSpace: "pre-wrap",
  },
  cardTitle: {
    color: "#3d406e",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontSize: "20px",
    fontFamily: "'Futura",
    marginBottom: "3px",
    textDecoration: "none",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 200,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 350,
    minWidth: 550,
    width: "50%",
    height: "60%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerModal: {
    display: "flex",
    // flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "flex-end",
    marginBottom: 30,
    flexDirection: "columnn",
    alignItems: "start",
  },
  addAndPaginate: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  autocomplete: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "25%",
  },
  filterUsers: {
    display: "flex",
    alignSelf: "flex-start",
    fontFamily: "futura",
    color: "#6E6FA9",
  },
  input: {
    color: "#6E6FA9",
    fontFamily: "futura",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "futura",
    fontSize: 10,
    backgroundColor: "white",
    color: "#6E6FA9",
    borderRadius: 13,
    boxShadow: theme.shadows[5],
    marginTop: 30,
    width: 180,
    height: 40,
  },
  pagination: {
    color: "red",
  },
  listItemRoot: {
    "&.Mui-selected": {
      backgroundColor: "#0CBABA",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "#0CBABA"
    }
  },
});

export default modalUserStyle;
