import {useEffect, useState} from "react";
import moment from "moment";
import localization from "moment/locale/fr";
import {
		reservationCleaningServices,
} from "services";

moment.updateLocale("fr", localization);

const CleaningListLogic = () => {
		const [refresh, setRefresh] = useState(false);

		const limits = {cleaning: 10};

		const [openNotif, setOpenNotif] = useState(false);
		const [notifMessage, setNotifMessage] = useState("");
		const [notifColor, setNotifColor] = useState("info");

		const [periodsList, setPeriodsList] = useState([]);

		const [cleaningList, setCleaningList] = useState(undefined);
		const [cleaningCount, setCleaningCount] = useState(0);
		const [pageCleaning, setPageCleaning] = useState(1);
		const [cleaningModalOpened, setCleaningModalOpened] = useState(false);
		const [newCleaningInfo, setNewCleaningInfo] = useState(undefined);
		const [modalCleaningType, setModalCleaningType] = useState(false);
		const [formCleaningValid, setFormCleaningValid] = useState(false);
		const [locationRecurrence, setLocationRecurrence] = useState([]);
		const [isKeyRegenerate, setIsKeyRegenerate] = useState(false);

		const [bookingDestroy, setBookingDestroy] = useState(undefined);
		const [bookingDestroyType, setBookingDestroyType] = useState(undefined);

		const toggleConfirmBookingDestroy = (type, booking) => {
			setBookingDestroyType(type);
			setBookingDestroy(booking);
		};

		const deleteBooking = uuid => {
			deleteCleaning(uuid);
			toggleConfirmBookingDestroy(null);
		};

		const modalMessageBooking = () => {
			return `Supprimer le ménage "${bookingDestroy.title}" ?`;
		};

		const refreshData = () => setRefresh(!refresh);

		useEffect(() => {
				(async () => {
					let cleaning = await reservationCleaningServices.getAll();
					if (cleaning) {
							setCleaningCount(cleaning.length);
							setCleaningList(
									cleaning.slice(
											limits.cleaning * (pageCleaning - 1),
											limits.cleaning * (pageCleaning - 1) + limits.cleaning
									)
							);
							const days = cleaning
									.slice(
											limits.cleaning * (pageCleaning - 1),
											limits.cleaning * (pageCleaning - 1) + limits.cleaning
									)
									.map(r => {
											let recurrence = r.working_days;
											if (!r.working_days) recurrence = "0000000";
											const days = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"];
											const rec = [];
											for (let i in recurrence) {
													if (recurrence[i] === "1")
															rec.push({d: days[i], active: true});
													else rec.push({d: days[i], active: false});
											}
											return rec;
									});
							setLocationRecurrence(days);
					}
				})();
				// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
				pageCleaning,
				refresh,
		]);

		const handlePaginationCleaning = page => {
				setPageCleaning(page);
		};

		const openNotification = (msg, color = "info", time = 6) => {
				setOpenNotif(false);
				setNotifMessage(msg);
				setNotifColor(color);
				setTimeout(() => setOpenNotif(false), time * 1000);
				setTimeout(() => setOpenNotif(true), 100);
		};

		const requestNewIcsKey = async uuid => {
				const res = await reservationCleaningServices.renewIcsKey(uuid);
				if (res) {
						const cleaningInfo = {...newCleaningInfo};
						cleaningInfo.ics_key = res?.ics_key;
						cleaningInfo.dialog = !cleaningInfo.dialog;
						setNewCleaningInfo(cleaningInfo);
						setIsKeyRegenerate(true);
						isFormCleaningValid(cleaningInfo);
				}
		};

		const toggleModalCleaning = (cleaning = null) => {
				if (isKeyRegenerate) {
						setIsKeyRegenerate(false);
						refreshData();
				}
				setFormCleaningValid(false);
				setModalCleaningType(cleaning ? true : false);
				const cleaningInfo = {
						uuid: cleaning?.uuid,
						title: cleaning?.title ? cleaning?.title : "",
						email: cleaning?.email ? cleaning?.email : "",
						email_cc: cleaning?.email_cc ? cleaning?.email_cc : "",
						managerFirstName: cleaning?.managerFirstName ? cleaning?.managerFirstName : "",
						managerLastName: cleaning?.managerLastName ? cleaning?.managerLastName : "",
						landlinePhoneNumber: cleaning?.landlinePhoneNumber ? cleaning?.landlinePhoneNumber : "",
						mobilePhoneNumber: cleaning?.mobilePhoneNumber ? cleaning?.mobilePhoneNumber : "",
						sendSMSNotification: cleaning?.sendSMSNotification ? cleaning?.sendSMSNotification : 0,
						SIREN: cleaning?.SIREN ? cleaning?.SIREN : "",
						addressStreet: cleaning?.addressStreet ? cleaning?.addressStreet : "",
						addressCity: cleaning?.addressCity ? cleaning?.addressCity : "",
						addressZipcode: cleaning?.addressZipcode ? cleaning?.addressZipcode : "",
						working_days: cleaning?.working_days ? cleaning?.working_days : "0000000",
						ics_key: cleaning?.ics_key ? cleaning?.ics_key : undefined,
						dialog: false,
				};
				setNewCleaningInfo(cleaningInfo);
				setCleaningModalOpened(!cleaningModalOpened);
		};

		const submitNewCleaning = async event => {
				event.preventDefault();
				const cleaningInfo = {
						title: newCleaningInfo.title,
						email: newCleaningInfo.email,
						email_cc: newCleaningInfo.email_cc,
						managerFirstName: newCleaningInfo.managerFirstName,
						managerLastName: newCleaningInfo.managerLastName,
						landlinePhoneNumber: newCleaningInfo.landlinePhoneNumber,
						mobilePhoneNumber: newCleaningInfo.mobilePhoneNumber,
						sendSMSNotification: newCleaningInfo.sendSMSNotification,
						SIREN: newCleaningInfo.SIREN,
						addressStreet: newCleaningInfo.addressStreet,
						addressCity: newCleaningInfo.addressCity,
						addressZipcode: newCleaningInfo.addressZipcode,
						working_days: newCleaningInfo.working_days,
				};
				if (newCleaningInfo.uuid) {
						if (
								await reservationCleaningServices.update(
										newCleaningInfo.uuid,
										cleaningInfo
								)
						) {
								openNotification(
										`Le ménage ${newCleaningInfo?.title} a été modifié`,
										"success"
								);
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de la modification du ménage ${newCleaningInfo?.title}`,
										"danger"
								);
						}
				} else {
						if (await reservationCleaningServices.add(cleaningInfo)) {
								openNotification(
										`Le ménage ${newCleaningInfo?.title} a été ajouté`,
										"success"
								);
						} else {
								openNotification(
										`Erreur, une erreur est survenu lors de l'ajout du ménage ${newCleaningInfo?.title}`,
										"danger"
								);
						}
				}
				refreshData();
				setCleaningModalOpened(!cleaningModalOpened);
				setFormCleaningValid(true);
		};

		const handleCleaningFormChange = (key, value, idx = 0) => {
				const cleaningInfo = {...newCleaningInfo};
				if (key === "dialog") {
						cleaningInfo[key] = !cleaningInfo[key];
				} else if (key === "recurrence") {
						cleaningInfo.working_days =
								cleaningInfo.working_days.substring(0, idx) +
								(value ? "1" : "0") +
								cleaningInfo.working_days.substring(idx + 1);
				} else {
						cleaningInfo[key] = value;
				}
				setNewCleaningInfo(cleaningInfo);
				if (key !== "dialog") isFormCleaningValid(cleaningInfo);
		};

		const isFormCleaningValid = cleaning => {
				if (isKeyRegenerate) {
						setFormCleaningValid(true);
						return;
				}
				if (!cleaning?.title?.length || !cleaning?.email?.length)
						setFormCleaningValid(false);
				else setFormCleaningValid(true);
		};

		const deleteCleaning = async uuid => {
				await reservationCleaningServices.destroy(uuid);
				openNotification(
						`Le ménage ${
								cleaningList.find(it => it.uuid === uuid)?.title
						} a été supprimé`,
						"success"
				);
				refreshData();
		};

		return {
				bookingDestroy,
				cleaningCount,
				cleaningList,
				cleaningModalOpened,
				formCleaningValid,
				limits,
				locationRecurrence,
				modalCleaningType,
				newCleaningInfo,
				notifColor,
				notifMessage,
				openNotif,
				pageCleaning,
				periodsList,
				refresh,
				deleteBooking,
				handleCleaningFormChange,
				handlePaginationCleaning,
				modalMessageBooking,
				refreshData,
				requestNewIcsKey,
				setOpenNotif,
				setPeriodsList,
				submitNewCleaning,
				toggleConfirmBookingDestroy,
				toggleModalCleaning
		};
};

export default CleaningListLogic;
