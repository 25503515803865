export const siteConstants = {
  GETALL_REQUEST: "SITES_GETALL_REQUEST",
  GETALL_SUCCESS: "SITES_GETALL_SUCCESS",
  GETALL_FAILURE: "SITES_GETALL_FAILURE",

  GETSITE_REQUEST: "SITES_GETSITE_REQUEST",
  GETSITE_SUCCESS: "SITES_GETSITE_SUCCESS",
  GETSITE_FAILURE: "SITES_GETSITE_FAILURE",

  UPDATE_REQUEST: "SITE_UPDATE_REQUEST",
  UPDATE_SUCCESS: "SITE_UPDATE_SUCCESS",
  UPDATE_FAILURE: "SITE_UPDATE_FAILURE",

  UPLOAD_REQUEST: "SITE_UPLOAD_REQUEST",
  UPLOAD_SUCCESS: "SITE_UPLOAD_SUCCESS",
  UPLOAD_FAILURE: "SITE_UPLOAD_FAILURE",

  IMG_DELETE_REQUEST: "SITE_IMG_DELETE_REQUEST",
  IMG_DELETE_SUCCESS: "SITE_IMG_DELETE_SUCCESS",
  IMG_DELETE_FAILURE: "SITE_IMG_DELETE_FAILURE",
};
