import { get_axs } from "../helpers/fetcher";

export const moduleServices = {
  getAll,
  getAllActives,
};

async function getAll() {
  return get_axs("/modules");
}
async function getAllActives() {
  return get_axs(`/modules/actives`);
}
