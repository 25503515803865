import React from "react";
import PropTypes from "prop-types";

import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../../helpers";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={props => {
        const prop =
          rest.refresh !== undefined
            ? { ...props, refresh: rest.refresh }
            : props;
        return isLogin() ? <Component {...prop} /> : <Redirect to="/login" />;
      }}
    />
  );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ])
};
