import moment    from "moment";
import PropTypes from "prop-types";
import React, { memo } from "react";
import {
  BarChart,
  LineChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

export const Graph = ({
  data, type, interval, grouped, dropped, pickedup
}) => (
  data ? (
    <div
      className="chart-container"
      style={{ height: "200px", width: "90%", float: "left", fontWeight: "401" }}
    >
      <ResponsiveContainer width="100%" height="100%">
        {!type ? (
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" type="number" domain={['dataMin', 'dataMax']} tickFormatter={tick => moment(tick).format('HH:mm')} tickCount={16} />
            <YAxis label={{ value: "Quantité", angle: -90, position: "insideLeft" }} />
            <Tooltip
              contentStyle={{ backgroundColor: "rgba(255, 255, 255, 0.85)" }} cursor={{ stroke: 'red', strokeWidth: 1 }}
              labelFormatter={(tick, _, time = moment(tick)) => 
                `${time?.format("HH[h]mm")}h à ${time?.add({ m: 60 / interval })?.format("HH[h]mm")}`
            } />
            <Legend />
            {dropped  !== false && <Bar dataKey="Colis déposé" fill="#769dd8" stackId={"total"} />}
            {pickedup !== false && <Bar dataKey="Colis retiré" fill="#82ca9d" stackId={"total"} />}
          </BarChart>
        ) : (
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" type="number" domain={['dataMin', 'dataMax']} tickFormatter={tick => moment(tick).format('HH:mm')} tickCount={16} />
            <YAxis label={{ value: "Quantité", angle: -90, position: "insideLeft" }} />
            <Tooltip
              contentStyle={{ backgroundColor: "rgba(255, 255, 255, 0.85)" }} cursor={{ stroke: 'red', strokeWidth: 1 }}
              labelFormatter={(tick, _, time = moment(tick)) =>
                `${time?.format("HH[h]mm")}h à ${time?.add({ m: 60 / interval })?.format("HH[h]mm")}`
            } />
            <Legend />
            <Line dataKey="Colis déposé" stroke="#769dd8" />
            <Line dataKey="Colis retiré" stroke="#82ca9d" />
          </LineChart>
        )}
      </ResponsiveContainer>
    </div>
  ) : (
    <>
      Pas de données
    </>
  )
);

Graph.propTypes = {
  data:           PropTypes.any,
  type:           PropTypes.number,
  interval:       PropTypes.number,
  grouped:        PropTypes.bool,
  dropped:        PropTypes.bool,
  pickedup:       PropTypes.bool
};

export default memo(Graph);
