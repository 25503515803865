import React, { useEffect } from "react";
import FeaturesLogic from "./FeaturesLogic";

import {
  CircularProgress,
  Modal,
  makeStyles,
  Button as MUIButton,
  Chip,
  Tooltip,
  Backdrop,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
// core components
import TableDrag from "components/Table/TableDrag.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import FormFeatures from "components/FormUsers/FormFeatures";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

const useStyles = makeStyles(styles);

export default function Features(props) {
  const classes = useStyles();

  const {
    editModalOpened,
    featureDestroy,
    featuresList,
    formAttributesList,
    formModulesList,
    formValid,
    modalType,
    newFeaturesInfo,
    notifColor,
    notifMessage,
    openNotif,
    deleteFeature,
    handleFormChange,
    refreshData,
    setOpenNotif,
    updatePosition,
    submitNewFeature,
    toggleModal,
    toggleConfirmFeatureDestroy,
    toggleEnableDisableFeature,
  } = FeaturesLogic();

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (featuresList) {
    return (
      <div>
        <Snackbar
          place="tr"
          message={notifMessage}
          open={openNotif}
          close
          icon={
            notifColor === "success"
              ? CheckCircleIcon
              : notifColor === "danger"
              ? WarningIcon
              : NotificationsIcon
          }
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {editModalOpened && newFeaturesInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <FormFeatures
                formAttributesList={formAttributesList}
                formModulesList={formModulesList}
                formValid={formValid}
                modalType={modalType}
                newFeaturesInfo={newFeaturesInfo}
                handleFormChange={handleFormChange}
                submitNewFeature={submitNewFeature}
                toggleModal={toggleModal}
              />
            </div>
          </Modal>
        )}
        {featureDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmFeatureDestroy(null)}
            open={featureDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmFeatureDestroy(null)}
                kind={`Supprimer le module "${featureDestroy.custom_title}" ?`}
                makeAction={() => deleteFeature(featureDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Modules",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MUIButton
                            className={classes.button}
                            onClick={() => toggleModal()}
                            startIcon={<AddIcon />}
                          >
                            Ajouter un module
                          </MUIButton>
                        </GridItem>
                      </GridContainer>
                    </CardBody>

                    <div>
                      {featuresList && featuresList.length === 0 ? (
                        <h4>Aucun module à afficher</h4>
                      ) : (
                        <TableDrag
                          tableHeaderColor="primary"
                          tableType="links"
                          tableHead={[
                            "",
                            "Titre personalisé",
                            "Titre privé",
                            "Module",
                            "Couleur",
                            "Attribut(s)",
                            "",
                          ]}
                          tableOrderFunc={updatePosition}
                          tableData={
                            featuresList &&
                            featuresList.map(feature => {
                              const featureList = {
                                uuid: feature?.uuid,
                                custom_title: feature?.custom_title,
                                internal_title: feature?.internal_title,
                                module: feature?.module?.title,
                                color: (
                                  <div style={{ display: "flex" }}>
                                    <Tooltip title={`#${feature?.color}`}>
                                      <FiberManualRecordIcon
                                        style={{ color: `#${feature?.color}` }}
                                      />
                                    </Tooltip>
                                    <div style={{ marginRight: "1%" }}>
                                      {`#${feature?.color}`}
                                    </div>
                                  </div>
                                ),
                                attributes:
                                  feature?.attributes &&
                                  feature?.attributes.map((it, j) => (
                                    <Chip
                                      key={j}
                                      label={it.name}
                                      color="primary"
                                      style={{ marginRight: "5px" }}
                                    />
                                  )),
                                actions: (
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <Button
                                      style={{ marginLeft: "auto" }}
                                      size="sm"
                                      onClick={() => toggleModal(feature)}
                                      color="primary"
                                    >
                                      Éditer
                                    </Button>
                                    <Button
                                      style={{
                                        marginLeft: "2%",
                                        minWidth: 105,
                                      }}
                                      size="sm"
                                      onClick={() => {}}
                                      color={
                                        feature.disabled ? "success" : "warning"
                                      }
                                      onClick={() =>
                                        toggleEnableDisableFeature(feature)
                                      }
                                    >
                                      {feature.disabled
                                        ? "Activer"
                                        : "Désactiver"}
                                    </Button>
                                    <Button
                                      size="sm"
                                      style={{ marginLeft: "2%" }}
                                      onClick={() =>
                                        toggleConfirmFeatureDestroy(feature)
                                      }
                                      color="danger"
                                    >
                                      Supprimer
                                    </Button>
                                  </div>
                                ),
                              };
                              return Object.values(featureList);
                            })
                          }
                        />
                      )}
                    </div>
                  </GridItem>
                ),
              },
            ]}
          />
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}
