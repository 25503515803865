import React from "react";
import moment from "moment";
import localization from "moment/locale/fr";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardIcon from "../../components/CardIcon";
import StatisticLogic from "./StatisticLogic";
import Graph from "./Graph";
import { default as StatOubabox } from "../Oubabox/Statistics";
import Tabs from "components/CustomTabs/CustomTabs.js";
import { makeStyles } from "@material-ui/core/styles";
import {
  DatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import {
  Person,
  Timer,
  GetApp/*,
  PublishIcon*/,
  Storage,
  Euro
} from "@material-ui/icons";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  Paper,
  Checkbox,
  Slider,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Backdrop
} from "@material-ui/core/";
import {
  getBookingsStatsByRessourceID,
  getAverageBookingDuration,
  getAverageBookingsNumber,
  getAverageBookingsPrice
} from "helpers/statistics";
import ConfirmModal from "components/ConfirmModal";
import modalUserStyle from "assets/jss/material-dashboard-react/views/newsStyle.js";
import { OubacessIcon } from "assets/img/icons";
import { OubaboxIcon } from "assets/img/icons";

const styles = {
  ...modalUserStyle(),
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  filters: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // boxShadow: "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 5px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)"
  },
  gridItem: {
    marginBottom: "0"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  }
};

moment.updateLocale("fr", localization);
const useStyles = makeStyles(styles);

const Statistics = ({ refresh }) => {
  const classes = useStyles();
  const {
    bookingsList,
    usersList,
    currency,
    myself,
    priceFilter,
    ressourceFilter,
    ressources,
    ressourcesLoaded,
    startDate,
    endDate,
    pendingUsers,
    notActivatedUsers,
    downloadStatistics,
    displayOubabox,
    handleFilter,
    setStartDate,
    setEndDate,
    sectorsList,
    estatesList,
    sectorFilter,
    estateFilter,
    toggleResetFilters,
    handlePriceFilter,
    isModalOpen,
    toggleModal
  } = StatisticLogic(refresh);
  
  const oubaccessStats = () => {
    return {
      tabName: `Oubaccess`,
      //tabIcon: OubacessIcon,
      tabOnClick: () => "",
      tabContent: (
        <div>
          <Card>
            <CardHeader className={classes.filters}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  autoOk
                  label="Date de début"
                  labelFunc={date => `${moment(date).format("DD MMMM YYYY")}`}
                  value={startDate}
                  onChange={date => {
                    date >= endDate && setEndDate(moment(date).add(1, "months"));
                    setStartDate(date);
                  }}
                />
                <DatePicker
                  autoOk
                  label="Date de fin"
                  labelFunc={date => `${moment(date).format("DD MMMM YYYY")}`}
                  value={endDate}
                  onChange={date => {
                    date <= startDate && setStartDate(moment(date).subtract(1, "months"));
                    setEndDate(date);
                  }}
                />
              </MuiPickersUtilsProvider>
              <div style={{ display: "inline-block", marginLeft: "10px", marginRight: "10px" }}>
                <InputLabel id="label">
                  Ressources
                </InputLabel>
                <Select
                  multiple
                  displayEmpty
                  labelId="Ressource"
                  id="select-ressource"
                  value={ressourceFilter}
                  renderValue={s => s.length
                    ? `${s.length} ressource${s.length > 1 ? 's' : ''} séléctionée${s.length > 1 ? 's' : ''}`
                    : "Ajouter une ressource"}
                  onChange={event => handleFilter("ressource", event.target.value)}
                >
                  <MenuItem value={null}>
                    <em>
                      Filtrer par ressource
                    </em>
                  </MenuItem>
                  {ressourcesLoaded && ressources?.map(ressource => (
                    <MenuItem
                      key={ressource.id}
                      value={ressource.id}
                    >
                      <Checkbox checked={ressourceFilter.indexOf(ressource.id) > -1} />
                      {ressource.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {myself?.rights.role_superadmin && (
                <div style={{ display: "inline-block", marginLeft: "10px", marginRight: "10px" }}>
                  <InputLabel id="label">
                    Résidence
                  </InputLabel>
                  <Select
                    multiple
                    displayEmpty
                    value={estateFilter}
                    renderValue={r => r.length ? `${r.length} résidence${r.length > 1 ? 's' : ''} séléctioné${r.length > 1 ? 's' : ''}` : "Ajouter une résidence"}
                    onChange={event => handleFilter("estate", event.target.value)}
                  >
                    <MenuItem value={null}>
                      <em>
                        Filtrer par résidence
                      </em>
                    </MenuItem>
                    {estatesList && estatesList.map(estate => (
                      <MenuItem
                        key={estate.id}
                        value={estate.uuid}
                      >
                        <Checkbox checked={estateFilter.indexOf(estate.uuid) > -1} />
                        {estate.title}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
              <div style={{ display: "inline-block", marginLeft: "10px", marginRight: "10px" }}>
                <InputLabel id="label">
                  Bâtiments
                </InputLabel>
                <Select
                  multiple
                  displayEmpty
                  labelId="Sector"
                  id="select-sector"
                  value={sectorFilter}
                  renderValue={s => s.length ? `${s.length} bâtiment${s.length > 1 ? 's' : ''} séléctioné${s.length > 1 ? 's' : ''}` : "Ajouter un bâtiment"}//s.length ? s.map(id => ressources.find(r => r.id === id)?.title || "").join(', ') || "Filtrer par ressource" : "Filtrer par ressource"}
                  onChange={event => handleFilter("sector", event.target.value)}
                >
                  <MenuItem value={null}>
                    <em>
                      Filtrer par bâtiment
                    </em>
                  </MenuItem>
                  {sectorsList && sectorsList.map(sector => (
                    <MenuItem
                      key={sector.id}
                      value={sector.id}
                    >
                      <Checkbox checked={sectorFilter.indexOf(sector.id) > -1} />
                      {sector.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <Button
                color="danger"
                style={{
                  fontFamily: "futura",
                  borderRadius: 10,
                  marginLeft: "10px",
                  marginRight: "10px"
                }}
                onClick={toggleResetFilters}
              >
                Enlever les filtres
              </Button>
            </CardHeader>
          </Card>
          <Grid
            container
            align="center"
            spacing={2}
            direction="row"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            {[{
              icon: Person,
              title: "Utilisateurs totaux",
              text: usersList ? usersList.length : "--"
            }, {
              icon: Person,
              title: "Utilisateurs en attente",
              text: pendingUsers ? pendingUsers.length : "--"
            }, {
              icon: Storage,
              title: "Réservations totales",
              text: bookingsList ? getAverageBookingsNumber(bookingsList) : "--"
            }, {
              icon: Euro,
              title: "Revenus totaux",
              // TODO: replace `null` by `bookingsList`
              text: `${getAverageBookingsPrice(bookingsList)}${currency}`
            }, {
              icon: Timer,
              title: "Durée Moyenne de réservation",
              text: bookingsList ? `${getAverageBookingDuration(bookingsList)} H` : "--"
            }].map(({ icon, title, text, primary, secondary }, index) => (
              <GridItem className={classes.gridItem} key={index}>
                <CardIcon
                  Icon={icon}
                  title={title}
                  text={text}
                  primary={primary}
                  secondary={secondary}
                />
              </GridItem>
            ))}
          </Grid>
          {myself && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <div style={{ display: "flex" }}>
                    <Graph
                      bookings={bookingsList}
                      users={usersList}
                      pendingUsers={pendingUsers}
                      notActivatedUsers={notActivatedUsers}
                      start={startDate}
                      end={endDate}
                    />
                    <Slider
                      min={0} max={1000}
                      style={{ height: "150px", float: "right", margin: "30px" }}
                      orientation="vertical"
                      defaultValue={priceFilter}
                      key={`slider-${priceFilter}`}
                      aria-labelledby="vertical-slider"
                      getAriaValueText={(value) => `${value}${currency}`}
                      valueLabelFormat={(value) => `${value}${currency}`}
                      valueLabelDisplay="auto"
                      onChangeCommitted={handlePriceFilter}
                      marks={[{
                        value: 0, label: `0${currency}`,
                      }, {
                        value: 150, label: `150${currency}`,
                      }, {
                        value: 500, label: `500${currency}`,
                      }, {
                        value: 1000, label: `1000${currency}`,
                      }]}
                    />
                  </div>
                </Card>
              </GridItem>
            </GridContainer>
          )}
          {ressourceFilter.length ? (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Nom
                    </TableCell>
                    <TableCell align="right">
                      Nombre de réservations
                    </TableCell>
                    <TableCell align="right">
                      {"Nombre d'annulations"}
                    </TableCell>
                    <TableCell align="right">
                      {"Temps moyen d'anticipation"}
                    </TableCell>
                    <TableCell align="right">
                      {`Temps moyen de réservation`}
                    </TableCell>
                    <TableCell align="right">
                      Revenus
                    </TableCell>
                  </TableRow>
                </TableHead>
                {ressourcesLoaded ? (
                  <TableBody>
                    {ressources.length ? ressources.map(ressource => {
                      if (!ressourceFilter.includes(ressource.id))
                        return null
                      const stats = getBookingsStatsByRessourceID(bookingsList, ressource.id);
                      return (
                        <TableRow key={ressource.title}>
                          <TableCell align="right">
                            {ressource.title}
                          </TableCell>
                          <TableCell align="right">
                            {stats.amount}
                          </TableCell>
                          <TableCell align="right">
                            {stats.cancel_number}
                          </TableCell>
                          <TableCell align="right">
                            {stats.anticipation ? `${stats.anticipation} jour${stats.anticipation > 0 ? 's' : ''}` : "indisponible"}
                          </TableCell>
                          <TableCell align="right">
                            {`${stats.duration} heure${stats.duration > 1 ? 's' : ''}`}
                          </TableCell>
                          <TableCell align="right">
                            {`${stats.price}${currency}`}
                          </TableCell>
                        </TableRow>
                      );
                    }) : (
                      <>
                        Aucune ressources à afficher.
                      </>
                    )}
                  </TableBody>
                ) : (
                  <CircularProgress />
                )}
              </Table>
            </TableContainer>
          ) : (
            <></>
          )}
          <div className={classes.container}>
            <Button
              color="primary"
              aria-label="export"
              startIcon={<GetApp />}
              onClick={() => toggleModal(true)}>
              Télécharger les statistiques
            </Button>
          </div>
        </div>
      ),
    }
  }
  
  const oubaboxStats = () => {
    return {
      tabName: "Oubabox",
      //tabIcon: OubaboxIcon,
      tabOnClick: () => "",
      tabContent: <StatOubabox />,
    }
  }

  return (bookingsList) ? (
    <>
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        className={classes.modal}
        onClose={() => toggleModal(false)}
        open={isModalOpen}
      >
        <div className={classes.paper}>
          <ConfirmModal
            closeModal={() => toggleModal(false)}
            kind={`Exporter ?`}
            message={`Vous êtes sur le point de télécharger les statistiques du ${startDate.format("DD/MM/YYYY")} au ${endDate.format("DD/MM/YYYY")} au format Excel (.xslx)`}
            makeAction={() => downloadStatistics()}
          />
        </div>
      </Modal>
      {!myself?.rights?.role_superadmin &&
        !myself?.rights?.role_statistics_read ? (
        <GridItem xs={12} sm={12} md={6}>
          <h4>
            {
              "Vous n'avez pas la permission de voir les statistiques"
            }
          </h4>
        </GridItem>
      ) : (
        <GridContainer>
          <Tabs
            tabs={[
              (myself?.rights?.role_superadmin || myself?.rights?.role_bookings_read) && oubaccessStats(),
              (myself?.rights?.role_superadmin || myself?.rights?.role_oubabox_provider ||  myself?.rights?.role_oubabox_maintainer) && displayOubabox && oubaboxStats()
            ].filter(_ => _)}
          />
        </GridContainer>
      )}
    </>
  ) : (
    <div className={classes.circularProgress}>
      <CircularProgress />
    </div>
  );
}

export default Statistics;
