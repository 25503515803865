import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { sitesFetching, siteSelection } from "./site.reducer";
import { groupsFetching, groupAttach, groupDetach } from "./group.reducer";
import { sectorsFetching, sectorAttach, sectorDetach } from "./sector.reducer";
import {
  controlAccess,
  users,
  getUser,
  myself,
  update,
  resetUserPassword, forgotPassword,
} from "./user.reducer";
import { displaySitesModal, displayUserModal } from "./modal.reducer";
import { paginateUsers, paginateBookings } from "./pagination.reducer";
import { searchUsers } from "./search.reducer";
import { tabUsersSelection } from "./tab.reducer";
import { bookingsFetching } from "./booking.reducer";
import { ressourcesFetching } from "./ressource.reducer";

const appReducer = combineReducers({
  authentication,
  bookingsFetching,
  controlAccess,
  displaySitesModal,
  displayUserModal,
  getUser,
  groupsFetching,
  groupAttach,
  groupDetach,
  myself,
  paginateUsers,
  paginateBookings,
  forgotPassword,
  ressourcesFetching,
  sitesFetching,
  sectorAttach,
  sectorDetach,
  sectorsFetching,
  searchUsers,
  siteSelection,
  tabUsersSelection,
  update,
  users,
});

const rootReducer = (state, action) => {
  if (action.type === "USERS_LOGOUT") {
    state = undefined;
    localStorage.clear();

  }
  return appReducer(state, action);
};
export default rootReducer;
