import { modalConstants } from "../constants";

export const modalActions = {
  showSitesModal,
  hideSitesModal,
  showUserModal,
  hideUserModal,
};

function showSitesModal() {
  return {
    type: modalConstants.SHOW_SITES_MODAL,
    open: true,
  };
}

function hideSitesModal() {
  return {
    type: modalConstants.HIDE_SITES_MODAL,
    open: false,
  };
}

function showUserModal() {
  return {
    type: modalConstants.SHOW_USER_MODAL,
    open: true,
  };
}

function hideUserModal() {
  return {
    type: modalConstants.HIDE_USER_MODAL,
    open: false,
  };
}
