import React, { useEffect } from "react";
import CleaningListLogic from "./../TabLogics/CleaningListLogic"
import {ReactComponent as AddIcon} from "assets/img/svg/AddIcon.svg";
import GridItem from "components/Grid/GridItem.js";
import FormCleaning from "components/FormUsers/FormCleaning.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import ConfirmModal from "components/ConfirmModal";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Pagination from "@material-ui/lab/Pagination";
import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {
	Button as MUIButton,
	CircularProgress,
	Modal,
	Backdrop,
	Chip
} from "@material-ui/core";

const paginationStyles = makeStyles(() => ({
		ul: {
				"& .MuiPaginationItem-root": {
						color: "#6E6FA9",
				},
				"& .Mui-selected": {
						backgroundColor: "#6E6FA9",
						color: "#E7E6ED",
				},
		},
}));

const useStyles = makeStyles(styles);

export default function CleaningListView(props) {
	
	const paginationClasses = paginationStyles();
	const classes = useStyles();
	const {
		cleaningList,
		locationRecurrence,
		toggleModalCleaning,
		bookingDestroy,
		toggleConfirmBookingDestroy,
		newCleaningInfo,
		modalMessageBooking,
		deleteBooking,
		cleaningModalOpened,
		formCleaningValid,
		modalCleaningType,
		handleCleaningFormChange,
		requestNewIcsKey,
		submitNewCleaning,
		cleaningCount,
		limits,
		pageCleaning,
		handlePaginationCleaning,
		refreshData,
		notifColor,
		notifMessage,
		openNotif,
		setOpenNotif
	} = CleaningListLogic();
	
	useEffect(() => {
		refreshData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.refresh]);
	
	return (
		<>
		<Snackbar
				place="tr"
				message={notifMessage}
				open={openNotif}
				close
				icon={
						notifColor === "success"
								? CheckCircleIcon
								: notifColor === "danger"
										? WarningIcon
										: NotificationsIcon
				}
				closeNotification={() => setOpenNotif(false)}
				color={notifColor}
		/>
		{bookingDestroy && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{timeout: 500}}
						className={classes.modal}
						onClose={() => toggleConfirmBookingDestroy(null)}
						open={!!bookingDestroy}
				>
						<div className={classes.paper}>
								<ConfirmModal
										closeModal={() => toggleConfirmBookingDestroy(null)}
										kind={modalMessageBooking()}
										makeAction={() => deleteBooking(bookingDestroy.uuid)}
								/>
						</div>
				</Modal>
		)}
		{cleaningModalOpened && newCleaningInfo && (
				<Modal
						BackdropComponent={Backdrop}
						BackdropProps={{
								timeout: 500,
						}}
						className={classes.modal}
						onClose={() => toggleModalCleaning()}
						open={cleaningModalOpened}
				>
						<div
								className={classes.paper}
								style={{
										maxHeight: "80%",
								}}
						>
								<FormCleaning
										formCleaningValid={formCleaningValid}
										modalCleaningType={modalCleaningType}
										newCleaningInfo={newCleaningInfo}
										handleCleaningFormChange={handleCleaningFormChange}
										requestNewIcsKey={requestNewIcsKey}
										submitNewCleaning={submitNewCleaning}
										toggleModalCleaning={toggleModalCleaning}
								/>
						</div>
				</Modal>
		)}
		<GridItem xs={12} sm={12} md={12}>
				<CardBody>
						<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
										<MUIButton
												className={classes.button}
												onClick={() => toggleModalCleaning()}
												startIcon={<AddIcon/>}
												style={{width: "auto"}}
										>
												Ajouter un nouveau ménage
										</MUIButton>
								</GridItem>
						</GridContainer>
				</CardBody>
				<GridItem xs={12} sm={12} md={12}>
						{cleaningList && cleaningList.length === 0 && (
								<h4>Aucun ménage à afficher</h4>
						)}
						{cleaningList && cleaningList.length > 0 && (
								<Table
										tableHeaderColor="primary"
										tableType="booking"
										tableHead={[
												"Titre",
												"Email",
												"Email CC",
												"Lien Calendrier iCal",
												"Jours travaillés",
												"",
										]}
										tableData={
												cleaningList &&
												cleaningList.map((cleaning, i) => {
														const CleaningList = {
																title: cleaning?.title,
																email: cleaning?.email,
																emailCc: cleaning?.email_cc,
																ics: (
																		<a
																				href={`https://ics.ouba.city/cleaning/${cleaning?.ics_key}/calendar.ics`}
																				target="_blank"
																				rel="noopener noreferrer"
																		>
																				{`https://ics.ouba.city/cleaning/${cleaning?.ics_key}/calendar.ics`}
																		</a>
																),
																days:
																		locationRecurrence?.length > i &&
																		locationRecurrence[i].length === 7 ? (
																				locationRecurrence[i].map((it, j) => (
																						<Chip
																								key={j}
																								label={it.d}
																								color={
																										it.active ? "primary" : "default"
																								}
																								style={{marginRight: "5px"}}
																						/>
																				))
																		) : (
																				<div className={classes.root}>
																						<CircularProgress/>
																				</div>
																		),
																actions: (
																		<div
																				style={{
																						display: "flex-end",
																				}}
																		>
																				<Button
																						onClick={() =>
																								toggleModalCleaning(cleaning)
																						}
																						size="sm"
																						color="warning"
																				>
																						Éditer
																				</Button>
																				<Button
																						style={{marginLeft: "2%"}}
																						size="sm"
																						onClick={() =>
																								toggleConfirmBookingDestroy(
																										"cleaning",
																										cleaning
																								)
																						}
																						color="danger"
																				>
																						Supprimer
																				</Button>
																		</div>
																),
														};
														return Object.values(CleaningList);
												})
										}
								/>
						)}
						{cleaningCount > 0 && (
								<div
										style={{
												display: "flex",
												justifyContent: "center",
												paddingTop: 100,
										}}
								>
										<Pagination
												count={Math.ceil(cleaningCount / limits.cleaning)}
												page={pageCleaning}
												onChange={(event, pageNumber) => {
														handlePaginationCleaning(pageNumber);
												}}
												classes={{ul: paginationClasses.ul}}
												size="large"
										/>
								</div>
						)}
				</GridItem>
		</GridItem>
		</>
	)
}