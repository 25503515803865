import { get_axs, post_axs, put_axs, remove_axs } from "../../../helpers/fetcher";

export const eventServices = {
  add,
  attachFeature,
  detachFeature,
  fetch,
  destroy,
  getAll,
  update,
  setImage,
};

async function fetch(eventUUID) {
  return get_axs(`/modules/calendars/events/${eventUUID}`);
}

async function add(data) {
  return post_axs(
    "/modules/calendars/events",
    new URLSearchParams({
      ...data,
    })
  );
}

async function destroy(eventUUID) {
  return remove_axs(`/modules/calendars/events/${eventUUID}`);
}

async function update(eventUUID, data) {
  return put_axs(
    `/modules/calendars/events/${eventUUID}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function setImage(uuid, data) {
  return post_axs(
    `/modules/calendars/events/${uuid}`,
    data,
    "multipart/form-data"
  );
}

async function getAll(page = 1, limit = 10) {
  return get_axs(
    `/modules/calendars/events?page=${page}&limit=${limit}`
  );
}

async function attachFeature(eventUUID, featureUUID) {
  return post_axs(
    `/modules/calendars/events/${eventUUID}/link/feature/${featureUUID}`
  );
}

async function detachFeature(eventUUID, featureUUID) {
  return remove_axs(
    `/modules/calendars/events/${eventUUID}/link/feature/${featureUUID}`
  );
}
