import React, { useEffect } from "react";
import B2ebikesLogic from "./B2ebikesLogic";

import {
  CircularProgress,
  Modal,
  CssBaseline,
  Container,
  makeStyles,
  TextField,
  Button as MUIButton,
  InputLabel,
  Grid,
  Typography,
  Backdrop,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

import Pagination from "@material-ui/lab/Pagination";

// core components
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const useStyles = makeStyles(styles);

export default function B2ebikes(props) {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    editModalOpened,
    limitB2ebike,
    modalType,
    newB2ebikeInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    b2ebikeDestroy,
    b2ebikeCount,
    b2ebikeList,
    deleteB2ebike,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewB2ebike,
    toggleConfirmB2ebikeDestroy,
    toggleModal,
  } = B2ebikesLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (b2ebikeList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {b2ebikeDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmB2ebikeDestroy(null)}
            open={b2ebikeDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmB2ebikeDestroy(null)}
                kind={`Supprimer le b2ebike de "${b2ebikeDestroy?.apiURL}" ?`}
                makeAction={() => deleteB2ebike(b2ebikeDestroy?.id)}
              />
            </div>
          </Modal>
        )}
        {editModalOpened && newB2ebikeInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <Container component="main" maxWidth="md" style={{
                overflowY: "auto",
              }}>
                <CssBaseline />
                <div>
                  <div className={classes.headerModal}>
                    {modalType ? (
                      <Typography variant="h5">Modifier le B2ebike</Typography>
                    ) : (
                      <Typography variant="h5">Ajouter un B2ebike</Typography>
                    )}
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => toggleModal()}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <form className={classes.form} onSubmit={submitNewB2ebike} noValidate>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <InputLabel>API Url *</InputLabel>
                        <TextField
                          defaultValue={newB2ebikeInfo.apiURL}
                          name="url"
                          required
                          fullWidth
                          id="url"
                          onChange={event =>
                            handleFormChange("apiURL", event.target.value)
                          }
                          autoFocus
                        />
                      </Grid>
                      <Grid item md={12}>
                        <InputLabel>Clé API *</InputLabel>
                        <TextField
                          defaultValue={newB2ebikeInfo.apiKey}
                          name="key"
                          required
                          fullWidth
                          id="key"
                          onChange={event =>
                            handleFormChange("apiKey", event.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={!newB2ebikeInfo?.apiURL?.length || !newB2ebikeInfo?.apiKey?.length}
                    >
                      Enregister
                    </Button>
                  </form >
                </div >
              </Container >
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "B2ebike",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {b2ebikeList && b2ebikeList.length < 1 && (<CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MUIButton
                            className={classes.button}
                            onClick={() => toggleModal()}
                            startIcon={<AddIcon />}
                          >
                            Ajouter un B2ebike
                          </MUIButton>
                        </GridItem>
                      </GridContainer>
                    </CardBody>)
                    }
                    <div>
                      {b2ebikeList && b2ebikeList.length === 0 ? (
                        <h4>Aucun B2ebike à afficher</h4>
                      ) :
                        (<div>
                          <Table
                            tableHeaderColor="primary"
                            tableType="links"
                            tableHead={
                              [
                                "API Url",
                                "Clé API",
                                ""
                              ]
                            }
                            tableData={
                              b2ebikeList &&
                              b2ebikeList.map(b2ebike => {
                                const BikeList = {
                                  url: <a href={b2ebike?.apiURL ? (
                                    b2ebike?.apiURL.search('https://') !== 0 &&
                                    b2ebike?.apiURL.search('http://') !== 0 ?
                                    'https://' : '') + b2ebike?.apiURL : '#'} target="_blank" rel="noopener noreferrer">{b2ebike?.apiURL}</a>,
                                  key: b2ebike?.apiKey,
                                  actions: (
                                    <div style={{
                                      display: "flex",
                                    }}>
                                      <Button
                                        style={{ marginLeft: 'auto' }}
                                        size="sm"
                                        onClick={() => toggleModal(b2ebike)}
                                        color="warning"
                                      >
                                        Éditer
                                      </Button>
                                      <Button
                                        size="sm"
                                        style={{ marginLeft: '2%' }}
                                        onClick={() => toggleConfirmB2ebikeDestroy(b2ebike)}
                                        color="danger"
                                      >
                                        Supprimer
                                      </Button>
                                    </div>
                                  ),
                                };
                                return Object.values(BikeList);
                              })
                            }
                          />
                          {b2ebikeCount > 0 && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: 100,
                              }}
                            >
                              <Pagination
                                count={Math.ceil(b2ebikeCount / limitB2ebike)}
                                page={page}
                                onChange={(event, pageNumber) => {
                                  handlePagination(pageNumber);
                                }}
                                classes={{ ul: paginationClasses.ul }}
                                size="large"
                              />
                            </div>
                          )}
                        </div>)
                      }
                    </div>
                  </GridItem>
                )
              }
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}