import React from "react";
import PropTypes from "prop-types";

import { InputLabel, CircularProgress } from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

export default function UploadImage(props) {
  const {
    deleteImg,
    getDelete,
    onChangeImg,
    setDelete,
    setImg,
    title,
    type,
    hasImage,
    uploadImg,
    uuid,
    getRefresh,
  } = props;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
                <InputLabel>{title}</InputLabel>
                {getRefresh(type) ? (
                  <CircularProgress />
                ) : (
                  (hasImage && (<img
                    style={{ marginTop: "10%", maxWidth: "150%" }}
                    src={`${
                      process.env.REACT_APP_CDN_URL
                    }/app/site/${uuid}/${type}?refresh=${Date.now()}`}
                    alt=""
                    onError={i => {
                      i.target.style.display = "none";
                      i.target.alt = "Aucune image disponible";

                      setDelete(false);
                    }}
                    // onError={() => setDelete(false)}
                    onLoad={() => setDelete(true)}
                  />))
                )}
                <input
                  style={{ marginTop: "10%" }}
                  type="file"
                  name="icon"
                  accept="image/*"
                  onChange={e => onChangeImg(e, setImg)}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter
            style={{
              display: "inline",
            }}
          >
            <Button onClick={() => uploadImg(type)} color="primary">
              Envoyer
            </Button>
            {getDelete(type) ? (
              <Button
                style={{ marginLeft: "5%" }}
                onClick={() => deleteImg(type)}
                color="danger"
              >
                Supprimer
              </Button>
            ) : null}
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

UploadImage.propTypes = {
  deleteImg: PropTypes.func,
  getDelete: PropTypes.func,
  onChangeImg: PropTypes.func,
  setDelete: PropTypes.func,
  setImg: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  hasImage: PropTypes.bool,
  uploadImg: PropTypes.func,
  uuid: PropTypes.string,
  getRefresh: PropTypes.func,
};
