import React, { useEffect } from "react";
import PartnersLogic from "./PartnersLogic";

import {
  CircularProgress,
  Modal,
  CssBaseline,
  Container,
  makeStyles,
  TextField,
  Button as MUIButton,
  InputLabel,
  Grid,
  Typography,
  Backdrop,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

import Pagination from "@material-ui/lab/Pagination";

// core components
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const useStyles = makeStyles(styles);

export default function Partners(props) {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    editModalOpened,
    limitPartners,
    modalType,
    newPartnersInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    partnerDestroy,
    partnersCount,
    partnersList,
    refreshImage,
    deletePartner,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewPartner,
    toggleConfirmPartnerDestroy,
    toggleModal,
  } = PartnersLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (partnersList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {partnerDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmPartnerDestroy(null)}
            open={partnerDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmPartnerDestroy(null)}
                kind={`Supprimer le partenaire "${partnerDestroy?.link}" ?`}
                makeAction={() => deletePartner(partnerDestroy?.uuid)}
              />
            </div>
          </Modal>
        )}
        {editModalOpened && newPartnersInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <Container component="main" maxWidth="md" style={{
                overflowY: "auto",
              }}>
                <CssBaseline />
                <div>
                  <div className={classes.headerModal}>
                    {modalType ? (
                      <Typography variant="h5">Modifier le partenaire</Typography>
                    ) : (
                      <Typography variant="h5">Ajouter un partenaire</Typography>
                    )}
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => toggleModal()}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <form className={classes.form} onSubmit={submitNewPartner} noValidate>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <InputLabel>Lien *</InputLabel>
                        <TextField
                          defaultValue={newPartnersInfo.link}
                          name="link"
                          required
                          fullWidth
                          id="link"
                          onChange={event =>
                            handleFormChange("link", event.target.value)
                          }
                          autoFocus
                        />
                      </Grid>
                      <Grid item md={12}>
                        <InputLabel>Icone *</InputLabel>
                        <div style={{ display: 'flex' }}>
                          {newPartnersInfo.uuid && refreshImage &&
                            (refreshImage[newPartnersInfo.uuid] ? (
                              <CircularProgress />
                            ) : (newPartnersInfo.uuid && (<img
                              style={{ marginTop: "1%", height: '100px', marginRight: '2%' }}
                              src={`${process.env.REACT_APP_CDN_URL
                                }/app/partners/${newPartnersInfo?.uuid}/logo?refresh=${Date.now()}`}
                              alt=""
                              onError={i => {
                                i.target.style.display = "none";
                                i.target.alt = "Aucune image disponible";
                              }}
                            />)
                            ))}
                          <input
                            style={{ marginTop: "1%", display: 'column', alignSelf: 'center' }}
                            type="file"
                            name="icon"
                            accept="image/*"
                            onChange={e => handleFormChange("img", e.target.files[0])}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={!newPartnersInfo?.link?.length || (!newPartnersInfo?.img && !modalType)}
                    >
                      Enregister
                    </Button>
                  </form >
                </div >
              </Container >
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Partenaires",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MUIButton
                            className={classes.button}
                            onClick={() => toggleModal()}
                            startIcon={<AddIcon />}
                          >
                            Ajouter un partenaire
                          </MUIButton>
                        </GridItem>
                      </GridContainer>
                    </CardBody>

                    <div>
                      {partnersList && partnersList.length === 0 ? (
                        <h4>Aucun partenaire à afficher</h4>
                      ) :
                        (<div>
                          <Table
                            tableHeaderColor="primary"
                            tableType="links"
                            tableHead={
                              [
                                "Logo",
                                "Lien",
                                ""
                              ]
                            }
                            tableData={
                              partnersList &&
                              partnersList.map(partner => {
                                const partnerList = {
                                  img: (refreshImage &&
                                    (refreshImage[partner?.uuid] ? (
                                      <CircularProgress />
                                    ) : (<img
                                      style={{ marginTop: "1%", width: '50px' }}
                                      src={`${process.env.REACT_APP_CDN_URL
                                        }/app/partners/${partner?.uuid}/logo?refresh=${Date.now()}`}
                                      alt=""
                                      onError={i => {
                                        i.target.style.display = "none";
                                        i.target.alt = "Aucune image disponible";
                                      }}
                                    />))),
                                  link: <a href={partner?.link ? (
                                    partner?.link.search('https://') !== 0 &&
                                      partner?.link.search('http://') !== 0 ?
                                      'https://' : '') + partner.link : '#'} target="_blank" rel="noopener noreferrer">{partner?.link}</a>,
                                  actions: (
                                    <div style={{
                                      display: "flex",
                                    }}>
                                      <Button
                                        style={{ marginLeft: 'auto' }}
                                        size="sm"
                                        onClick={() => toggleModal(partner)}
                                        color="warning"
                                      >
                                        Éditer
                                      </Button>
                                      <Button
                                        size="sm"
                                        style={{ marginLeft: '2%' }}
                                        onClick={() => toggleConfirmPartnerDestroy(partner)}
                                        color="danger"
                                      >
                                        Supprimer
                                      </Button>
                                    </div>
                                  ),
                                };
                                return Object.values(partnerList);
                              })
                            }
                          />
                          {partnersCount > 0 && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: 100,
                              }}
                            >
                              <Pagination
                                count={Math.ceil(partnersCount / limitPartners)}
                                page={page}
                                onChange={(event, pageNumber) => {
                                  handlePagination(pageNumber);
                                }}
                                classes={{ ul: paginationClasses.ul }}
                                size="large"
                              />
                            </div>
                          )}
                        </div>)
                      }
                    </div>
                  </GridItem>
                )
              }
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}