import React, { useEffect } from "react";
import CollectionsLogic from "./CollectionsLogic";

import {
  CircularProgress,
  Modal,
  makeStyles,
  CssBaseline,
  Container,
  Typography,
  IconButton,
  Grid,
  InputLabel,
  Button as MUIButton,
  List,
  RootRef,
  Divider,
  TextField,
  Backdrop,
} from "@material-ui/core";

import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

// core components
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal";
import CardList from "components/Card/CardList.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

const useStyles = makeStyles(styles);

export default function Collections(props) {
  const classes = useStyles();

  const {
    collectionDestroy,
    collectionsList,
    columns,
    editModalOpened,
    modalType,
    newCollectionsInfo,
    notifColor,
    notifMessage,
    openNotif,
    deleteCollection,
    handleFormChange,
    onDragEnd,
    refreshData,
    setOpenNotif,
    submitNewCollection,
    toggleModal,
    toggleConfirmCollectionDestroy,
  } = CollectionsLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (collectionsList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {collectionDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmCollectionDestroy(null)}
            open={collectionDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmCollectionDestroy(null)}
                kind={`Supprimer la collection "${collectionDestroy.title}" ?`}
                makeAction={() => deleteCollection(collectionDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        {editModalOpened && newCollectionsInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <Container component="main" maxWidth="md" style={{
                overflowY: "auto",
              }}>
                <CssBaseline />
                <div>
                  <div className={classes.headerModal}>
                    {modalType ? (
                      <Typography variant="h5">Modifier la collection</Typography>
                    ) : (
                      <Typography variant="h5">Ajouter une collection</Typography>
                    )}
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => toggleModal()}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <form className={classes.form} onSubmit={submitNewCollection} noValidate>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <InputLabel>Titre *</InputLabel>
                        <TextField
                          autoComplete="fname"
                          defaultValue={newCollectionsInfo.title}
                          name="title"
                          required
                          fullWidth
                          id="title"
                          onChange={event =>
                            handleFormChange("title", event.target.value)
                          }
                          autoFocus
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={!newCollectionsInfo?.title?.length}
                    >
                      Enregister
                    </Button>
                  </form >
                </div >
              </Container >
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Collections",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MUIButton
                            className={classes.button}
                            onClick={() => toggleModal()}
                            startIcon={<AddIcon />}
                          >
                            Ajouter une collection
                          </MUIButton>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    {/* <Table
                      tableHeaderColor="primary"
                      tableType="alerts"
                      tableHead={
                        [
                          "Titre",
                          "Groupes",
                          ""
                        ]
                      }
                      tableData={
                        collectionsList &&
                        collectionsList.map(collection => {
                          let collectionList = {
                            title: collection?.title,
                            group: collection?.groups &&
                              collection.groups.map((it, i) =>
                                <Chip key={i}
                                  label={it.title}
                                  color='primary'
                                  style={{ marginRight: '5px' }}
                                />),
                            actions: (
                              <div style={{
                                display: "flex",
                                width: '100%',
                                justifyContent: "right"
                              }}>
                                <Button
                                  size="sm"
                                  onClick={() => toggleModal(collection)}
                                  color="warning"
                                >
                                  Éditer
                                </Button>
                                <Button
                                  size="sm"
                                  style={{ marginLeft: '2%' }}
                                  onClick={() => toggleConfirmCollectionDestroy(collection)}
                                  color="danger"
                                >
                                  Supprimer
                                </Button>
                              </div>
                            )
                          }
                          return Object.values(collectionList);
                        })
                      }
                    /> */}
                    {columns && (
                      <div style={{
                        maxWidth: '98%',
                        marginLeft: '1%',
                      }}>
                        <div
                          style={{
                            display: 'flex',
                            overflowX: "auto",
                          }}
                        >
                          <DragDropContext onDragEnd={onDragEnd}>
                            {Object.values(columns).map((column) => {
                              return (
                                <Grid item key={column.id}>
                                  <div
                                    style={{
                                      backgroundColor: "#ebecf0",
                                      borderRadius: 5,
                                      margin: 10,
                                      padding: 20,
                                      width: '300px',
                                      maxWidth: '300px',
                                    }}
                                  >
                                    <Typography variant={"h5"}>{column.id}</Typography>
                                    <div style={{
                                      display: "flex",
                                      width: '100%',
                                      justifyContent: "space-evenly",
                                    }}>
                                      <Button
                                        size="sm"
                                        onClick={() => toggleModal(collectionsList.find(it => it.title === column.id))}
                                        color="warning"
                                      >
                                        Éditer
                                      </Button>
                                      <Button
                                        size="sm"
                                        style={{ marginLeft: '2%' }}
                                        onClick={() => toggleConfirmCollectionDestroy(collectionsList.find(it => it.title === column.id))}
                                        color="danger"
                                      >
                                        Supprimer
                                      </Button>
                                    </div>
                                    <Divider style={{ marginTop: '5px', background: '#868686' }} />
                                    <Droppable droppableId={column.id}>
                                      {(provided) => (
                                        <RootRef rootRef={provided.innerRef}>
                                          <List style={{
                                            maxHeight: '850px',
                                            overflowY: "auto",
                                          }}>
                                            {column.list.map((itemObject, index) => {
                                              return <Draggable draggableId={itemObject.id} key={itemObject.id} index={index}>
                                                {(provided) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                  >
                                                    <CardList>
                                                      <CardBody>
                                                       <div><small><b>Appartement :</b></small> {itemObject.aptName}</div>
                                                       <div><small><b>Immeuble :</b></small> {itemObject.buildName}</div>
                                                      </CardBody>
                                                    </CardList>
                                                  </div>
                                                )}
                                              </Draggable>;
                                            })}
                                            {provided.placeholder}
                                          </List>
                                        </RootRef>
                                      )}
                                    </Droppable>
                                  </div>
                                </Grid>
                              );
                            })}
                          </DragDropContext>
                        </div>
                      </div>)
                    }
                  </GridItem>
                )
              }
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}