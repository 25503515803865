import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  CssBaseline,
  Grid,
  TextField,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-react/views/userStyle.js";

// core components
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

const TitleForm = ({
  categoryTitle,
  titleValidate,
  handleTitle,
  submitNewCategory,
  toggleCreateModal,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div>
          <div className={classes.headerModal}>
            <Typography variant="h5">Ajouter une catégorie</Typography>
            <IconButton
              size="small"
              aria-label="delete"
              onClick={() => toggleCreateModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <form
            className={classes.form}
            onSubmit={submitNewCategory}
            noValidate
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  value={categoryTitle}
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="Nom"
                  name="title"
                  onChange={event => handleTitle(event.target.value)}
                  autoComplete="email"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={titleValidate}
            >
              Enregistrer
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

TitleForm.propTypes = {
  categoryTitle: PropTypes.string,
  titleValidate: PropTypes.bool,
  handleTitle: PropTypes.func,
  submitNewCategory: PropTypes.func,
  toggleCreateModal: PropTypes.func,
};

export default TitleForm;
