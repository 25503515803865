import { get_axs, post_axs, put_axs, remove_axs } from "../../../helpers/fetcher";

export const reservationLocationServices = {
  add,
  update,
  destroy,
  getAll,
  getItem,
  setCleaningToLocation,
  unsetCleaningForLocation,
  setSectionToLocation,
  unsetSectionForLocation,
  setImage,
};

async function add(data) {
  return post_axs(
    "/modules/reservations/locations",
    new URLSearchParams({
      ...data,
    })
  );
}

async function update(uuid, data) {
  return put_axs(
    `/modules/reservations/locations/${uuid}`,
    new URLSearchParams({
      ...data,
    })
  );
}

async function setCleaningToLocation(cleaningUUID, uuid) {
  return put_axs(
    `/modules/reservations/locations/${uuid}/set/cleaning/${cleaningUUID}`
  );
}

async function unsetCleaningForLocation(uuid) {
  return put_axs(`/modules/reservations/locations/${uuid}/unset/cleaning`);
}

async function setSectionToLocation(sectionUUID, uuid) {
  return put_axs(
    `/modules/reservations/locations/${uuid}/set/section/${sectionUUID}`
  );
}

async function unsetSectionForLocation(uuid) {
  return put_axs(`/modules/reservations/locations/${uuid}/unset/section`);
}

async function getAll() {
  return get_axs("/modules/reservations/locations");
}

async function getItem(uuid) {
  return get_axs(`/modules/reservations/locations/${uuid}`);
}

async function destroy(uuid) {
  return remove_axs(`/modules/reservations/locations/${uuid}`);
}

async function setImage(uuid, data) {
  return post_axs(
    `/modules/reservations/locations/${uuid}`,
    data,
    'multipart/form-data'
  );
}