export const sectorConstants = {
  GETALL_REQUEST: "SECTOR_GETALL_REQUEST",
  GETALL_SUCCESS: "SECTOR_GETALL_SUCCESS",
  GETALL_FAILURE: "SECTOR_GETALL_FAILURE",

  ATTACH_REQUEST: "SECTOR_ATTACH_REQUEST",
  ATTACH_SUCCESS: "SECTOR_ATTACH_SUCCESS",
  ATTACH_FAILURE: "SECTOR_ATTACH_FAILURE",

  DETACH_REQUEST: "SECTOR_DETACH_REQUEST",
  DETACH_SUCCESS: "SECTOR_DETACH_SUCCESS",
  DETACH_FAILURE: "SECTOR_DETACH_FAILURE",
};
