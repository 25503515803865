import React, { useEffect } from "react";
import MailsLogic from "./MailsLogic";

import {
  Backdrop,
  CircularProgress,
  Button as MUIButton,
  makeStyles,
  Modal,
} from "@material-ui/core";
import Tabs from "components/CustomTabs/CustomTabs.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody";
import FormMails from "components/FormUsers/FormMails";
import MailIcon from '@material-ui/icons/Mail';
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(styles);

export default function Mails(props) {
  const classes = useStyles();

  const {
    formValid,
    groupFilter,
    groups,
    mailModalOpened,
    myself,
    newMailInfo,
    notifColor,
    notifMessage,
    openNotif,
    sectorFilter,
    sectors,
    statusFilter,
    userList,
    handleFormChange,
    handleUserFiltering,
    refreshData,
    setOpenNotif,
    submitNewMail,
    toggleModal,
  } = MailsLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (userList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {mailModalOpened && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={mailModalOpened}
          >
            <div className={classes.paper} style={{
              maxHeight: "80%",
              maxWidth: "80%"
            }}>
              <FormMails
                formValid={formValid}
                groupFilter={groupFilter}
                groups={groups}
                newMailInfo={newMailInfo}
                handleFormChange={handleFormChange}
                handleUserFiltering={handleUserFiltering}
                rights={myself?.rights}
                sectorFilter={sectorFilter}
                sectors={sectors}
                statusFilter={statusFilter}
                submitNewMail={submitNewMail}
                toggleModal={toggleModal}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Mails",
                tabIcon: MailIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {(myself?.rights?.role_superadmin || myself?.rights?.role_mails_send) && (
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <MUIButton
                              className={classes.button}
                              onClick={() => toggleModal()}
                              startIcon={<AddIcon />}
                            >
                              Envoyer un nouveau mail
                            </MUIButton>
                          </GridItem>
                        </GridContainer>
                      </CardBody>)
                    }
                  </GridItem>
                )
              }]}
          />
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}
