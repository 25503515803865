import { get_axs, post_axs, put_axs, remove_axs } from "../helpers/fetcher";
import { history } from "../helpers";
import { saveState } from "helpers/localStorage";

export const userService = {
  login,
  getAllBySite,
  getUser,
  getSelf,
  createUser,
  update,
  linkEstateToUser,
  unLinkEstateToUser,
  forgotPassword,
  sendNewPassword,
  updateOccupationStatus,
  toggleEnable,
  getAllPending,
  controlAccess,
  removeFromResidence,
  downloadProofOfResidence,
  updateOwnerName,
};

async function login(username, password) {
  const user = await post_axs(
    "/auth/login",
    new URLSearchParams({
      username: username,
      password: password,
    }),
  );
  if (!user) {
    throw ("login error");
  }
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  // localStorage.setItem("user", JSON.stringify(user));
  saveState("user", user);
  return user;
}

export const logout = () => {
  // remove user and persisted reducer from local storage to log user out
  localStorage.clear();
  history.push("/");
  window.location.reload();
};

async function getAllBySite(page, search, state, sectors, groups) {
  return get_axs(
    `/users?currentPage=${page}&pageSize=20&search=${search}&state=${state}${sectors}${groups}`,
  );
}

async function getUser(uid) {
  return get_axs(`/users/${uid}`);
}

async function getSelf() {
  const myself = await get_axs("/users/self");
  return myself;
}

async function createUser(user) {
  return post_axs(
    "/users",
    new URLSearchParams({
      ...user,
    }),
  );
}

async function update(user) {
  return put_axs(
    `/users/${user.uid}`,
    new URLSearchParams({
      ...user,
    }),
  );
}

async function updateOwnerName(eTuUUID, data) {
  return put_axs(
    `/users/${eTuUUID}/owner`,
    new URLSearchParams({
      ...data,
    }),
  );
}

async function toggleEnable(user) {
  return put_axs(
    `/users/${user.uid}/enable/${user.enabled}`,
  );
}

async function forgotPassword(email) {
  return post_axs(
    `/users/password/forgot`, new URLSearchParams({ email }));
}

async function sendNewPassword(uid) {
  return post_axs(
    `/users/${uid}/password/reset`,
  );
}

async function getAllPending(search) {
  return get_axs(`/users?state=pending${search && `&search=${search}`}`);
}

// Pierre Chauchoy told me to bring up a word
async function controlAccess(userUUID, decision, reason) {
  // FIXME not sure about the "review token" part
  return await put_axs(
    `/users/${userUUID}/review-account`,
    new URLSearchParams({
      validate: decision,
      reason,
    }),
  );
}

async function linkEstateToUser(userUUID, sectorUUID, groupUUID) {
  return await put_axs(
    `/users/${userUUID}/estate`,
    new URLSearchParams({
      sector_uuid: sectorUUID,
      group_uuid: groupUUID,
    }),
  );
}

async function updateOccupationStatus(
  userUUID,
  occupationStatus,
  group_id,
) {
  return await put_axs(
    `/users/${userUUID}/occupation-status`,
    new URLSearchParams({
      occupationStatus,
      group_id,
    }),
  );
}

async function unLinkEstateToUser(userUUID, sectorUUID, groupUUID) {
  return await remove_axs(
    `/users/${userUUID}/estate`,
    new URLSearchParams({
      sector_uuid: sectorUUID,
      group_uuid: groupUUID,
    }),
  );
}

async function removeFromResidence(userUUID) {
  return await remove_axs(
    `/users/${userUUID}/residence/remove-user`,
  );
}

async function downloadProofOfResidence(userUUID, fileName) {
  return get_axs(`/users/${userUUID}/proof/${fileName}`, undefined, undefined, "blob");
}