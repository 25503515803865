const customTabsStyle = {
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px",
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important",
  },
  displayNone: {
    display: "none !important",
  },
  tabsRoot: {
    minHeight: "unset !important",
    overflowX: "visible",
    "& $tabRootButton": {
      fontSize: "0.875rem",
    },
  },
  tabRootButton: {
    display: "flex", 
    justifyContent: "space-around",
    minHeight: "unset !important",
    minWidth: "unset !important",
    minWidth: 200,
    height: 50,
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    borderRadius: 10,
    lineHeight: "24px",
    border: "0 !important",
    color: "#3D406E",
    backgroundColor: "white",
  },
  tabSelected: {
    backgroundColor: "#3D406E",
    color: "white",
    transition: "0.2s background-color 0.1s",
  },
  scrollButtons: {
    backgroundColor: "#3D406E",
    color: "white",
    borderRadius: 10,
    transition: "0.2s background-color 0.1s",
  }, 
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    fontWeight: "500",
    fontSize: "12px",
    marginTop: "1px",
    marginLeft: 15,
    "& > svg,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0 !important",
    },
  },
  badge: {
    height: "20px",
    display: "flex",
    padding: "0 6px",
    zIndex: "1",
    position: "relative",
    flexWrap: "wrap",
    fontSize: "0.75rem",
    minWidth: "20px",
    boxSizing: "border-box",
    transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "center",
    fontFamily: "futura",
    fontWeight: "500",
    lineHeight: "1",
    alignContent: "center",
    borderRadius: "10px",
    flexDirection: "row",
    justifyContent: "center",
  },
};

export default customTabsStyle;
