import React, { useEffect, useMemo } from "react";
import FilesLogic from "./FilesLogic";

import moment from "moment";
import localization from "moment/locale/fr";

import {
  CircularProgress,
  makeStyles,
  Modal,
  Backdrop,
  TextField,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

import Pagination from "@material-ui/lab/Pagination";

// core components
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GetAppIcon from '@material-ui/icons/GetApp';
import { IconButton } from "@material-ui/core";

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fbfbfb',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

moment.updateLocale("fr", localization);

const useStyles = makeStyles(styles);

export default function Files(props) {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    file,
    fileCount,
    fileDestroy,
    fileList,
    isDragActive,
    isDragAccept,
    isDragReject,
    limitFile,
    myself,
    newFileTitle,
    notifColor,
    notifMessage,
    openNotif,
    page,
    deleteFile,
    downloadFile,
    getInputProps,
    getRootProps,
    handleTitleChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    toggleConfirmFileDestroy,
    uploadfile,
  } = FilesLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  if (fileList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {fileDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmFileDestroy(null)}
            open={fileDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmFileDestroy(null)}
                kind={`Supprimer le fichier "${fileDestroy.name}" ?`}
                makeAction={() => deleteFile(fileDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Documents",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <div style={{ marginTop: '20px' }}>
                    {(myself?.rights?.role_superadmin || myself?.rights?.role_files_add) &&
                      (<div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        {isDragActive ?
                          <p style={{ fontSize: 16 }}>Déposer le fichier ici ...</p> :
                          <p style={{ fontSize: 16 }}>Déposer le fichier ici ou selectionner le en cliquant ici</p>
                        }
                      </div>)
                    }
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: '1.5%',
                        fontSize: 18,
                        marginBottom: '15px',
                      }}>
                        {newFileTitle !== undefined && (<TextField
                          autoComplete="fname"
                          value={newFileTitle}
                          name="title"
                          label="Nom du document"
                          style={{ minWidth: '35%' }}
                          id="title"
                          variant='outlined'
                          onChange={event =>
                            handleTitleChange(event.target.value)
                          }
                          error={!newFileTitle.length
                            ? true : (fileList.find(f => f.name === newFileTitle)
                              ? true : false)}
                          helperText={!newFileTitle.length ?
                            "Le nom du document ne peut pas être vide" :
                            (fileList.find(f => f.name === newFileTitle) ?
                              "Le nom de ce fichier est déjà utilisé" : " ")}
                        />)
                        }
                      </GridItem>
                      {file?.name && (<GridItem xs={12} sm={12} md={12} style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}>
                        <Button
                          onClick={uploadfile}
                          color="primary"
                          disabled={(!file || !newFileTitle?.length || fileList.find(f => f.name === newFileTitle))
                            ? true : false}
                        >
                          Ajouter le fichier
                        </Button>
                      </GridItem>)
                      }
                      <Card
                        style={{
                          boxShadow: "none",
                          marginTop: 0,
                          paddingTop: 0,
                        }}>
                        {!myself?.rights?.role_superadmin && !myself?.rights?.role_files_read ? (
                          <GridItem xs={12} sm={12} md={6}>
                            <h4>
                              {
                                "Vous n'avez pas la permission de voir les fichiers"
                              }
                            </h4>
                          </GridItem>
                        ) : (
                          <CardBody>
                            {fileCount === 0 ? (
                              <h4>Aucun document à afficher</h4>
                            ) :
                              (<Table
                                tableHeaderColor="primary"
                                tableType="files"
                                tableHead={
                                  [
                                    "Nom du document",
                                    ""
                                  ]
                                }
                                tableData={
                                  fileList &&
                                  fileList.map(file => {
                                    const fileslist = {
                                      name: (
                                        <div>
                                          {file.name}
                                          <IconButton
                                            aria-label="download"
                                            onClick={() => downloadFile(file)}
                                          >
                                            <GetAppIcon />
                                          </IconButton>
                                        </div>),
                                      actions: (
                                        <div style={{
                                          display: "flex",
                                        }}>
                                          {(myself?.rights?.role_superadmin || myself?.rights?.role_files_delete) &&
                                            (<Button
                                              size="sm"
                                              style={{ marginLeft: 'auto' }}
                                              onClick={() => toggleConfirmFileDestroy(file)}
                                              color="danger"
                                            >
                                              Supprimer
                                            </Button>)
                                          }
                                        </div>
                                      ),
                                    };
                                    return Object.values(fileslist);
                                  })
                                }
                              />)
                            }
                            {fileCount > 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: 100,
                                }}
                              >
                                <Pagination
                                  count={Math.ceil(fileCount / limitFile)}
                                  page={page}
                                  onChange={(event, pageNumber) => {
                                    handlePagination(pageNumber);
                                  }}
                                  classes={{ ul: paginationClasses.ul }}
                                  size="large"
                                />
                              </div>
                            )}
                          </CardBody>)
                        }
                      </Card>
                    </GridContainer>
                  </div>
                )
              }
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}