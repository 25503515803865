import React from "react";
import PropTypes from "prop-types";

// import PropTypes from "prop-types";

import MuiPhoneNumber from "material-ui-phone-number";

import {
  Container,
  CssBaseline,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  makeStyles,
  Select,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import styles from "assets/jss/material-dashboard-react/views/userStyle.js";

// core components

import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function FormUsers({
  editModalOpened,
  userSelected,
  toggleModal,
  modalType,
  submitCreateHandle,
  submitUpdateHandler,
  userTitle,
  handleUserEdition,
  toggleSector,
  toggleGroup,
  handleUserAssociation,
  sectors,
  sector,
  sectorGroups,
  group,
  loading,
  updateValidation,
  createValidation,
  rgpd
}) {
  const classes = useStyles();

  return (
    <div>
      {editModalOpened && (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <div className={classes.headerModal}>
              {modalType ? (
                <Typography variant="h5">Modifier</Typography>
              ) : (
                <Typography variant="h5">Ajouter un utilisateur</Typography>
              )}
              <IconButton
                size="small"
                aria-label="delete"
                onClick={() => toggleModal(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <form
              className={classes.form}
              onSubmit={modalType ? submitUpdateHandler : submitCreateHandle}
              noValidate
            >
              <Grid container spacing={3}>
                {!rgpd && (
                  <Grid item xs={12}>
                    <InputLabel>Civilité *</InputLabel>
                    <Select
                      value={userTitle || "null"}
                      onChange={event =>
                        handleUserEdition("civility", event.target.value)
                      }
                      inputProps={{
                        name: "title",
                        id: "title",
                      }}
                    >
                      <MenuItem value={"null"}>
                        <b>
                          <em>Non précisé</em>
                        </b>
                      </MenuItem>
                      <br />

                      <MenuItem value={"MM"}>Madame</MenuItem>
                      <MenuItem value={"MR"}>Monsieur</MenuItem>
                    </Select>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    defaultValue={userSelected.firstName}
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="Prénom"
                    onChange={event =>
                      handleUserEdition("firstName", event.target.value)
                    }
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={userSelected.lastName}
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Nom"
                    name="lastName"
                    onChange={event =>
                      handleUserEdition("lastName", event.target.value)
                    }
                    autoComplete="lname"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    defaultValue={userSelected.email}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    onChange={event =>
                      handleUserEdition("email", event.target.value)
                    }
                    autoComplete="email"
                  />
                </Grid>
                {!rgpd && (
                  <Grid item xs={12} sm={12}>
                    <InputLabel>Numéro de téléphone</InputLabel>
                    <MuiPhoneNumber
                      defaultCountry={"fr"}
                      value={userSelected.mobilePhone}
                      onChange={(event, country) =>
                        handleUserEdition("phone", event, country.coundryCode)
                      }
                    />
                  </Grid>
                )}
              </Grid>
              {!modalType && !rgpd && (
                <>
                  <br />
                  <Grid item xs={12} sm={6}>
                    <Select
                      value={sector || "null"}
                      onChange={event =>
                        handleUserAssociation("sector", event.target.value)
                      }
                      inputProps={{
                        name: "sector",
                        id: "sector",
                      }}
                      style={{ minWidth: 240 }}
                    >
                      <MenuItem value={"null"}>
                        <b>
                          <em>Bâtiment</em>
                        </b>
                      </MenuItem>
                      <br />
                      {sectors &&
                        sectors.map((sector, indexAttribute) => (
                          <MenuItem
                            key={indexAttribute}
                            value={sector.uuid}
                            onClick={() => toggleSector(sector.id)}
                          >
                            {sector.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <br />
                  {sectorGroups && (
                    <>
                      {sectorGroups && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Select
                              value={group || "null"}
                              onChange={event =>
                                handleUserAssociation(
                                  "group",
                                  event.target.value
                                )
                              }
                              inputProps={{
                                name: "group",
                                id: "group",
                              }}
                              style={{ minWidth: 240 }}
                            >
                              <MenuItem value="null">
                                <b>
                                  <em>Lot - Appartement</em>
                                </b>
                              </MenuItem>
                              <br />
                              {sectorGroups.map((group, i) => (
                                <MenuItem
                                  key={i}
                                  value={group.uuid}
                                  onClick={() => toggleGroup(group.id)}
                                >
                                  {group.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <div style={{ display: 'flex' }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{ marginBottom: '10px' }}
                  color="primary"
                  // className={classes.submit}
                  disabled={loading || (modalType ? updateValidation : createValidation)}
                >
                  Enregistrer
                </Button>
                {loading && (
                  <div className={classes.root} style={{ marginLeft: '2%', marginTop: '1%' }}>
                    <CircularProgress />
                  </div>
                )}
              </div>
            </form>
          </div>
        </Container>
      )}
    </div>
  );
}

FormUsers.propTypes = {
  editModalOpened: PropTypes.bool,
  userSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toggleModal: PropTypes.func,
  modalType: PropTypes.bool,
  userTitle: PropTypes.string,
  handleUserEdition: PropTypes.func,
  toggleSector: PropTypes.func,
  toggleGroup: PropTypes.func,
  handleUserAssociation: PropTypes.func,
  sectors: PropTypes.array,
  sector: PropTypes.string,
  sectorGroups: PropTypes.array,
  group: PropTypes.string,
  loading: PropTypes.bool,
  updateValidation: PropTypes.bool,
  createValidation: PropTypes.bool,
  submitCreateHandle: PropTypes.func,
  submitUpdateHandler: PropTypes.func,
  rgpd: PropTypes.bool,
};
