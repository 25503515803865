import React, { useEffect } from "react";
import EventsLogic from "./EventsLogic";
import moment from "moment";
import localization from "moment/locale/fr";

import {
  Backdrop,
  Button as MUIButton,
  CircularProgress,
  makeStyles,
  Divider,
  Modal,
  Chip,
} from "@material-ui/core";
import Tabs from "components/CustomTabs/CustomTabs.js";

// Other front packages
import Table from "components/Table/Table.js";

import Pagination from "@material-ui/lab/Pagination";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ConfirmModal from "components/ConfirmModal";
import Card from "components/Card/Card.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import FormEvents from "components/FormUsers/FormEvents";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// const textStyles = makeStyles({
//   formControlRoot: {
//     fontFamily: "futura",
//     color: "#6E6FA9",
//     borderRadius: "7px",
//     "& label.Mui-focused": {
//       color: "#6E6FA9",
//     },
//   },
//   inputLabelRoot: {
//     color: "#6E6FA9",
//     fontFamily: "futura",
//     "&.focused": {
//       color: "#6E6FA9",
//     },
//   },
// });
const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

moment.updateLocale("fr", localization);

const useStyles = makeStyles(styles);

export default function Events(props) {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    editModalOpened,
    eventDestroy,
    eventsCount,
    eventsList,
    eventsRecurrence,
    formModulesList,
    formValid,
    limitEvents,
    modalType,
    myself,
    notifColor,
    notifMessage,
    newEventInfo,
    openNotif,
    page,
    refreshImage,
    deleteEvent,
    handlePagination,
    handleFormChange,
    refreshData,
    setOpenNotif,
    submitNewEvent,
    toggleConfirmEventDestroy,
    toggleModal,
  } = EventsLogic();

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (eventsList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {eventDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmEventDestroy(null)}
            open={eventDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmEventDestroy(null)}
                kind={`Supprimer l'évènement "${eventDestroy.title}" ?`}
                makeAction={() => deleteEvent(eventDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        {editModalOpened && newEventInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <FormEvents
                formModulesList={formModulesList}
                formValid={formValid}
                modalType={modalType}
                newEventInfo={newEventInfo}
                refreshImage={refreshImage}
                handleFormChange={handleFormChange}
                submitNewEvent={submitNewEvent}
                toggleModal={toggleModal}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Événements",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {(myself?.rights?.role_superadmin ||
                      myself?.rights?.role_events_add) && (
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <MUIButton
                                className={classes.button}
                                onClick={() => toggleModal()}
                                startIcon={<AddIcon />}
                              >
                                Ajouter un événement
                              </MUIButton>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      )}
                    {!myself?.rights?.role_superadmin &&
                      !myself?.rights?.role_events_read ? (
                      <GridItem xs={12} sm={12} md={6}>
                        <h4>
                          {
                            "Vous n'avez pas la permission de voir les événements"
                          }
                        </h4>
                      </GridItem>
                    ) : (
                      <div>
                        {eventsList && eventsList.length === 0 && (
                          <h4>Aucun événement à afficher</h4>
                        )}
                        {eventsList &&
                          eventsList?.map((event, i) => (
                            <div key={i}>
                              <Card>
                                <CardHeader
                                  style={{
                                    borderRadius: 1,
                                    paddingTop: 0,
                                    border: "none",
                                    boxShadow:
                                      "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
                                  }}
                                >
                                  <h4 className={classes.cardTitle}>
                                    {event?.title}
                                  </h4>
                                  <p className={classes.cardDescription}>
                                    {event?.description}
                                  </p>
                                </CardHeader>
                                <CardBody>
                                  <div style={{ display: 'flex' }}>
                                    {refreshImage &&
                                      (refreshImage[event?.uuid] ? (
                                        <CircularProgress />
                                      ) : (event?.has_picture &&
                                        (<img
                                          style={{ marginTop: "1%", height: '100px' }}
                                          src={`${process.env.REACT_APP_CDN_URL
                                            }/app/module/calendar/events/${event?.uuid}/image?refresh=${Date.now()}`}
                                          alt=""
                                          onError={i => {
                                            i.target.style.display = "none";
                                            i.target.alt = "Aucune image disponible";
                                          }}
                                        />)))
                                    }
                                    < GridItem xs={12} sm={12} md={12} style={{ display: 'column', alignSelf: 'center' }}>
                                      {event?.features &&
                                        event.features.map((it, i) =>
                                          <Chip key={i}
                                            label={it.custom_title}
                                            color='primary'
                                            style={{ marginRight: '5px' }}
                                          />)
                                      }
                                    </GridItem>
                                  </div>
                                  <Table
                                    tableHeaderColor="primary"
                                    tableType="event"
                                    tableHead={[
                                      "Date de début",
                                      "Date de fin",
                                      "Heure de début",
                                      "Heure de fin",
                                      "Toute la journée",
                                      "Récurrences",
                                    ]}
                                    tableData={[
                                      Object.values({
                                        dateStart: event?.date_begin
                                          ? moment(event.date_begin).format(
                                            "DD/MM/YYYY"
                                          )
                                          : "",
                                        dateEnd: event?.date_end
                                          ? moment(event.date_end).format(
                                            "DD/MM/YYYY"
                                          )
                                          : "",
                                        start: event?.time_begin
                                          ? event.time_begin
                                          : "",
                                        end: event?.time_end
                                          ? event.time_end
                                          : "",
                                        allDay: event?.is_all_day_long
                                          ? "Oui"
                                          : "Non",
                                        recurence:
                                          eventsRecurrence?.length > i &&
                                            eventsRecurrence[i].length === 7 ? (
                                            eventsRecurrence[i].map((it, j) => (
                                              <Chip
                                                key={j}
                                                label={it.d}
                                                color={
                                                  it.active
                                                    ? "primary"
                                                    : "default"
                                                }
                                                style={{ marginRight: "5px" }}
                                              />
                                            ))
                                          ) : (
                                            <div className={classes.root}>
                                              <CircularProgress />
                                            </div>
                                          ),
                                      }),
                                    ]}
                                  />
                                  <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{ marginTop: "1%" }}
                                  >
                                    {(myself?.rights?.role_superadmin ||
                                      myself?.rights?.role_events_edit) && (
                                        <Button
                                          onClick={() => toggleModal(event)}
                                          color="warning"
                                        >
                                          Éditer
                                        </Button>
                                      )}
                                    {(myself?.rights?.role_superadmin ||
                                      myself?.rights?.role_events_delete) && (
                                        <Button
                                          style={{ marginLeft: "2%" }}
                                          onClick={() => toggleConfirmEventDestroy(event)}
                                          color="danger"
                                        >
                                          Supprimer
                                        </Button>
                                      )}
                                  </GridItem>
                                </CardBody>
                                <CardFooter>
                                  Crée le{" "}
                                  {moment(event.created_at).format(
                                    "DD/MM/YYYY à HH:mm"
                                  )}
                                </CardFooter>
                              </Card>
                              {eventsList.length - 1 !== i && <Divider />}
                            </div>
                          ))}
                        {eventsCount > 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: 100,
                            }}
                          >
                            <Pagination
                              count={Math.ceil(eventsCount / limitEvents)}
                              page={page}
                              onChange={(event, pageNumber) => {
                                handlePagination(pageNumber);
                              }}
                              classes={{ ul: paginationClasses.ul }}
                              size="large"
                            />
                          </div>
                        )}
                      </div>
                    )
                    }
                  </GridItem>
                ),
              },
            ]}
          />
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}
