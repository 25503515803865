import React, { useEffect } from "react";
import LinksLogic from "./LinksLogic";

import {
  CircularProgress,
  Modal,
  makeStyles,
  Button as MUIButton,
  Chip,
  Backdrop,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

import Pagination from "@material-ui/lab/Pagination";

// core components
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import FormLinks from "components/FormUsers/FormLinks";
import CardBody from "components/Card/CardBody.js";
import ConfirmModal from "components/ConfirmModal";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const useStyles = makeStyles(styles);

export default function Links(props) {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    editModalOpened,
    formGroupsList,
    formModulesList,
    formValid,
    newLinksInfo,
    notifColor,
    notifMessage,
    modalType,
    myself,
    openNotif,
    linkDestroy,
    linksCount,
    linksList,
    limitLinks,
    page,
    refreshImage,
    deleteIcon,
    deleteLink,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewLink,
    toggleConfirmLinkDestroy,
    toggleModal,
  } = LinksLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (linksList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {linkDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmLinkDestroy(null)}
            open={linkDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmLinkDestroy(null)}
                kind={`Supprimer le lien "${linkDestroy.title}" ?`}
                makeAction={() => deleteLink(linkDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        {editModalOpened && newLinksInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <FormLinks
                formGroupsList={formGroupsList}
                formModulesList={formModulesList}
                formValid={formValid}
                modalType={modalType}
                newLinksInfo={newLinksInfo}
                refreshImage={refreshImage}
                deleteIcon={deleteIcon}
                handleFormChange={handleFormChange}
                submitNewLink={submitNewLink}
                toggleModal={toggleModal}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Liens",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {(myself?.rights?.role_superadmin ||
                      myself?.rights?.role_links_add) && (
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <MUIButton
                                className={classes.button}
                                onClick={() => toggleModal()}
                                startIcon={<AddIcon />}
                              >
                                Ajouter un lien
                              </MUIButton>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      )}
                    {!myself?.rights?.role_superadmin &&
                      !myself?.rights?.role_links_read ? (
                      <GridItem xs={12} sm={12} md={6}>
                        <h4>
                          {
                            "Vous n'avez pas la permission de voir les liens"
                          }
                        </h4>
                      </GridItem>
                    ) : (
                      <div>
                        {linksList && linksList.length === 0 ? (
                          <h4>Aucun lien à afficher</h4>
                        ) :
                          (<Table
                            tableHeaderColor="primary"
                            tableType="links"
                            tableHead={
                              [
                                "Titre",
                                "Image",
                                "Groupe",
                                "Module(s)",
                                "Lien",
                                ""
                              ]
                            }
                            tableData={
                              linksList &&
                              linksList.map(link => {
                                const linkList = {
                                  title: link?.title,
                                  img: (refreshImage &&
                                    (refreshImage[link?.uuid] ? (
                                    <CircularProgress />
                                  ) : (link?.has_image && (<img
                                    style={{ marginTop: "1%", width: '50px' }}
                                    src={`${process.env.REACT_APP_CDN_URL
                                      }/app/module/links/points/${link?.uuid}/image?refresh=${Date.now()}`}
                                    alt=""
                                    onError={i => {
                                      i.target.style.display = "none";
                                      i.target.alt = "Aucune image disponible";
                                    }}
                                  />)))),
                                  group: link?.group?.title,
                                  module: link?.features &&
                                    link?.features.map((it, i) => (
                                      <Chip
                                        key={i}
                                        label={it.custom_title}
                                        color="primary"
                                        style={{ marginRight: "5px" }}
                                      />
                                    )),
                                  link: link?.link ? (
                                    <a href={link?.link ? (
                                      link.link.search('https://') !== 0 &&
                                      link.link.search('http://') !== 0 ?
                                      'https://' : '') + link.link : '#'} target="_blank" rel="noopener noreferrer">
                                      {link?.link}
                                    </a>
                                  ) : "",
                                  actions: (
                                    <div style={{
                                      display: "flex",
                                    }}>
                                      {(myself?.rights?.role_superadmin || myself?.rights?.role_links_edit) &&
                                        (<Button
                                          style={{ marginLeft: 'auto', }}
                                          size="sm"
                                          onClick={() => toggleModal(link)}
                                          color="warning"
                                        >
                                          Éditer
                                        </Button>)
                                      }
                                      {(myself?.rights?.role_superadmin || myself?.rights?.role_links_delete) &&
                                        (<Button
                                          size="sm"
                                          style={{ marginLeft: '2%' }}
                                          onClick={() => toggleConfirmLinkDestroy(link)}
                                          color="danger"
                                        >
                                          Supprimer
                                        </Button>)
                                      }
                                    </div>
                                  ),
                                };
                                return Object.values(linkList);
                              })
                            }
                          />)
                        }
                        {linksCount > 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: 100,
                            }}
                          >
                            <Pagination
                              count={Math.ceil(linksCount / limitLinks)}
                              page={page}
                              onChange={(event, pageNumber) => {
                                handlePagination(pageNumber);
                              }}
                              classes={{ ul: paginationClasses.ul }}
                              size="large"
                            />
                          </div>
                        )}
                      </div>)
                    }
                  </GridItem>
                )
              }
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}