import React /*useEffect*/ from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  InputLabel,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  Switch,
} from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function FormA1001Doors(props) {
  const classes = useStyles();

  const {
    formA1001List,
    formDoorsValid,
    formLocationDoorsList,
    modalDoorsType,
    newA1001DoorsInfo,
    handleDoorsFormChange,
    submitNewDoors,
    toggleModalA1001Doors,
  } = props;
  return (
    <Container
      component="main"
      maxWidth="md"
      style={{
        overflowY: "auto",
      }}
    >
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {modalDoorsType ? (
            <Typography variant="h5">Modifier la porte</Typography>
          ) : (
            <Typography variant="h5">Ajouter une porte</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModalA1001Doors()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewDoors} noValidate>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Nom de la porte *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newA1001DoorsInfo.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleDoorsFormChange("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>AccessPoint Token *</InputLabel>
              <TextField
                id="accessPointToken"
                value={newA1001DoorsInfo.accessPointToken}
                onChange={e =>
                  handleDoorsFormChange("accessPointToken", e.target.value)
                }
                multiline
                required
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Door Token *</InputLabel>
              <TextField
                id="doorToken"
                value={newA1001DoorsInfo.doorToken}
                onChange={e =>
                  handleDoorsFormChange("doorToken", e.target.value)
                }
                multiline
                required
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <div>
                Récupération des AccessPoint Token :
                /vapix/pacs?action=pacs:GetAccessPointInfoList
              </div>
              <div>
                Récupération des Door Token :
                /vapix/doorcontrol?action=tdc:GetDoorInfoList
              </div>
            </Grid>
            <Grid item md={12}>
              <InputLabel>Ouverture rapide (sans code)</InputLabel>
              <Switch
                id="fastOpen"
                checked={newA1001DoorsInfo.fastOpen}
                onChange={e =>
                  handleDoorsFormChange("fastOpen", e.target.checked)
                }
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
            <Grid item md={3}>
              <InputLabel>A1001 associé</InputLabel>
              {formA1001List ? (
                <Select
                  value={newA1001DoorsInfo.a1001}
                  style={{ top: "16px" }}
                  id="select-type"
                  fullWidth
                  displayEmpty
                  onChange={event =>
                    handleDoorsFormChange("a1001", event.target.value)
                  }
                >
                  <MenuItem value={""}>
                    <em>Aucune séléction</em>
                  </MenuItem>
                  {formA1001List &&
                    formA1001List.map((it, i) => (
                      <MenuItem key={i} value={it.uuid}>
                        <div>{it.title}</div>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
            <Grid item md={6}>
              <InputLabel>Emplacement(s) associé(s)</InputLabel>
              {formLocationDoorsList ? (
                <Select
                  value={newA1001DoorsInfo.location}
                  style={{ top: "16px" }}
                  id="select-type"
                  fullWidth
                  multiple
                  displayEmpty
                  onChange={event =>
                    handleDoorsFormChange("location", event.target.value)
                  }
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return <em>Aucune séléction</em>;
                    }
                    return formLocationDoorsList
                      .filter(location =>
                        selected.find(it => it === location.uuid)
                      )
                      .map(location => location.title)
                      .join(", ");
                  }}
                >
                  {formLocationDoorsList &&
                    formLocationDoorsList.map((it, i) => (
                      <MenuItem key={i} value={it.uuid}>
                        <div>{it.title}</div>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formDoorsValid}
          >
            Enregister
          </Button>
        </form>
      </div>
    </Container>
  );
}

FormA1001Doors.propTypes = {
  formA1001List: PropTypes.array,
  formDoorsValid: PropTypes.bool,
  formLocationDoorsList: PropTypes.array,
  modalDoorsType: PropTypes.bool,
  newA1001DoorsInfo: PropTypes.object,
  handleDoorsFormChange: PropTypes.func,
  submitNewDoors: PropTypes.func,
  toggleModalA1001Doors: PropTypes.func,
};
