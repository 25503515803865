/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  InputLabel,
  makeStyles,
  Typography,
  Select,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function FormLinks(props) {
  const classes = useStyles();

  const {
    formGroupsList,
    formModulesList,
    formValid,
    modalType,
    newLinksInfo,
    refreshImage,
    deleteIcon,
    handleFormChange,
    submitNewLink,
    toggleModal,
  } = props;
  return (
    <Container component="main" maxWidth="md" style={{
      overflowY: "auto",
    }}>
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {modalType ? (
            <Typography variant="h5">Modifier le lien</Typography>
          ) : (
            <Typography variant="h5">Ajouter un lien</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewLink} noValidate>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Titre *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newLinksInfo.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleFormChange("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Lien *</InputLabel>
              <TextField
                defaultValue={newLinksInfo.link}
                name="link"
                required
                fullWidth
                id="link"
                onChange={event =>
                  handleFormChange("link", event.target.value)
                }
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Adresse</InputLabel>
              <TextField
                defaultValue={newLinksInfo.address}
                name="adress"
                required
                fullWidth
                id="adress"
                onChange={event =>
                  handleFormChange("adress", event.target.value)
                }
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Latitude</InputLabel>
              <TextField
                defaultValue={newLinksInfo.latitude}
                name="latitude"
                required
                fullWidth
                id="latitude"
                onChange={event =>
                  handleFormChange("latitude", event.target.value)
                }
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Longitude</InputLabel>
              <TextField
                defaultValue={newLinksInfo.longitude}
                name="longitude"
                required
                fullWidth
                id="longitude"
                onChange={event =>
                  handleFormChange("longitude", event.target.value)
                }
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Image - Icone</InputLabel>
              <Grid>
                {newLinksInfo.has_image && refreshImage && newLinksInfo.uuid &&
                  (refreshImage[newLinksInfo.uuid] ? (
                    <CircularProgress />
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <img
                        style={{ marginTop: "1%", width: '25%' }}
                        src={`${process.env.REACT_APP_CDN_URL
                          }/app/module/links/points/${newLinksInfo?.uuid}/image?refresh=${Date.now()}`}
                        // src={`data:image/png;base64,${newLinksInfo?.icon}`}
                        alt=""
                        onError={i => {
                          i.target.style.display = "none";
                          i.target.alt = "Aucune image disponible";
                        }}
                      />
                      <Button
                        style={{ marginTop: "1%", marginLeft: '2%', display: 'column', alignSelf: 'center' }}
                        variant="contained"
                        size='sm'
                        color="danger"
                        className={classes.submit}
                        onClick={deleteIcon}
                      >
                        Supprimer
                      </Button>
                    </div>))
                }
              </Grid>
              <input
                style={{ marginTop: "1%" }}
                type="file"
                name="icon"
                accept="image/*"
                onChange={e => handleFormChange("img", e.target.files[0])}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Groupe</InputLabel>
              {formGroupsList ? (
                <Select
                  value={newLinksInfo.group}
                  style={{ top: "16px" }}
                  id="select-type"
                  fullWidth
                  displayEmpty
                  onChange={event =>
                    handleFormChange("group", event.target.value)
                  }
                >
                  <MenuItem value={""}>
                    <em>Aucune séléction</em>
                  </MenuItem>
                  {formGroupsList &&
                    formGroupsList.map((it, i) => (
                      <MenuItem key={i} value={it.uuid}>
                        <div>{it.title}</div>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Module(s)</InputLabel>
              {formModulesList ? (
                <Select
                  value={newLinksInfo.features}
                  style={{ top: "16px" }}
                  id="select-type"
                  fullWidth
                  multiple
                  displayEmpty
                  onChange={event =>
                    handleFormChange("features", event.target.value)
                  }
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return <em>Aucune séléction</em>;
                    }
                    return formModulesList
                      .filter(feature => selected.find(it => it === feature.uuid))
                      .map(feature => feature.custom_title)
                      .join(", ");
                  }}
                >
                  {formModulesList &&
                    formModulesList.map((it, i) => (
                      <MenuItem key={i} value={it.uuid}>
                        <div>{it.custom_title}</div>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formValid}
          >
            Enregister
          </Button>
        </form >
      </div >
    </Container >
  );
}

FormLinks.propTypes = {
  formGroupsList: PropTypes.array,
  formModulesList: PropTypes.array,
  formValid: PropTypes.bool,
  modalType: PropTypes.bool,
  newLinksInfo: PropTypes.object,
  refreshImage: PropTypes.object,
  deleteIcon: PropTypes.func,
  handleFormChange: PropTypes.func,
  submitNewLink: PropTypes.func,
  toggleModal: PropTypes.func,
};
