import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";

import {
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import ReorderIcon from "@material-ui/icons/Reorder";

const useStyles = makeStyles(styles);

export default function CustomTableDrag({ tableHead, tableData, tableHeaderColor, tableOrderFunc }) {
  const classes = useStyles();

  const [localItems, setLocalItems] = useState(tableData);

  useEffect(() => {
    setLocalItems(tableData)
  }, [tableData]);

  // normally one would commit/save any order changes via an api call here...
  const handleDragEnd = async (result, provided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    if (tableOrderFunc)
      tableOrderFunc(result.draggableId, result.destination.index - result.source.index)
    setLocalItems((prev) => {
      const tmp = Array.from(prev);
      const [removed] = tmp.splice(result.source.index, 1);
      tmp.splice(result.destination.index, 0, removed);

      return tmp;
    });
  };

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead?.map((prop, key) => (
                <TableCell
                  className={`${classes.tableCell} ${classes.tableHeadCell}`}
                  key={key}
                >
                  {prop}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided) => (
              <TableBody
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {localItems?.map((prop, key) => (
                  <Draggable
                    key={prop[0]}
                    draggableId={prop[0]}
                    index={key}
                  >
                    {(
                      draggableProvided,
                      snapshot
                    ) => {
                      return (
                        <TableRow
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            background: snapshot.isDragging
                              ? "rgba(245,245,245, 0.75)"
                              : "none"
                          }}
                          className={classes.tableBodyRow}
                        >
                          <TableCell align="left">
                            <div {...draggableProvided.dragHandleProps}>
                              <ReorderIcon />
                            </div>
                          </TableCell>
                          {prop.filter((it, i) => i !== 0).map((prop, key) => (
                            <TableCell className={classes.tableCell} key={key} align={tableHead[key + 1].length === 0 ? "right" : 'left'}>
                              {prop}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    }}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </div>
  );
}

CustomTableDrag.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTableDrag.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableType: PropTypes.oneOf(["user", "booking", "news", "categories", "event", "links", "files", "alerts"]),
  tableHead: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  tableData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.any])),
  tableOrderFunc: PropTypes.func,
};
