import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  alertServices
} from "../../services";

const AlertsLogic = () => {

  const { myself } = useSelector(userState => userState.myself);

  const [refresh, setRefresh] = useState(false);

  const limitAlerts = 20

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [page, setPage] = useState(1)
  const [alertsList, setAlertsList] = useState(undefined)
  const [alertsCount, setAlertsCount] = useState(undefined)

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newAlertsInfo, setNewAlertsInfo] = useState(undefined);
  const [modalType, setModalType] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const [alertDestroy, setAlertDestroy] = useState(undefined);

  const toggleConfirmAlertDestroy = alert => setAlertDestroy(alert);

  useEffect(() => {
    (async () => {
      const alert = await alertServices.getAll()
      if (alert) {
        setAlertsCount(alert.length)
        setAlertsList(alert.slice(limitAlerts * (page - 1), limitAlerts * (page - 1) + limitAlerts))
      }
    })();
  }, [refresh, page]);

  const refreshData = () => setRefresh(!refresh);

  const handlePagination = page => {
    setPage(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const handleChangeEnabled = async (enabled, alert) => {
    if (enabled) {
      await alertServices.enable(alert.uuid)
    } else {
      await alertServices.disable(alert.uuid)
    }
    openNotification(`L'alerte est maintenant ${enabled ? 'activée' : 'désactivée'}.`, "success")
    refreshData()
  }

  const deleteAlert = async uuid => {
    await alertServices.destroy(uuid)
    openNotification(`L'alerte ${alertsList.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
    toggleConfirmAlertDestroy(null)
    refreshData()
  };

  const toggleModal = async (alert = null) => {
    setFormValid(false);
    setModalType(alert ? true : false);
    const alertInfo = {
      uuid: alert?.uuid,
      title: alert?.title ? alert?.title : '',
      content: alert?.content ? alert?.content : '',
      type: alert?.type ? alert?.type : 'INFO',
      enabled: alert?.enabled ? alert?.enabled : false,
    };
    setNewAlertsInfo(alertInfo);
    setEditModalOpened(!editModalOpened);
  };

  const isFormValid = alertInfo => {
    if (!alertInfo?.title?.length || !alertInfo?.content?.length) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  };

  const handleFormChange = (key, value) => {
    let alertInfo = { ...newAlertsInfo };
    alertInfo[key] = value;
    setNewAlertsInfo(alertInfo);
    isFormValid(alertInfo);
  };

  const submitNewAlert = async event => {
    event.preventDefault();
    setFormValid(false);
    const alertInfo = {
      type: newAlertsInfo.type,
      title: newAlertsInfo.title,
      content: newAlertsInfo.content,
      enabled: newAlertsInfo.enabled,
    };
    if (newAlertsInfo.uuid) {
      if (await alertServices.update(newAlertsInfo.uuid, alertInfo)) {
        openNotification(`L'alerte ${newAlertsInfo?.title} a été modifié`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de la modification de l'alerte ${newAlertsInfo?.title}`, 'danger')
      }
    } else {
      if (await alertServices.add(alertInfo)) {
        openNotification(`L'alerte ${newAlertsInfo?.title} a été ajouté`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de l'ajout de l'alerte ${newAlertsInfo?.title}`, 'danger')
      }
    }
    setEditModalOpened(!editModalOpened);
    setFormValid(true);
    refreshData();
  };

  return {
    alertDestroy,
    alertsCount,
    alertsList,
    editModalOpened,
    formValid,
    limitAlerts,
    myself,
    newAlertsInfo,
    notifColor,
    notifMessage,
    modalType,
    openNotif,
    page,
    deleteAlert,
    handleChangeEnabled,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewAlert,
    toggleConfirmAlertDestroy,
    toggleModal,
  }
}

export default AlertsLogic