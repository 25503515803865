import React from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  Grid,
  FormControlLabel,
  Switch,
  InputLabel,
  makeStyles,
  Checkbox,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function FormRoles(props) {
  const classes = useStyles();

  const {
    collectionsList,
    editRoleInfo,
    myself,
    getTitle,
    handleFormChange,
    submitRoleEdit,
    toggleModal,
  } = props;
  return (
    <Container component="main"
      style={{
        overflowY: "auto",
      }}>
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          <Typography variant="h5">
            Modifier les rôles de {editRoleInfo.name}
          </Typography>
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitRoleEdit} noValidate>
          <Grid container spacing={3}>
            {editRoleInfo.roles &&
              editRoleInfo.roles.map((role, i) => (
                ((myself?.rights?.role_superadmin || role.key !== "edit_privilege" ||
                  (role.key === "edit_privilege" && myself?.rights?.role_edit_privilege && !editRoleInfo.hasPrivilege))
                  ? <Grid container key={i}>
                    <Grid item md={12} style={{ display: "flex" }}>
                      <InputLabel style={{ marginRight: "2%", marginTop: "1%" }}>
                        {role.title}
                      </InputLabel>
                      <Switch
                        checked={role.global}
                        onChange={e =>
                          handleFormChange(role.key, e.target.checked)
                        }
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      style={{ display: "flex", marginBottom: "1%" }}
                    >
                      {role.formattedRole &&
                        role.formattedRole.map((it, j) => {
                          if (typeof it.role === 'boolean')
                            return <FormControlLabel
                              key={j}
                              control={
                                <Checkbox
                                  disabled={!role.global}
                                  checked={it.role}
                                  onChange={e =>
                                    handleFormChange(
                                      role.key,
                                      e.target.checked,
                                      it.title
                                    )
                                  }
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "secondary checkbox",
                                  }}
                                />
                              }
                              label={getTitle(it.title) + ":"}
                              labelPlacement="start"
                            />
                          if (it.title === 'collection' && collectionsList?.length)
                            return (
                              <Select
                                key={j}
                                value={it.role}
                                style={{ width: "200px" }}
                                id="select-type"
                                displayEmpty
                                onChange={event =>
                                  handleFormChange(
                                    role.key,
                                    event.target.value,
                                    it.title
                                  )
                                }
                              >
                                <MenuItem value={-1}>
                                  <em>Tous</em>
                                </MenuItem>
                                {collectionsList &&
                                  collectionsList.map((collection, k) => (
                                    <MenuItem key={k} value={collection.id}>
                                      <div>{collection.title}</div>
                                    </MenuItem>
                                  ))}
                              </Select>
                            )
                          return null
                        })}
                    </Grid>
                  </Grid> : null)
              ))}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Enregister
          </Button>
        </form>
      </div>
    </Container>
  );
}

FormRoles.propTypes = {
  collectionsList: PropTypes.array,
  editRoleInfo: PropTypes.object,
  myself: PropTypes.object,
  getTitle: PropTypes.func,
  handleFormChange: PropTypes.func,
  submitRoleEdit: PropTypes.func,
  toggleModal: PropTypes.func,
};
