import React from "react";

import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

export default function DialogNewsLink(props) {
  const {
    linkDialog,
    linkInfo,
    handleLinkChange,
    submitLink,
    toggleLinkDialog,
  } = props;
  return (
    <Dialog open={linkDialog} onClose={toggleLinkDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <div style={{ fontSize: 22 }}>
          Insérer un lien
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={12}>
            <TextField
              autoFocus
              margin="dense"
              id="link"
              label="URL du lien"
              inputProps={{ style: { fontSize: 16 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              required
              onChange={event =>
                handleLinkChange("link", event.target.value)
              }
              fullWidth
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              margin="dense"
              id="name"
              label="Text à afficher"
              inputProps={{ style: { fontSize: 16 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              onChange={event =>
                handleLinkChange("title", event.target.value)
              }
              fullWidth
            />
          </Grid>
          <Grid item md={12}>
            <FormControlLabel
              control={<Checkbox
                checked={linkInfo ? linkInfo.window : true}
                onChange={e =>
                  handleLinkChange("window", e.target.checked)
                }
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />}
              label={<div style={{ fontSize: 16 }}>
                Ouvrir dans un nouvel onglet
              </div>}
            />
          </Grid>
          <Grid item md={12}>
            <FormControlLabel
              control={<Checkbox
                checked={linkInfo ? linkInfo.tcp : true}
                onChange={e =>
                  handleLinkChange("tcp", e.target.checked)
                }
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />}
              label={<div style={{ fontSize: 16 }}>
                Utiliser le protocole par default
              </div>}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleLinkDialog} color="primary">
          Annuler
        </Button>
        <Button onClick={submitLink} color="primary" disabled={linkInfo?.link.length === 0}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogNewsLink.propTypes = {
  linkDialog: PropTypes.bool,
  linkInfo: PropTypes.object,
  handleLinkChange: PropTypes.func,
  submitLink: PropTypes.func,
  toggleLinkDialog: PropTypes.func,
};