/* eslint-disable react/no-children-prop */
import React from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  Divider,
  InputLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

const useStyles = makeStyles(styles);

export default function FormRules(props) {
  const classes = useStyles();

  const {
    formRuleValid,
    newRuleInfo,
    modalRuleType,
    pageModalRule,
    handleRuleFormChange,
    handlePaginationModalRule,
    submitNewRule,
    toggleModalRule,
  } = props;
  return (
    <Container
      component="main"
      style={{
        width: "100%",
        overflowY: "auto",
      }}
    >
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {pageModalRule === 1 ? (
            modalRuleType ? (
              <Typography variant="h5"> Modifier la règle</Typography>
            ) : (
              <Typography variant="h5">Ajouter une règle</Typography>
            )
          ) : (
            <Typography variant="h5">Prévisualisation</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModalRule()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {pageModalRule === 1 && (
          <form
            className={classes.form}
            onSubmit={() => handlePaginationModalRule(2)}
            noValidate
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <InputLabel>Titre de la règle *</InputLabel>
                <TextField
                  defaultValue={newRuleInfo.title}
                  name="title"
                  required
                  fullWidth
                  id="title"
                  onChange={event =>
                    handleRuleFormChange("title", event.target.value)
                  }
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputLabel>Contenu *</InputLabel>
                <TextField
                  id="content"
                  value={newRuleInfo.content}
                  onChange={e =>
                    handleRuleFormChange("content", e.target.value)
                  }
                  multiline
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!formRuleValid}
            >
              Suivant
            </Button>
          </form>
        )}
        {pageModalRule === 2 && (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <h1 style={{ fontWeight: "bold" }}>{newRuleInfo.title}</h1>
                <Divider />
                {newRuleInfo.content.split("\r\n").map((it, i) =>
                  it.length ? (
                    <ReactMarkdown
                      key={i}
                      remarkPlugins={[gfm]}
                      children={it.replaceAll(
                        "\t",
                        "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                      )}
                    />
                  ) : (
                    <div key={i} style={{ whiteSpace: "pre-wrap" }}>
                      {"\r\n"}
                    </div>
                  )
                )}
              </Grid>
            </Grid>
            <div style={{ display: "flex" }}>
              <Button
                style={{ margin: "1%" }}
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => handlePaginationModalRule(1)}
              >
                Précédent
              </Button>
              <Button
                style={{ margin: "1%" }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitNewRule}
              >
                Valider
              </Button>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}

FormRules.propTypes = {
  formRuleValid: PropTypes.bool,
  newRuleInfo: PropTypes.object,
  modalRuleType: PropTypes.bool,
  pageModalRule: PropTypes.number,
  handleRuleFormChange: PropTypes.func,
  handlePaginationModalRule: PropTypes.func,
  submitNewRule: PropTypes.func,
  toggleModalRule: PropTypes.func,
};
