import { get_axs, post_axs, remove_axs, put_axs } from "../helpers/fetcher";

export const partnerServices = {
  add,
  setImage,
  getAll,
  getLogo,
  destroy,
  update,
};

async function add(link) {
  return post_axs(
    "/partners",
    new URLSearchParams({
      link,
    }),
    // "application/json"
  );
}

async function setImage(uuid, file) {
  return post_axs(
    `/partners/${uuid}`,
    file,
    'multipart/form-data'
  );
}

async function getAll() {
  return get_axs("/partners");
}

async function update(uuid, link) {
  return put_axs(
    `/partners/${uuid}`,
    new URLSearchParams({
      link,
    })
  );
}

async function getLogo(uuid) {
  return get_axs(`/partners/${uuid}/logo`);
}

async function destroy(uuid) {
  return remove_axs(`/partners/${uuid}`);
}
