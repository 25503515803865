import { useEffect, useState } from "react";
import { useSelector } from "react-redux"; // @material-ui/core components
import moment from "moment";
import localization from "moment/locale/fr";
import {
	reservationRuleServices,
} from "services";

moment.updateLocale("fr", localization);

const BookingRulesLogic = () => {

	const [refresh, setRefresh] = useState(false);

	const { myself } = useSelector(state => state.myself);
	const limits = {
		subscription: 15,
		rule: 4,
	}
	const [rules, setRules] = useState(undefined)
	const [rulesCount, setRulesCount] = useState(0)
	const [pageRule, setPageRule] = useState(1)
	const [ruleModalOpened, setRuleModalOpened] = useState(false);
	const [newRuleInfo, setNewRuleInfo] = useState(undefined)
	const [modalRuleType, setModalRuleType] = useState(false)
	const [formRuleValid, setFormRuleValid] = useState(false)
	const [pageModalRule, setPageModalRule] = useState(1)
	const [openNotif, setOpenNotif] = useState(false);
	const [notifMessage, setNotifMessage] = useState("");
	const [notifColor, setNotifColor] = useState("info");

	const [itemDestroy, setItemDestroy] = useState(undefined);

	const toggleConfirmItemDestroy = item => setItemDestroy(item);

	const refreshData = () => setRefresh(!refresh);

	useEffect(() => {
		(async () => {
			const rule = await reservationRuleServices.getAll()
			if (rule) {
				rule.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
				setRulesCount(rule.length)
				setRules(rule.slice(limits.rule * (pageRule - 1), limits.rule * (pageRule - 1) + limits.rule))
			}
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageRule, refresh])

	const handlePaginationRule = page => {
		setPageRule(page);
	};

	useEffect(() => {
		window.scrollTo(0, 0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageModalRule])

	const handlePaginationModalRule = page => {
		setPageModalRule(page);
	};

	const toggleModalRule = (rule = null) => {
		setPageModalRule(1)
		setFormRuleValid(false)
		setModalRuleType(rule ? true : false)
		const ruleInfo = {
			uuid: rule?.uuid,
			title: rule?.title,
			content: rule?.content
		}
		setNewRuleInfo(ruleInfo)
		setRuleModalOpened(!ruleModalOpened)
	}
	
	const openNotification = (msg, color = "info", time = 6) => {
		setOpenNotif(false);
		setNotifMessage(msg);
		setNotifColor(color);
		setTimeout(() => setOpenNotif(false), time * 1000);
		setTimeout(() => setOpenNotif(true), 100);
	};

	const submitNewRule = async (event) => {
		event.preventDefault()
		const ruleInfo = {
			title: newRuleInfo.title,
			content: newRuleInfo.content,
		}
		if (newRuleInfo.uuid) {
			if (await reservationRuleServices.update(newRuleInfo.uuid, ruleInfo)) {
				openNotification(`La régle ${ruleInfo?.title} a été modifié`, 'success')
			} else {
				openNotification(`Erreur, une erreur est survenu lors de la modification de la règle ${ruleInfo?.title}`, 'danger')
			}
		} else {
			if (await reservationRuleServices.add(ruleInfo)) {
				openNotification(`La régle ${ruleInfo?.title} a été ajouté`, 'success')
			} else {
				openNotification(`Erreur, une erreur est survenu lors de l'ajout de la règle ${ruleInfo?.title}`, 'danger')
			}
		}
		refreshData()
		setRuleModalOpened(!ruleModalOpened)
		setFormRuleValid(true)
	}

	const handleRuleFormChange = (key, value) => {
		const ruleInfo = { ...newRuleInfo }
		ruleInfo[key] = value
		setNewRuleInfo(ruleInfo)
		isFormRuleValid(ruleInfo)
	}

	const isFormRuleValid = (rule) => {
		if (!rule?.title?.length || !rule?.content?.length)
			setFormRuleValid(false)
		else
			setFormRuleValid(true)
	}

	const deleteRule = async (uuid) => {
		await reservationRuleServices.destroy(uuid)
		openNotification(`La régle ${rules.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
		toggleConfirmItemDestroy(null)
		refreshData()
	}

	return {
		itemDestroy,
		formRuleValid,
		limits,
		modalRuleType,
		myself,
		newRuleInfo,
		pageRule,
		pageModalRule,
		ruleModalOpened,
		rules,
		rulesCount,
		deleteRule,
		handlePaginationRule,
		handleRuleFormChange,
		refreshData,
		handlePaginationModalRule,
		submitNewRule,
		toggleModalRule,
		toggleConfirmItemDestroy,
		notifMessage,
		openNotif,
		setOpenNotif,
		notifColor
	};
};

export default BookingRulesLogic;
