import { loadState } from "../helpers/localStorage";

export const authHeader = () => {
  // return authorization header with jwt token
  try {
    const jwtToken = loadState("user");
    if (jwtToken) {
      const currentSite = loadState("site");
      if (currentSite && currentSite.uuid) {
        return {
          Authorization: `Bearer ${jwtToken}`,
          siteUUID: currentSite.uuid,
        };
      }
      return { Authorization: `Bearer ${jwtToken}` };
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const logout = () => localStorage.clear();

export const isLogin = () => loadState("user") ? true : false;

export const getOubaboxToken = () => ({
  Authorization: `Bearer ${loadState("user")}`
})