/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import {
  TextField,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Backdrop,
  Chip,
  IconButton,
  Switch,
  Modal,
} from "@material-ui/core";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import CustomPost from "components/CustomPost/CustomPost"

const useStyles = makeStyles(styles);

export default function FormPosts(props) {
  const classes = useStyles();
  // const textClasses = textStyles();

  const {
    autoCompleteKey,
    editModalOpened,
    featuresList,
    file,
    filesAssociated,
    formFileList,
    formModulesList,
    isDragActive,
    loading,
    newsCategory,
    newsCategoryList,
    newsPublished,
    newsSelected,
    newsTitle,
    style,
    textClasses,
    downloadFile,
    getRootProps,
    getInputProps,
    handleFormFileChange,
    handleNewsCategory,
    handleNewsFeatures,
    submitUpdateHandler,
    toggleLinkDialog,
    toggleModal,
    toggleNewsPublished,
    toggleNewsTitle,
    togglePictureDialog,
    uploadfile,
  } = props;
  return (
    <Modal
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}
      onClose={() => toggleModal(false)}
      open={editModalOpened}
    >
      <div
        className={classes.paper}
        style={{
          height: '92%',
          width: '95%',
          maxHeight: "92%",
          maxWidth: "95%",
          overflowY: "auto",
        }}
      >
        <IconButton
          size="medium"
          aria-label="delete"
          onClick={() => toggleModal(false)}
          style={{
            alignSelf: "flex-end",
          }}
        >
          <CloseIcon style={{ fontSize: 25 }} />
        </IconButton>
        <GridContainer style={{ width: '100%' }} spacing={3}>
          <GridItem xs={12} sm={12} md={6}>
            <InputLabel style={{ fontSize: 16 }}>Titre de l'actualité *</InputLabel>
            <TextField
              variant="standard"
              fullWidth
              classes={{
                root: textClasses.formControlRoot,
              }}
              inputProps={{
                className: classes.inputPost,
                onChange: event => {
                  toggleNewsTitle(event.target.value);
                },
              }}
              InputLabelProps={{
                classes: {
                  root: textClasses.inputLabelRootPost,
                },
              }}
              value={newsTitle}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <InputLabel style={{ fontSize: 16 }}>Publié</InputLabel>
            <Switch
              checked={newsPublished}
              onChange={e =>
                toggleNewsPublished(e.target.checked)
              }
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2} style={{ marginTop: '20px' }}>
            <InputLabel style={{ fontSize: 16 }}>Module(s)</InputLabel>
            {formModulesList ? (
              <Select
                value={featuresList}
                id="select-type"
                fullWidth
                multiple
                displayEmpty
                inputProps={{
                  className: classes.inputPost,
                }}
                onChange={event =>
                  handleNewsFeatures(event.target.value)
                }
                renderValue={selected => {
                  if (selected.length === 0) {
                    return <em>Aucun module séléctionnée</em>;
                  }
                  return formModulesList
                    .filter(feature => selected.find(it => it === feature.uuid))
                    .map(feature => feature.custom_title)
                    .join(", ");
                }}
              >
                {formModulesList &&
                  formModulesList.map((it, i) => (
                    <MenuItem key={i} value={it.uuid}>
                      <div style={{ fontSize: 16 }}>{it.custom_title}</div>
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <Typography variant="h3">
                <Skeleton width="auto" />
              </Typography>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
          </GridItem>
          <GridItem xs={12} sm={12} md={2} style={{ marginTop: '20px' }}>
            <InputLabel style={{ fontSize: 16 }}>Catégorie</InputLabel>
            <Select
              value={newsCategory?.id || ""}
              id="select-type"
              fullWidth
              displayEmpty
              inputProps={{
                className: classes.inputPost,
              }}
              onChange={event =>
                handleNewsCategory(newsCategoryList.find(it => it.id === event.target.value))
              }
            >
              <MenuItem value={""}>
                <em style={{ fontSize: 16 }}>Aucune catégorie sélectionnée</em>
              </MenuItem>
              {newsCategoryList &&
                newsCategoryList.map((it, i) => (
                  <MenuItem key={i} value={it.id}>
                    <div style={{ fontSize: 16 }}>{it.title}</div>
                  </MenuItem>
                ))}
            </Select>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
            <CustomPost
              newsSelected={newsSelected}
              toggleLinkDialog={toggleLinkDialog}
              togglePictureDialog={togglePictureDialog}
            />
            {/* <MUIRichTextEditor
                  label="Rédigez votre actualité ici"
                  defaultValue={newsSelected}
                  onSave={submitUpdateHandler}
                  inlineToolbar={true}
                /> */}
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
            <InputLabel style={{ fontSize: 16 }}>Fichiers associés</InputLabel>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p style={{ fontSize: 16 }}>
                  Déposer le fichier ici ...
                </p>
              ) : (
                <p style={{ fontSize: 16 }}>
                  Déposer le fichier ici ou selectionner le en cliquant ici
                </p>
              )}
            </div>
          </GridItem>
          {file?.name && (<GridItem xs={12} sm={12} md={12} style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: '1.5%',
            fontSize: 18,
            marginBottom: '15px',
          }}>
            {file?.name}
          </GridItem>)
          }
          {file?.name && (<GridItem xs={12} sm={12} md={12} style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <Button
              onClick={uploadfile}
              disabled={!file}
              color="primary"
            >
              Ajouter le fichier
            </Button>
          </GridItem>)
          }
          <GridItem xs={12} sm={12} md={4}>
            {formFileList?.length > 0 && (
              <Autocomplete
                key={autoCompleteKey}
                id="tags-groups"
                getOptionSelected={(option, value) => option.uuid === value.uuid}
                options={formFileList}
                getOptionLabel={option => option.name}
                onChange={(_, value) => {
                  handleFormFileChange('add', value);
                }}
                renderOption={(option) => (
                  <div style={{ fontSize: 16 }}>
                    {option.name}
                  </div>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    classes={{
                      root: textClasses.formControlRoot,
                    }}
                    label="Associer un fichier"
                    InputProps={{
                      ...params.InputProps,
                      className: classes.inputPost,
                    }}
                    InputLabelProps={{
                      classes: {
                        root: textClasses.inputLabelRootPost,
                      },
                    }}
                  />
                )}
              />)
            }
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{
            overflowY: "auto",
            maxHeight: '120px'
          }}>
            {filesAssociated && filesAssociated.length > 0 && filesAssociated.map((file, i) => (
              <Chip key={i}
                label={file.name}
                color='primary'
                onDelete={() => handleFormFileChange('delete', file)}
                onClick={() => downloadFile(file)}
                style={{ marginRight: '5px', marginTop: '1%', fontSize: 16 }}
              />
            ))}
            {filesAssociated?.length === 0 && (
              < div style={{ marginTop: '1%', fontSize: 16 }}>
                Aucun fichier associé
              </div>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex' }}>
              <Button
                onClick={submitUpdateHandler}
                color="primary"
                style={{ marginBottom: '10px' }}
                disabled={loading}
              >
                Enregister
              </Button>
              {loading && (
                <div className={classes.root} style={{ marginLeft: '2%' }}>
                  <CircularProgress />
                </div>
              )}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Modal >
  )
}

FormPosts.propTypes = {
  autoCompleteKey: PropTypes.number,
  editModalOpened: PropTypes.bool,
  featuresList: PropTypes.array,
  file: PropTypes.object,
  filesAssociated: PropTypes.array,
  formFileList: PropTypes.array,
  formModulesList: PropTypes.array,
  isDragActive: PropTypes.bool,
  loading: PropTypes.bool,
  newsCategory: PropTypes.object,
  newsCategoryList: PropTypes.array,
  newsPublished: PropTypes.bool,
  newsSelected: PropTypes.string,
  newsTitle: PropTypes.string,
  style: PropTypes.object,
  textClasses: PropTypes.object,
  downloadFile: PropTypes.func,
  getRootProps: PropTypes.func,
  getInputProps: PropTypes.func,
  handleFormFileChange: PropTypes.func,
  handleNewsCategory: PropTypes.func,
  handleNewsFeatures: PropTypes.func,
  submitUpdateHandler: PropTypes.func,
  toggleLinkDialog: PropTypes.func,
  toggleModal: PropTypes.func,
  toggleNewsPublished: PropTypes.func,
  toggleNewsTitle: PropTypes.func,
  togglePictureDialog: PropTypes.func,
  uploadfilem: PropTypes.func,
};