import React, { useEffect } from "react";
import ClassifiedLogic from "./ClassifiedLogic";

import moment from "moment";
import localization from "moment/locale/fr";

import {
  CircularProgress,
  Modal,
  makeStyles,
  Container,
  IconButton,
  InputLabel,
  CssBaseline,
  Divider,
  Typography,
  TextField,
  Grid,
  Backdrop,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/eventStyle";

import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";

// core components
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

moment.updateLocale("fr", localization);

const textStyles = makeStyles({
  formControlRoot: {
    fontFamily: "futura",
    color: "#6E6FA9",
    borderRadius: "7px",
    "& label.Mui-focused": {
      color: "#6E6FA9",
    },
  },
  inputLabelRoot: {
    color: "#6E6FA9",
    fontFamily: "futura",
    "&.focused": {
      color: "#6E6FA9",
    },
  },
});
const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const useStyles = makeStyles(styles);

export default function Classified(props) {
  const classes = useStyles();
  const textClasses = textStyles();
  const paginationClasses = paginationStyles();

  const {
    categories,
    categoriesFilter,
    classifiedCount,
    classifiedList,
    direction,
    directionFilter,
    editModalOpened,
    newClassifiedInfo,
    notifColor,
    notifMessage,
    myself,
    openNotif,
    limitClassified,
    page,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewClassified,
    toggleModal,
    updateCategoriesFilter,
    updateDirectionFilter,
  } = ClassifiedLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (classifiedList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {editModalOpened && newClassifiedInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <Container component="main" maxWidth="md" style={{
                overflowY: "auto",
              }}>
                <CssBaseline />
                <div>
                  <div className={classes.headerModal}>
                    <Typography variant="h5">Supprimer cette annonce</Typography>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => toggleModal()}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <form className={classes.form} onSubmit={submitNewClassified} noValidate>
                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <InputLabel>
                          Avant de supprimer cette annonce vous devez
                          renseigner la raison de la suppression. Elle sera ensuite
                          envoyée à l'auteur de l'annonce
                          {` (${newClassifiedInfo.publication?.user?.first_name} ${newClassifiedInfo.publication?.user?.last_name} - ${newClassifiedInfo.publication?.user?.email})`}
                        </InputLabel>
                      </Grid>
                      <Grid item md={12}>
                        <InputLabel>Raison *</InputLabel>
                        <TextField
                          defaultValue={newClassifiedInfo.reason}
                          name="reason"
                          required
                          fullWidth
                          multiline
                          id="reason"
                          onChange={event =>
                            handleFormChange("reason", event.target.value)
                          }
                          autoFocus
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={!newClassifiedInfo?.reason?.length}
                    >
                      Valider
                    </Button>
                  </form >
                </div >
              </Container >
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Petites annonces",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {!myself?.rights?.role_superadmin &&
                      !myself?.rights?.role_classified_read ? (
                      <GridItem xs={12} sm={12} md={6}>
                        <h4>
                          {
                            "Vous n'avez pas la permission de voir les petites annonces"
                          }
                        </h4>
                      </GridItem>
                    ) : (
                      <div>
                        <Card
                          style={{
                            boxShadow: "none",
                            marginTop: 0,
                            paddingTop: 0,
                          }}>
                          <CardHeader
                            style={{
                              borderRadius: 1,
                              paddingTop: 0,
                              border: "none",
                              boxShadow:
                                "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
                            }}
                          >
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={3}>
                                <Autocomplete
                                  multiple
                                  id="tags-state"
                                  options={direction}
                                  getOptionSelected={(option, value) => option.id === value.id}
                                  getOptionLabel={option => option.title}
                                  onChange={(_, value) => {
                                    updateDirectionFilter(value);
                                  }}
                                  value={directionFilter}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      classes={{
                                        root: textClasses.formControlRoot,
                                      }}
                                      label="Filtrer par état"
                                      placeholder="états sélectionnés"
                                      InputProps={{
                                        ...params.InputProps,
                                        className: classes.input,
                                      }}
                                      InputLabelProps={{
                                        classes: {
                                          root: textClasses.inputLabelRoot,
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={5}>
                                <Autocomplete
                                  multiple
                                  id="tags-category"
                                  options={categories.filter(it => it.activated)}
                                  getOptionSelected={(option, value) => option.id === value.id}
                                  getOptionLabel={option => option.title}
                                  onChange={(_, value) => {
                                    updateCategoriesFilter(value);
                                  }}
                                  value={categoriesFilter}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      classes={{
                                        root: textClasses.formControlRoot,
                                      }}
                                      label="Filtrer par Catégorie"
                                      placeholder="catégories sélectionnées"
                                      InputProps={{
                                        ...params.InputProps,
                                        className: classes.input,
                                      }}
                                      InputLabelProps={{
                                        classes: {
                                          root: textClasses.inputLabelRoot,
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </GridItem>
                            </GridContainer>
                          </CardHeader>
                        </Card>
                        {classifiedList && classifiedList.length === 0 && (
                          <h4>Aucune petite annonce à afficher</h4>
                        )}
                        {classifiedList &&
                          classifiedList?.map((publication, i) => (
                            <div key={i}>
                              <Card>
                                <CardHeader
                                  style={{
                                    borderRadius: 1,
                                    paddingTop: 0,
                                    border: "none",
                                    boxShadow:
                                      "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
                                  }}
                                >
                                  <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <h4 className={classes.cardTitle}>
                                      {publication?.title}
                                    </h4>
                                    {publication?.user &&
                                      (<h4 className={classes.cardTitle} style={{ marginLeft: '10px', fontStyle: 'italic', opacity: '0.7' }}>
                                        {`- ${publication?.user?.first_name} ${publication?.user?.last_name}`}
                                      </h4>)
                                    }
                                  </GridItem>
                                </CardHeader>
                                <CardBody>
                                  <GridContainer>
                                    <GridItem md={8} style={{ marginTop: '2%' }}>
                                      <p className={classes.cardDescription} style={{ fontSize: 16 }}>
                                        {publication?.description}
                                      </p>
                                    </GridItem>
                                    <GridItem md={4} style={{ marginTop: '1%' }}>
                                      {publication?.has_picture &&
                                        (<img
                                          style={{ marginTop: "1%", width: '100%' }}
                                          src={`${process.env.REACT_APP_CDN_URL
                                            }/app/module/classified/publications/${publication?.uuid}/image?refresh=${Date.now()}`}
                                          alt=""
                                          onError={i => {
                                            i.target.style.display = "none";
                                            i.target.alt = "Aucune image disponible";
                                          }}
                                        />)
                                      }
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: '1%' }}>
                                      <Table
                                        tableHeaderColor="primary"
                                        tableType="event"
                                        tableHead={[
                                          "Module",
                                          "Type",
                                          "Catégorie",
                                          "Type de catégorie",
                                          "Prix",
                                        ]}
                                        tableData={[
                                          Object.values({
                                            module: publication?.feature?.custom_title,
                                            type: (publication?.direction === 'request') ? 'Requête' : 'Offre',
                                            category: publication?.category?.title,
                                            category_type: (publication?.category?.type === 'services') ? 'Services' : 'Biens',
                                            price: publication?.price ? publication?.price + " €" : ""
                                          }),
                                        ]}
                                      />
                                    </GridItem>
                                    <GridItem
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      style={{ marginTop: "1%" }}
                                    >
                                      {(myself?.rights?.role_superadmin ||
                                        myself?.rights?.role_classified_delete) && (
                                          <Button
                                            onClick={() => toggleModal(publication)}
                                            color="danger"
                                          >
                                            Supprimer
                                          </Button>
                                        )}
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                                <CardFooter>
                                  Crée le{" "}
                                  {moment(publication.created_at).format(
                                    "DD/MM/YYYY à HH:mm"
                                  )}
                                </CardFooter>
                              </Card>
                              {classifiedList.length - 1 !== i && <Divider />}
                            </div>
                          ))}
                        {classifiedCount > 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: 100,
                            }}
                          >
                            <Pagination
                              count={Math.ceil(classifiedCount / limitClassified)}
                              page={page}
                              onChange={(event, pageNumber) => {
                                handlePagination(pageNumber);
                              }}
                              classes={{ ul: paginationClasses.ul }}
                              size="large"
                            />
                          </div>
                        )}
                      </div>
                    )
                    }
                  </GridItem>
                ),
              },
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}