import React, { useState, useEffect, memo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { getData } from "../../helpers/statistics";

export const Graph = ({
  bookings, users, pendingUsers, notActivatedUsers, start, end
}) => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    setData(getData({ bookings, users, pendingUsers, notActivatedUsers, start, end }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(getData({ bookings, users, pendingUsers, notActivatedUsers, start, end }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings, users]);

  return (data ? (
    <div
      className="chart-container"
      style={{ height: "200px", width: "90%", float: "left", fontWeight: "401" }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis label={{ value: "Quantité", angle: -90, position: "insideLeft" }} />
          <Tooltip contentStyle={{ backgroundColor: "rgba(255, 255, 255, 0.85)" }} cursor={{ stroke: 'red', strokeWidth: 1 }} />
          <Legend height="0px" />
          <Bar dataKey="Comptes Ouba créés parmi vos résidents" fill="#769dd8" />
          <Bar dataKey="Utilisateurs n'ayant pas activé leur compte" fill="#000000" />
          <Bar dataKey="Associations acceptées" stackId="total" fill="#82ca9d" />
          <Bar dataKey="Associations en attente de validation" stackId="total" fill="#d89d88" />
          <Bar dataKey="Associations refusées" stackId="total" fill="#e84d28" />
          <Bar dataKey="Associations désactivées" stackId="total" fill="#ff99ff" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : 
    <>
      Pas de données
    </>
  );
};

export default memo(Graph);
