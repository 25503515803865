/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  InputLabel,
  makeStyles,
  Chip,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

const textStyles = makeStyles({
  formControlRoot: {
    fontFamily: "futura",
    color: "#6E6FA9",
    borderRadius: "7px",
    "& label.Mui-focused": {
      color: "#6E6FA9",
    },
  },
  inputLabelRoot: {
    color: "#6E6FA9",
    fontFamily: "futura",
    "&.focused": {
      color: "#6E6FA9",
    },
  },
});

export default function FormMails(props) {
  const classes = useStyles();
  const textClasses = textStyles();

  const {
    formValid,
    groupFilter,
    groups,
    newMailInfo,
    handleFormChange,
    handleUserFiltering,
    rights,
    sectorFilter,
    sectors,
    statusFilter,
    submitNewMail,
    toggleModal,
  } = props;
  return (
    <Container component="main" maxWidth="md" style={{
      overflowY: "auto",
    }}>
      <CssBaseline />
      <Dialog
        open={newMailInfo.dialog}
        onClose={() => handleFormChange("dialog", null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Voulez-vous vraiment envoyer ce mail"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span role="img" aria-label="warning">
              ⚠️
            </span>
            {' '}Vous allez envoyer ce mail à {newMailInfo.users?.length} personne(s).
            Veuillez confirmer l'envoi du mail.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleFormChange("dialog", null)}
            color="info"
          >
            Annuler
          </Button>
          <Button
            onClick={submitNewMail}
            color="primary"
            autoFocus
            disabled={newMailInfo.loading}
          >
            {newMailInfo.loading ? "Patientez..." : "Envoyer"}
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <div className={classes.headerModal}>
          <Typography variant="h5">Envoyer un mail groupé</Typography>
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={(event) => {
          event.preventDefault()
          handleFormChange("dialog", null)
        }} noValidate>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Objet du mail *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newMailInfo.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleFormChange("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Contenu du mail *</InputLabel>
              <TextField
                id="content"
                value={newMailInfo.content}
                onChange={e => handleFormChange("content", e.target.value)}
                multiline
                required
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Filter les destinataire(s)</InputLabel>
              <div style={{ display: 'flex' }}>

                {groups ? (<Autocomplete
                  multiple
                  id="tags-groups"
                  options={groups}
                  style={{ width: '48%' }}
                  getOptionLabel={option => option.title}
                  onChange={(_, value) => {
                    handleUserFiltering("group", value);
                  }}
                  value={groupFilter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      classes={{
                        root: textClasses.formControlRoot,
                      }}
                      label="Filtrer par appartement"
                      placeholder="Appartements sélectionnés"
                      InputProps={{
                        ...params.InputProps,
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: textClasses.inputLabelRoot,
                        },
                      }}
                    />
                  )}
                />) :
                  (<Typography variant="h3">
                    <Skeleton width="auto" />
                  </Typography>)
                }
                {sectors ? (<Autocomplete
                  multiple
                  id="tags-sectors"
                  getOptionSelected={(option, value) => option.id === value.id}
                  options={sectors}
                  style={{ marginLeft: '2%', width: '50%' }}
                  getOptionLabel={option => option.name}
                  onChange={(_, value) => {
                    handleUserFiltering("sector", value);
                  }}
                  value={sectorFilter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      classes={{
                        root: textClasses.formControlRoot,
                      }}
                      label="Filtrer par bâtiments"
                      placeholder="Bâtiments sélectionnés"
                      InputProps={{
                        ...params.InputProps,
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: textClasses.inputLabelRoot,
                        },
                      }}
                    />
                  )}
                />) :
                  (<Typography variant="h3">
                    <Skeleton width="auto" />
                  </Typography>)
                }
                <Autocomplete
                  id="tags-state"
                  getOptionSelected={(option, value) => option.id === value.id}
                  options={[
                    {id: 1, name: 'Propriétaire', key:'OWNER'},
                    {id: 2, name: 'Locataire', key: 'TENANT'},
                  ]}
                  style={{ marginLeft: '2%', width: '50%' }}
                  getOptionLabel={option => option.name}
                  onChange={(_, value) => {
                    handleUserFiltering("status", value);
                  }}
                  value={statusFilter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      classes={{
                        root: textClasses.formControlRoot,
                      }}
                      label="Filtrer par statut"
                      InputProps={{
                        ...params.InputProps,
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: textClasses.inputLabelRoot,
                        },
                      }}
                    />
                  )}
                />
              </div>
            </Grid>
            {rights?.role_superadmin && (
              <Grid item md={12}>
                <InputLabel>
                  Expéditeur
                </InputLabel>
                <TextField
                  fullWidth
                  autoFocus
                  placeholder="Emérance Galliard <contact@ouba.eu>"
                  defaultValue={newMailInfo.contactEmail}
                  name="contactEmail"
                  id="contactEmail"
                  onChange={({ target }) =>
                    handleFormChange("contactEmail", target.value)
                  }
                />
              </Grid>
            )}
            <Grid item md={12}>
              <Grid container>
                <InputLabel>Destinataire(s)</InputLabel>
                <div><a onClick={() => {
                  let emails = [];
                  newMailInfo.users.forEach(user => {
                    
                  })
                }} href="#" style={{ marginLeft: '5px' }}>Copier les destinataires</a></div>
              </Grid>
              {newMailInfo.usersList.length > 0 && (
                <Autocomplete
                  key={newMailInfo.autoCompleteKey}
                  id="tags-groups"
                  getOptionSelected={(option, value) => option.id === value.id}
                  options={newMailInfo.usersList}
                  getOptionLabel={option => option.email}
                  onChange={(_, value) => {
                    handleFormChange('usersList', value);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      classes={{
                        root: textClasses.formControlRoot,
                      }}
                      label="Ajouter un destinataire"
                      InputProps={{
                        ...params.InputProps,
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: textClasses.inputLabelRoot,
                        },
                      }}
                    />
                  )}
                />)
              }
              <div style={{
                overflowY: "auto",
                maxHeight: '150px'
              }}>
                {newMailInfo.users && newMailInfo.users.map(user => (
                  <Chip key={user.key + 100}
                    label={user.email}
                    color='primary'
                    onDelete={() => handleFormChange('users', user)}
                    style={{ marginRight: '5px', marginTop: '1%' }}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formValid}
          >
            Envoyer
          </Button>
        </form>
      </div>
    </Container>
  );
}

FormMails.propTypes = {
  formValid: PropTypes.bool,
  groupFilter: PropTypes.array,
  groups: PropTypes.array,
  newMailInfo: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleUserFiltering: PropTypes.func,
  rights: PropTypes.object,
  sectorFilter: PropTypes.array,
  sectors: PropTypes.array,
  statusFilter: PropTypes.object,
  submitNewMail: PropTypes.func,
  toggleModal: PropTypes.func,
};
