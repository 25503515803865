import { useEffect, useState } from "react";

import {
  partnerServices
} from "../../services";

const PartnersLogic = () => {

  const [refresh, setRefresh] = useState(false);

  const limitPartners = 20

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [page, setPage] = useState(1)
  const [partnersList, setPartnersList] = useState(undefined)
  const [partnersCount, setPartnersCount] = useState(undefined)

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newPartnersInfo, setNewPartnersInfo] = useState(undefined);
  const [modalType, setModalType] = useState(false);

  const [partnerDestroy, setPartnerDestroy] = useState(undefined);
  const [refreshImage, setRefreshImage] = useState(undefined);

  const toggleConfirmPartnerDestroy = partner => setPartnerDestroy(partner);

  useEffect(() => {
    (async () => {
      const partners = await partnerServices.getAll()
      if (partners) {
        setPartnersCount(partners.length)
        const p = partners.slice(limitPartners * (page - 1), limitPartners * (page - 1) + limitPartners)
        setPartnersList(p)
        if (!refreshImage) {
          let r = {}
          p.forEach(it => r[it.uuid] = false)
          setRefreshImage(r)
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, page]);

  const refreshData = () => setRefresh(!refresh);

  const handlePagination = page => {
    setPage(page);
  };

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const deletePartner = async uuid => {
    const link = partnersList.find(it => it.uuid === uuid)?.link;
    setPartnersList(partnersList.filter(it => it.uuid !== uuid))
    await partnerServices.destroy(uuid);
    openNotification(`Le partenaire ${link} a été supprimé`, 'success');
    toggleConfirmPartnerDestroy(null)
    refreshData()
  };

  const toggleModal = async (partner = null) => {
    setModalType(partner ? true : false);
    const partnersInfo = {
      uuid: partner?.uuid,
      link: partner?.link ? partner?.link : '',
      img: null,
    };
    setNewPartnersInfo(partnersInfo);
    setEditModalOpened(!editModalOpened);
  };

  const handleFormChange = (key, value) => {
    let partnersInfo = { ...newPartnersInfo };
    partnersInfo[key] = value;
    setNewPartnersInfo(partnersInfo);
  };

  const submitNewPartner = async event => {
    event.preventDefault();
    if (newPartnersInfo.uuid) {
      if (newPartnersInfo.img) {
        let data = new FormData();
        data.append("file", newPartnersInfo.img);
        await partnerServices.setImage(newPartnersInfo.uuid, data)
        refreshImg(newPartnersInfo.uuid)
      }
      if (await partnerServices.update(newPartnersInfo.uuid, newPartnersInfo.link)) {
        openNotification(`Le partenaire ${newPartnersInfo?.link} a été modifié`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de la modification du partenaire ${newPartnersInfo?.link}`, 'danger')
      }
    } else {
      const partner = await partnerServices.add(newPartnersInfo.link);
      if (partner) {
        let data = new FormData();
        data.append("file", newPartnersInfo.img);
        await partnerServices.setImage(partner.uuid, data)
        refreshImg(partner.uuid)
        openNotification(`Le partenaire ${newPartnersInfo?.link} a été ajouté`, 'success')
      } else {
        openNotification(`Erreur, une erreur est survenu lors de l'ajout du partenaire ${newPartnersInfo?.link}`, 'danger')
      }
    }
    setEditModalOpened(!editModalOpened);
    refreshData();
  };

  const refreshImg = uuid => {
    const refreshOn = { ...refreshImage }
    refreshOn[uuid] = true
    setRefreshImage(refreshOn);
    setTimeout(() => {
      const refreshOff = { ...refreshImage }
      refreshOff[uuid] = false
      setRefreshImage(refreshOff)
    }, 700);
  };

  return {
    editModalOpened,
    limitPartners,
    modalType,
    newPartnersInfo,
    notifColor,
    notifMessage,
    openNotif,
    page,
    partnerDestroy,
    partnersCount,
    partnersList,
    refreshImage,
    deletePartner,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewPartner,
    toggleConfirmPartnerDestroy,
    toggleModal,
  }
}

export default PartnersLogic