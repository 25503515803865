import { useEffect, useState } from "react";

import {
  collectionServices,
  groupServices,
} from "../../services";

const CollectionsLogic = () => {
  const [refresh, setRefresh] = useState(false);

  const [openNotif, setOpenNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");
  const [notifColor, setNotifColor] = useState("info");

  const [groupsList, setGroupsList] = useState(undefined)
  const [collectionsList, setCollectionsList] = useState(undefined)
  const [columns, setColumns] = useState(undefined);

  const [editModalOpened, setEditModalOpened] = useState(false);
  const [newCollectionsInfo, setNewCollectionsInfo] = useState(undefined);
  const [modalType, setModalType] = useState(false);

  const [collectionDestroy, setCollectionDestroy] = useState(undefined);

  const toggleConfirmCollectionDestroy = collection => setCollectionDestroy(collection);

  useEffect(() => {
    (async () => {
      const group = await groupServices.getAllByEstate()
      if (group)
        setGroupsList(group)
      const collection = await collectionServices.getAll()
      if (collection) {
        setCollectionsList(collection)
        const columsList = {}
        collection.forEach(it => {
          const groups = it.groups.sort((a, b) => b.title.toLowerCase() > a.title.toLowerCase() ? -1 : 1)
          columsList[it.title] = {
            id: it.title,
            list: groups.map(group => ({
              id: group.id.toString(),
              aptName: group.title,
              buildName: group.sector.name
            }))
          }
        })
        setColumns(columsList)
      }
    })();
  }, [refresh]);

  const refreshData = () => setRefresh(!refresh);

  const openNotification = (msg, color = "info", time = 6) => {
    setOpenNotif(false);
    setNotifMessage(msg);
    setNotifColor(color);
    setTimeout(() => setOpenNotif(false), time * 1000);
    setTimeout(() => setOpenNotif(true), 100);
  };

  const deleteCollection = async uuid => {
    await collectionServices.destroy(uuid)
    openNotification(`La collection ${collectionsList.find(it => it.uuid === uuid)?.title} a été supprimé`, 'success')
    toggleConfirmCollectionDestroy(null)
    refreshData()
  };

  const toggleModal = async (collection = null) => {
    setModalType(collection ? true : false);
    const collectionInfo = {
      uuid: collection?.uuid,
      title: collection?.title ? collection?.title : '',
    };
    setNewCollectionsInfo(collectionInfo);
    setEditModalOpened(!editModalOpened);
  };


  const handleFormChange = (key, value) => {
    let collectionInfo = { ...newCollectionsInfo };
    collectionInfo[key] = value;
    setNewCollectionsInfo(collectionInfo);
  };

  const submitNewCollection = async event => {
    event.preventDefault();
    if (collectionsList.find(it => it.title === newCollectionsInfo.title && (!newCollectionsInfo?.uuid || newCollectionsInfo?.uuid !== it.uuid))) {
      openNotification("Erreur, ce titre est déjà utilisé", "danger")
    } else {
      if (newCollectionsInfo.uuid) {
        if (await collectionServices.update(newCollectionsInfo.uuid, newCollectionsInfo.title)) {
          openNotification(`La collection ${newCollectionsInfo?.title} a été modifié`, 'success')
        } else {
          openNotification(`Erreur, une erreur est survenu lors de la modification de la collection ${newCollectionsInfo?.title}`, 'danger')
        }
      } else {
        if (await collectionServices.add(newCollectionsInfo.title)) {
          openNotification(`La collection ${newCollectionsInfo?.title} a été ajouté`, 'success')
        } else {
          openNotification(`Erreur, une erreur est survenu lors de l'ajout de la collection ${newCollectionsInfo?.title}`, 'danger')
        }
      }
      setEditModalOpened(!editModalOpened);
      refreshData();
    }
  };

  const onDragEnd = async ({ source, destination }) => {
    if (destination === undefined || destination === null) return null;

    if (source.droppableId === destination.droppableId &&
      destination.index === source.index)
      return null;

    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];

    if (start === end) {
      const newList = start.list.filter((_, idx) => idx !== source.index);

      newList.splice(destination.index, 0, start.list[source.index]);
      const newCol = {
        id: start.id,
        list: newList
      };
      setColumns((state) => ({ ...state, [newCol.id]: newCol }));
      return null;
    } else {
      const newStartList = start.list.filter((_, idx) => idx !== source.index);
      const newStartCol = {
        id: start.id,
        list: newStartList
      };
      const newEndList = end.list;
      newEndList.splice(destination.index, 0, start.list[source.index]);
      const newEndCol = {
        id: end.id,
        list: newEndList
      };
      setColumns((state) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol
      }));
      openNotification(`L'appartement ${start.list[source.index]?.aptName} a été déplacé dans vers ${destination.droppableId}`, "success")
      await groupServices.updateCollection(
        groupsList.find(it => it.id.toString() === start.list[source.index].id).uuid,
        collectionsList.find(it => it.title === destination.droppableId).uuid)
      return null;
    }
  };

  return {
    collectionDestroy,
    collectionsList,
    columns,
    editModalOpened,
    modalType,
    newCollectionsInfo,
    notifColor,
    notifMessage,
    openNotif,
    deleteCollection,
    handleFormChange,
    onDragEnd,
    refreshData,
    setOpenNotif,
    submitNewCollection,
    toggleModal,
    toggleConfirmCollectionDestroy,
  }
}

export default CollectionsLogic