import React from "react";

import PropTypes from "prop-types";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

export default function DialogNewsPicture(props) {
  const {
    isDragActive,
    pictureDialog,
    pictureInfo,
    style,
    getRootProps,
    getInputProps,
    handlePictureChange,
    submitPicture,
    togglePictureDialog,
  } = props;
  return (
    <Dialog open={pictureDialog} onClose={togglePictureDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <div style={{ fontSize: 22 }}>
          Insérer une image
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p style={{ fontSize: 16 }}>
                  Déposer l'image ici ...
                </p>
              ) : (
                <p style={{ fontSize: 16 }}>
                  Déposer l'image ici ou selectionner la en cliquant ici
                </p>
              )}
            </div>
          </Grid>
          <Grid item md={12}>
            <TextField
              margin="dense"
              id="url"
              label="URL de l'image"
              inputProps={{ style: { fontSize: 16 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              onChange={event =>
                handlePictureChange("url", event.target.value)
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={togglePictureDialog} color="primary">
          Annuler
        </Button>
        <Button onClick={submitPicture} color="primary" disabled={pictureInfo?.url.length === 0}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogNewsPicture.propTypes = {
  isDragActive: PropTypes.bool,
  pictureDialog: PropTypes.bool,
  pictureInfo: PropTypes.object,
  style: PropTypes.object,
  getRootProps: PropTypes.func,
  getInputProps: PropTypes.func,
  handlePictureChange: PropTypes.func,
  submitPicture: PropTypes.func,
  togglePictureDialog: PropTypes.func,
};