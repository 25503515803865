import React, { memo } from "react";
import Card from "components/Card/Card.js";
import makeStyles from "@material-ui/core/styles/makeStyles";

const CardIcon = ({ Icon, title, text, primary, secondary }) => {
  const styles = {
    card: {
      display: "flex",
      backgroundColor: secondary || "#fff",
      borderRadius: "0.5rem",
      boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
      flexDirection: "row",
      marginBottom: "0",
      minWidth: "100px",
      maxWidth: "250px",
      height: "110px",
      overflow: "hidden"
    },
    leftSide: {
      backgroundColor: primary || "#3D406E",
      padding: "0rem 1rem 0rem 1rem",
      color: secondary || "#fff",
      width: "200px",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    rightSide: {
      backgroundColor: secondary || "#fff",
      height: "auto",
      padding: "1.25em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignSelf: "flex-end",
      // alignItems: 'center',
    },
    icon: {
      fontSize: "1rem",
      textTransform: "uppercase",
      letterSpacing: "0.1rem",
      marginBottom: "0.5rem"
    },
    title: {
      fontWeight: "normal",
      fontSize: "0.8rem",
      textTransform: "uppercase",
      letterSpacing: "0.08rem"
    },
    content: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      padding: "0 0.5rem",
      margin: "0.35rem 0"
    },
    text: {
      display: "ruby",
      fontWeight: "bold",
      fontSize: "1.25rem",
      textTransform: "uppercase",
      letterSpacing: "0.1rem",
      color: primary || "#3D406E"
    }
  };

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.leftSide}>
        <p className={classes.icon}>
          <Icon />
        </p>
        <div className={classes.title}>
          {title}
        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.content}>
          <p className={classes.text}>
            {text}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default memo(CardIcon);