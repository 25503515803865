/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  InputLabel,
  makeStyles,
  Checkbox,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function FormEvents(props) {
  const classes = useStyles();

  const {
    formModulesList,
    formValid,
    modalType,
    newEventInfo,
    refreshImage,
    handleFormChange,
    submitNewEvent,
    toggleModal,
  } = props;
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {modalType ? (
            <Typography variant="h5">Modifier l'événement</Typography>
          ) : (
            <Typography variant="h5">Ajouter un événement</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewEvent} noValidate>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Titre de l'événement *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newEventInfo.title}
                name="title"
                required
                fullWidth
                id="title"
                onChange={event =>
                  handleFormChange("title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Date de début *</InputLabel>
              <TextField
                type="date"
                defaultValue={newEventInfo.date_begin}
                name="start"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="start"
                onChange={event =>
                  handleFormChange("date_begin", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Date de fin *</InputLabel>
              <TextField
                type="date"
                defaultValue={newEventInfo.date_end}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="end"
                name="end"
                onChange={event =>
                  handleFormChange("date_end", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <InputLabel>Journée entière</InputLabel>
              <Switch
                checked={newEventInfo.is_all_day_long}
                onChange={e =>
                  handleFormChange("is_all_day_long", e.target.checked)
                }
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel>Heure de début *</InputLabel>
              <TextField
                type="time"
                disabled={newEventInfo.is_all_day_long}
                defaultValue={newEventInfo.time_begin}
                name="timeStart"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="timeStart"
                onChange={event =>
                  handleFormChange("time_begin", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel>Heure de fin *</InputLabel>
              <TextField
                type="time"
                disabled={newEventInfo.is_all_day_long}
                defaultValue={newEventInfo.time_end}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="timeEnd"
                name="timeEnd"
                onChange={event =>
                  handleFormChange("time_end", event.target.value)
                }
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Jours récurrents</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventInfo.days_recurrence[0] === "1"}
                    onChange={e =>
                      handleFormChange("recurrence", e.target.checked, 0)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Lundi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventInfo.days_recurrence[1] === "1"}
                    onChange={e =>
                      handleFormChange("recurrence", e.target.checked, 1)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Mardi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventInfo.days_recurrence[2] === "1"}
                    onChange={e =>
                      handleFormChange("recurrence", e.target.checked, 2)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Mercredi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventInfo.days_recurrence[3] === "1"}
                    onChange={e =>
                      handleFormChange("recurrence", e.target.checked, 3)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Jeudi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventInfo.days_recurrence[4] === "1"}
                    onChange={e =>
                      handleFormChange("recurrence", e.target.checked, 4)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Vendredi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventInfo.days_recurrence[5] === "1"}
                    onChange={e =>
                      handleFormChange("recurrence", e.target.checked, 5)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Samedi"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventInfo.days_recurrence[6] === "1"}
                    onChange={e =>
                      handleFormChange("recurrence", e.target.checked, 6)
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label="Dimanche"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Module(s) *</InputLabel>
              {formModulesList ? (
                <Select
                  value={newEventInfo.features}
                  id="select-type"
                  fullWidth
                  multiple
                  displayEmpty
                  variant="filled"
                  onChange={event =>
                    handleFormChange("features", event.target.value)
                  }
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return <em>Aucune séléction</em>;
                    }
                    return formModulesList
                      .filter(feature =>
                        selected.find(it => it === feature.uuid)
                      )
                      .map(feature => feature.custom_title)
                      .join(", ");
                  }}
                >
                  {formModulesList &&
                    formModulesList.map((it, i) => (
                      <MenuItem
                        key={i}
                        value={it.uuid}
                        classes={{ root: classes.listItemRoot }}
                      >
                        {it.custom_title}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
            <Grid item md={12}>
              <InputLabel>Description *</InputLabel>
              <TextField
                id="description"
                value={newEventInfo.description}
                onChange={e => handleFormChange("description", e.target.value)}
                multiline
                required
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Image</InputLabel>
              <div style={{ display: "flex" }}>
                {refreshImage &&
                  newEventInfo?.uuid &&
                  (refreshImage[newEventInfo.uuid] ? (
                    <CircularProgress />
                  ) : (
                    newEventInfo.has_picture && (
                      <img
                        style={{
                          marginTop: "1%",
                          height: "100px",
                          marginRight: "2%",
                        }}
                        src={`${
                          process.env.REACT_APP_CDN_URL
                        }/app/module/calendar/events/${
                          newEventInfo?.uuid
                        }/image?refresh=${Date.now()}`}
                        alt=""
                        onError={i => {
                          i.target.style.display = "none";
                          i.target.alt = "Aucune image disponible";
                        }}
                      />
                    )
                  ))}
                <input
                  style={{
                    marginTop: "1%",
                    display: "column",
                    alignSelf: "center",
                  }}
                  type="file"
                  name="icon"
                  accept="image/*"
                  onChange={e => handleFormChange("image", e.target.files[0])}
                />
              </div>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formValid}
          >
            Enregister
          </Button>
        </form>
      </div>
    </Container>
  );
}

FormEvents.propTypes = {
  formModulesList: PropTypes.array,
  formValid: PropTypes.bool,
  modalType: PropTypes.bool,
  newEventInfo: PropTypes.object,
  refreshImage: PropTypes.object,
  handleFormChange: PropTypes.func,
  submitNewEvent: PropTypes.func,
  toggleModal: PropTypes.func,
};
