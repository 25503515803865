import React, { memo } from "react";
import PropTypes       from "prop-types";
import Button          from "components/CustomButtons/Button.js";
import Oubabox         from "./Oubabox";
import { OubaboxIcon } from "assets/img/icons";
import { loadState   } from "helpers/localStorage";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper
} from "@material-ui/core";

const OubaboxList = ({
  oubaboxsList,
  sizesList,
  order,
  orderBy,
  refreshData,
  toggleModal,
  toggleDisplayLogs,
  toggleConfirmDestroy,
  handleUpdateBoxSize,
  getOubaboxStatusColor,
  onRequestSort,
  getComparator,
  stableSort,
  roles=loadState("myself")?.rights || {}
}) => (
  <div>
    <TableContainer
      component={Paper}
      style={{
        marginTop: "2%",
        width:     '100%'
      }}>
      <Table
        aria-label="collapsible table"
        style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
          {[
              { label: "",                                  },
              { label: "Status",       id: "isAvailable"    },
              { label: "Nom",          id: "label"          },
              { label: "Résidence",    id: "estateId"       },
              { label: "Etat",         id: "state"          },
              { label: "Dernier Etat", id: "stateUpdatedAt" },
              { label: "Modèle", id: "model" },
              { label: "Version", id: "version" },
              { label: "Incidents"                          },
              (roles.role_superadmin  || roles?.role_oubabox_provider || roles?.role_oubabox_maintainer) &&
              { label: "Actions" },
              (roles.role_superadmin  || roles?.role_oubabox_provider) &&
              { label: "Edition" }
            ].filter(_ => _).map((headCell, key) => (
              headCell.id ? (
                <TableCell
                  align="center"
                  key={key}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={    orderBy === headCell.id}
                    direction={ orderBy === headCell.id ? order : "asc"}
                    onClick={event => onRequestSort(event, headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span style={{
                        top:      20,
                        width:    1,
                        padding:  0,
                        border:   0,
                        height:   1,
                        margin:   -1,
                        clip:     "rect(0 0 0 0)",
                        overflow: "hidden",
                        position: "absolute"
                      }}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell
                  align="center"
                  key={key}
                >
                  {headCell.label}
                </TableCell>
              )
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {oubaboxsList?.length ? (
            stableSort(oubaboxsList, getComparator(order, orderBy))
            .map((data, key) => (
              <Oubabox
                key={key}
                sizesList={sizesList}
                toggleModal={toggleModal}
                toggleDisplayLogs={toggleDisplayLogs}
                toggleConfirmDestroy={toggleConfirmDestroy}
                handleUpdateBoxSize={handleUpdateBoxSize}
                getOubaboxStatusColor={getOubaboxStatusColor}
                data={({ ...data,
                  incidentNumber: data?.logs?.length
                })}
              />
            ))
          ) : (
            <div style={{
              display:        "flex",
              alignItems:     "center",
              justifyContent: "center"
            }}>
              <div>
                Aucune Oubabox rattaché à cette résidence
              </div>
            </div>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);

OubaboxList.propTypes = {
  order:                  PropTypes.string,
  orderBy:                PropTypes.string,
  sizesList:              PropTypes.array,
  oubaboxsList:           PropTypes.array,
  refreshData:            PropTypes.func,
  toggleModal:            PropTypes.func,
  toggleDisplayLogs:      PropTypes.func,
  toggleConfirmDestroy:   PropTypes.func,
  handleUpdateBoxSize:    PropTypes.func,
  getOubaboxStatusColor:  PropTypes.func,
  onRequestSort:          PropTypes.func,
  getComparator:          PropTypes.func,
  stableSort:             PropTypes.func
};

export default memo(OubaboxList);
