/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  InputLabel,
  makeStyles,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";

import Skeleton from "@material-ui/lab/Skeleton";
import InputColor from "react-input-color";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function FormFeatures(props) {
  const classes = useStyles();

  const {
    formAttributesList,
    formModulesList,
    formValid,
    modalType,
    newFeaturesInfo,
    handleFormChange,
    submitNewFeature,
    toggleModal,
  } = props;
  return (
    <Container component="main" maxWidth="md" style={{
      overflowY: "auto",
    }}>
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {modalType ? (
            <Typography variant="h5">Modifier le module</Typography>
          ) : (
            <Typography variant="h5">Ajouter un module</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModal()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form className={classes.form} onSubmit={submitNewFeature} noValidate>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Titre personalisé *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newFeaturesInfo.custom_title}
                name="custom_title"
                required
                fullWidth
                id="custom_title"
                onChange={event =>
                  handleFormChange("custom_title", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Titre privé *</InputLabel>
              <TextField
                defaultValue={newFeaturesInfo.internal_title}
                name="internal_title"
                required
                fullWidth
                id="internal_title"
                onChange={event =>
                  handleFormChange("internal_title", event.target.value)
                }
              />
            </Grid>
            <Grid item md={3}>
              <InputLabel>Couleur</InputLabel>
              <InputColor
                initialValue={newFeaturesInfo.color}
                onChange={color => handleFormChange("color", color.hex)}
                placement="right"
              />
            </Grid>
            <Grid item md={9}>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Type de module *</InputLabel>
              {formModulesList ? (
                <Select
                  value={newFeaturesInfo.module}
                  style={{ top: "16px" }}
                  id="select-type"
                  fullWidth
                  displayEmpty
                  onChange={event =>
                    handleFormChange("module", event.target.value)
                  }
                >
                  <MenuItem value={""}>
                    <em>Aucune séléction</em>
                  </MenuItem>
                  {formModulesList &&
                    formModulesList.map((it, i) => (
                      <MenuItem key={i} value={it.identifier}>
                        <div>{it.title}</div>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Attribut(s)</InputLabel>
              {formAttributesList ? (
                <Select
                  value={newFeaturesInfo.attributes}
                  style={{ top: "16px" }}
                  id="select-type"
                  fullWidth
                  multiple
                  displayEmpty
                  onChange={event =>
                    handleFormChange("attributes", event.target.value)
                  }
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return <em>Aucune séléction</em>;
                    }
                    return formAttributesList
                      .filter(attribute => selected.find(it => it === attribute.uuid))
                      .map(attribute => attribute.name)
                      .join(", ");
                  }}
                >
                  {formAttributesList &&
                    formAttributesList.map((it, i) => (
                      <MenuItem key={i} value={it.uuid}>
                        <div>{it.name}</div>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formValid}
          >
            Enregister
          </Button>
        </form >
      </div >
    </Container >
  );
}

FormFeatures.propTypes = {
  formAttributesList: PropTypes.array,
  formModulesList: PropTypes.array,
  formValid: PropTypes.bool,
  modalType: PropTypes.bool,
  newFeaturesInfo: PropTypes.object,
  handleFormChange: PropTypes.func,
  submitNewFeature: PropTypes.func,
  toggleModal: PropTypes.func,
};
