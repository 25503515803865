import React, {useEffect} from "react";
// Other front packages
import Tabs from "components/CustomTabs/CustomTabs.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";

import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import RoomIcon from "@material-ui/icons/Room";
import StorageIcon from "@material-ui/icons/Storage";
import DateRangeIcon from "@material-ui/icons/DateRange";
import BookIcon from "@material-ui/icons/Book";
import DialpadIcon from "@material-ui/icons/Dialpad";

import TwoNListView from "./TabViews/TwoNListView";
import A1001ListView from "./TabViews/A1001ListView";
import A1001DoorListView from "./TabViews/A1001DoorListView";
import CleaningListView from "./TabViews/CleaningListView";
import LocationListView from "./TabViews/LocationListView";
import ResourceListView from "./TabViews/ResourceListView";

import moment from "moment";
import localization from "moment/locale/fr";
moment.updateLocale("fr", localization);

export default function BookingsSettings(props) {

    return (
        <div>
            <GridContainer>
                <Tabs
                    tabs={[
                        {
                            // tabWidth: 'auto',
                            tabName: "Ressources",
                            tabIcon: BookIcon,
                            tabContent: <ResourceListView />,
                        },
                        {
                            // tabWidth: 'auto',
                            tabName: "Emplacements",
                            tabIcon: RoomIcon,
                            tabContent: <LocationListView />,
                        },
                        {
                            // tabWidth: 150,
                            tabName: "Ménage",
                            tabIcon: DateRangeIcon,
                            tabContent: <CleaningListView />,
                        },
                        {
                            // tabWidth: 120,
                            tabName: "A1001",
                            tabIcon: StorageIcon,
                            tabContent: <A1001ListView />,
                        },
                        {
                            // tabWidth: 140,
                            tabName: "Portes A1001",
                            tabIcon: MeetingRoomIcon,
                            tabContent: <A1001DoorListView />,
                        },
                        {
                            // tabWidth: 140,
                            tabName: "2N",
                            tabIcon: DialpadIcon,
                            tabContent: <TwoNListView />,
                        },
                    ]}
                />
            </GridContainer>
        </div>
    );
}
