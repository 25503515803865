import React, { /*useEffect*/ } from "react";
import PropTypes from "prop-types";

import {
  CssBaseline,
  Container,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/views/eventStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(styles);

export default function Form2ns(props) {
  const classes = useStyles();

  const {
    formTwoNValid,
    modalTwoNType,
    formLocationList,
    newTwoNInfo,
    handle2nFormChange,
    submitNew2n,
    toggleModal2n,
  } = props;
  return (
    <Container component="main" maxWidth="md" style={{
      overflowY: 'scroll',
    }}>
      <CssBaseline />
      <div>
        <div className={classes.headerModal}>
          {modalTwoNType ? (
            <Typography variant="h5">Modifier le 2N</Typography>
          ) : (
            <Typography variant="h5">Ajouter un 2N</Typography>
          )}
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => toggleModal2n()}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <form
          className={classes.form}
          onSubmit={submitNew2n}
          noValidate
        >
          <Grid container spacing={3}>
            <Grid item md={12}>
              <InputLabel>Label du 2N *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newTwoNInfo.label}
                name="label"
                required
                fullWidth
                id="label"
                onChange={event =>
                  handle2nFormChange("label", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={4}>
              <InputLabel>IP / Domaine *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newTwoNInfo.host}
                name="host"
                required
                fullWidth
                id="host"
                variant="outlined"
                margin="normal"
                onChange={event =>
                  handle2nFormChange("host", event.target.value)
                }
              />
            </Grid>
            <Grid item md={2}>
              <InputLabel>Port *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newTwoNInfo.port}
                name="port"
                required
                fullWidth
                type="number"
                variant="outlined"
                margin="normal"
                InputProps={{
                  inputProps: { min: 0 }
                }}
                id="port"
                onChange={event =>
                  handle2nFormChange("port", event.target.value)
                }
              />
            </Grid>
            <Grid item md={6}>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Emplacement *</InputLabel>
              {formLocationList ? (
                <Select
                  value={newTwoNInfo.location}
                  style={{ top: "10px" }}
                  id="select-type"
                  fullWidth
                  displayEmpty
                  onChange={event =>
                    handle2nFormChange("location", event.target.value)
                  }
                >
                  <MenuItem value={""}>
                    <em>Aucune séléction</em>
                  </MenuItem>
                  {formLocationList &&
                    formLocationList.map((it, i) => (
                      <MenuItem key={i} value={it.uuid}>
                        <div>{it.title}</div>
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <Typography variant="h3">
                  <Skeleton width="auto" />
                </Typography>
              )}
            </Grid>
            <Grid item md={12}>
              <InputLabel>Utilisateur *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newTwoNInfo.username}
                name="username"
                required
                fullWidth
                id="username"
                onChange={event =>
                  handle2nFormChange("username", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>Mot de passe *</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newTwoNInfo.password}
                name="password"
                required
                fullWidth
                id="password"
                type={newTwoNInfo.showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handle2nFormChange('showPassword', null)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {newTwoNInfo.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }}
                onChange={event =>
                  handle2nFormChange("password", event.target.value)
                }
                autoFocus
              />
            </Grid>
            <Grid item md={12}>
              <InputLabel>UUID de l'utilisateur publique</InputLabel>
              <TextField
                autoComplete="fname"
                defaultValue={newTwoNInfo.free_user_uuid}
                name="free_user_uuid"
                required
                fullWidth
                id="free_user_uuid"
                onChange={event =>
                  handle2nFormChange("free_user_uuid", event.target.value)
                }
                autoFocus
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!formTwoNValid}
          >
            Enregister
          </Button>
        </form>
      </div>
    </Container>
  )
}

Form2ns.propTypes = {
  formTwoNValid: PropTypes.bool,
  formLocationList: PropTypes.array,
  modalTwoNType: PropTypes.bool,
  newTwoNInfo: PropTypes.object,
  handle2nFormChange: PropTypes.func,
  submitNew2n: PropTypes.func,
  toggleModal2n: PropTypes.func,
};
