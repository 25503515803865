import React, { useEffect } from "react";
import AlertsLogic from "./AlertsLogic";

import {
  CircularProgress,
  Modal,
  makeStyles,
  Button as MUIButton,
  Switch,
  Backdrop,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-react/views/newsStyle.js";

import Pagination from "@material-ui/lab/Pagination";

// core components
import Table from "components/Table/Table.js";
import Tabs from "components/CustomTabs/CustomTabs.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal";
import FormAlerts from "components/FormUsers/FormAlerts";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const useStyles = makeStyles(styles);

export default function Alerts(props) {
  const classes = useStyles();
  const paginationClasses = paginationStyles();

  const {
    alertDestroy,
    alertsCount,
    alertsList,
    editModalOpened,
    formValid,
    limitAlerts,
    myself,
    newAlertsInfo,
    notifColor,
    notifMessage,
    modalType,
    openNotif,
    page,
    deleteAlert,
    handleChangeEnabled,
    handleFormChange,
    handlePagination,
    refreshData,
    setOpenNotif,
    submitNewAlert,
    toggleConfirmAlertDestroy,
    toggleModal,
  } = AlertsLogic();

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  if (alertsList) {
    return (
      <div>
        <Snackbar
          place='tr'
          message={notifMessage}
          open={openNotif}
          close
          icon={notifColor === 'success' ? CheckCircleIcon : (notifColor === 'danger' ? WarningIcon : NotificationsIcon)}
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {alertDestroy && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleConfirmAlertDestroy(null)}
            open={alertDestroy ? true : false}
          >
            <div className={classes.paper}>
              <ConfirmModal
                closeModal={() => toggleConfirmAlertDestroy(null)}
                kind={`Supprimer l'alerte "${alertDestroy.title}" ?`}
                makeAction={() => deleteAlert(alertDestroy.uuid)}
              />
            </div>
          </Modal>
        )}
        {editModalOpened && newAlertsInfo && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={classes.modal}
            onClose={() => toggleModal()}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <FormAlerts
                formValid={formValid}
                modalType={modalType}
                newAlertsInfo={newAlertsInfo}
                handleFormChange={handleFormChange}
                submitNewAlert={submitNewAlert}
                toggleModal={toggleModal}
              />
            </div>
          </Modal>
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: "Bannières d'info",
                tabIcon: AssignmentIcon,
                tabContent: (
                  <GridItem xs={12} sm={12} md={12}>
                    {(myself?.rights?.role_superadmin ||
                      myself?.rights?.role_alerts_add) && (
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <MUIButton
                                className={classes.button}
                                onClick={() => toggleModal()}
                                startIcon={<AddIcon />}
                              >
                                Ajouter une alerte
                              </MUIButton>
                            </GridItem>
                          </GridContainer>
                        </CardBody>)
                    }
                    {!myself?.rights?.role_superadmin &&
                      !myself?.rights?.role_alerts_read ? (
                      <GridItem xs={12} sm={12} md={6}>
                        <h4>
                          {
                            "Vous n'avez pas la permission de voir les alertes"
                          }
                        </h4>
                      </GridItem>
                    ) : (
                      <div>
                        {alertsList && alertsList.length === 0 ? (
                          <h4>Aucune alerte à afficher</h4>
                        ) :
                          (<Table
                            tableHeaderColor="primary"
                            tableType="alerts"
                            tableHead={
                              [
                                "Titre",
                                "Type",
                                "Contenu",
                                "Date"
                              ].concat(((myself?.rights?.role_superadmin ||
                                myself?.rights?.role_alerts_change_status) ?
                                ["Désactiver / Activer", ""] : [""]))
                            }
                            tableData={
                              alertsList &&
                              alertsList.map(alert => {
                                let alertList = {
                                  title: alert?.title,
                                  type: alert?.type,
                                  desc: alert?.content,
                                  date: alert?.date
                                }
                                if (myself?.rights?.role_superadmin ||
                                  myself?.rights?.role_alerts_change_status)
                                  alertList.active = (
                                    <Switch
                                      checked={alert.enabled}
                                      onChange={e => handleChangeEnabled(e.target.checked, alert)}
                                      color="primary"
                                      inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />)
                                alertList.actions = (
                                  <div style={{
                                    display: "flex",
                                  }}>
                                    {(myself?.rights?.role_superadmin ||
                                      myself?.rights?.role_alerts_edit) && (<Button
                                        style={{ marginLeft: 'auto', }}
                                        size="sm"
                                        onClick={() => toggleModal(alert)}
                                        color="warning"
                                      >
                                        Éditer
                                      </Button>)
                                    }
                                    {(myself?.rights?.role_superadmin ||
                                      myself?.rights?.role_alerts_delete) && (<Button
                                        size="sm"
                                        style={{ marginLeft: '2%' }}
                                        onClick={() => toggleConfirmAlertDestroy(alert)}
                                        color="danger"
                                      >
                                        Supprimer
                                      </Button>)
                                    }
                                  </div>
                                )
                                return Object.values(alertList);
                              })
                            }
                          />)
                        }
                        {alertsCount > 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: 100,
                            }}
                          >
                            <Pagination
                              count={Math.ceil(alertsCount / limitAlerts)}
                              page={page}
                              onChange={(event, pageNumber) => {
                                handlePagination(pageNumber);
                              }}
                              classes={{ ul: paginationClasses.ul }}
                              size="large"
                            />
                          </div>
                        )}
                      </div>)
                    }
                  </GridItem>
                )
              }
            ]}
          />
        </GridContainer>
      </div>
    )
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}