import { paginationConstants } from "../constants";

export function paginateUsers(state = {}, { type, page }) {
  switch (type) {
    case paginationConstants.GET_PAGE_USERS:
      return {
        page,
      };
    case paginationConstants.SET_PAGE_USERS:
      return {
        page,
      };
    default:
      return state;
  }
}

export function paginateBookings(state = {}, { type, page }) {
  switch (type) {
    case paginationConstants.GET_PAGE_BOOKINGS:
      return {
        page,
      };
    case paginationConstants.SET_PAGE_BOOKINGS:
      return {
        page,
      };
    default:
      return state;
  }
}
