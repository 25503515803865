import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  CssBaseline,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-react/views/userStyle.js";

// core components
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

const ConfirmModal = ({
  closeModal,
  kind,
  message,
  makeAction
}) => {
  const classes = useStyles();

  return (
    <div>
      <Container component="main" style={{ maxWidth: "100%" }}>
        <CssBaseline />
        <div>
          <div className={classes.headerModal}>
            <Typography style={{ maxWidth: "100%", textAlign: "center" }} variant="h5">
              {kind}
            </Typography>
            <IconButton
              size="small"
              aria-label="delete"
              onClick={closeModal}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            {message}
          </div>
          <div style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-evenly"
          }}>
            <Button
              type="submit"
              variant="contained"
              color="danger"
              className={classes.submit}
              onClick={makeAction}
            >
              Valider
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={closeModal}
            >
              Annuler
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

ConfirmModal.defaultProps = {
  tableHeaderColor: "gray",
};

ConfirmModal.propTypes = {
  closeModal: PropTypes.func,
  kind: PropTypes.string,
  message: PropTypes.string,
  makeAction: PropTypes.func
};


export default ConfirmModal;