import React, { useEffect, useMemo } from "react";
import UsersLogic from "./UsersLogic";

import {
  Backdrop,
  CircularProgress,
  Button as MUIButton,
  TextField,
  Tooltip,
  Box,
  Typography,
  makeStyles,
  Modal,
  Switch,
} from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import styles from "assets/jss/material-dashboard-react/views/userStyle.js";

// core components
import Tabs from "components/CustomTabs/CustomTabs.js";
import CollapsingTable from "components/Table/TableCollapse";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import Subscriptions from "./Subscriptions";
import CustomDialog from "components/CustomDialog/CustomDialog";
import FormUsers from "components/FormUsers/FormUsers";

import WarningIcon from "@material-ui/icons/Warning";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// SVG
import { ReactComponent as AddIcon } from "assets/img/svg/AddIcon.svg";

const textStyles = makeStyles({
  formControlRoot: {
    fontFamily: "futura",
    color: "#6E6FA9",
    borderRadius: "7px",
    "& label.Mui-focused": {
      color: "#6E6FA9",
    },
  },
  inputLabelRoot: {
    color: "#6E6FA9",
    fontFamily: "futura",
    "&.focused": {
      color: "#6E6FA9",
    },
  },
});

const paginationStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#6E6FA9",
    },
    "& .Mui-selected": {
      backgroundColor: "#6E6FA9",
      color: "#E7E6ED",
    },
  },
}));

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fbfbfb",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const useStyles = makeStyles(styles);

export default function Users({ refresh, rgpd }) {
  const classes = useStyles();
  const textClasses = textStyles();
  const paginationClasses = paginationStyles();

  const {
    createValidation,
    currentUser,
    dialogSelected,
    drawer,
    errorFileData,
    editModalOpened,
    errorUpload,
    estateSelected,
    file,
    fileData,
    fileTemplate,
    fileUpload,
    group,
    groups,
    groupFilter,
    isDragAccept,
    isDragActive,
    isDragReject,
    loading,
    query,
    modalType,
    myself,
    notifColor,
    notifMessage,
    openDialog,
    openNotif,
    page,
    pendingUserNumber,
    progress,
    sector,
    sectors,
    sectorFilter,
    sectorGroups,
    reject,
    updateValidation,
    uploadUserStart,
    usersList,
    usersMeta,
    userSelected,
    userState,
    userTitle,
    validationModal,
    downloadProofOfResidence,
    getInputProps,
    getRootProps,
    handleAttach,
    handleClearSearch,
    handleClickOpen,
    handleDetach,
    handlePagination,
    handleRemoveFromResidence,
    handleUpdateOccupationStatus,
    handleUser,
    handleUserAssociation,
    handleUserEdition,
    handleUserFiltering,
    handleUserState,
    handleReason,
    handleReject,
    readFile,
    refreshData,
    setErrorFileData,
    setOpenNotif,
    sendNewPassword,
    searchFilterHandling,
    submitCreateHandle,
    submitUpdateHandler,
    toggleDialog,
    toggleDrawer,
    toggleGroup,
    toggleQuery,
    toggleModal,
    toggleModalUserType,
    toggleSector,
    toggleValidationModal,
    uploadUser,
    validate,
  } = UsersLogic(rgpd);

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  if (usersList && sectors && groups && page) {
    return (
      <div>
        <Snackbar
          place="tr"
          message={notifMessage}
          open={openNotif}
          close
          icon={
            notifColor === "success"
              ? CheckCircleIcon
              : notifColor === "danger"
                ? WarningIcon
                : NotificationsIcon
          }
          closeNotification={() => setOpenNotif(false)}
          color={notifColor}
        />
        {editModalOpened && (
          <Modal
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            className={classes.modal}
            onClose={() => toggleModal(false)}
            open={editModalOpened}
          >
            <div className={classes.paper}>
              <FormUsers
                editModalOpened={editModalOpened}
                userSelected={userSelected}
                toggleModal={toggleModal}
                modalType={modalType}
                loading={loading}
                submitCreateHandle={submitCreateHandle}
                submitUpdateHandler={submitUpdateHandler}
                userTitle={userTitle}
                handleUserEdition={handleUserEdition}
                toggleSector={toggleSector}
                toggleGroup={toggleGroup}
                handleUserAssociation={handleUserAssociation}
                sectors={sectors}
                sector={sector}
                sectorGroups={sectorGroups}
                group={group}
                userState={userState}
                updateValidation={updateValidation}
                createValidation={createValidation}
                rgpd={rgpd}
              />
            </div>
          </Modal>
        )}
        {openDialog && userSelected && dialogSelected && (
          <CustomDialog
            userSelected={userSelected}
            openDialog={openDialog}
            dialogSelected={dialogSelected}
            toggleDialog={toggleDialog}
            toggleSector={toggleSector}
            toggleGroup={toggleGroup}
            handleAttach={handleAttach}
            handleDetach={handleDetach}
            handleUserAssociation={handleUserAssociation}
            handleRemoveFromResidence={handleRemoveFromResidence}
            sectors={sectors}
            sector={sector}
            sectorGroups={sectorGroups}
            groups={groups}
            group={group}
            userState={userState}
          />
        )}
        <GridContainer>
          <Tabs
            tabs={[
              {
                tabName: `Gérer les utilisateurs`,
                tabIcon: VerifiedUserIcon,
                tabOnClick: () => handleUserState("accepted"),
                tabContent: (
                  <Card style={{
                    boxShadow: "none",
                    marginTop: 0,
                    paddingTop: 0,
                  }}>
                    {(myself?.rights?.role_superadmin ||
                      myself?.rights.role_users) && (
                        <CardHeader style={{
                          borderRadius: 1,
                          paddingTop: 0,
                          border: "none",
                          boxShadow:
                            "inset 0 0 0 rgba(0,0,0,0), 0.65em 0 0 2px rgb(255,255,255), 0.5em 0.5em 0.6em rgba(130,130,130,0.4)",
                        }}>
                          {!rgpd && (
                            <div style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}>
                              <h4 className={classes.filterUsers}>
                                Filtrer par utilisateur :
                              </h4>
                              <div style={{
                                display: "flex",
                                alignSelf: "center",
                                marginLeft: 30,
                              }}>
                                <b style={{
                                  alignSelf: "center",
                                  fontFamily: "Roboto",
                                  color: "#6E6FA9",
                                }}>
                                  Activé
                                </b>
                                <Switch
                                  disableRipple
                                  color="primary"
                                  checked={userState === "accepted" ? false : true}
                                  onClick={() =>
                                    userState === "accepted"
                                      ? handleUserState("disabled")
                                      : handleUserState("accepted")
                                  }
                                  name="checkedA"
                                  inputProps={{
                                    "aria-label": "secondary checkbox",
                                  }}
                                />
                                <b style={{
                                  alignSelf: "center",
                                  fontFamily: "Roboto",
                                  color: "#6E6FA9",
                                }}>
                                  Désactivé
                                </b>
                              </div>
                            </div>
                          )}
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                              <TextField
                                fullWidth
                                variant="standard"
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                                label="Rechercher par mot clé"
                                onChange={ev => {
                                  ev.preventDefault();
                                  searchFilterHandling();
                                }}
                                inputProps={{
                                  // placeholder: "Rechercher par mot clé",
                                  className: classes.input,
                                  onChange: event => {
                                    toggleQuery(event.target.value);
                                  },
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: textClasses.inputLabelRoot,
                                  },
                                }}
                                value={query}
                              />
                            </GridItem>
                            {!rgpd && (
                              <GridItem xs={12} sm={12} md={3}>
                                <Autocomplete
                                  multiple
                                  id="tags-groups"
                                  options={groups}
                                  getOptionLabel={option => option.title}
                                  onChange={(_, value) => {
                                    handleUserFiltering("group", value);
                                    searchFilterHandling();
                                  }}
                                  value={groupFilter}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      classes={{
                                        root: textClasses.formControlRoot,
                                      }}
                                      label="Filtrer par groupe"
                                      placeholder="Groupes sélectionnés"
                                      InputProps={{
                                        ...params.InputProps,
                                        className: classes.input,
                                      }}
                                      InputLabelProps={{
                                        classes: {
                                          root: textClasses.inputLabelRoot,
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </GridItem>
                            )}
                            <GridItem xs={12} sm={12} md={3}>
                              <Autocomplete
                                multiple
                                id="tags-sectors"
                                options={sectors}
                                getOptionLabel={option => option.name}
                                onChange={(_, value) => {
                                  handleUserFiltering("sector", value);
                                  searchFilterHandling();
                                }}
                                value={sectorFilter}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    classes={{ root: textClasses.formControlRoot }}
                                    label="Filtrer par bâtiments"
                                    placeholder="Bâtiments sélectionnés"
                                    InputProps={{
                                      ...params.InputProps,
                                      className: classes.input,
                                    }}
                                    InputLabelProps={{
                                      classes: {
                                        root: textClasses.inputLabelRoot,
                                      }
                                    }}
                                  />
                                )}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                              <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                              }}>
                                <Button
                                  onClick={event => {
                                    handleClearSearch(event);
                                    searchFilterHandling(event);
                                  }}
                                  color="danger"
                                  style={{
                                    fontFamily: "futura",
                                    fontSize: 10,
                                    width: 110,
                                    borderRadius: 10,
                                }}>
                                  Effacer
                                </Button>
                                <Button
                                  onClick={event => searchFilterHandling(event)}
                                  color="success"
                                  style={{
                                    fontFamily: "futura",
                                    fontSize: 10,
                                    width: 110,
                                    borderRadius: 10,
                                }}>
                                  Rechercher
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                        </CardHeader>
                      )}
                    <GridItem xs={12} sm={12} md={12}>
                      <CardBody>
                        <GridContainer>
                          {(myself?.rights?.role_superadmin ||
                            myself?.rights.role_users_add) && (
                              <GridItem xs={12} sm={12} md={8}>
                                <MUIButton
                                  className={classes.button}
                                  startIcon={<AddIcon />}
                                  onClick={() => {
                                    handleUserEdition("null");
                                    toggleModalUserType();
                                }}>
                                  Ajouter un utilisateur
                                </MUIButton>
                              </GridItem>
                            )}
                        </GridContainer>
                        {usersList &&
                          usersList?.length >= 1 &&
                          !myself?.rights?.role_superadmin &&
                          !myself?.rights?.role_users_read ? (
                          <GridItem xs={12} sm={12} md={6}>
                            <h4>
                              {
                                "Vous n'avez pas la permission de voir les utilisateurs activés"
                              }
                            </h4>
                          </GridItem>
                        ) : (
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <CollapsingTable
                                usersList={usersList}
                                drawer={drawer}
                                sectors={sectors}
                                toggleDrawer={toggleDrawer}
                                currentUser={currentUser}
                                downloadProofOfResidence={downloadProofOfResidence}
                                handleClickOpen={handleClickOpen}
                                sendNewPassword={sendNewPassword}
                                toggleModalUserType={toggleModalUserType}
                                handleUpdateOccupationStatus={handleUpdateOccupationStatus}
                                myself={myself}
                                userState={userState}
                                rgpd={rgpd}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              {usersList &&
                                usersList.length > 0 &&
                                (myself?.rights?.role_superadmin ||
                                  myself?.rights.role_users_read) && (
                                  <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: 100,
                                  }}>
                                    <Pagination
                                      count={usersMeta?.pageCount}
                                      page={page}
                                      onChange={(_, pageNumber) => handlePagination(pageNumber)}
                                      classes={{ ul: paginationClasses.ul }}
                                      size="large"
                                    />
                                  </div>
                                )}
                            </GridItem>
                            {usersList.length < 1 && (
                              <GridItem xs={12} sm={12} md={6}>
                                <h4>Aucun utilisateur</h4>
                              </GridItem>
                            )}
                          </GridContainer>
                        )}
                      </CardBody>
                    </GridItem>
                  </Card>
                ),
              }, !rgpd && {
                isBadge: true,
                amount: pendingUserNumber,
                tabName: "Demandes d'inscriptions",
                tabIcon: PersonAddIcon,
                tabOnClick: () => handleUserState("pending"),
                tabContent: (
                  <Subscriptions
                    estateSelected={estateSelected}
                    toggleValidationModal={toggleValidationModal}
                    validationModal={validationModal}
                    userSelected={userSelected}
                    handleUser={handleUser}
                    usersList={usersList}
                    handleUserState={handleUserState}
                    validate={validate}
                    reject={reject}
                    userState={userState}
                    downloadProofOfResidence={downloadProofOfResidence}
                    handleUserFiltering={handleUserFiltering}
                    searchFilterHandling={searchFilterHandling}
                    handleClearSearch={handleClearSearch}
                    toggleQuery={toggleQuery}
                    query={query}
                    page={page}
                    usersMeta={usersMeta}
                    groups={groups}
                    groupFilter={groupFilter}
                    sectors={sectors}
                    sectorFilter={sectorFilter}
                    handleReason={handleReason}
                    handleReject={handleReject}
                    handlePagination={handlePagination}
                    handleUpdateOccupationStatus={handleUpdateOccupationStatus}
                  />
                ),
              },
            ].filter(_ => _).concat(
              myself?.rights?.role_superadmin || myself?.rights?.role_users_add
                ? {
                  tabName: "Importer des utilisateurs",
                  tabIcon: GroupAddIcon,
                  tabContent: (
                    <div style={{ marginTop: "20px" }}>
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p style={{ fontSize: 16 }}>
                            Déposer le fichier ici ... (format .xsl, .xlsx,
                            .ods, .csv)
                          </p>
                        ) : (
                          <p style={{ fontSize: 16 }}>
                            Déposer le fichier ici ou selectionner le en
                            cliquant ici (format .xls, .xlsx, .ods, .csv)
                          </p>
                        )}
                      </div>
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "1.5%",
                            fontSize: 18,
                            marginBottom: "15px",
                          }}
                        >
                          {file?.name}
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            onClick={readFile}
                            disabled={!file}
                            color="primary"
                          >
                            Lire le fichier
                          </Button>
                        </GridItem>
                        <Card
                          style={{
                            boxShadow: "none",
                            marginTop: 0,
                            paddingTop: 0,
                          }}
                        >
                          <CardBody>
                            {fileUpload && (
                              <Table
                                tableHeaderColor="primary"
                                tableType="user"
                                tableHead={["Ligne"].concat(
                                  fileTemplate
                                    .map(
                                      it =>
                                        it.title + (it.allowNull ? "" : " *")
                                    )
                                    .concat(["Statut"])
                                )}
                                tableData={
                                  fileData &&
                                  fileData.map(line => {
                                    const lineList = { ligne: line.line };
                                    fileTemplate.forEach(
                                      it =>
                                      (lineList[it.title] =
                                        line.data[it.key])
                                    );
                                    const missingData =
                                      fileTemplate
                                        .map(
                                          it =>
                                            line.data[it.key] === null &&
                                            !it.allowNull
                                        )
                                        .reduce((a, b) => a + b, 0) > 0;
                                    if (missingData && !errorFileData) {
                                      setErrorFileData(true);
                                    }
                                    lineList.status = missingData ? (
                                      <Tooltip title="Un ou plusieurs champs sont manquant">
                                        <WarningIcon
                                          style={{ color: "#f0ad4e" }}
                                        />
                                      </Tooltip>
                                    ) : (
                                      <CheckCircleIcon
                                        style={{ color: "#5cb85c" }}
                                      />
                                    );
                                    return Object.values(lineList);
                                  })
                                }
                              />
                            )}
                          </CardBody>
                          {fileUpload && (
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "2%",
                                marginBottom: "2%",
                              }}
                            >
                              <Button
                                onClick={() => uploadUser(rgpd)}
                                disabled={errorFileData}
                                color="primary"
                              >
                                Ajouter les utilisateurs
                              </Button>
                            </GridItem>
                          )}
                          {uploadUserStart && (
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "1%",
                              }}
                            >
                              <Box position="relative" display="inline-flex">
                                <CircularProgress
                                  variant="determinate"
                                  value={progress}
                                />
                                <Box
                                  top={0}
                                  left={0}
                                  bottom={0}
                                  right={0}
                                  position="absolute"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="textSecondary"
                                  >{`${Math.round(progress)}%`}</Typography>
                                </Box>
                              </Box>
                            </GridItem>
                          )}
                          {errorUpload &&
                            errorUpload.map((error, i) => (
                              <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                key={i}
                              >
                                <WarningIcon style={{ color: "#f0ad4e" }} />
                                <div style={{ marginLeft: "10px" }}>
                                  {error}
                                </div>
                              </GridItem>
                            ))}
                        </Card>
                      </GridContainer>
                    </div>
                  ),
                }
                : []
            )}
          />
        </GridContainer>
      </div>
    );
  } else {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
}
